import React from "react";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import styles from '../../assets/jss/material-dashboard-react/components/modalStyle'

const useStyles = makeStyles(styles);


const Modals = (props) => {

    const classes=useStyles()
    const { children, ...rest } = props;
  return (
    <Modal 
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modalBox}
    onClose={()=>{
      props.setModal(false)
    props.setAssetIdBrowse(false)
  }}
    open={props.open}
    BackdropComponent={Backdrop}
    
    BackdropProps={{
      timeout: 500,
    }}>
      {children}
    </Modal>
  );
};
Modals.propTypes = {
    children: PropTypes.node,
  };

  export default Modals