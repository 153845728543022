import axios from "axios";
//importing api from the config file
import { homeApis } from "../../../config";

//Api calls
//Get all blackout for all the Channles
export const getAllBlackoutForAllTheChannlesApiCall = async (p_id) => {
  const options = {
    method: "GET",
    url: `${homeApis.getAllBlackout}`,
    params: { p_id: p_id },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const allBlackoutsresponse = await axios.request(options);
  return allBlackoutsresponse;
};

//Get all the Blackout Schedule Api call
export const getAllTheBlackoutScheduleApiCall = () => {};

//get all the Linear channel api call
export const getAllLinearChannelApiCall = async (p_id) => {
  const options = {
    method: "GET",
    url: `${homeApis.getAllLinearChannel}`,
    params: { p_id: p_id },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const allBlackoutsresponse = await axios.request(options);

  return allBlackoutsresponse;
};

//get all the epg api call
export const getAllEpgApiCall = async (channelPid) => {
  const data = channelPid.linearChannel;
  const options = {
    method: "POST",
    url: homeApis.getEPG,
    params: { p_id: channelPid.p_id },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  };

  const response = await axios.request(options);
  return response;
};

//schedule blackout api call
export const scheduleBlackoutApiCall = async (scheduleBlackoutDetails) => {
  const data = scheduleBlackoutDetails.scheduleDetails;
  const options = {
    method: "POST",
    url: homeApis.scheduleBlackout,
    params: { p_id: scheduleBlackoutDetails?.p_id },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  };

  const response = await axios.request(options);
};

//Deleting the Blackout api call
export const deleteBlackoutApiCall = async (paramsPidBlackoutDetails) => {
  const options = {
    method: "DELETE",
    url: homeApis.deleteBlackout,
    params: {
      p_id: paramsPidBlackoutDetails.p_id,
      blackoutId: paramsPidBlackoutDetails.blackoutDetails?._id,
    },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const response = await axios.request(options);

  return response;
};
