import { Box, Dialog, makeStyles, rgbToHex, Typography } from "@material-ui/core";
import React from "react";

import Device from 'assets/img/Device.png';
import Iphone from 'assets/img/Iphone_xs.png';

import style from "assets/jss/material-dashboard-react/views/screenStyle";
import GridScreenView from "./GridScreenView";
import DeviceScreenView from "./DeviceScreenView";
const useStyle = makeStyles(style);


const DevicePreview = (props)=>{

    const classes = useStyle();

    const handleClose = ()=>{
        props.setOpen(false)
    }

    console.log('widget preview');
     
    return (
        <Box>
            <Dialog open={props.open} onClose={handleClose}
             PaperProps={{
                       variant:'outlined',
                       elevation:16,
                        style:{
                          left:'31px', 
                          position: "relative",
                         background: "none",
                         backgroundColor: 'none',
                         "backdrop-filter": "blur(16px)",
                         borderColor:"rgb(0,0,0,0)",
                         objectFit:'contain',
                         overflow: "clip",
                         "&::-webkit-scrollbar": {
                            display: "none",
                          },
                         "-ms-overflow-style": "none",
                         "scrollbar-width": "none",
                    }
                  }}
                         >
               <img src={props.platform === 'Android Mobile App' ? Device : Iphone} className={classes.deviceImg} ></img>
               <Box className={classes.view}>
               {
                  props?.widget?.some(widget=>{
                    if(typeof widget.containerId  ==="string"){
                        return true
                    }
                })?
                  props?.widget?.filter((x)=>{
                    if(x?.item?.playlist?.type !== "K_PDF" && x?.item?.playlist?.type !== "ATHENA"&&x.item?.playlist?.data?.result?.totalCount){
                       if(x?.item?.playlist?.data?.result?.error?.code !== "4018"){
                          return x;
                       }
                    }
                  }).map((widget,i)=>
                    <DeviceScreenView widget={widget}/>
                  ):<></>
              }
               </Box>
            </Dialog>
        </Box>    
    )
}

export default DevicePreview;