export const ProjectInitialState = {
    loading:false,
    allProjects:[],
    createdData:{},
    projectDetails:{
        name:'',
        icon:'',
        p_id:'',
        appConfig:'',
    },
    allLanguage:{},
    createConfigData:{},
    updatedData:{},
    projectReset:false,
    kalturaConfig:{},
    createReset:false,
    updateReset:false,
    platformReset:false,
    viewJson:{},
    packages:[],
}