export const GET_CATEGORY_TREE='GET_CATEGORY_TREE'
export const GET_CATEGORY_ASSET_LIST='GET_CATEGORY_ASSETS'
export const GET_CATEGORY_PLAYLIST='GET_CATEGORY_PLAYLIST_BY_ID'
export const GET_CATEGORY_PLAYLIST_RESPONSE='GET_CATEGORY_PLAYLIST_RESPONSE'
export const GET_PLAYLIST_ERROR_MSG='GET_PLAYLIST_ERROR_MSG'
export const CREATE_WIDGET_ACTION='CREATE_WIDGET_ACTION'
export const EDIT_WIDGET_ACTION='EDIT_WIDGET_ACTION'
export const DELETE_WIDGET_ACTION='DELETE_WIDGET_ACTION'
export const GET_WIDGET_ACTION='GET_WIDGET_ACTION'
export const GET_WIDGET_RESPONSE='GET_WIDGET_RESPONSE'
export const GET_SINGLE_WIDGET_ACTION='GET_SINGLE_WIDGET_ACTION'
export const GET_SINGLE_WIDGET_RESPONSE='GET_SINGLE_WIDGET_RESPONSE'
export const SEARCH_PLAYLIST_ACTION='SEARCH_PLAYLIST_ACTION'
export const GET_PROJECT_BY_ID='GET_PROJECT_BY_ID'
export const  GET_PROJECT_BY_ID_RESPONSE='GET_PROJECT_BY_ID_RESPONSE'
export const CREATE_WIDGET_RESPONSE='CREATE_WIDGET_RESPONSE'
export const EDIT_WIDGET_RESPONSE='EDIT_WIDGET_RESPONSE'
export const DELETE_WIDGET_RESPONSE='DELETE_WIDGET_RESPONSE'
export const GET_CATEGORY_ASSET_LIST_ERROR='GET_CATEGORY_ASSET_LIST_ERROR'
export const GET_SEARCH_WIDGET_ACTION = 'GET_SEARCH_WIDGET_ACTION'
export const SUCCESSFULLY_GET_SEARCH_WIDEGT = 'SUCCESSFULLY_GET_SEARCH_WIDGET' 
export const CLOSE_SNACKBAR_FROM_PVS="CLOSE_SNACKBAR_FROM_PVS"