import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

//Actions
import {
  getAllScreenAction,
  getAllWidgetsAction,
  getSingleScreenAction,
  publishScreenAction,
  reorderWidgetDetailsAction,
} from "screens/PlatformManager/actions/PlatformAction";

import { getProjectByIdAction } from "screens/WidgetManager/actions";
import { addProjectNameInNavbarAction } from "../../../Projects/actions/projectActions";

//Material Ui Components
import { Box, Card, Divider, Grid, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
//Style Component
import style from "assets/jss/material-dashboard-react/views/screenStyle";

//Child Component
import Screen_Widgets from "./Screen_Widget";
import GridScreenView from "components/PlatformScreensView/GridScreenView";
import ListScreenView from "components/PlatformScreensView/ListScreenView";
import GridOrListViewScreenButton from "components/PlatformScreensView/GridOrListViewScreenView";
import DevicePreview from "components/PlatformScreensView/DevicePreview";
import LaptopDevicePreview from "components/PlatformScreensView/LaptopDevice";
import Live_icon from "assets/img/icons/live.svg";
import Draft_icon from "assets/img/icons/draft.svg";
import LoadingBar from "react-top-loading-bar";
import Snackbar from "components/Snackbar/CustomSnackbar";
import { getPlatformAction } from "screens/PlatformManager/actions/PlatformAction";
import { Skeleton } from "@material-ui/lab";
import { clearCreateScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { clearExportScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { clearPublishScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { clearDeleteScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { getAllScreensDataAction } from "screens/PlatformManager/actions/PlatformAction";
import { clearUpdateScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { getDefaultScreensAction } from "screens/PlatformManager/actions/PlatformAction";
import { clearScreenWidgetDetailsArray } from "screens/PlatformManager/actions/PlatformAction";
import { clearScreenReorderResponse } from "screens/PlatformManager/actions/PlatformAction";
import { getWidgetByIdData } from "screens/PlatformManager/actions/PlatformAction";
import SortableWidgetList from "components/Sortable/SortableWidgetList";
import SortableWidgetItem from "components/Sortable/SortableWidgetItem";
import EditorialBadgeAIWidget from '../../../../components/PlatformScreensView/editorialBadgeAiWidget'
import AddTrendingAiWidgetInfo from '../../../../components/PlatformScreensView/AddTrendingAiWidgetInfo'
import { closeSnackBarFromPVS, editWidgetAction } from "../../../../screens/WidgetManager/actions";


const useStyle = makeStyles(style);

const VisualizationScreen = (props) => {
  const [value, setValue] = useState(0);
  const [tabValue, setTabValue] = useState(false);
  const [listView, setListView] = useState(true);
  const [screen, setScreen] = useState();
  const [screenName, setScreenName] = useState();
  const [screenStatus, setScreenStatus] = useState();
  const [reorderScreen, setReorderScreen] = useState(false);
  const [screenDet, setScreenDet] = useState();
  const [screenType, setScreenType] = useState("custom");
  const [pinnedWidgets,setPinnedWidgets] = useState({})
  const [open, setOpen] = useState(false);
  const [laptopPreview, setLaptopPreview] = useState(false);

  const [progress, setProgress] = useState(0);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("warning");

  const [screenCreated, setScreenCreated] = useState(false);
  const [screenDeleted, setScreenDeleted] = useState(false);
  const [screenExported, setScreenExported] = useState(false);
  const [screenStatusSnack, setScreenStatusSnack] = useState(false);

  const localPlatform = JSON.parse(localStorage.getItem("platform"));

  const [platformName, setPlatformName] = useState(localPlatform?.name);
  const [platform, setPlatform] = useState(localPlatform?.shortName);

  const [duplicateWidget, setDuplicate] = useState(false);

  const [widgetOrder, setWidgetOrder] = useState(props.widgetDetailsArray);
  const [updateWidgets, setUpdateWidgets] = useState(false);

  const [expanded, setExpanded] = React.useState(-1);
  const [openEditAiWidget,setOpenEditAIWidget]=React.useState(false)
  const [openSnackbarEdit, setOpenSnackbaEdit] = React.useState(false);

  const [bias,setBias]=React.useState([


  ])

  const [openAiTrendingWidget,setOpenAiTrendingWidget]=React.useState(false)
  const [aiWidgetDetails,setAiWidgeDetails]=React.useState({})
  const [aiWidgetTrending,setAiWidgetTrending]=React.useState({})
  const [aiWidgetTrendingPicked,setAiWidgetTrendingPick]=React.useState('')
  const [aiWidgetTrendingDetails,setAiWidgetTrendingDetails]=React.useState({})

  const [openPreview, setOpenPreview] = React.useState(-1);
  const dragCounter = useRef(0);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  

  // useEffect(() => {
  //   props.editWidget?.hzStatus === 200 && props.widgetEdit ? setOpenSnackbaEdit(true) : props.editWidget?.hzStatus === 400 && props.widgetEdit?setOpenSnackbaEdit(true) : null;
  // }, [props.editWidget]);
 
 useEffect(() => {
  const _pinnedWidget = {
      
  }
  if (props?.widgetDetailsArray?.length){
    props?.widgetDetailsArray.map(widget=>{
      if(widget?.pinning===true){
        _pinnedWidget[widget?._id ?? widget?.id] = true
      }else{
        _pinnedWidget[widget?._id ?? widget?.id] = false
      }
    })
  }

  if(Object.keys(_pinnedWidget).length>0){
    setPinnedWidgets(prev=>{
      return Object.assign(prev,_pinnedWidget)
    })
  }
  
}, [props?.widgetDetailsArray]);

useEffect(()=>{
  if(  props.editWidget?.hzStatus === 200 ){
        let _id = props.editWidget?._id ?? props.editWidget?.id
        let pinning = props.editWidget?.pinning ?? false
        setPinnedWidgets(prev=>{
          return ({
          ...prev,[_id]:pinning
          })
        })
  }else if (typeof props.editWidget?.hzStatus ==="number"){
    // if(pinnedWidgets?.lateset){
    //   setPinnedWidgets(prev=>{
    //     return ({
    //     ...prev,[_id]:prev._id,
    //     lateset:null
    //     })
    //   })
    // }

  }

},[props.widgetEdit])

  const classes = useStyle();
  //Screen Tab onChange event function
  const handlechange = (_, _value) => {
    setTabValue(false);
    setValue(_value);
    setScreenType("custom");

  };
  function updatePinning(id,parameters,pinning,prevScreen="screens"){
    let body = {
      pinning
    }
    setPinnedWidgets(prev=>{
      return ({
      ...prev,[id]:pinning,
      lateset:id
      })
    })
    updateWidget(id,body,parameters,prevScreen)
  }
  
  function updateWidget (id,parameters,body,prevScreen){
    props.editWidgetAction(
      id,
      parameters,
      body,
      prevScreen
    )
  }

  const handleTabChange = (_, _value) => {
     setValue(false);
      setTabValue(_value);
      setScreenType("default");


  };

  const createMessages = {
    success: `${props.createScreen?.data?.data?.name} screen has been created.`,
    warning: `${props.createScreen?.data?.message}`,
    error: `There seems to be an error creating ${props.createScreen?.data?.data?.name} screen. Please try again.`,
  };

  const deleteMessages = {
    success: props.deleteScreen?.data?.message,
    warning: props.deleteScreen?.data?.message,
    error: `There seems to be an error deleting ${props.deleteScreen?.data?.message} screen. Please try again.`,
  };
  

  const exportMessages = {
    success: `${props.exportScreen?.data?.data?.[0]?.name} has been exported.`,
    warning: props.exportScreen?.data?.message,
    error: `There seems to be an error exporting ${
      props.exportScreen?.data?.data?.[0]
        ?.name
    } screen. Please try again.`,
  };

  const screenStatusMessages = {
    success: props.screenStatusRes?.payload?.data?.message,
    warning: props.screenStatusRes?.payload?.data?.message,
    error: `There seems to be an error publishing ${props.deleteScreen?.data?.message} screen. Please try again.`,
  };
  const editMessages = {
    success: `${props.editWidget?.data?.name} widget has been edited.`,
    warning: ``,
    error: `There seems to be an error editing ${props.createWidget?.data?.name} widget. Please try again.`,
  };
 //Messages for Widget Creation Snackbar
//  React.useEffect(()=>{props.closeSnackBarFromPVS()});
//  React.useEffect(() => {
//   props.editWidget?.hzStatus === 200 && props.widgetEdit ?setOpenSnackbaEdit(true) :props.editWidget?.hzStatus === 400 && props.widgetEdit?setOpenSnackbaEdit(true): null;
// }, [props.editWidget]);
 React.useEffect(()=>{props.closeSnackBarFromPVS()});

 




  //Drag and Drop events

  //DragStart for the widget dragging from widget section to screens visulization
  const onDragStart = (ev, id) => ev.dataTransfer.setData("id", id);
  function findIndexOfItem(mouseY, rootItem) {
    const children = [...rootItem.children].slice(0, -2)
    if (children.length === 0) {
      return -1
    }
    const distances = children.map((e) => {
      const rect = e.getBoundingClientRect()
      const midPoint = rect.y + rect.height/2.0
      return (mouseY - midPoint)
    })
    console.log("test9876", "distances", distances)
    var minIdx = 0
    for (var i = 0; i < distances.length; i++) {
      if (Math.abs(distances[i]) < Math.abs(distances[minIdx])) {
        minIdx = i
      }
    }
    console.log("test9876", "minIdx", minIdx, "distance", distances[minIdx])
    if (minIdx == distances.length - 1 && distances[minIdx] > 0) {
      minIdx = -1
      console.log("test9876", "special case hogya bro")

    }

    return minIdx
  }
  //OnDrop function to control the drop in that area (using same function for the both widget adding and reordering using checks)
  const onDrop = (ev) => {
    console.log("test5566","Drop Position", ev)
    if (props.accessLevel === "user") {
      setSnackMessage("You do not have access to add widgets.");
      setSnackSeverity("warning");
      setSnackOpen(true);
      return;
    }

    if (screenType === "custom" && !props.allScreens.length) {
      setSnackMessage("Please create a screen to add widgets.");
      setSnackSeverity("error");
      setSnackOpen(true);
      return;
    }

    if (screenType === "default" && !props.defaultScreens.length) {
      setSnackMessage("Please create a screen to add widgets.");
      setSnackSeverity("error");
      setSnackOpen(true);
      return;
    }

    const id = ev.dataTransfer.getData("id");

    if (props.widgetDetailsArray.find((widget) => widget?._id === id)) {
      setDuplicate(true);
      return;
    }

    const allWidgets = [
      ...(props.widgets?.widgets ?? []),
      ...(props.widgets?.defaultWidgets ?? []),
      ...(props.widgets?.aiWidgets ?? []),
    ];

    const newWidget = allWidgets.find((widget) => widget?._id === id);
    if (!newWidget) return;
   /*
  if(newWidget?.item?.playlist?.predefPlaylistType=="AT_MRC"){
    const root=document.getElementById("visualization_box");
    const idx = findIndexOfItem(ev.clientY,root)
   setAiWidgeDetails({...aiWidgetDetails,newWidget:newWidget,root:root,idx:idx})
    setOpenEditAIWidget(true)
  }
  else if(newWidget?.item?.playlist?.predefPlaylistType=="TRND_NW"||newWidget?.item?.playlist?.predefPlaylistType=='EVGN_PK'){
    const root=document.getElementById("visualization_box");
    const idx = findIndexOfItem(ev.clientY,root)
   setAiWidgeDetails({...aiWidgetDetails,newWidget:newWidget,root:root,idx:idx})
   setAiWidgetTrendingPick(newWidget?.item?.playlist?.predefPlaylistType)
    setOpenAiTrendingWidget(true)
  } */

  //else{
      // const currentElement=document.elementFromPoint(ev.clientX,ev.clientY);
      const root=document.getElementById("visualization_box");
      const idx = findIndexOfItem(ev.clientY,root)
      console.log("test14134", "foundIdx:", idx)
      // const scrollY = document.getElementById("visualization_box").scrollTop;
      // const mouseY = ev.screenY - 200;
      // const idx = Math.ceil((scrollY + mouseY) / 103.0) - 2;
      // console.log(idx, scrollY, mouseY, ev, "idx");
      const result = idx != -1 ? [].concat(props.widgetDetailsArray.slice(0, idx), [newWidget], props.widgetDetailsArray.slice(idx, props.widgetDetailsArray.length))
                                                : [...props.widgetDetailsArray, newWidget];
      console.log(result, "result");
  // const updateWidgetsList = [newWidget, ...props.widgetDetailsArray];
  const updateWidgetsList=result;
      props.getWidgetByIdData(updateWidgetsList);
      setUpdateWidgets(true);
      setDragOverIndex(null);
      dragCounter.current = 0;
  //}
  };


  const  continueToAddai = (genre,subGenre,director,actor,language,title) => {
    let temArr = [genre, subGenre, director, actor, language, title];
   
    setBias( temArr.filter((category, index) => {
        return (
          category !== undefined 
         
        );
      }))
 
  
    const result = aiWidgetDetails.idx != -1 ? [].concat(props.widgetDetailsArray.slice(0,aiWidgetDetails.idx), [aiWidgetDetails.newWidget], props.widgetDetailsArray.slice(aiWidgetDetails.idx, props.widgetDetailsArray.length))
    : [...props.widgetDetailsArray,aiWidgetDetails.newWidget];
// const updateWidgetsList = [newWidget, ...props.widgetDetailsArray];
const updateWidgetsList=result;
props.getWidgetByIdData(updateWidgetsList);
setUpdateWidgets(true);
setOpenEditAIWidget(false)

  };

  const continueToAddTrendinAi=()=>{
   // let tempArrTrend=[year,month,day]
    
    const result = aiWidgetDetails.idx != -1 ? [].concat(props.widgetDetailsArray.slice(0,aiWidgetDetails.idx), [aiWidgetDetails.newWidget], props.widgetDetailsArray.slice(aiWidgetDetails.idx, props.widgetDetailsArray.length))
    : [...props.widgetDetailsArray,aiWidgetDetails.newWidget];
// const updateWidgetsList = [newWidget, ...props.widgetDetailsArray];
const updateWidgetsList=result;
props.getWidgetByIdData(updateWidgetsList);
setUpdateWidgets(true);
setOpenAiTrendingWidget(false)
  }

  //Onclick event
  //Calling an action for getting single screen details

  const openScreen = (screenDetails) => {
    console.log("🚀 ~ openScreen ~ screenDetails:", screenDetails)
    setScreenName(screenDetails.name);
    setScreenStatus(screenDetails.status);

    setScreen(screenDetails._id);
    setScreenDet(screenDetails);
    props.getWidgetByIdData(screenDetails?.widgets || [])
    // props.clearScreenWidgetDetailsArray();
    props.getSingleScreenAction(screenDetails);
  };

  useEffect(() => {
    props.getProjectByIdAction(props?.location?.state?.projectId);
    if (!props?.platforms?.length) props.getPlatformAction(props?.location?.state?.projectId);
  }, []);

  useEffect(() => {
    if (screenType !== "custom") return;

    if (!props.allScreens.length) {
      props.getWidgetByIdData([]);
      setScreen(null)
      setScreenName(null)
      setScreenStatus(null)
      setScreenDet(null)
      console.log(null,"screenName")
      return;
    }

    setScreenName(props.allScreens?.[value]?.name);
    setScreen(props.allScreens?.[value]?._id);
    setScreenStatus(props.allScreens?.[value]?.status);
    setScreenDet(props.allScreens?.[value]);
    if (props.allScreens?.[value]) props.getSingleScreenAction(props.allScreens?.[value]);
  }, [props.allScreens]);

  useEffect(() => {
    if (screenType !== "default") return;

    setScreenName(props.defaultScreens?.[tabValue]?.name);
    setScreen(props.defaultScreens?.[tabValue]?._id);
    setScreenStatus(props.defaultScreens?.[tabValue]?.status);
    setScreenDet(props.defaultScreens?.[tabValue]);
    if (props.defaultScreens?.[tabValue]) props.getSingleScreenAction(props.defaultScreens?.[tabValue]);
  }, [props.defaultScreens]);



  useEffect(() => {
    if (props.reorderScreenData && Object.keys(props.reorderScreenData).length) {
      props.getAllScreenAction(props?.location?.state?.projectId, platform, screenName);
      props.clearScreenReorderResponse();
    }
  }, [props.reorderScreenData]);

  useEffect(() => {
    props.getAllScreenAction(props?.location?.state?.projectId, platform, screenName);
    props.getAllWidgetsAction(props?.location?.state?.projectId, platform);
  }, [platform]);

  useEffect(()=>{
    props.getAllWidgetsAction(props?.location?.state?.projectId, platform);
  },[props.widgetEdit]);

  useEffect(() => {
    if (props.updatedScreen.length && props.updatedScreen?.[0]?.hzStatus !== 200) {
      setSnackMessage(props.updatedScreen?.[0]?.message);
      setSnackSeverity("error");
      setSnackOpen(true);
      return;
    }

    if (props.updatedScreen?.[0]?.hzStatus !== 200) return;

    const updatedScreenData = props.updatedScreen?.[0]?.data;
    if (screenType==="custom"){
      const allScreens = props.allScreens?.map((screen) => {
        if (screen.name !== props.updatedScreen?.[1]) return screen;
  
        return {
          ...screen,
          status: updatedScreenData.status,
          version: updatedScreenData.version,
          widgets: updatedScreenData.widgets,
          theme:updatedScreenData.theme
        };
      });
      props.getAllScreensDataAction(allScreens);
      
    }else{
      const defaultScreens = props.defaultScreens?.map((screen) => {
        if (screen.name !== props.updatedScreen?.[1]) return screen;
  
        return {
          ...screen,
          status: updatedScreenData.status,
          version: updatedScreenData.version,
          widgets: updatedScreenData.widgets,
        };
      });
      props.getDefaultScreensAction(defaultScreens);
    }
  
    
    props.clearUpdateScreenData();
  }, [props.updatedScreen]);

  useEffect(() => {
    if (props.createScreen && !Object.keys(props.createScreen).length) return;

    setScreenCreated(true);

    if (props.createScreen?.data?.hzStatus === 200) {
      const allScreens = [...props.allScreens, props.createScreen?.data?.data];
      props.getAllScreensDataAction(allScreens);
      setValue(props.createScreen?.data?.data?.displayOrder || props?.allScreens?.length )
      openScreen(props.createScreen?.data?.data)
    }
  }, [props.createScreen]);

  useEffect(() => {
    props.exportScreen?.data?.hzStatus === 200 ? setScreenExported(true) : setScreenExported(false);
  }, [props.exportScreen]);

  useEffect(() => {
    if (props.screenStatusRes?.payload?.data?.hzStatus !== 200) {
      setScreenStatusSnack(false);
      return;
    }

    setScreenStatusSnack(true);

    if (screenType === "custom") {
      const allScreens = props.allScreens?.map((screen) => {
        if (screen._id !== props.screenStatusRes?.action?.id) return screen;
        return { ...screen, status: props.screenStatusRes?.action?.status };
      });

      props.getAllScreensDataAction(allScreens);
      return;
    }

    if (screenType === "default") {
      const defaultScreens = props.defaultScreens?.map((screen) => {
        if (screen._id !== props.screenStatusRes?.action?.id) return screen;
        return { ...screen, status: props.screenStatusRes?.action?.status };
      });

      props.getDefaultScreensAction(defaultScreens);
      return;
    }
  }, [props.screenStatusRes]);

  useEffect(() => {
    setScreenType("custom");
    props.clearScreenWidgetDetailsArray();
  }, [platformName]);

  useEffect(() => {
    if (!props.updatedScreenDetails.length) return;
    if(props.updatedScreenDetails?.[0]?.data?.hzStatus === 200 && props.updatedScreenDetails?.[0]?.data?.data?.hasOwnProperty("pinning")) return;
    if (props.updatedScreenDetails?.[0]?.data?.hzStatus === 200) {
      const allScreens = props.allScreens?.map((screen) => {
        if (screen?.name !== props.updatedScreenDetails?.[1]) return screen;

        return {
          ...screen,
          name: props.updatedScreenDetails?.[0]?.data?.data?.name,
          status: props.updatedScreenDetails?.[0]?.data?.data?.status,
          version: props.updatedScreenDetails?.[0]?.data?.data?.version,
          displayStyle: props.updatedScreenDetails?.[0]?.data?.data?.displayStyle,
          screenIcon: props.updatedScreenDetails?.[0]?.data?.data?.screenIcon,
        };
      });

      props.getAllScreensDataAction(allScreens);

      setSnackMessage(
        `Updated ${props.updatedScreenDetails?.[0]?.data?.data?.name} ${
          props.updatedScreenDetails?.[0]?.data
            ?.message
        }`,
      );
      setSnackSeverity("success");
      setSnackOpen(true);
    } else {
      setSnackMessage(props.updatedScreenDetails?.[0]?.message);
      setSnackSeverity("error");
      setSnackOpen(true);
    }
  }, [props.updatedScreenDetails]);

  useEffect(() => {
    setWidgetOrder(props.widgetDetailsArray);
  }, [props.widgetDetailsArray]);

  useEffect(() => {
    const widgetsList = widgetOrder?.map((widget, index) => {
      if(aiWidgetDetails?.newWidget?._id===widget._id && bias.length>0)return{ displayOrder: index, id: widget._id, layout: widget.layout,name:widget.name,kalturaChannelId:widget?.item?.playlist?.kalturaChannelId,kalturaOTTImageType:widget?.kalturaOTTImageType ,bias:{...bias}}

      else if(aiWidgetDetails?.newWidget?._id===widget._id && Object.keys(aiWidgetTrendingDetails).length>0)return{displayOrder: index, id: widget._id, layout: widget.layout ,name:widget?.name,kalturaChannelId:widget?.item?.playlist?.kalturaChannelId,kalturaOTTImageType:widget?.kalturaOTTImageType,bias:aiWidgetTrendingDetails}
      else return { displayOrder: index, id: widget._id, layout: widget.layout,name:widget?.name,kalturaChannelId:widget?.item?.playlist?.kalturaChannelId,kalturaOTTImageType:widget?.kalturaOTTImageType };
    });

    const publishScreen = {
      p_id: props.location?.state?.projectId,
      platform: platform,
      newScreenName: screenName,
      status: "UNPUBLISHED",
      widgets: widgetsList,
      screenIcon: screenDet?.screenIcon,
      displayStyle: screenDet?.displayStyle,
    };

    if (updateWidgets) {
      props.publishScreenAction(publishScreen);
      setUpdateWidgets(false);
    }
  }, [widgetOrder]);

  useEffect(() => {
    if (props?.widgetDetailsArray?.length) setProgress(100);

    // let timer = setTimeout(() => {
    //   setProgress(100);
    // }, 1000);

    // return () => clearTimeout(timer);
  }, [props?.widgetDetailsArray]);

  useEffect(() => {
    const handleDragEnd = () => {
      dragCounter.current = 0;
      setDragOverIndex(null);
    };
    document.body.addEventListener("dragend", handleDragEnd);
    return () => {
      document.body.removeEventListener("dragend", handleDragEnd);
    };
  }, []);
 
  useEffect(()=>{
    document.querySelector(".makeStyles-mainPanelOp-3")?.scrollTo({
      "top":0,
      "behavior":"smooth"
    })
  },[])
  const handleDragOver = (e) => {
    e.preventDefault();
    const scrollY = document.getElementById("visualization_box")?.scrollTop;
    const mouseY = e.screenY - 200;
    const idex = Math.ceil((scrollY + mouseY) / 103.0) - 2;
    if (dragOverIndex !== idex) {
      setDragOverIndex(idex);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverIndex(null);
    }
    if (e.clientY < 0 || e.clientY >= window.innerHeight) {
      dragCounter.current = 0;
      setDragOverIndex(null);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    dragCounter.current++;
    if (dragOverIndex === null) {
      const scrollY = document.getElementById("visualization_box").scrollTop;
      const mouseY = e.clientY - 200;
      const idex = Math.ceil((scrollY + mouseY) / 103.0) - 2;
      setDragOverIndex(idex);
    }
  };

 console.log(Array(5).fill("*"),"Arary")
  console.log("🚀 ~ VisualizationScreen ~   props.widgetDetailsArray:",   props.widgetDetailsArray)
  return (
    <Box>
      <div>
        <LoadingBar
          color="#885CF7"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          shadow={true}
          height={3}
        />
      </div>
      <Snackbar open={snackOpen} message={snackMessage} severity={snackSeverity} setOpen={() => setSnackOpen(false)} />
      <Snackbar
        open={screenCreated}
        severity={props.createScreen?.data?.data?.acknowledged
          ? "warning"
          : props.createScreen?.data?.hzStatus === 200
          ? "success"
          : "error"}
        message={props.createScreen?.data?.data?.acknowledged
          ? createMessages.warning
          : props.createScreen?.data?.hzStatus === 200
          ? createMessages.success
          : props.createScreen?.hzStatus === 400
          ? `${props.createScreen?.message}`
          : createMessages.error}
        setOpen={() => {
          setScreenCreated(false);
          setTimeout(() => props.clearCreateScreenData(), 200);
        }}
      />
      <Snackbar
        open={screenDeleted}
        severity={props.deleteScreen?.data?.hzStatus === 200 ? "success" : "error"}
        message={props.deleteScreen?.data?.hzStatus === 200 ? deleteMessages.success : deleteMessages.error}
        setOpen={() => {
          setScreenDeleted(false);
          setTimeout(() => props.clearDeleteScreenData(), 200);
        }}
      />
      <Snackbar
        open={screenExported}
        severity={props.exportScreen?.data?.hzStatus === 200 ? "success" : "error"}
        message={props.exportScreen?.data?.hzStatus === 200 ? exportMessages.success : exportMessages.error}
        setOpen={() => {
          setScreenExported(false);
          setTimeout(() => props.clearExportScreenData(), 200);
        }}
      />
      <Snackbar
        open={screenStatusSnack}
        severity={props.screenStatusRes?.payload?.data?.hzStatus === 200 ? "success" : "error"}
        message={props.screenStatusRes?.payload?.data?.hzStatus === 200
          ? screenStatusMessages.success
          : screenStatusMessages.error}
        setOpen={() => {
          setScreenStatusSnack(false);
          setTimeout(() => props.clearPublishScreenData(), 200);
        }}
      />
      <Snackbar
        open={duplicateWidget}
        severity="error"
        message="Widget has already been added to this screen"
        setOpen={() => setDuplicate(false)}
      />
       <Snackbar
        open={openSnackbarEdit}
        severity={props.editWidget?.hzStatus === 200 ? "success" : "error"}
        message={
          props.editWidget?.hzStatus === 200 ? editMessages.success : editMessages.error
        }
        setOpen={() => setOpenSnackbaEdit(false)}
  
      />
     
      <Grid container>
        <Grid item xs={12} md={12} lg={8}>
          <GridOrListViewScreenButton
            listView={listView}
            setListView={setListView}
            platformName={platformName}
            open={open}
            setOpen={setOpen}
            laptopPreview={laptopPreview}
            setLaptopPreview={setLaptopPreview}
            setPlatform={setPlatform}
            setPlatformName={setPlatformName}
            platforms={props.platforms}
            setTabValue={setValue}
            setDefaultTabValue={setTabValue}
          />
          <Card className={classes.screenBar}>
            <Box display="flex" flexDirection="row">
              <Box paddingLeft="32px" paddingTop="8px">
                <Box className={classes.screenAvatar}>
                  {props.allScreens?.[value]?.displayStyle === "name"
                    ? null
                    : <img src={props.allScreens?.[value]?.screenIcon} width="24px" />}
                </Box>
              </Box>
              <Box pl={2} style={{ overflow: "auto", width: "calc(100% - 255px)" }}>
                <Tabs
                  value={value}
                  TabIndicatorProps={{
                    style: { background: "#FFFF", height: "4px" },
                  }}
                  onChange={handlechange}
                  variant="scrollable"
                  style={{ height: "52px" }}
                >
                  {props.allScreens?.map((x, i) => (
                    <Tab
                      className={classes.screenTab}
                      classes={{
                        selected: classes.selectedScreenTab,
                        labelIcon: classes.lableIcon,
                      }}
                      style={{ minWidth: 50 }}
                      value={i}
                      label={x.name}
                      icon={x.status === "PUBLISHED"
                        ? <img src={Live_icon} style={{ width: "30px" }} />
                        : <img src={Draft_icon} style={{ width: "30px" }} />}
                      iconposition="end"
                      onClick={() => openScreen(x)}
                      key={i}
                    />
                  ))}
                </Tabs>
              </Box>
              <Divider orientation="vertical" style={{ width: "2px", height: "52px" }} />
              <Box pl={2}>
                <Tabs
                  value={tabValue}
                  TabIndicatorProps={{
                    style: { background: "#FFFF", height: "4px" },
                  }}
                  onChange={handleTabChange}
                  style={{ height: "52px" }}
                >
                  {props?.defaultScreens?.map((x, i) => (
                    <Tab
                      className={classes.screenTab}
                      classes={{
                        selected: classes.selectedScreenTab,
                        labelIcon: classes.lableIcon,
                      }}
                      style={{ minWidth: 50 }}
                      value={i}
                      label={x.name}
                      icon={x.status === "PUBLISHED"
                        ? <img src={Live_icon} style={{ width: "30px" }} />
                        : <img src={Draft_icon} style={{ width: "30px" }} />}
                      iconposition="end"
                      onClick={() => openScreen(x)}
                      key={i}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Card>

          {!props?.widgetDetailsArray?.length &&
              props.allScreens?.[value]?.widgets?.length &&
              screenType === "custom"
            ? (
              <Box className={classes.skeletonArray}>
                {props.allScreens?.[value]?.widgets?.map((_, index) => (
                  <Skeleton
                    variant="rect"
                    component="div"
                    style={{ height: "50px", marginBottom: "16px", borderRadius: "6px" }}
                    key={index}
                  />
                ))}
              </Box>
            )
            : !props?.widgetDetailsArray?.length && props.defaultScreens?.[tabValue]?.widgets?.length
            ? (
              <Box className={classes.skeletonArray}>
                {props.defaultScreens?.[tabValue]?.widgets?.map((_, index) => (
                  <Skeleton
                    variant="rect"
                    component="div"
                    style={{ height: "50px", marginBottom: "16px", borderRadius: "6px" }}
                    key={index}
                  />
                ))}
              </Box>
            )
            : listView || (props?.widgetDetailsArray.length>0 && props?.widgetDetailsArray?.some(widget=>{
                if(typeof widget.containerId  ==="string"){
                    return true
                }
            })) ?<>
         <Box className={
                classes.widgetArray}
                onDrop={onDrop} 
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDragEnter={handleDragEnter}
                id={"visualization_box"}
                  style={{
                    transition: 'background-color 0.3s ease, border 0.3s ease'
                  }}
                >
             
                <SortableWidgetList
                  items={widgetOrder}
                  onChange={setWidgetOrder}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  accessLevel={props.accessLevel}
                  setSnackOpen={setSnackOpen}
                  setSnackMessage={setSnackMessage}
                  setUpdateWidgets={setUpdateWidgets}
                  renderItem={(item, index, items, expanded, setExpanded, removeItem) => (
                    <SortableWidgetItem
                      key={item}
                      id={item}
                      item={item}
                      index={index}
                      items={items}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      listView={listView}
                      removeItem={removeItem}
                      accessLevel={props.accessLevel}
                      setSnackOpen={setSnackOpen}
                      setSnackMessage={setSnackMessage}
                    >
                      {listView
                        ? (
                          <>
                            {dragOverIndex === index && (
                              <div style={{
                                height:'40px',
                                marginBottom:'15px',
                                textAlign: 'center',
                                alignContent: 'center',
                                backgroundColor: 'rgba(0, 0, 255, 0.1)',
                                border: '2px dashed blue',
                                transition: 'background-color 0.3s ease, border 0.3s ease'
                              }} >Drop Here</div>
                            )}
                           <ListScreenView
                            id={item}
                            index={index}
                            widget={items[index]}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            removeWidget={removeItem}
                            accessLevel={props.accessLevel}
                            setSnackOpen={setSnackOpen}
                            setSnackMessage={setSnackMessage}
                            openPreview={openPreview}
                            setOpenPreview={setOpenPreview}
                            pinnedWidgets={pinnedWidgets}
                            updatePinning={updatePinning}
                          />
                          </>
                         
                        )
                        : (
                          !items?.[index]?.item?.playlist?.data?.result?.objects &&
                          screenType === "custom"
                          ? (<GridScreenView widget={items[index]} />
                            
                          ): <GridScreenView widget={items[index]} />
                        )   }
                    </SortableWidgetItem>
                  )}     
                />
              </Box> 
          
            </>:(
                screenType === "custom"
                ? (
                  <Box className={classes.skeletonArray}>
                    {props.allScreens?.[value]?.widgets?.map((_, index) => (
                      <Skeleton
                        variant="rect"
                        component="div"
                        style={{ height: "100px", marginBottom: "16px", borderRadius: "6px" }}
                        key={index}
                      />
                    ))}
                  </Box>
                ):
                <Box className={classes.skeletonArray}>
                {props.defaultScreens?.[tabValue]?.widgets?.map((_, index) => (
                  <Skeleton
                    variant="rect"
                    component="div"
                    style={{ height: "50px", marginBottom: "16px", borderRadius: "6px" }}
                    key={index}
                  />
                ))}
              </Box>
            )}
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Screen_Widgets
            allScreens={props.allScreens}
            data={props?.location.state}
            getaction={props?.getAllScreenAction}
            onDragStart={onDragStart}
            screen={screen}
            screenName={screenName}
            screenStatus={screenStatus}
            reorderScreen={reorderScreen}
            setReorderScreen={setReorderScreen}
            platform={platform}
            setValue={setValue}
            aspectRatio={props.projectAspectRatio}
            projectId={props?.location?.state?.projectId}
            setSnackOpen={setSnackOpen}
            setSnackMessage={setSnackMessage}
            setSnackSeverity={setSnackSeverity}
            setScreenDeleted={setScreenDeleted}
          />
        </Grid>
      </Grid>
      {open
        ? <DevicePreview open={open} setOpen={setOpen} widget={props.widgetDetailsArray} platform={platformName} />
        : (
          ""
        )}
      {laptopPreview
        ? (
          <LaptopDevicePreview
            laptopPreview={laptopPreview}
            setLaptopPreview={setLaptopPreview}
            widget={props.widgetDetailsArray}
          />
        )
        : (
          ""
        )}

        <EditorialBadgeAIWidget
        open={openEditAiWidget}
        bias={bias}
        setBias={setBias}
        continueToAddai={continueToAddai}
        setModal={setOpenEditAIWidget}
        />
        <AddTrendingAiWidgetInfo
          open={openAiTrendingWidget}
          setModal={setOpenAiTrendingWidget}
          setAiWidgetTrendingDetails={setAiWidgetTrendingDetails}
          aiWidgetTrendingDetails={aiWidgetTrendingDetails}
         setAiWidgetTrendingPick={setAiWidgetTrendingPick}
          aiWidgetTrendingPicked ={aiWidgetTrendingPicked}
          continueToAddTrendinAi={continueToAddTrendinAi}
          />
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    allScreens: State.screenVisualizationReducer.screens,
    defaultScreens: State.screenVisualizationReducer.defaultScreens,
    widgets: State.screenVisualizationReducer.widgets,
    widgetDetailsArray: State.screenVisualizationReducer.widgetDetailsArray,
    reorderWidgets: State.screenVisualizationReducer.reorderWidgets,
    screenReset: State.screenVisualizationReducer.screenReset,
    reorderScreenData: State.screenVisualizationReducer.reorderScreenData,
    loading: State.screenVisualizationReducer.loading,
    widgetDetails: State.screenVisualizationReducer.widgetDetails,
    statusChanged: State.screenVisualizationReducer.statusChanged,
    projectAspectRatio: State.PlayListReducer.projectAspectRatio,
    createScreen: State.screenVisualizationReducer.createScreenData,
    deleteScreen: State.screenVisualizationReducer.deleteScreenRes,
    exportScreen: State.screenVisualizationReducer.exportScreenRes,
    screenStatusRes: State.screenVisualizationReducer.screenStatusRes,
    projectDetails: State.ProjectReducer.projectDetails,
    platforms: State.screenVisualizationReducer.platforms,
    platformDetails: State.screenVisualizationReducer.platformDetails,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
    updatedScreen: State.screenVisualizationReducer.updatedScreen,
    updatedScreenDetails: State.screenVisualizationReducer.updatedScreenDetails,
    widgetEdit: State.PlayListReducer.widgetEdit,
    editWidget: State.PlayListReducer.editWidget,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getAllScreenAction: (projectID, platform, screenName) => {
      dispatch(getAllScreenAction(projectID, platform, screenName));
    },
    getAllWidgetsAction: (projectId, platform) => {
      dispatch(getAllWidgetsAction(projectId, platform));
    },
   
    reorderWidgetDetailsAction: (array) => {
      dispatch(reorderWidgetDetailsAction(array));
    },
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    },
    getSingleScreenAction: (screenDetails) => {
      dispatch(getSingleScreenAction(screenDetails));
    },
    publishScreenAction: (details) => {
      dispatch(publishScreenAction(details));
    },
    getPlatformAction: (projectId) => {
      dispatch(getPlatformAction(projectId));
    },
    getAllScreensDataAction: (screens) => {
      dispatch(getAllScreensDataAction(screens));
    },
    getDefaultScreensAction: (screens) => {
      dispatch(getDefaultScreensAction(screens));
    },
    getProjectByIdAction: (projectId) => {
      dispatch(getProjectByIdAction(projectId));
    },
    getWidgetByIdData: (widgetList) => {
      dispatch(getWidgetByIdData(widgetList));
    },
    clearCreateScreenData: () => {
      dispatch(clearCreateScreenData());
    },
    clearDeleteScreenData: () => {
      dispatch(clearDeleteScreenData());
    },
    clearExportScreenData: () => {
      dispatch(clearExportScreenData());
    },
    clearPublishScreenData: () => {
      dispatch(clearPublishScreenData());
    },
    clearUpdateScreenData: () => {
      dispatch(clearUpdateScreenData());
    },
    clearScreenWidgetDetailsArray: () => {
      dispatch(clearScreenWidgetDetailsArray());
    },
    clearScreenReorderResponse: () => {
      dispatch(clearScreenReorderResponse());
    },
    closeSnackBarFromPVS:()=>{
      dispatch(closeSnackBarFromPVS());
    },
    editWidgetAction: (id,body, parameters, prevScreen) => dispatch(editWidgetAction(id,body, parameters, prevScreen))
  };
};

export default connect(mapStateToProps, mapDispatchToState)(VisualizationScreen);
