import { Box, Typography, Switch, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom'; 
import LayersIcon from '@mui/icons-material/Layers';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const CustomTemplateList = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ListItem
        icon={""}
        title="Signin Template"
        description=""
        path="/admin/signin-template"
      />
      <ListItem
        icon={""}
        title="Settings Template"
        description=""
        path="/admin/settings-template"
      />
      <ListItem
        icon={""}
        title="Screen Template"
        description=""
        path="/admin/screen-template"
      />
    </Box>
  );
};

const ListItem = ({ icon, title, description, path, switchChecked = false }) => {
  const history = useHistory(); 

  const handleClick = () => {
    history.push(path); 
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        backgroundColor: '#e8ebf8',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        '&:hover': { backgroundColor: '#d7daf3' }, 
      }}
    >
      <Box display="flex" alignItems="center">
        {icon}
        <Box ml={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: '#6b6b6b' }}>
            {description}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton>
          <OpenInNewIcon sx={{ color: '#6b6b6b' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomTemplateList;
