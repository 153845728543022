import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";
import Rectangle from "assets/img/sideNavIcons/Rectangle 32.png";

const headerStyle = () => ({
  appBar: {
    // background: 'linear-gradient(151.34deg, #E0F1FF 27.65%, #ECE2FF 157.63%)',
    boxShadow: "5px 0 10px rgb(0 20 151 / 30%)",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[7],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    position:'sticky',
    background:"linear-gradient(180deg, #FFFFFF 0%, #CBDDFF 100%)"
  },
  container: {
    ...container,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
    display: '-webkit-box'
  },
  projectIcon:{
    //position: 'absolute',
    width: '40px',
    height: '40px',
    background: '#C4C4C4',
    borderRadius:'40px'
  },
  projectName:{
    'font-family': 'Open Sans',
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '19px',
    //'line-height': '27px',
    color: '#424242',
  },
  dropdown:{
    width: '100%',
  //height: '30px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20px',
  color: '#141D46',
  boxShadow:'none'
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
});

export default headerStyle;
