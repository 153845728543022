import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "assets/img/icons/svg/CloseIcon.svg";

const useStyles = makeStyles(styles);

export default function CustomDialog(props) {
  const classes = useStyles();

  const {
    open,
    title,
    button1,
    button2,
    button1Event,
    button2Event,
    icon,
    margin,
    width,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={button1Event}
      PaperProps={{
        style: { borderRadius: "16px" },
      }}
    >
      <DialogContent
      className={classes.content}
      >
        <Box className={classes.dialogBox}>
          <Button onClick={button1Event} className={classes.button}>
            <img
            src={CloseIcon}
            style={{ width: "14px" }}
          />
          </Button>
          <div className={classes.image}>
            <img src={icon} style={{ margin: margin, width: width ? width : null }} />
          </div>
          <DialogTitle>
            <Typography className={classes.title}>{title}</Typography>
          </DialogTitle>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={classes.cancelButton}
              onClick={button1Event}
              autoFocus
            >
              <Typography className={classes.cancelButtonText}>
                {button1}
              </Typography>
            </Button>
            <Button
              className={classes.yesButton}
              onClick={button2Event}
            >
              <Typography className={classes.yesButtonText}>
                {button2}
              </Typography>
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
