import { Box, Typography, Paper, Collapse } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import React from "react";
import { useLayoutEffect } from "react";
import PushPinIcon from '@mui/icons-material/PushPin';
//images
import sixDots from "assets/img/platformScreenVisualization/sixDots.svg";
import downbutton from "assets/img/platformScreenVisualization/downbutton.svg";
import sideButton from "assets/img/platformScreenVisualization/sideButton.svg";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";

//Components
import GridScreenView from "./GridScreenView";
import RemoveWidgetPopUp from "./RemoveWidgetPopUp";
import { Edit } from "@material-ui/icons";
import { Router, useHistory } from "react-router-dom/cjs/react-router-dom";

const ListScreenView = (props) => {
  const [removeWidgetPopup, setRemoveWidgetPopUp] = React.useState(false);
  const [widgetName, setWidgetName] = React.useState("");
  const [widgetId, setWidgetId] = React.useState("");
  const [indexWidget, setIndexWidget] = React.useState();
  const [bgColor, setBgColor] = React.useState("rgba(255, 255, 255, 0.6)");
  const history = useHistory();
  //Calculation of the image resolution
  const calImageRatio = eval(props.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/"));
  const  isWidgetPinned = props.pinnedWidgets[(props?.widget?._id ?? props?.widget?.id)]
  const innerBox = {
    color: "#0192D4",
    border: "1px solid transparent",
    boxShadow: " 0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(20px)",
    background:
      "linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box",
    borderRadius: "8px",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
  };

  const outerBox = {
    border: "1px dashed #D0D0D1",
    width: "89px",
    height: "58px",
    boxSizing: "border-box",
    borderRadius: "8px",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  };

  const widgetNameStyle = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "140%",
    color: "#424242",
  };

  const widgetDetailStyle = {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "140%",
    color: "#777777",
  };

  const removeWidget = () => {
    props.accessLevel !== "user"
      ? [
          setWidgetName(props?.widget?.name),
          setWidgetId(props?.widget?._id?.slice(-5) || props?.widget?.id?.slice(-5)),
          setIndexWidget(props?.index),
          setRemoveWidgetPopUp(true),
        ]
      : [props.setSnackMessage("You do not have access to delete widgets."), props.setSnackOpen(true)];
  };

  useLayoutEffect(() => {
   props?.openPreview ? setBgColor("#FFF") : setBgColor("rgba(255, 255, 255, 0.6)");
  }, [props?.openPreview]);

  const handleEditClick = (x, i) => {
    if(x?.item?.playlist?.type == 'K_PDF' || x?.item?.playlist?.type == "ATHENA"){
     
 }
else{
  history.push({
    pathname: "/admin/widgetmanager/edit",
    state: {
      widgetDetails: props?.widget,
      prevPage: "PlatformVis"
    },
  })
}
  localStorage.setItem("tabValue", "1")
  };
  return (
    <>
      <Paper
        style={{
          padding: "8.5px",
          background: bgColor,
          borderRadius: "6px",
          boxShadow: "none",
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          {/* {calImageRatio > 1 ? (
            <Box style={outerBox}>
              <div
                style={{
                  width: "73px",
                  height: "43px",
                  ...innerBox,
                }}
              >
                {props?.widget?.kalturaOTTImageType}
                <br/>
                {props?.widget?.layout}
              </div>
            </Box>
          ) : calImageRatio < 1 ? (
            <Box style={outerBox}>
              <div
                style={{
                  width: "33px",
                  height: "42px",
                  ...innerBox,
                }}
              >
                {props?.widget?.kalturaOTTImageType}
                <br/>
                {props?.widget?.layout}
              </div>
            </Box>
          ) : calImageRatio === 1 ? (
            <Box style={outerBox}>
              <div
                style={{
                  width: "43px",
                  height: "43px",
                  ...innerBox,
                }}
              >{}
                {props?.widget?.kalturaOTTImageType}
                <br/>
                {props?.widget?.layout}
              </div>
            </Box>
          ) : props.widget?.type === "ADS" || props.widget?.layout === "CUS"  ? (
            <Box style={outerBox}>
              <div
                style={{
                  width: "43px",
                  height: "43px",
                  ...innerBox,
                }}
              >
                {props.widget?.layout}
              </div>
            </Box>
          ) : null} */}
          <div style={{width:"10%"}}>

          </div>
          <div
            style={{
              display: "flex",
              width: "90%",
              justifyContent: "space-between",
            }}
          >
            <Box style={{display:"flex" ,gap:10,width:"40%"}}>
              <div>
              <Typography style={widgetNameStyle}>{props?.widget?.name}</Typography>
              {props?.widget?.layout === "HRO" ? (
                <Typography style={widgetDetailStyle}>Hero Banner</Typography>
              ) : (
                <Typography style={{...widgetDetailStyle}}>
                  PL-{props?.widget?.item?.playlist?.kalturaChannelId || props?.widget?.kalturaChannelId}&
                  {(props?.widget?.item?.title|| props?.widget?.title)?? props.widget?.item?.platform}
                  <br />
                  {props.widget?.item?.playlist?.data?.result?.error &&
                  props.widget?.item?.playlist?.type !== "K_PDF" &&
                  props.widget?.item?.playlist?.type !== "ATHENA" ? (
                    <span style={{ color: "red" }}>{props.widget?.item?.playlist?.data?.result?.error.message}</span>
                  ) : null}
                </Typography>
              )}
              </div>    
              
            </Box>
            <div style={{display:"flex" , flexDirection:"column" ,gap:"4px",width:"40%"}}>
            <Typography style={{...widgetDetailStyle }}>
                ID: WD-{props?.widget?._id?.slice(-5) || props?.widget?.id?.slice(-5)}
                </Typography>
                <Typography style={{...widgetDetailStyle }}>
                Layout: {props?.widget?.layout} & Aspect: {props?.widget?.kalturaOTTImageType}
                </Typography>
            </div>
     
          {props.widget?.item?.playlist?.predefPlaylistType === "MY_W" ||
            props.widget?.item?.playlist?.predefPlaylistType === "CON_W" ||
            props.widget?.item?.playlist?.predefPlaylistType === "WA_AGN" ||
            props.widget?.item?.playlist?.predefPlaylistType === "AT_MRC" ||
            props.widget?.item?.playlist?.predefPlaylistType === "AT_BYW" ||
            props.widget?.item?.playlist?.type === "ATHENA" ||
            props.widget?.item?.playlist?.data?.result?.error?.code == "4018" ||
            props.widget?.type === "ADS" ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "28%",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      lineHeight: "140%",
                      textAlign: "center",
                      background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    No Preview
                  </Typography>
                </span>

                {(typeof props?.widget!=="undefined" &&  typeof props?.widget?.containerId==="string" && (!((props?.widget?.item?.playlist?.type ) === 'K_PDF' || props?.widget?.item?.playlist?.type === "ATHENA")) || !(props?.widget?.item?.playlist?.type))
                ?<>
                                <div style={{ borderLeft: "1px solid blue", height: "20px" }}></div>
                                <Edit key={props?.widget?.name} style={{cursor:"pointer"}} onClick={handleEditClick}/>
                </> :                <div style={{ borderLeft: "1px solid blue", height: "20px" }}></div>
                }

               
                <img src={deleteBucket} style={{ cursor: "pointer" }} onClick={removeWidget} />
                <div
                style={{
                  cursor:"pointer"
                }}
                
                onClick={()=>{
                  if(props?.widget?._id  ){
                    let parameters = {
                      layout: props?.widget?.layout,
                      type:props?.widget?.type ,
                      p_id: props?.widget?.project || null
                    }
                    props.updatePinning(props?.widget?._id,parameters,!isWidgetPinned)
                  }
                }}> 
          <PushPinIcon style={{
                  cursor:"pointer",
                  height:"23px",
                  color:!isWidgetPinned?"#808080":"#008000"
                }}
                
                onClick={()=>{
                  if(props?.widget?._id ){
                    let parameters = {
                      layout: props?.widget?.layout,
                      type:props?.widget?.type ,
                      p_id: props?.widget?.project || null
                    }
                    props.updatePinning(props?.widget?._id,parameters,!isWidgetPinned)
                  }
                }}  />
                </div>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // gap:"10px",
                  width: "28%",
                  
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props?.setOpenPreview(props?.index===props?.openPreview?-1:props?.index);
                    props.setExpanded(props.expanded === props?.index ? -1 : props?.index);
                  }}
                >
                  {props?.openPreview===props?.index ? <img src={downbutton} /> : <img src={sideButton} />}
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      lineHeight: "140%",
                      marginLeft: "5px",
                      marginTop: "1px",
                      background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      verticalAlign: "bottom",
                      width: "56px",
                    }}
                  >
                    Preview
                  </Typography>
                </span>

                <div style={{ borderLeft: "1px solid blue", height: "20px" }}></div>
                {(typeof props?.widget!=="undefined" &&  typeof props?.widget?.containerId==="string" && (!((props?.widget?.item?.playlist?.type ) === 'K_PDF' || props?.widget?.item?.playlist?.type === "ATHENA")) || !(props?.widget?.item?.playlist?.type))
                ? <Edit key={props?.widget?.name} style={{cursor:"pointer"}} onClick={handleEditClick}/>:<></>
                }
                <img src={deleteBucket} style={{ cursor: "pointer" }} onClick={removeWidget} />
                <PushPinIcon style={{
                  cursor:"pointer",
                  height:"23px",
                  color:!isWidgetPinned?"#808080":"#008000"
                }}
                
                onClick={()=>{
                  if(props?.widget?._id ){
                    let parameters = {
                      layout: props?.widget?.layout,
                      type:props?.widget?.type ,
                      p_id: props?.widget?.project || null
                    }
                    props.updatePinning(props?.widget?._id,parameters,!isWidgetPinned)
                  }
                }}  />
              </Box>
            )}
          </div>
        </Box>
        <Collapse in={props.expanded === props?.index} timeout="auto" unmountOnExit>
          <GridScreenView widget={props.widget} />
        </Collapse>
      </Paper>
      <RemoveWidgetPopUp
        open={removeWidgetPopup}
        setRemoveWidgetPopUp={setRemoveWidgetPopUp}
        widgetName={widgetName}
        widgetId={widgetId}
        id={props?.widget?._id}
        removeWidget={props.removeWidget}
        indexWidget={indexWidget}
      />
    </>
  );
};

export default ListScreenView;
