import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@material-ui/core";
import "./styles.scss";
import sixDots from "assets/img/platformScreenVisualization/sixDots.svg";

const SortableScreensItem = ({ children, id }) => {
  const { attributes, isDragging, listeners, transform, transition, setNodeRef, setActivatorNodeRef } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} className="sortable-item" style={style} {...attributes}>
      <img
        draggable={false}
        src={sixDots}
        alt="Image"
        className="sortable-drag-handle"
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
      />
      <Box className="sortable-item-children">{children}</Box>
    </Box>
  );
};

export default SortableScreensItem;
