import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { Box, TableFooter, Tooltip } from "@material-ui/core";
import DeleteWidgetPopUp from "components/widgetManagerComponents/deleteWidgetPopup";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Edit } from "@material-ui/icons";

//Images
import deleteBucket from "../../assets/img/platformScreenVisualization/deleteBucket.svg";

// Styles
const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const [deleteWidgetState, setDeleteWidgetState] = React.useState({
    widgetName: "",
    widgetId: "",
    status:false
  });
  const [copiedWidgets,setCopiedWidgets] = useState({})

  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText)
      .then(() => {
        setCopiedWidgets(copiedWidgets=>{
          return {[copyText]:true}
        })
        setTimeout(()=>{
          setCopiedWidgets(copiedWidgets=>{
            return {[copyText]:false}
          })
        },1000)
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const { tableHead, tableData, tableHeaderColor, pagination } = props;

  const history = useHistory();

  // Function to navigate to edit widget page
  const editWidget = (WidgetDetails) => {
    localStorage.setItem("VIVREWidgetPageNumber", props.pageNumber);
    if(WidgetDetails?.item?.playlist?.type == 'K_PDF' || WidgetDetails?.item?.playlist?.type == "ATHENA"){
      // history.push({
      //   pathname: "/admin/widgetmanager/edit",
      //   state: { widgetDetails: WidgetDetails,
      //     prevPage: "WidgetManager" },
      // })
 }
 else{
      history.push({
        pathname: "/admin/widgetmanager/edit",
        state: { widgetDetails: WidgetDetails,
          prevPage: "WidgetManager" },
      })
 }
  };

  // Function to open delete widget popup
  const deleteWidgetPopup = (widgetDetails) => {
    setDeleteWidgetState({
          widgetName: widgetDetails.name,
          widgetId: widgetDetails._id,
          status:true
    })
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {/* Table Head */}
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, id) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={prop+id}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        {/* Table Body */}
        <TableBody>
          {tableData?.map((prop, key) => {
            return (
              <TableRow key={prop._id} className={classes.tableBodyRow}>
              <TableCell  className={classes.tableCell + " " + classes.tableBodyCell}  onClick={() => editWidget(prop)}>
              
          <Box  style={{display:"flex",alignItems:"center", justifyContent:"center",gap:"6px" , position:"relative"}}>
          
        <p >{prop._id.slice(-5)}</p>
        <div className={classes.copyIcon}>
        <Tooltip  
        placement="top" 
        enterDelay={100} 
        arrow
        id="widgetId-tooltip"  
        title={copiedWidgets[prop._id]?"Copied":"Copy"}
        >

{copiedWidgets[prop._id]?<CheckCircleIcon style={{width:"14px",color:"#3f51b5"}}/>:
  <FileCopyIcon    onClick={(e)=>{
          e.stopPropagation()
          handleCopy(prop._id)
          
        }}  style={{width:"14px",color:"#3f51b5"}}/>
}


        </Tooltip>
        </div>
        
       
            </Box>             

      </TableCell>
                <TableCell className={classes.tableCell + " " + classes.tableBodyCell}  onClick={() => editWidget(prop)}>
                  {prop.name}
                </TableCell>
                <TableCell className={classes.tableCell + " " + classes.tableBodyCell}  onClick={() => editWidget(prop)}>
                  {prop.layout}{prop.kalturaOTTImageType && `(${prop.kalturaOTTImageType})` }
                </TableCell>
                {prop.layout === 'HRO' ? (
                  <TableCell className={classes.tableCell + " " + classes.tableBodyCell}  onClick={() => editWidget(prop)}>
                    {prop.item?.landingPage?.playlist?.kalturaChannelId}
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableCell + " " + classes.tableBodyCell} onClick={() => editWidget(prop)}>
                    {prop.item?.playlist?.kalturaChannelId}
                  </TableCell>
                )}
                <TableCell className={classes.tableCell + " " + classes.tableBodyCell}  onClick={() => editWidget(prop)}>
                  {prop.supportedDevices.length}
                </TableCell>
                <TableCell className={classes.tableCell + " " + classes.tableBodyCell}   onClick={() => editWidget(prop)}>
                  {prop.status}
                </TableCell>
               
                <TableCell  className={classes.tableCell + " " + classes.tableBodyCell}  >
        <Box style={{display:"flex",gap:"20px"}}>
          {
            prop?.item?.playlist?.type == 'K_PDF' || prop?.item?.playlist?.type == "ATHENA"
            ?    <div style={{height:"18px",width:"23px"}} />:
            <Edit onClick={() => editWidget(prop)} style={{height:"18px"}}/>

          }
{
            prop?.item?.playlist?.type == 'K_PDF' || prop?.item?.playlist?.type == "ATHENA"
            ?    <div style={{height:"18px",width:"23px"}} />:
            <img src={deleteBucket} alt="delete"  onClick={() => deleteWidgetPopup(prop)}/>

          }

      
        </Box>
                  
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {/* Table Footer */}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={12} style={{ paddingBottom: "32px" }}>
              {pagination}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {/* Delete Widget Popup */}
      <DeleteWidgetPopUp
        setRemoveWidgetPopUp={(value)=>setDeleteWidgetState(state=>({...state,status:value}))}
        open={deleteWidgetState.status}
        widgetName={deleteWidgetState.widgetName}
        widgetId={deleteWidgetState.widgetId}
        projectDetails={props.projectDetails}
      />
    </div>
  );
}


CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};


CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired
};
