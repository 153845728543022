import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    
    padding: "0 15px !important",
    display:'flex',
    // justifyContent:'space-between'
    gap:"40px"
   
  },
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {

  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid} spacing={3} >
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
};
