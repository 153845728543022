import React from "react";
import classNames from "classnames";

//Material ui v4 component
import { Box, makeStyles, IconButton, Select, MenuItem } from "@material-ui/core";

//Image Import
import ListViewImage from "../../assets/img/platformScreenVisualization/ListViewImage.svg";
import GridViewImage from "../../assets/img/platformScreenVisualization/GridViewImage2.svg";
import style from "assets/jss/material-dashboard-react/views/screenStyle";
import { Computer, Smartphone } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { isDev } from "utils/getEnvironment";

const useStyle = makeStyles(style);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 230,
      borderRadius: "8px",
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const GridOrListViewScreenButton = props => {
  const classes = useStyle();
  const history = useHistory();

  //OnClick Function
  const selectList = () => {
    props.setListView(true);
  };

  const selectGrid = () => {
    props.setListView(false);
  };

  const handlePreview = () => {
    props.setOpen(true);
  };

  const handleLaptopPreview = () => {
    props.setLaptopPreview(true);
  };
  const handleChange = event => {
    props.setPlatformName(event.target.value);
    props?.platforms.map(x => {
      if (x._id.name === event.target.value) {
        history.replace({
          pathname:props?.listView !== undefined ? "/admin/projectsplateformmanager/screens":"/admin/projectsplateformmanager/appscreens",
          state: {
            projectId: history.location.state.projectId,
            platform: x._id.shortName,
            platformName: event.target.value,
          },
        });
        props.setPlatform(x._id.shortName);
        localStorage.setItem("platform", JSON.stringify(x._id));
      }
     
    });
    if(props?.listView!==undefined){
    props?.setTabValue(0);
    props?.setDefaultTabValue(false);}
  };

  var gridSelect;
  var listSelect;
  if (props?.listView) {
    listSelect = classNames({
      ["  " + classes.listSelected]: true,
    });
  } else {
    gridSelect = classNames({
      ["  " + classes.gridSelected]: true,
    });
  }

  return (
    <div className={classes.mainContainerListAndGridViewButton}>
      <Box>
        <Select
          onChange={handleChange}
          className={classes.dropdown}
          value={props.platformName}
          MenuProps={MenuProps}
          variant="standard"
          fullWidth
          size="small"
          disableUnderline
        >
          {props?.platforms?.map((x, i) => {
            return (
              <MenuItem value={x?._id?.name} style={{ color: "#777777" }} key={i}>
                <div className={classes.menuItemContainer}>
                  <img
                    src={`${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${
                      x?._id?.shortName
                    }.svg`}
                    width="30px"
                  />
                  {x?._id?.name}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box style={{ display: "flex", gap: "5px" }}>
        <Box className={classes.devicePreviewContainer}>
          {props.platformName === "Android Mobile App" || props.platformName === "iOS Mobile App" ? (
            <span>
              <IconButton onClick={handlePreview}>
                <Smartphone style={{ color: "black" }} />
              </IconButton>
            </span>
          ) : (
            <span>
              <IconButton onClick={handleLaptopPreview}>
                <Computer style={{ color: "black" }} />
              </IconButton>
            </span>
          )}
        </Box>
        {props?.listView !== undefined ? (
          <Box className={classes.gridListButtonsContainer}>
            <span className={classNames(classes.gridOrListViewButton, listSelect)} onClick={selectList}>
              <img src={ListViewImage} alt="slide button" />
            </span>
            <span className={classNames(classes.gridOrListViewButton, gridSelect)} onClick={selectGrid}>
              <img src={GridViewImage} alt="slide button" />
            </span>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default GridOrListViewScreenButton;
