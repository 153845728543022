const dialogStyle = {
  content: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  dialogBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 22px 22px",
  },
  title: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#424242",
    paddingBottom: "0.4rem",
  },
  cancelButton: {
    width: "180px",
    height: "48px",
    boxSizing: "border-box",
    borderRadius: "33px",
    background:
      "linear-gradient(#FFF, #FFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) border-box",
    border: "1px solid transparent",
    boxShadow: "none",
  },
  yesButton: {
    width: "180px",
    height: "48px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "none",
    borderRadius: "33px",
    marginLeft: "20px !important",
    filter: "drop-shadow(0px 2px 20px rgba(0, 60, 118, 0.6))",
  },
  cancelButtonText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "140%",
    textAlign: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    textTransform: "none",
  },
  yesButtonText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "none",
  },
  button: {
    position: "absolute",
    top: "21px",
    right: "21px",
    cursor: "pointer",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "50%",
  },
  image: {
    height: "72px",
    width: "72px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(180deg, rgba(1, 157, 243, 0.06468) 0%, rgba(1, 157, 243, 0.098) 100%)",
  },
};

export default dialogStyle;
