export default function rgbaToHex(r, g, b, a) {
  let outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach((part, i) => {
    if (part.length === 1) outParts[i] = "0" + part;
  });

  return "#" + outParts.join("");
}
