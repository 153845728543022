import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const NoResults = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="80vh"
      textAlign="center"
    >
      <ManageSearchIcon style={{ fontSize: 80, color: '#9e9e9e' }} />
      <Typography variant="h4" style={{ color: '#9e9e9e' }}>
        No Results Found
      </Typography>
      <Typography variant="body1" style={{ color: '#9e9e9e' }}>
        Try adjusting your search or filter to find what you are looking for.
      </Typography>
    </Box>
  );
};

export default NoResults;
