export const InitialState = {
  loading: false,
  platforms: [],
  allPlatforms: {},
  screens: [],
  defaultScreens: [],
  widgets: [],
  widgetDetails: {},
  createScreenData: {},
  widgetDetailsArray: [],
  reorderWidgets: false,
  screenReset: false,
  widgetReset: false,
  updateWidgetData: {},
  reorderScreenData: {},
  refreshRemoveScreen: false,
  statusChanged: false,
  deleteScreenRes: {},
  exportScreenRes: {},
  screenStatusRes: {},
  platformDetails: {},
  splashResponse: {},
  introResponse: {},
  updatedScreen: [],
  updatedScreenDetails: [],
  widgetUsage: []
};
