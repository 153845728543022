import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

//Material ui components
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

//importing Carousel
import Carousel from "react-multi-carousel";

import NextIcon from "assets/img/platformScreenVisualization/NextIcon.svg";
import BackIcon from "assets/img/platformScreenVisualization/BackIcon.svg";

import styles from "assets/jss/material-dashboard-react/components/linearChannelStyle";

const useStyles = makeStyles(styles);


//Linear channel Carousel View
const LinearChannel = (props) => {
  const classes = useStyles();

  const responsive = {
    extraLarge: {
      breakpoint: { max: 800, min: 700 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      paritialVisibilityGutter: 30,
    },
  };

  //Custom Arrow for the Carousel used to display linear channel
  //Next Custom Arrow
  const NextArrow = ({ onClick, type }) => {
    const classes = useStyles();
    return (
      <div
        className={classNames(classes.Arrows, classes.nextArrowHorizontal)}
        onClick={() => onClick()}
      >
        <img src={NextIcon} />
      </div>
    );
  };

  //Back Custom Arrow
  const BackArrow = ({ onClick, type }) => {
    const classes = useStyles();
    return (
      <div
        className={classNames(classes.Arrows, classes.backArrowHorizontal)}
        onClick={() => onClick()}
      >
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  };

  return (
    <div style={{ marginTop: "10px" }}>
     {props?.rail?.length>0?
      <Carousel
        style={{ marginTop: "8px" }}
        swipeable={true}
        // partialVisbile
        deviceType={props?.deviceType}
        itemClass="image-item-grid"
        keyBoardControl={true}
        customTransition={"transform 500ms ease-in-out"}
        showDots={false}
        //transitionDuration={300}
        // ref={(el) => (Carousel = el)}

        centerMode={true}
        customLeftArrow={<BackArrow type="HorizontalRail" />}
        customRightArrow={<NextArrow type="HorizontalRail" />}
        //customRightArrow={
        //  <CustomRightArrow
        // onbuttonClick={() => {
        // setTimeout(() => {
        //         if (items.length > 0) {
        //           items = [];
        //         }
        //       }, 2000);
        //     }}
        //   />
        // }
        // customLeftArrow={<CustomLeftArrow />}
        // containerClass='carousel-container'
        removeArrowOnDeviceType={["mobile", "tablet"]}
        sliderClass="sliderClassGrid"
        //  CustomButtonGroupAsArrows={<CustomButtonGroupAsArrows />}
        responsive={responsive}
        minimumTouchDrag={10}
        slidesToSlide={2}
        additionalTransfrom={0}
      > 

        {props?.rail?.map((channel, index) => {
          let selectedChannel;
          if (index === props?.selectedChannelIndex) {
            selectedChannel = classNames({
              ["  " + classes.selectChannel]: true,
            });
          }

          return (
            <div
              className={classNames(
                selectedChannel,
                classes.linearChannelDetailBox
              )}
              onClick={() => props.selectLinearChannel(channel,index)}
            >
              <img
                alt="Imge"
                src={`${channel?.images[0]?.url}/width/500/height/280`}
                style={{
                  cursor: "pointer",
                  objectFit: "contain",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "96%",
                  borderRadius: "4px",
                  // width: "280px",
                  // height: "100px",
                  // margin: 0,
                  // objectFit:''
                }}
                // align="center"
              />
            </div>
          );
        })}
      </Carousel>:<div>No linear Channel available</div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearChannel);
