import React from 'react';
import {
  FormControl,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  Switch,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const WhiteSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 22,
    padding: 0,
    display: 'flex',
    paddingBottom: '2px',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 12,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: '20px',
  },
  select: {
    minWidth: 150,
  },
  multiSelectDropdown: {
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: 190,
  },
}));

const MetadataComponent = ({ metaDataDetails = {}, setMetaDataDetails ,openMandatoryWarn,setOpenMandatoryWarn,setHasSelection}) => {
  const classes = useStyles();

 
  const {
    metadata: {
      enabled = false,
      position = "TR",
      displayContent = {},
    } = {},
  } = metaDataDetails; 

  const handleToggleChange = () => {
    setMetaDataDetails((prev) => ({
      ...prev,
      metadata: { ...prev.metadata, enabled: !enabled },
    }));
  };

  const handlePositionChange = (event) => {
    setMetaDataDetails((prev) => ({
      ...prev,
      metadata: { ...prev.metadata, position: event.target.value },
    }));
  };

  const handleSelectChange = (key) => {
    setMetaDataDetails((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        displayContent: {
          ...displayContent,
          [key]: !displayContent[key],
        },
      },
    }));
    setOpenMandatoryWarn(false)
    setHasSelection(Object.values(displayContent).some((value) => value === true));
  };

  console.log(metaDataDetails,"dfdfd")
  const hasSelection = Object.values(displayContent).some((value) => value === true);
  setHasSelection(hasSelection);
  
  return (
    <Box className={classes.root}>
      <Box className={classes.switchContainer}>
        <Typography variant="body1">Enable Metadata</Typography>
        <WhiteSwitch checked={enabled} onChange={handleToggleChange} />
      </Box>

      {enabled && (
        <>
          <FormControl variant="outlined" className={classes.select}>
            <InputLabel>Metadata Position</InputLabel>
            <Select
              value={position}
              onChange={handlePositionChange}
              label="Metadata Position"
            >
              <MenuItem value="TR">Top Right</MenuItem>
              <MenuItem value="BR">Bottom Right</MenuItem>
              <MenuItem value="BL">Bottom Left</MenuItem>
              <MenuItem value="TL">Top Left</MenuItem>
            </Select>
          </FormControl>

          {/* Multi-select Dropdown */}
          <FormControl variant="outlined" className={classes.multiSelectDropdown} style={{marginTop:openMandatoryWarn?"23px":""}}>
            <InputLabel>Select Metadata</InputLabel>
            <Select
              multiple
              value={Object.keys(displayContent).filter((key) => displayContent[key])}
              renderValue={() => 'Select Metadata'}
              label="Select Metadata"
            >
              <MenuItem disabled value="">
                <>Select</>
              </MenuItem>
              {Object.keys(displayContent).map((key) => (
                <MenuItem key={key} onClick={() => handleSelectChange(key)}>
                  <Checkbox checked={displayContent[key]} />
                  <Typography variant="body2">{key}</Typography>
                </MenuItem>
              ))}
            </Select>
            {(openMandatoryWarn) && <span className={classes.validationText } style={{color:"#FF2727"}}>* Mandatory Field</span>}

          </FormControl>
        </>
      )}
    </Box>
  );
};

export default MetadataComponent;
