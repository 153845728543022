
import React, { useEffect, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import VivreAutocomplete from "../Autocomplete/Autocomplete";

const AspectRatio = ({
  classes,
  projectAspectRatio,
  getImageDimensions,
  openMandatoryWarn,
  imageRatio,
  type,
  selectedAspectRatio,
  setSelectedAspectRatio,
  bannerSelected, 
}) => {
  const [filteredAspectRatios, setFilteredAspectRatios] = useState([]);

  useEffect(() => {
    console.log("projectASPECt------------------->>>>>",projectAspectRatio);
    
    const filteredRatios =
      type === "Carousel"
        ? projectAspectRatio.filter((ratio) => ratio !== "1x1")
        : projectAspectRatio;

    setFilteredAspectRatios(filteredRatios);

    if (typeof bannerSelected === "number") {
      setSelectedAspectRatio(projectAspectRatio[bannerSelected]);
    } else {
      setSelectedAspectRatio(""); 
    }
     
    console.log("Filtered Aspect Ratios:", filteredRatios);
  }, [type, projectAspectRatio, bannerSelected, setSelectedAspectRatio]);

  const handleAspectRatioChange = (event, value) => {
    setSelectedAspectRatio(value); 
    handleImageDimensions(value);  
  };

  const handleImageDimensions = (dimension) => {
    const ratioParts = dimension?.split("x");
    const imageCal = ratioParts?.length === 2 ? ratioParts[0] / ratioParts[1] : 0;

    if (imageCal <= 1.5 && imageCal > 1) {
      getImageDimensions(dimension, 144, 96, 3);
    } else if (imageCal > 1.5) {
      getImageDimensions(dimension, 170, 96, 2);
    } else if (imageCal > 0.5 && imageCal < 1) {
      getImageDimensions(dimension, 64, 96, 6);
    } else if (imageCal === 1) {
      getImageDimensions(dimension, 96, 96, 4);
    }
  };

  return (
    <div className={classes.widgetDetailContainer}>
      <Box sx={{ pt: 2 }}>
        <VivreAutocomplete
          key={type} 
          value={selectedAspectRatio || ""} 
          defaultValue={
            typeof bannerSelected === "number"
              ? projectAspectRatio?.[bannerSelected]
              : ""  
          }
          options={filteredAspectRatios} 
          getOptionLabel={(option) => option}
          renderInput={(props) => (
            <TextField
              {...props}
              size="small"
              className={classes.textField}
              placeholder="Select Banner Layout*"
              variant="outlined"
              label="Banner Layout"
              style={{
                width: "230px",
                background: "white",
                height: "40px",
                borderRadius: "5px",
                marginBottom: openMandatoryWarn && !imageRatio ? "2px" : "0",
              }}
            />
          )}
          onChange={handleAspectRatioChange} 
        />
        {openMandatoryWarn && !imageRatio && (
          <span
            className={classes.validationText}
            style={{ color: "red", marginTop: "-5px" }}
          >
            * Mandatory Field
          </span>
        )}
      </Box>
    </div>
  );
};

export default AspectRatio;

