import React from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export * from "./useRouteName";

export function useCloseDropdown(intialValue) {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(intialValue);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  console.log("Ref laga ke dekh", ref);
  return { open, setOpen, ref };
}

export const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search
  const query = new URLSearchParams(search);

  const setQueryparams = (name,value) => {
    query.set(name, value);
    history.push({ ...location, search: query.toString() });
  };

  const removeQueryparams = (name) => {
    query.delete(name)
    history.push({ ...location, search: query.toString() });
  };

  const getQueryparams = (name) => {
    const searchParams = query.get(name);
    return searchParams
  };

  return [getQueryparams, setQueryparams, removeQueryparams];
};
