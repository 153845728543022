import {
  Box,
  Button,
  Card,
  colors,
  FormHelperText,
  Input,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Primary from "components/Typography/Primary";
import React, { useEffect, useState } from "react";
import Style from "../../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
import ColorPickerComponent from "components/ColorPicker";
import { SketchPicker } from "react-color";
import rgbaToHex from "utils/rgbaToHex";
import axios from "axios";

import {
  publishScreenAction,
} from "screens/PlatformManager/actions/PlatformAction";
import { connect } from "react-redux";
import { updateScreenDetails } from "screens/PlatformManager/actions/PlatformAction";
import { useHistory } from "react-router-dom";


const useStyle = makeStyles(Style);

let manditoryErrorText = "*Mandatory Field";

const ScreenTheme = (props) => {
  const [iTheme, setiTheme] = useState({
    colors:{}
  });
  const [error, setError] = useState({
    bgImage1x1: "",
    bgImage16x9: "",
    manditory: false,
  });
  const [displayColorPicker, setDisplayColorPicker] = useState({
    bgColor: false,
    fgColor: false,
    hgColor: false,
    gdColor: false,
    ftColor: false,
    mnColor: false,
    btn_gdColor: false,
  });
  const classes = useStyle();
  const history = useHistory()


  const handleBackgroundOption = (event) => {
    switch (event.target.value) {
      case "bgColor":
        setiTheme({
          ...iTheme,
          bgOption: event.target.value,
          colors: { ...iTheme.colors, gdColor: "" },
          ...(iTheme?.bgImage?.["1x1"] || 
            iTheme?.bgImage?.["1x1"]? ({bgImage:{
              "1x1": "",
              "16x9": "",
            }}):{})
        });
        break;
      case "gdColor":
        setiTheme({
          ...iTheme,
          bgOption: event.target.value,
          colors: { ...iTheme.colors, bgColor: "" },
           ...(iTheme?.bgImage?.["1x1"] || 
            iTheme?.bgImage?.["1x1"]? ({bgImage:{
              "1x1": "",
              "16x9": "",
            }}):{}),
        });
        break;
      case "bgImage":
        setiTheme({
          ...iTheme,
          bgOption: event.target.value,
          colors: { ...iTheme.colors, gdColor: "", bgColor: "" },
        });
        break;
    }
  };
console.log("theme",iTheme)
  const handleColor = (color,value) => {

    setiTheme({
      ...iTheme,
      colors: { ...iTheme.colors, [color]: value },
    });
  };

  const handleColorPicker = (color) => {
    setDisplayColorPicker((prev) => {
      return { ...prev, [color]: !prev[color] };
    });
  };

  const handleImageInputError = (name, message, reset = false) => {
    setError((error) => ({
      ...error,
      [name]: message,
    }));
  };
  const getRatio = async (url) => {
    console.log("URL rat", url);
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        let ratio = img.width / img.height;
        resolve(ratio);
      };
    });
  };

  
  const getBase64 = async (files) => {
    if (files.length === 1) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = async (e) => {
          const ratio = await getRatio(e.target.result);
          resolve([{ b64: reader.result, ratio }]);
        };
      });
    } else {
      const fileData = Object.values(files);
      const filePathsPromises = [];
      fileData.forEach((file) => {
        filePathsPromises.push(
          new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
              const ratio = await getRatio(e.target.result);
              resolve({ b64: reader.result, ratio });
            };
          }),
        );
      });
      const filePaths = await Promise.all(filePathsPromises);
      return filePaths;
    }
  };
  const imageHandler = async (event,index=null) => {
    const files = event.target.files;

    const imageSizes = [...Array(files.length)].map(
      (_, index) => files[index].size,
    );

    const fileType = [...Array(files.length)].map((_, index) => {
      return files[index].type;
    });
    let _supportedImageTypes;
    const _backgroundImageSupportedImageTypes = ["image/png", "image/jpeg"];
    if(event.target.id=="16x9" || event.target.id=="1x1"){
        _supportedImageTypes = _backgroundImageSupportedImageTypes
    }
    if (
      fileType.every((type) => {
        console.log(type, "type");
        return !_supportedImageTypes.includes(type);
      })
    ) {
      return files.length
        ? handleImageInputError(
            event.target.id,
            `Only ${_supportedImageTypes.map(type=>type.slice(5,type.length)).join(",")} files are supported.`,
          )
        : null;
    }

    if (imageSizes.every((size) => size < 1048576*5)) {
      const images = await getBase64(files);
      console.log("images", images, images.length);

      const uploadAPI = async (images) => {
        let _endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`
        return await axios
          .post(
            _endpoint,
            { file: images[0].b64 },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_X_API_KEY,
                Authorization: localStorage.getItem("token"),
              },
            },
          )
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log("Error:", err);
          });
      };

      const checkAR = (images, platform) => {
        console.log(images[0].ratio, "ratio");
        if (images.length > 1) {
          if (platform === "wide") {
            return images.every((img) => img.ratio > 1 && img.ratio < 2)
              ? true
              : false;
          } else if (platform === "small") {
            return images.every((img) => img.ratio <= 1) ? true : false;
          }
        } else if (images.length === 1) {
          if (platform === "wide") {
            return images[0].ratio > 1 && images[0].ratio < 2 ? true : false;
          } else if (platform === "small") {
            return images[0].ratio <= 1 ? true : false;
          }
        }
      };

      if (images.length && event.target.id) {
        let S3image;
        console.log(event.target.id, "event.target.id");
        if (event.target.id === "16x9") {
          if (!checkAR(images, "wide")) {
            return handleImageInputError(
              event.target.id,
              "This platform supports 16x9 image ratio only.",
            );
          }
        } else if (event.target.id === "1x1") {
          if (!checkAR(images, "small")) {
            return handleImageInputError(
              event.target.id,
              "This platform supports 9x16 image ratio only.",
            );
          }
        }
        S3image = await uploadAPI(images);
        if (S3image?.data?.uploadResult?.Location) {
          handleImageInputError(event.target.id, "");
          if(event.target.id === "1x1" || event.target.id==="16x9"){
            setiTheme({
              ...iTheme,
              bgImage: {
                ...(iTheme?.bgImage
                  ? iTheme?.bgImage
                  : {}),
                [event.target.id]: S3image?.data?.uploadResult?.Location,
              },
            })
          }
         
        } else {
          handleImageInputError(
            event.target.id,
            "Image could not be uploaded. Please try again.",
          );
        }
      } else {
        handleImageInputError(
          event.target.id,
          "Image could not be uploaded. Please try again.",
        );
      }
    } else {
      handleImageInputError(event.target.id, "Maximum file limit is 150MB.");
    }
    event.target.value = null;
  };
  useEffect(() => {
    if (
      typeof props?.location?.state?.theme === "object" &&
      Object.keys(props?.location?.state?.theme).length > 0
    ) {
      setiTheme(props?.location?.state?.theme);
    }
  }, [props?.location?.state?.theme]);

  const validateTheme = ()=>{
    const bgOption = iTheme?.bgOption || "bgColor"
    if(
      (bgOption==="bgImage" && !iTheme?.bgImage?.["1x1"] && !iTheme?.bgImage?.["16x9"] ) ||
      (bgOption==="gdColor" && !iTheme?.colors?.gdColor) ||
      (bgOption==="bgColor" && !iTheme?.colors?.bgColor) ||
      (iTheme?.colors?.fgColor === undefined || iTheme?.colors?.fgColor === "")
      ){
      setError(error=>({...error,manditory:true}))
      return false
    }
    return true
  }
  const handleSubmitTheme = ()=>{
    if(validateTheme()){
      const publishScreen = {
        p_id: screenData.p_id,
        platform: screenData.platform,
        context: "theme",
        status: "DRAFT",
        name:screenData?.screenName,
        theme:iTheme,
        prevScreen:"PlatformVis"
      };
      if(screenData){
        props.updateScreenDetails(publishScreen);
      }


    }

  }
  console.log(props?.location?.state)
  const screenData = props?.location?.state
  return (
    <div style={{minHeight:"80vh"}}>
      <Primary>Configure Screen Theme</Primary>
      <Box display={"flex"} gridGap={"40px"} >
        <Box   paddingBottom="6px">
          <Typography className={classes.inputLabel}>
            Button Gradient Color
          </Typography>
          <Box
            className={classes.swatch}
            style={{
              background: iTheme?.colors?.btn_gdColor,
            }}
            onClick={()=>handleColorPicker("btn_gdColor")}
          >
            <Box className={classes.color} />
          </Box>
          {displayColorPicker.btn_gdColor ? (
            <Box className={classes.popover}>
              <Box
                className={classes.cover}
                onClick={()=>handleColorPicker("btn_gdColor")}
              />
              <ColorPickerComponent
                key="btn_gdColorPicker"
                color={iTheme.colors?.btn_gdColor}
                onChange={(val)=>handleColor("btn_gdColor",val)}
              />
            </Box>
          ) : null}
        </Box>
        <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                        Menu Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                     
                      style={{
                        background: iTheme?.colors?.mnColor,
                                            
                      }}
                      onClick={()=>handleColorPicker("mnColor")}
     
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background: iTheme?.colors?.mnColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.mnColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={()=>handleColorPicker("mnColor")}
                        />
                        <SketchPicker
                          key="mnColorPicker"
                          color={iTheme?.colors?.mnColor || "white"}
                          onChange={(value)=>handleColor("mnColor",rgbaToHex(
                            value.rgb.r,
                            value.rgb.g,
                            value.rgb.b,
                            value.rgb.a,
                          )
                      )}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                      Highlighting Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                      style={{
                        background: iTheme.colors?.hgColor,
                      }}
                      onClick={()=>handleColorPicker("hgColor")}
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background: iTheme.colors?.hgColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.hgColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={()=>handleColorPicker("hgColor")}
                        />
                        <SketchPicker
                          key="hgColorPicker"
                          color={iTheme.colors?.hgColor}
                          onChange={(value)=>handleColor("hgColor",rgbaToHex(
                            value.rgb.r,
                            value.rgb.g,
                            value.rgb.b,
                            value.rgb.a,
                          ))}
                        />
                      </Box>
                    ) : null}
                    <FormHelperText  style={{ color:"red"}}>
                    {error.manditory &&
          (iTheme.colors?.hgColor === undefined || iTheme.colors?.hgColor === "")
            ? manditoryErrorText
            : ""}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                        Font Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                     
                      style={{
                        background: iTheme?.colors?.ftColor,
                      }}
                      onClick={()=>handleColorPicker("ftColor")}
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background:
                            iTheme.colors?.ftColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.ftColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={()=>handleColorPicker("ftColor")}
                        />
                        <SketchPicker
                          key="ftColorPicker"
                          color={iTheme?.colors?.ftColor|| "white"}
                          onChange={(value)=>handleColor("ftColor",rgbaToHex(
                            value.rgb.r,
                            value.rgb.g,
                            value.rgb.b,
                            value.rgb.a,
                          ))}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                      Foreground Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                      style={{
                        background:  iTheme.colors?.fgColor,
                      }}
                      onClick={()=>handleColorPicker("fgColor")}
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background:
                            iTheme.colors?.fgColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.fgColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={()=>handleColorPicker("fgColor")}
                        />
                        <SketchPicker
                          key="fgColorPicker"
                          color={ iTheme.colors?.fgColor || "white"}
                          onChange={(value)=>handleColor("fgColor",rgbaToHex(
                            value.rgb.r,
                            value.rgb.g,
                            value.rgb.b,
                            value.rgb.a,
                          ))}
                        />
                      </Box>
                    ) : null}
                    <FormHelperText  style={{ color:"red"}}>
                    {error.manditory && (iTheme.colors?.fgColor === undefined || iTheme.colors?.fgColor === "")
            ? manditoryErrorText
            : ""}
                    </FormHelperText>
                  </Box>
      </Box>
      <Box paddingBottom="20px">
        <Typography className={classes.inputLabel}>
          Background Option
        </Typography>
        <TextField
          id="backgroundType"
          value={iTheme?.bgOption || ""}
          onChange={handleBackgroundOption}
          select
          size="small"
          variant="outlined"
          style={{
            minWidth: "150px",
            backgroundColor: "#FFF",
            display: "flex",
            width: "30%",
          }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
          }}
        >
          <MenuItem value={"bgColor"}>Background Color</MenuItem>
          <MenuItem value={"bgImage"}>Background Image</MenuItem>
          <MenuItem value={"gdColor"}>Background Gradient</MenuItem>
        </TextField>
        <FormHelperText style={{ color: "red" }}>
          {error.manditory &&
          (iTheme.bgOption === undefined || iTheme.bgOption === "")
            ? manditoryErrorText
            : ""}
        </FormHelperText>
      </Box>
      <Box display={"flex"} gridGap={"40px"} >
<Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                      Background Color
                    </Typography>
                    <Box
                      className={classes.swatch}
                    
                      style={{
                        background: iTheme.colors?.bgColor,
                        cursor:iTheme?.bgOption==="bgColor"?"pointer":"not-allowed"
                      }}
                      onClick={(e)=>{
                        if (iTheme?.bgOption==="bgColor"){
                          handleColorPicker("bgColor")
                        }
                      }
                    }
     
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background: iTheme.colors?.bgColor,
                        }}
                      />
                    </Box>
                    {displayColorPicker.bgColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={(e)=>{
                            handleColorPicker("bgColor")
                          }
                        }
                        />
                        <SketchPicker
                          key="bgColorPicker"
                          color={iTheme?.colors?.bgColor}
                          onChange={(value)=>handleColor("bgColor",rgbaToHex(
                            value.rgb.r,
                            value.rgb.g,
                            value.rgb.b,
                            value.rgb.a,
                          ))}
                        />
                      </Box>
                    ) : null}
                    <FormHelperText  style={{ color:"red"}}>
                    {error.manditory &&iTheme?.bgOption==="bgColor"  &&
          (iTheme.colors?.bgColor === undefined || iTheme.colors?.bgColor === "")
            ? manditoryErrorText
            : ""}
            
                      </FormHelperText>
      </Box>
      <Box paddingBottom="6px">
                    <Typography className={classes.inputLabel}>
                      Background Gradient
                    </Typography>
                    <Box
                      className={classes.swatch}
                      style={{
                        background: iTheme.colors?.gdColor,
                        cursor:iTheme?.bgOption==="gdColor"?"pointer":"not-allowed"
                      }}
                      onClick={(e)=>{
                        if (iTheme?.bgOption==="gdColor"){
                        handleColorPicker("gdColor")
                        }
                      }
                      }
                    >
                      <Box className={classes.color} />
                    </Box>
                    {displayColorPicker.gdColor ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={()=>handleColorPicker("gdColor")}
                        />
                        <ColorPickerComponent
                          key="gdColorPicker"
                          color={iTheme.colors?.gdColor}
                          onClick={(e)=>{
                            handleColorPicker("gdColor")
                           
                            }
                          }
                          onChange={(val)=>handleColor("gdColor",val)}
                        />
                      </Box>
                    ) : null}
                     <FormHelperText  style={{ color:"red"}}>
                        {error.manditory && iTheme?.bgOption==="gdColor" && (iTheme.colors?.gdColor === undefined ||
                        iTheme.colors?.gdColor === "")
                          ? manditoryErrorText
                          : ""}
                      </FormHelperText>
                  </Box>
</Box>
<Box>
                <Typography
                  style={{ marginBottom: "10px" }}
                  className={classes.inputlabel}
                >
                  Background Image
                </Typography>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <Box paddingBottom="5px">
                    <label htmlFor="16x9" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ 
                          display: "none",
                          cursor:iTheme?.bgOption==="bgImage"?"pointer":"not-allowed"
                         }}
                        id="16x9"
                        name="16x9"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        required
                        inputProps={{ accept: "image/*" }}
                        fullWidth
                        disabled =  {iTheme?.bgOption==="bgImage"?false:true}

                      />
                      <Box
                        className={classes.logofield}
                        style={{
                                                    cursor:iTheme?.bgOption==="bgImage"?"pointer":"not-allowed"
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        <Box>
                          <Typography className={classes.logoTypo}>
                            16x9
                          </Typography>
                        </Box>
                      </Box>
                    </label>
                    {
            error["16x9"] && <FormHelperText  style={{ color:"red"}}>{error["16x9"]}</FormHelperText>
}
{
  iTheme?.bgOption==="bgImage" && error.manditory && <FormHelperText  style={{ color:"red"}}>
  {  ( iTheme?.bgImage?.["16x9"] ===
                undefined ||
                iTheme?.bgImage?.["16x9"] === "")
                &&  manditoryErrorText
                 }
            </FormHelperText>
}
                    {iTheme?.bgImage?.["16x9"] && (
                      <Box className={classes.Image16}>
                        <img
                          className={classes.Image16}
                          src={
                            iTheme?.bgImage?.[
                              "16x9"
                            ]
                          }
                        ></img>
                      </Box>
                    )}
                  </Box>
                  <Box paddingBottom="5px">
                    <label htmlFor="1x1" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ 
                          display: "none",
                          cursor:iTheme?.bgOption==="bgImage"?"pointer":"not-allowed"
                         }}
                        id="1x1"
                        name="1x1"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        required
                        inputProps={{ accept: "image/*" }}
                        fullWidth
                        disabled =  {iTheme?.bgOption==="bgImage"?false:true}
                      />
                      <Box
                       style={{
                        cursor:iTheme?.bgOption==="bgImage"?"pointer":"not-allowed"
}}
                        className={classes.logofield}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        <Box>
                          <Typography className={classes.logoTypo}>
                            9x16
                          </Typography>
                        </Box>
                      </Box>
                    </label>
                    {
    error.bgImage1x1&& <FormHelperText style={{ color:"red"}}> {error.bgImage1x1}</FormHelperText>
}
{
  iTheme?.bgOption==="bgImage" &&  error.manditory && <FormHelperText  style={{ color:"red"}}>
  {  ( iTheme?.bgImage?.["1x1"] ===
                undefined ||
                iTheme?.bgImage?.["1x1"] === "")
                &&  manditoryErrorText
                 }
            </FormHelperText>

}
                    {iTheme?.bgImage?.["1x1"] && (
                      <Box className={classes.Image9x16}>
                        <img
                          className={classes.Image9x16}
                          src={
                            iTheme?.bgImage?.["1x1"]
                          }
                        ></img>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Paper
                  style={{
                    width: "95%",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100vw",
                    zIndex: 2,
                    boxSizing: "border-box",
                  }}
                >
              <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      height: "80px",
                      paddingRight: "60px",
                      alignItems: "center",
                    }}
                  >
                     <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                        className={classes.submitButton}
                        onClick={handleSubmitTheme}
                      >
                        
                           Publish
                      </Button>
                    
                  </div>
             
                      </Paper>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToState = (dispatch) => {
  return {
    updateScreenDetails: (details) => {
      dispatch(updateScreenDetails(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToState)(ScreenTheme);
