import { LoginActionType, RESET_LOGIN, SuccessfullyLoginType ,UNSUCCESSFULL_LOGIN} from "../actionTypes/loginActionType"

export const LoginAction = (authDetails)=>{
    return {type:LoginActionType,payload:authDetails}
}

export const SuccessfullyLoginAction = (token)=>{
    return {type:SuccessfullyLoginType,payload:token}
}

export const UnsuccesFullyLogin=(errorMessage)=>{
return{type:UNSUCCESSFULL_LOGIN,payload:errorMessage}
}

export const ResetLogin=()=>{
    return{type:RESET_LOGIN}
    }