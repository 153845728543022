

import React from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  footer: {
    position: "relative",
    bottom: 0,
    width: "100%",
    backgroundColor: "#e0e5ff",
    paddingTop: "0px",  
    paddingBottom: "0px",  
    textAlign: "center",
    borderTop: "1px solid #e7e7e7",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
    marginTop: "auto",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  left: {
    flex: "1 1 auto",
    textAlign: "left",
  },
  right: {
    flex: "1 1 auto",
    textAlign: "right",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    listStyle: "none",
    display: "flex",
    justifyContent: "flex-end",
  },
  listItem: {
    paddingTop: "0px",  // Adjust top padding here
    paddingBottom: "0px",  // Adjust bottom padding here
  },
  block: {
    color: "#3C4858",
    textDecoration: "none",
    "&:hover": {
      color: "#002b5c", // Add hover effect if needed
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  function handleNavigation() {
    history.push("/admin/privacypolicy");
  }

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <p>
            <span>
              &copy; {1900 + new Date().getYear()}{" "}
              <a href="" target="_blank" className={classes.block}>
                Horizon Broadband
              </a>
            </span>
          </p>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <p  className={classes.block}>
                Terms and Conditions
              </p>
            </ListItem>
            <ListItem className={classes.listItem}>
              <p className={classes.block}>
                Privacy Policy
              </p>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

