import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { homeApis } from "../../../config";
import {
  createScreenApi,
  deleteScreenApiCall,
  exportScreenApi,
  getplatformApi,
  getScreenApi,
  getSingleScreenApi,
  getWidgetApi,
  getSingleWidgetApi,
  getWidgetByIdApi,
  publishIntroScreenApi,
  reorderScreenApiCall,
  upadteScreenDetailsApi,
  uPdateScreenApi,
  updateScreenStatusApi,
  uploadSplashScrenApi,
  getScreensForAllplatformsApi,
  getScreenApiV3,
} from "../apis/platformApis";
import {
  CreateScreenDataAction,
  deleteScreenResponse,
  exportScreenResponse,
  getAllplatformDataAction,
  getAllScreensDataAction,
  getAllWidgetDataAction,
  getDefaultScreensAction,
  getplatformDataAction,
  getSingleScreenAction,
  getSingleWidgetDataAction,
  getWidgetByIdData,
  publishIntroResponse,
  publishSplashResponse,
  screenReorderResponse,
  ScreenStatueChangeAction,
  screenStatusResponse,
  successfullyUpdateScreenDetails,
  updateScreenApiResponse,
  updateWidgetImageTypeData,
} from "../actions/PlatformAction";

//Api Calls while handling action

//CreateNew Sccreen Api Function
const handleCreateNewScreen = async (screenDetails) => {
  const screenReq = {
    screenName: screenDetails.screenName,
    status: screenDetails.status,
    displayOrder: screenDetails.displayOrder,
    displayStyle: screenDetails.displayStyle,
    screenIcon: screenDetails.screenIcon,
  };
  try {
    const res = await axios.post(createScreenApi, screenReq, {
      params: {
        p_id: screenDetails.params.projectId,
        platform: screenDetails.params.platform,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

//handle Get All Widget Api Function
const handleGetAllWidgets = async (projectId, platforms) => {
  try {
    const res = await axios.get(getWidgetApi, {
      params: { p_id: projectId, platform: platforms },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });

    return res.data.widgets;
  } catch (err) {
    console.log(err);
  }
};

const handleGetSingleWidget = async (payload) => {
  try {
    const res = await axios.get(getSingleWidgetApi, {
      params: payload,
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });

    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

//Handle Get all platform api function
const handleGetAllPlatforms = async (projectId) => {
  try {
    const res = await axios.get(getplatformApi, {
      params: { p_id: projectId },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data.platforms;
  } catch (err) {
    console.log(err);
  }
};

// hadlet Get all screens from all platforms apii function 
const handleGetAllScreensFromAllPlatforms = async (projectId) => {
  try {
    const res = await axios.get(getScreensForAllplatformsApi, {
      params: { p_id: projectId },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

//Hanmdle Get all Screen api call function
const handleGetAllScreens = async (projectId, plat) => {
  try {
    const res = await axios.get(getScreenApiV3, {
      params: { p_id: projectId, platform: plat },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    console.log("get all the screen", res?.data?.data);
    localStorage.setItem(
      "stateHash",
      res?.data?.data?.screens?.[0]?.stateHash ?? res?.data?.data?.defaultScreens?.[0]?.stateHash,
    );
    return res?.data?.data;
  } catch (err) {
    console.log(err);
  }
};

//Handle get Widget by id
const handleGetWidget = async (widgetId, disOrder, projectId) => {
  try {
    const res = await axios.get(getWidgetByIdApi, {
      params: { widget: widgetId, displayOrder: disOrder, p_id: projectId },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

//get all Widgets for a screen
const getAllWidgetsForScreen = async (widgets, p_id) => {
  try {
    const res = await Promise.allSettled(
      widgets.map((widget) =>
        axios.get(getWidgetByIdApi, {
          params: { widget: widget._id, displayOrder: widget.displayOrder, p_id },
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        })
      ),
    );

    return res.map((widget) => widget.value.data);
  } catch (err) {
    console.log(err);
  }
};

//update Widget api
const updateWidgetApi = async (details) => {
  let type;

  if (details.layout === "HRO") {
    type = details.item.imageSource;
  } else {
    type = details.type;
  }

  if (details?.item?.landingPage?.type === "AST") {
    details.item.assetId = details.item.landingPage.assetId.result.id;
    details.item.landingPage = details.item.landingPage.type;
  } else if (details?.item?.landingPage?.type === "PLT") {
    details.playlistId = details.item.landingPage.playlist.kalturaChannelId;
    details.item.landingPage = details.item.landingPage.type;
  } else if (details.layout != "HRO") {
    details.playlistId = details.item.playlist.kalturaChannelId;
  } else {
    details.item.landingPage = details.item.landingPage.type;
  }

  details.itemData = details.item;

  let projectid = details._id;

  let params = {
    layout: details.layout,
    type: type,
    p_id: details.project,
  };

  const options = {
    method: "PUT",
    url: `${homeApis.updateWidget}/${projectid}`,
    params: params,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: details,
  };

  const res = await axios
    .request(options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

//HandlerSagaFunction for each Action Which are watched by saga

// Hanndle Create Screen Action
function* handleCreateScreen({ payload }) {
  const screenData = yield call(handleCreateNewScreen, payload);
  yield put(CreateScreenDataAction(screenData));
}

//Handle Get Widget action
function* handleGetWidgets({ payload, platforms }) {
  const allWidgets = yield call(handleGetAllWidgets, payload, platforms);
  yield put(getAllWidgetDataAction(allWidgets));
}

//Handle Get Single Widget action
function* handleGetOneWidget({ payload, platforms }) {
  const singleWidget = yield call(handleGetSingleWidget, payload, platforms);
  yield put(getSingleWidgetDataAction(singleWidget));
}

//handle Get Platform action
function* handleGetPlatforms({ payload }) {
  const allplatforms = yield call(handleGetAllPlatforms, payload);
  yield put(getplatformDataAction(allplatforms));
}

//handle Get All Platform actions 
function* handleGetAllPlatformsForAllProject({ payload }) {
 
  const _platforms = {}
  const _allprojects = yield call(handleGetAllScreensFromAllPlatforms)
  for (let project of _allprojects){
    _platforms[project.p_id] = project.platforms
  }

  yield put(getAllplatformDataAction(_platforms));
}

//handle Get  Screen Action
function* handleGetScreens({ payload, platforms, screenName }) {
  const allScreens = yield call(handleGetAllScreens, payload, platforms);

  yield put(getDefaultScreensAction(allScreens?.defaultScreens));

  const screenSort = allScreens?.screens?.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  let selectedScreen = screenSort?.find((screen) => screen.name === screenName);

  if (screenSort?.length>0) {
    yield put(getAllScreensDataAction(screenSort));
    if(!selectedScreen){
        selectedScreen = screenSort[0]
    }
    const widgetSort = selectedScreen?.widgets?.sort((a, b) => {
      return a?.displayOrder - b?.displayOrder;
    });
    yield put(getWidgetByIdData(widgetSort || []))
    
    // if (selectedScreen) yield put(getSingleScreenAction(selectedScreen));
  } else {
    yield put(getAllScreensDataAction([]));
    yield put(getSingleScreenAction([0]));
  }
}

//Handling single Screen Action
function* handleGetSingleScreenAction({ payload }) {
  try {
    const screenResult = yield call(getSingleScreenApi, payload);

    const screen = screenResult instanceof Array ? screenResult[0] : screenResult;

    const widgetSort = screen?.widgets?.sort((a, b) => {
      return a?.displayOrder - b?.displayOrder;
    });

    // yield put(getWidgetByIdData(widgetSort))
    // console.log(widgetSort,"widgetSort")
    if (widgetSort) {
      const allWidgets = yield call(getAllWidgetsForScreen, widgetSort, payload.p_id);

      // const widgets = allWidgets.filter((widget) => widget?.hzStatus === 200).map((widget) => widget?.data)
      // console.log(widgets,"widgets")
      yield put(
        getWidgetByIdData(allWidgets.filter((widget) => widget?.hzStatus === 200).map((widget) => widget?.data)),
      );
    }
  } catch (error) {}
}

//Handle PublishScreen api
function* handleUpdateScreenAction({ payload }) {
  const response = yield call(uPdateScreenApi, payload);

  if (response.status === 200) yield put(updateScreenApiResponse(response.data, payload.newScreenName));
  else yield put(updateScreenApiResponse(response, payload.newScreenName));
}

//handle Update Screen Status published or unpublished
function* handleUpdateScreenStatusAction({ payload }) {
  const res = yield call(updateScreenStatusApi, payload);
  yield put(ScreenStatueChangeAction());
  yield put(screenStatusResponse(res, payload));
}

//hndle Screen export to other platform
function* handleExportScreenAction({ payload }) {
 
  const p_id = payload.p_id
  const screenName = payload.screenName
  const platforms = payload.platforms

  const ex = yield call(exportScreenApi, payload);
  if(platforms?.length>0){
    const updateScreenStatus = new Promise(async(res,rej)=>{
      for (let i=0 ; i<platforms.length;i++){
        const allScreens =await handleGetAllScreens(p_id, platforms.at(i))
        const newScreen = allScreens?.screens?.at(-1)
        const id = newScreen._id
        updateScreenStatusApi({
          p_id:p_id,
          status:"PUBLISHED",
          id:id
        })     
      }      
    })
    
  }


  
  
  yield put(exportScreenResponse(ex));
}

function* handleUpdateWidgetImage({ payload }) {
  const res = yield call(updateWidgetApi, payload);
  yield put(updateWidgetImageTypeData(res));
}
//Screen Reorder Handler Saga
function* hendleScreenReorderAction({ payload, projectId, platform }) {
  try {
    const response = yield call(reorderScreenApiCall, payload, projectId, platform);
    yield put(screenReorderResponse(response));
  } catch (err) {}
}

//Delete Screen Handler
function* handleDeleteScreenAction({ payload }) {
  const deleteResponse = yield call(deleteScreenApiCall, payload);
  yield put(deleteScreenResponse(deleteResponse));
}

//Publish Splash Handler
function* handlePublishSplashAction({ payload }) {
  const splashResponse = yield call(uploadSplashScrenApi, payload);
  yield put(publishSplashResponse(splashResponse));
}

//Publish Intro Handler
function* handlePublihIntroAction({ payload }) {
  const introResponse = yield call(publishIntroScreenApi, payload);
  yield put(publishIntroResponse(introResponse));
}

function* handleUpdateScreenDetails({ payload }) {
  let prevScreen
  if(payload?.prevScreen){
     prevScreen = payload?.prevScreen
    delete prevScreen?.prevScreen
  }

  const updateRes = yield call(upadteScreenDetailsApi, payload);
  
  yield put(successfullyUpdateScreenDetails(updateRes, payload.name));

  if(prevScreen==='PlatformVis'){
    window.history.back("/admin/projectsplateformmanager/screens")
  }
}

//Watcher Saga function for every action in the visualization screen
export default function* watchVisualizationScreenSaga() {
  yield takeLatest("CREATE_NEW_SCREEN_ACTION", handleCreateScreen);
  yield takeLatest("GET_ALL_WIDGETS_ACTION", handleGetWidgets);
  yield takeLatest("GET_SINGLE_WIDGET_ACTION", handleGetOneWidget);
  yield takeLatest("GET_PLATFORM_ACTION", handleGetPlatforms);
  yield takeLatest("GET_ALL_PLATFORM_ACTION", handleGetAllPlatformsForAllProject);
  yield takeLatest("GET_ALL_SCREENS_ACTION", handleGetScreens);
  yield takeLatest("GET_SINGLE_SCREEN_ACTION", handleGetSingleScreenAction);
  yield takeLatest("PUBLISH_SCREEN_ACTION", handleUpdateScreenAction);
  yield takeLatest("UPDATE_SCREEN_STATUS_ACTION", handleUpdateScreenStatusAction);
  yield takeLatest("EXPORT_SCREEN_TO_PLATFORMS_ACTION", handleExportScreenAction);
  yield takeLatest("UPDATE_WIDGET_IMAGE_TYPE_ACTION", handleUpdateWidgetImage);
  yield takeLatest("SCREEN_REORDER_ACTION_ACTION", hendleScreenReorderAction);
  yield takeLatest("DELETE_SCREEN_ACTION", handleDeleteScreenAction);
  yield takeLatest("PUBLISH_SPLASH_ACTION", handlePublishSplashAction);
  yield takeLatest("PUBLISH_INTRO_ACTION", handlePublihIntroAction);
  yield takeLatest("UPDATE_SCREEN_DETAILS", handleUpdateScreenDetails);
}
