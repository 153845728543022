import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@material-ui/core";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";
import CustomDialog from "components/Dialog/Dialog";
import { useState } from "react";
import "./styles.scss";
import { getMediaType } from "components/PlatformScreensView/AppScreenView";

const sortableIntroItem = ({
  id,
  children,
  removeItem,
  accessLevel,
  setAccessSnack,
  setAccessSnackMessage,
  setAccessSnackSeverity,
}) => {
  const { attributes, isDragging, listeners, transform, transition, setNodeRef } = useSortable({
    id,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const [openDelete, setOpenDelete] = useState(false);

  return (
    <Box ref={setNodeRef} className="sortable-item-intro" style={style} {...attributes}>
      <Box {...attributes} {...listeners}>
        {children}
      </Box>
      <Box
        onClick={() => {
          accessLevel !== "user"
            ? setOpenDelete(true)
            : [setAccessSnackMessage("You do not have access to delete intro screens."), setAccessSnack(true)];
        }}
      >
        <img src={deleteBucket} className="sortable-delete" />
      </Box>
      <CustomDialog
        open={openDelete}
        title={
          <span className="dialogDesc">
            Are you sure you want to <br /> delete this Intro {getMediaType(id)}?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenDelete(false)}
        button2Event={() => removeItem(id)}
        icon={deleteBucket}
        margin="8px"
        width="24px"
      />
    </Box>
  );
};

export default sortableIntroItem;
