const AddProjectstepperStyle = {
  stepper: {
    "border-radius": "8px",
    background: "rgba(255, 255, 255, 0.2)",
    "box-shadow": "13.0709px 13.0709px 104.567px rgb(63 39 102 / 10%)",
    "backdrop-filter": "blur(26.1418px)",
  },
  stepperConnector: {
    flexGrow: "0",
    flexBasis: "auto",
    flexShirnk: "1",
  },
  textfield: {
    boxSizing: "border-box",
    background: "#FCFCFC",
    border: "1px  #C7C7C7",
    borderRadius: "8px",
  },
  selectmenu: {
    top: "500",
  },
  bottomCard: {
    maxwidth: "100%",
    height: "75px",
    background: "rgba(255, 255, 255, 0.7)",
    backdropFilter: "blur(24px)",
    //left: 'calc(50% - 1440px/2)',
    bottom: "0px",
    Top: "0px",
    width: "100%",
  },
  cancelButton: {
    width: "180px",
    height: "48px",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 2px 20px rgba(0, 60, 118, 0.6))",
    borderRadius: "33px",
  },
  addButton: {
    width: "180px",
    height: "48px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
  },
  cancelButtonText: {
    width: "111px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    "text-align": "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
  },
  addButtonText: {
    width: "111px",
    height: "22px",
    left: "calc(50% - 311px/2 - 0.5px)",
    top: "calc(50% - 22px/2)",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 700,
    "font-size": "16px",
    "line-height": "140%",
    "text-align": "center",
    color: "#FFFFFF",
  },
  inputLabel: {
    height: "20px",
    top: "calc(50% - 20px/2 + 26px)",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    color: " #141D46",
    paddingBottom: "8px",
  },
  EditCard: {
    width: "957px",
    height: "auto",
    left: "435px",
    top: "181px",
    background: "rgba(255, 255, 255, 0.6)",
    "box-shadow": "0px 8px 20px rgba(63, 39, 102, 0.12)",
    "backdrop-filter": "blur(20px)",
    "border-radius": "24px",
  },
  dialog: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#424242",
  },
  dialogFocus: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#141D46",
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  logofield:{
    boxSizing: 'border-box',
    background: '#FFFFFF',
    opacity: '0.5',
    border: '1px dashed #777777', 
    bordeRadius: '4px',
    height:'50px',
    width: '250px',
    maxWidth:'100%',
    maxHeight:'100%',
    display:"inital",
},
logoTypo:{
  maxWidth:"243px",
  maxHeight:'24px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '24px',
  textAlign: 'center',
  backgroundImage:'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
  'WebkitTextFillColor': 'transparent',
  'WebkitBackgroundClip': 'text'
},
Image16:{
  position: 'relative',
  maxWidth: '100%',
  maxHeight:'100%',
  width:'256px',
  height:'144px',
  //'padding-top': '1px',
  'border-radius': '12px',
  'background': '#2B2B2B',
},
ContentBadgeImage:{
  position: 'relative',
  maxWidth: '100%',
  maxHeight:'100%',
  width:'256px',
  height:'144px',
  //'padding-top': '1px',
  'border-radius': '12px',
  'background': '#2B2B2B',
  "&:hover": {
    opacity:0.6
  }
},
Image1x1:{
  position: 'relative',
margin:"auto",
  width:'144px',
  height:'144px',
  //'padding-top': '1px',
  'border-radius': '12px',
  'background': '#2B2B2B',
},
Image9x1:{
  position: 'relative',
margin:"auto",
  width:'144px',
  height:'250px',
  //'padding-top': '1px',
  'border-radius': '12px',
  'background': '#2B2B2B',
},
cancelButton: {
  height: "50px",
  width: "180px",
  color:'#FFFFFF',
  fontSize: '16px',
  lineHeight:'140%',
  color: "#0192D4",
  border: "1px solid transparent",
  background:  'linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
   borderRadius: '33px',
   border:' 1px solid transparent'
 // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
},
submitButton: {
  color:'#FFFFFF',
  fontSize: '16px',
  lineHeight:'140%',
  height: "50px",
  width: "202px",
  marginLeft: "20px",
  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
  borderRadius:'33px'
 // border: "1px solid transparent",
 // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
},
};

export default AddProjectstepperStyle;
