//Importing all the Action Types
import * as actionTypes from "../actionTypes";

//Actions for calling the Api for creating and deleting Schedules

//Get All Linear Channels(Linear Channel means Live Channels)
export const getAllLinearChannelAction = (p_id) => {
  return {
    type: actionTypes.GET_ALL_LINEAR_CHANNEL_ACTION,
    payload:p_id
  };
};

//Action for calling the Api for getting all the schedule for all channles
export const getAllTheBlackoutsForAllChannelsAction = (p_id) => {
  return {
    type: actionTypes.GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_ACTION,
    payload: p_id,
  };
};

//Get All Blackout Action
export const getAllBlackoutAction = () => {
  return {
    type: actionTypes.GET_ALL_BLACKOUT_ACTION,
  };
};

//Schedule the Blackout Action
export const scheduleBlackoutAction = (scheduleDetails,p_id) => {
  return {
    type: actionTypes.SCHEDULE_BLACKOUT_ACTION,
    payload:{scheduleDetails:scheduleDetails,p_id:p_id}
  };
};

//Get All the EPG of a specific Action
export const getAllEpgAction = (linearChannel,p_id) => {
  return {
    type: actionTypes.GET_EPG_ACTION,
    payload:{linearChannel:linearChannel,p_id:p_id}
  };
};

//Delete Blackout Actioln
export const deleteBlackoutAction = (blackoutDetails,p_id) => {
  return {
    type: actionTypes.DELETE_BLACKOUT_ACTION,
    payload:{blackoutDetails:blackoutDetails,p_id:p_id}
  };
};

//Actions for getting the respponse from Api
//Response Action for get all linear Channel
export const getAllLinearChannelResponse = (linearChannels) => {
    
  return {
    type: actionTypes.GET_ALL_LINEAR_CHANNEL_RESPONSE,
    payload:linearChannels
  };
};

//Response Action for getting all the blackout for all the channel
export const getAllBlackoutForAllChannelResponse = (blackouts) => {
  return {
    type: actionTypes.GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_RESPONSE,
    payload:blackouts
  };
};

//Response Action for get all blackouts
export const getAllBlackoutResponse = () => {
  return {
    type: actionTypes.GET_ALL_BLACKOUT_RESPONSE,
  };
};

//Resposne action for the get all the Epg
export const getAllEpgResponse = (epg) => {
  return {
    type: actionTypes.GET_EPG_RESPONSE,
    payload:epg.objects
  };
};

//Response Actions Schedule Action
export const scheduleBlackoutResponse = () => {
  return {
    type: actionTypes.SCHEDULE_BLACKOUT_RESPONSE,
  };
};

//Response Action for Delete Schedule Response
export const deleteBlackoutResponse = () => {
  return {
    type: actionTypes.DELETE_BLACKOUT_RESPONSE,
  };
};


//Error Message 
export const getAllLinearChannelErroMessage=(errorMessage)=>{
  return{
    type:actionTypes.GET_ALL_LINEAR_CHANNEL_ERROR_MESSAGE,
    payload:errorMessage
  }
}