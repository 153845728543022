import {
  Box,
  InputBase,
  makeStyles,
} from "@material-ui/core";
import Table from "components/pushNotificationComponents/Table";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Menuitem from "components/Menu/MenuItem";
import Primary from "components/Typography/Primary";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import styles from "assets/jss/material-dashboard-react/views/pushNotificationStyle";

//Icons
import SearchIcon from "../../../assets/img/icons/SearchIcon.svg";
import DropDown from "../../../assets/img/icons/dropDown.svg";
import { getPushNotification } from "../actions";
import NoResults from "components/widgetManagerComponents/Notfound";
import { ClockLoader } from "react-spinners";
import pushNotificationIcon from "assets/img/sideNavIcons/Pushnotification.svg";

const useStyle = makeStyles(styles);
const allowedNotificationTypes = ["InApp","Normal","Email","SMS"]

const placeholderNotifications = {
  "InApp":{
        notification_type: "InApp",
        frequency: null,
        campaign_schedule: {
          from_date: "",
          till_date: "",
        },
        event_trigger: [],
        category: "",
        notification_content: {
          type: [],
          layout: "",
          position: "",
        },
        target_group: "",
        platform_target: [],
        status: "DRAFT",
    },
  "Normal":{
      notification_type: "Normal",
      frequency: null,
      campaign_schedule: {
        from_date: "",
        till_date: "",
      },
      event_trigger: [],
      category: "",
      notification_content: {
        type: [],
        layout: "",
        position: "",
      },
      target_group: "",
      platform_target: [],
      status: "DRAFT",
  },
  "Email":{
    notification_type: "Email",
    frequency: null,
    campaign_schedule: {
      from_date: "",
      till_date: "",
    },
    event_trigger: [],
    category: "",
    notification_content: {
      type: [],
      layout: "",
      position: "",
    },
    target_group: "",
    status: "DRAFT",
    platform_target: [],
},
"SMS":{
  notification_type: "SMS",
  frequency: null,
  campaign_schedule: {
    from_date: "",
    till_date: "",
  },
  event_trigger: [],
  category: "",
  notification_content: {
    type: [],
    layout: "",
    position: "",
  },
  target_group: "",
  status: "DRAFT",
  platform_target: [],
}
}
const PushNotification = (props) => {
  const classes = useStyle();
  console.log(props.notifications,"notification_data")
  
  //internal state
  const [searchInput, setSearchInput] = React.useState("");

  //Snakbar
  const [accessSnackMessage, setAccessSnackMessage] = React.useState("");

  //Filters
  const [platformTypeOpen, setplatformTypeOpen] = React.useState(false);
  const [selectedPlatformType, setSelectedPlatformType] = React.useState("All");

  //Table
  const [currpageNumber, setCurrpageNumber] = React.useState(1);
  
  const finalNotifications = useMemo(()=>{
    let notificationsHash ={}
    for (let ele of props.notifications){
      notificationsHash[ele.notification_type] = ele
    }
    return Object.keys(placeholderNotifications).map(key=>{
      if (key in notificationsHash){
        return notificationsHash[key]
      }
      else{
        return placeholderNotifications[key]
      }
    })
    
  },[props.notifications])

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
console.log(props,"props")
 
  useEffect(()=>{
    let payload ={
      p_id: props?.projectDetails?.p_id,
    }
    props.getPushNotification(payload)
  },[])
  
  
console.log(finalNotifications)

  return (
    <div>
      <Box display={"flex"}>
        <img src={pushNotificationIcon} alt="Your Icon" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
        <Primary>Push Notification</Primary>
      </Box>
      <GridContainer>
        <GridItem>
          <Box className={classes.searchBoxWidgetManager}>
            <img
              className={classes.searchicon}
              src={SearchIcon}
              alt="Search Icon"
            />
            <InputBase
              placeholder="Search Notification"
              className={classes.widgetManagerViewTextField}
              labelwidth={0}
              onChange={handleSearchInput}
              value={searchInput}
            />
          </Box>
        </GridItem>
        {!props?.notification_details?.loading  ? (
            <GridContainer>
              <Table
                tableHead={[
                  "Type",
                  "Frequency",
                  "Category",
                  "Content Type",
                  "Status",
                  "",
                ]}
                pageNumber={currpageNumber}
                projectDetails={props.projectDetails}
                tableData={finalNotifications || []}
              />
            </GridContainer>
          ) 
         : (
          <Box
            display="flex"
            width={"90vw"}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
            textAlign="center"
          >
            <ClockLoader color="rgb(63, 81, 181)" />
          </Box>
        )}
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  let notification_details = {
    loading:state.PushNotificationReducer.loading
  }
  return {
    projectDetails: state.ProjectReducer.projectDetails,
    featureConfig: state.ProjectReducer.featureConfig,
    accessLevel: state.loginReducer.authTokens?.accessLevel,
    notifications: state.PushNotificationReducer.notifications,
    notification_details
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPushNotification: (parameters) => dispatch(getPushNotification(parameters)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);
