export const GET_PLATFORM_ACTION = "GET_PLATFORM_ACTION";
export const GET_ALL_PLATFORM_ACTION = "GET_ALL_PLATFORM_ACTION";
export const getPlatformActionDataType = "SUCCESSFULLY_GET_ALL_PLATFROMS";
export const getAllPlatformActionDataType = "SUCCESSFULLY_GET_ALL_PLATFROMS_FOR_ALL_PROJECTS";
export const getAllScreensActionType = "GET_ALL_SCREENS_ACTION";
export const getAllScreensDataType = "SUCCESSFULLY_GET_ALL_SCREENS";
export const getAllWidgetsActionType = "GET_ALL_WIDGETS_ACTION";
export const getAllWidgetsDataType = "SUCCESSFULLY_GET_ALL_WIDGETS";
export const getSingleWidgetsActionType = "GET_SINGLE_WIDGET_ACTION";
export const getSingleWidgetDataType = "SUCCESSFULLY_GET_SINGLE_WIDGET";
export const getWidgetByIdDataType = "SUCCESSFULLY_GET_WIDGET_BY_ID_DATA";
export const CreateScreenActionType = "CREATE_NEW_SCREEN_ACTION";
export const CreateScreenDataType = "SUCCESSFULLY_CREATE_NEW_SCREEN";
export const getScreenResetType = "GET_SCREEN_RESET";
export const REORDER_WIDGET_MANAGER = "REORDER_WIDGET_MANAGER";
export const GET_SINGLE_SCREEN_ACTION = "GET_SINGLE_SCREEN_ACTION";
export const PUBLISH_SCREEN_ACTION = "PUBLISH_SCREEN_ACTION";
export const UPDATE_SCREEN_STATUS_ACTION = "UPDATE_SCREEN_STATUS_ACTION";
export const EXPORT_SCREEN_TO_PLATFORMS_ACTION = "EXPORT_SCREEN_TO_PLATFORMS_ACTION";
export const UPDATE_WIDGET_IMAGE_TYPE_ACTION = "UPDATE_WIDGET_IMAGE_TYPE_ACTION";
export const SUCCEESFULLY_UPDATE_IMAGE_TYPE_DATA = "SUCCESSFULLY_UPDATE_IMAGE_TYPE_DATA";
export const SCREEN_REORDER_ACTION_ACTION = "SCREEN_REORDER_ACTION_ACTION";
export const SCREEN_REORDER_RESPONSE = "SCREEN_REORDER_RESPONSE";
export const UPDATE_SCREEN_RESPONSE = "UPDATE_SCREEN_RESPONSE";
export const DELETE_SCREEN_ACTION = "DELETE_SCREEN_ACTION";
export const DELETE_SCREEN_RESPONSE = "DELETE_SCREEN_RESPONSE";
export const SCREEN_STATUS_CHANGE = "SCREEN_STATUS_CHANGE";
export const EXPORT_SCREEN_RESPONSE = "EXPORT_SCREEN_RESPONSE";
export const SCREEN_STATUS_RESPONSE = "SCREEN_STATUS_RESPONSE";
export const PLATFORM_DETAILS = "PLATFORM_DETAILS";
export const PUBLISH_SPLASH_ACTION = "PUBLISH_SPLASH_ACTION";
export const PUBLISH_SPLASH_RESPONSE = "PUBLISH_SPLASH_RESPONSE";
export const PUBLISH_INTRO_ACTION = "PUBLISH_INTRO_ACTION";
export const PUBLISH_INTRO_RESPONSE = "PUBLISH_INTRO_RESPONSE";
export const UPDATE_SCREEN_DETAILS = "UPDATE_SCREEN_DETAILS";
export const CLEAR_SCREEN_WIDGETS = "CLEAR_SCREEN_WIDGETS";
export const SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS = "SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS";
export const CLEAR_CREATE_SCREEN_DATA = "CLEAR_CREATE_SCREEN_DATA";
export const CLEAR_DELETE_SCREEN_DATA = "CLEAR_DELETE_SCREEN_DATA";
export const CLEAR_EXPORT_SCREEN_DATA = "CLEAR_EXPORT_SCREEN_DATA";
export const CLEAR_PUBLISH_SCREEN_DATA = "CLEAR_PUBLISH_SCREEN_DATA";
export const CLEAR_UPDATE_SCREEN_DATA = "CLEAR_UPDATE_SCREEN_DATA";
export const SUCCESSFULLY_UPDATE_SCREEN_DETAILS = "SUCCESSFULLY_UPDATE_SCREEN_DETAILS";
export const CLEAR_UPDATE_SCREEN_DETAILS = "CLEAR_UPDATE_SCREEN_DETAILS";
export const CLEAR_SCREEN_REORDER_RESPONSE = "CLEAR_SCREEN_REORDER_RESPONSE";
