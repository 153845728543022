import React from "react";
import Modals from "components/Modal/Modals";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SubHead from "components/Typography/SubHead";

import style from "assets/jss/material-dashboard-react/views/screenStyle";

import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(style);

const AddTrendingAiWidgetInfo = (props) => {
  const classes = useStyles();
  const [day,setDay]=React.useState()
  const [month,setMonth]=React.useState()
  const [year,setYear]=React.useState()

  React.useEffect(() => {
    console.log("Props for Ai Widget ", props);
  }, [props]);

const detailsChange=(event,value,names)=>{
    console.log("Event and value",event.target.name,event.target.value, value,names);
if(event.target.name==="Year"){
    props.setAiWidgetTrendingDetails({...props.aiWidgetTrendingDetails,year:event.target.value})
//setYear(event.target.value)
}
else if(names==="Month"){
//setMonth(event.target.value)
props.setAiWidgetTrendingDetails({...props.aiWidgetTrendingDetails,month:parseInt(value,10)})

}
else if(names==="Day"){
//setDay(event.target.value)
props.setAiWidgetTrendingDetails({...props.aiWidgetTrendingDetails,day:parseInt(value,10)})

}
}

  return (
    <Modals open={props.open}
    setModal={props.setModal}
    >
      <Paper>
        <div className={classes.editAIWidgetMainCintainer}>
          <Box className={classes.editAIWidgetTextfieldContainer}>
            <SubHead>Configure Trending Widget</SubHead>
          </Box>

          <Box className={classes.calenderInputField}>
            {props?.aiWidgetTrendingPicked == "EVGN_PK" ? (
             <Autocomplete
              options={["1","3","6","12"]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                name="Month"

                  size="medium"
                  className={classes.editAiWidgetTextField}
                  {...params}
                  placeholder="Enter Months"
                  variant="outlined"
                 
                />
              )}
              onChange={(event,value)=>detailsChange(event,value,"Month")}
            />
            ) : null}
           
        {props?.aiWidgetTrendingPicked == "TRND_NW" ?(
             <Autocomplete
              options={["2","7","15","30"]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                name="Days"

                  size="medium"
                  className={classes.editAiWidgetTextField}
                  {...params}
                  placeholder="Enter Days"
                  variant="outlined"
                 
                />
              )}
              onChange={(event,value)=>detailsChange(event,value,"Day")}
            />):null}
            {/* <TextField
                          name="Day"

              className={classes.editAiWidgetTextField}
              variant="outlined"
              placeholder="Enter Day"
              // value={widgetCommonDetails.name}
              required
              size="Normal"
              // className={classes.textField}
              labelWidth={0}
              onChange={detailsChange}

            ></TextField> */}
          </Box>
          <Box className={classes.editWidgetAiButtonContainer}>
            <Button
              className={classes.editWidgetAiButton}
              onClick={()=>props.continueToAddTrendinAi()}
            >
            {props?.aiWidgetTrendingPicked == "TRND_NW"?"Add Days":"Add Months"}  
            </Button>
          </Box>
        </div>
      </Paper>
    </Modals>
  );
};

export default AddTrendingAiWidgetInfo;
