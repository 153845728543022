import React,{useEffect} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography, Button } from "@material-ui/core";
import LogoutIcon from "assets/img/sideNavIcons/Logout.svg";
import LogoutIconDial from "assets/img/icons/svg/logout.svg";
import { useDispatch } from "react-redux";
import Dialog from "components/Dialog/Dialog";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function Profile(props) {
  const history = useHistory();
  const classes = useStyles();
  const [openLogout, setOpenLogout] = React.useState(false);
  const [showLogoutButton, setShowLogoutButton] = React.useState(false); // New state to control logout button visibility
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.isDrawerStatus) {
      setShowLogoutButton(false);
    }
  }, [props.isDrawerStatus]);

  const handleClickProfile = () => {
    props.setDrawerStatus((state) => !state);
    setShowLogoutButton((prevState) => !prevState); 

  };

  
  const openDialog = () => {
    setOpenLogout(true);
  };


  const logOutUser = () => {
    dispatch({ type: "LOGOUT_REQUEST" });
    localStorage.clear();
    dispatch({ type: "RESET_LOGIN" });
    history.push("/signin");
  };

  return (
    <div style={{ display: "flex", alignItems: "center", margin:"20px 10px 15px 20px", justifyContent: "center" }}>
      {/* Avatar with or without name based on drawer status */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="transparent"
          className={classes.buttonLink}
          onClick={handleClickProfile}
          style={{ background: "transparent", border: "none", padding: "0", cursor: "pointer" ,zIndex:"1"}}
        >
          <Avatar
            style={{
              width: "35px",
              height: "35px",
              border: "3px solid #FFF",
              // marginLeft: "8px",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {props?.name?.charAt(0)}
            </Typography>
          </Avatar>
        </Button>
        {props.isDrawerStatus && (
          <Typography className={classes.userName}  style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "140px", 
            marginLeft: "5px",
            fontWeight:"700" 
          }}>{props.name}</Typography>
        )}
        {props.isDrawerStatus && (
          <Button
            color="transparent"
            style={{ background: "transparent", border: "none", padding: "0", cursor: "pointer",zIndex:"1" }}
            onClick={openDialog}
          >
            <img src={LogoutIcon} style={{ width: "30px", height: "30px" }} alt="Logout Icon" />
          </Button>
        )}
      </div>

      {/* Logout Dialog */}
      <Dialog
        open={openLogout}
        title={
          <span className={classes.dialog}>
            Are you sure you want to Logout?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenLogout(false)}
        button2Event={logOutUser}
        icon={LogoutIconDial}
      />
    </div>
  );
}
