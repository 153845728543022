import React, { useEffect, useState } from "react";
import { Typography,Input, Box, TextField, makeStyles, Button } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";
import AddSign from "assets/img/addWidgetManager/AddSign.svg";
import { Cancel } from "@material-ui/icons";
import CloseIcon from '../../assets/img/icons/svg/CloseIcon.svg';
//import CloseIcon from "assets/img/icons/svg/close.svg";
import { AntSwitch } from "components/Switch/AntSwitch";


const useStyles = makeStyles(styles);


const CustomWidget = (props)=>{
    const classes = useStyles();
    const [key,setKey] = useState();
    const [value,setValue] = useState();
    const [custom,setCustom] = useState({})
    const [dynamicObject, setDynamicObject] = useState({});

useEffect(()=>{
  if(props?.path==="/admin/widgetmanager/edit")
setDynamicObject({...props.customWidgetDataType})
},[])

    const handleToAddCustom = ()=>{
        if(key != "" && key != undefined && value != "" && value != undefined){
            setDynamicObject((prevObject) => ({
              ...prevObject,
              newKey: '',
            }));
            updateFormattedOutput();
            setKey("")
            setValue("")
            props.setOpenMandatoryWarn(false)
        }
        else{
            props.setOpenMandatoryWarn(true);
        }
    }

    const updateFormattedOutput = ()=>{
        const filteredObject = Object.fromEntries(
            Object.entries(dynamicObject).filter(([key, value]) => value.trim() !== '' && key !== 'key')
        );
        // setCustom(filteredObject);
        props.setCustomWidgetDataType(filteredObject);
    }

    const updateKey = (e) => {
        const newKey = e.target.value;
        setKey(e.target.value);
        setDynamicObject((prevObject) => ({
          ...prevObject,
          key: newKey.trim() !== '' ? newKey : undefined,
        }));
      };

      const updateValue = (e) => {
        const newValue = e.target.value;
        setValue(newValue)
        setDynamicObject((prevObject) => {
          const updatedObject = { ...prevObject };
          if (key && newValue.trim() !== '') {
            updatedObject[key] = newValue;
          }
          return updatedObject;
        });
      };

      const handleKeyEdit = (oldKey, newKey) => {

        props.setCustomWidgetDataType((prevObject) => {
            const updatedObject = {};
            Object.keys(prevObject).forEach((key) => {
              if (key === oldKey) {
                updatedObject[newKey] = prevObject[key];
              } else {
                updatedObject[key] = prevObject[key];
              }
            });
            return updatedObject;
          });

        setDynamicObject((prevObject) => {
            const updatedObject = {};
            Object.keys(prevObject).forEach((key) => {
              if (key === oldKey) {
                updatedObject[newKey] = prevObject[key];
              } else {
                updatedObject[key] = prevObject[key];
              }
            });
            return updatedObject;
          });
      };

      const removeObject = (key)=>{
        props.setCustomWidgetDataType((prevObject) => {
            const updatedObject = { ...prevObject };
            delete updatedObject[key];
            return updatedObject;
          });
          setDynamicObject((prevObject) => {
            const updatedObject = { ...prevObject };
            delete updatedObject[key];
            return updatedObject;
          });
      }

      const enableSchedule = (event) => {
        props.setWidgetCommonDetails({
          ...props.widgetCommonDetails,
          enableSchedule: event.target.checked,
        });
      };
    return (
        <div>
        <div style={{display:"flex",flexDirection:"row", width:'100%'}}>
          <div style={{paddingRight:'30px'}}>
            <div className={classes.widgetDetailContainer}style={{flexDirection:'column'}} >
            <Box className={classes.validateBox}>
            <Typography style={{ paddingBottom: "10px" }}>Custom Key*</Typography>
            <TextField
                variant="outlined"
                className={classes.customTextField}
                size="small"
                placeholder="Enter Key*"
                onChange={updateKey}
                value={key}
            />
            </Box>
            {props.openMandatoryWarn ? (
                  key ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
                
            </div>
        </div>
        <div  style={{paddingRight:'30px'}}>
        <div className={classes.widgetDetailContainer} style={{flexDirection:'column'}}>
          <Box className={classes.validateBox}>
          <Typography style={{ paddingBottom: "10px" }}>Custom Value*</Typography>
            <TextField
                variant="outlined"
                className={classes.customTextField}
                size="small"
                placeholder="Enter Value*"
                onChange={(e) => updateValue(e, dynamicObject.key)}
                value={value}
            />
          </Box>
          {props.openMandatoryWarn ? (
                  value ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
        </div>
      </div>
      <div style={{marginTop:'50px'}} onClick={handleToAddCustom}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
                  borderRadius: "50%",
                  height: "32px",
                  width: "32px",
                  cursor:'pointer'
                }}
               >
                <img style={{ width: "11.6px", height: "11.6px" }} src={AddSign} />
              </span>
              <span> {value != undefined && value != "" ? <>please click add</> : ""}</span>
      </div>
    </div>
    <div>
        {Object.entries(props?.customWidgetDataType).map(([key,value])=>{
            return (
                <div  style={{display:"flex",flexDirection:"row", width:'100%'}}>
                     <div  style={{paddingRight:'30px'}}>
                         <div className={classes.widgetDetailContainer}>
                            <TextField
                                variant="outlined"
                                className={classes.customTextField}
                                size="small"
                                placeholder="Enter Value*"
                                onChange={(e) => handleKeyEdit(key, e.target.value)}                  
                                value={key}
                            />
                        </div>
                        {props.openMandatoryWarn ? (
                          Object.keys(props?.customWidgetDataType).length>0
                          ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
                    </div>
                    
                    <div  style={{paddingRight:'30px'}}>
                         <div className={classes.widgetDetailContainer}>
                                <TextField
                                    variant="outlined"
                                    className={classes.customTextField}
                                    size="small"
                                    placeholder="Enter Value*"
                                    onChange={(e) =>
                                      { setDynamicObject((prevObject) => ({
                                            ...prevObject,
                                            [key]: e.target.value
                                          }));
                                        // setCustom((prevObject) => ({ ...prevObject, [key]: e.target.value }))
                                        props.setCustomWidgetDataType((prevObject) => ({ ...prevObject, [key]: e.target.value }))
                                    }}
                                    value={value}
                                />
                           </div>
                           {props.openMandatoryWarn ? (
                           Object.values(props?.customWidgetDataType).length>0
                           ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
                      </div>
                      <div style={{marginTop:'17px'}} onClick={()=>{removeObject(key)}}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  border:'1px solid',
                  height: "32px",
                  width: "32px",
                  cursor:'pointer'
                }}
               >
                <img style={{ width: "15px", height: "15px" }} src={CloseIcon} />
              </span>
              </div>
            </div>
            )
        })}
    </div>
    <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {" "}
        <Typography style={{}}>Enable Schedule</Typography>{" "}
        <AntSwitch key={props?.widgetCommonDetails?.enableSchedule+"Enable Schedule"} name="checkedC" onChange={enableSchedule} defaultChecked={(props?.widgetCommonDetails?.enableSchedule)} />
      </div>
    </div>
    )
}

export default CustomWidget;