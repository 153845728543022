import React, { useState } from "react";
import reactCss from "reactcss";
import ColorPicker from "react-best-gradient-color-picker";
import './colorPicker.css'

export default function ColorPickerComponent(props) {
const _defaultValues = {
    color:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, RGB(255, 255, 255) 100%)"
}
const {color,onChange} = props

  const handleChange = (newColor) => {
    onChange(newColor)
  };

  const pickerCss = reactCss({
    default: {
      card: {
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "0 0 6px rgb(0,0,0, .25)",
        padding: "8px",
        position: "relative",
        width: "290px",
        zIndex: 102
      },
    },
  });

  return (
    <div >
      <div style={pickerCss.card}>
        <ColorPicker
          value={color||_defaultValues.color}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
