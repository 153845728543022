import {
    Box,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
    Select,
    MenuItem,
    Chip,
    Card,
    Input,
  } from "@material-ui/core";
  import Switch from '@mui/material/Switch';
  import React from "react";
  import { connect } from "react-redux";
  

  import Style from "../../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
  

  
  const useStyle = makeStyles(Style);

  
  const ApplicationConfig = (props)=>{
    const classes = useStyle();
    const appConfig = props?.appConfig || null

    return (
      <Box>
        <Card className={classes.EditCard}>
          <Box paddingLeft="24px" paddingTop="24px" paddingBottom="24px">
            <Box  pb={3} paddingRight="33px">
              <Box gridGap={"50px"} component="form" role="form">
             <div style={{ cursor:"not-allowed"}}>
            <Typography>Application Config</Typography>

             <div style={{display:"grid",width:"50%",marginTop:"10px" , marginBottom:"10px"}}>
             <Box margin={"5px"} paddingBottom="10px" style={{display:"flex" , alignItems:"center"}}>
              <div style={{display:"flex", alignItems:"center" ,gap:"5px"}}>
              <Typography style={{marginTop:"9px"}} className={classes.inputLabel}>PIP</Typography>
            
              <Switch disabled defaultChecked={appConfig?.pip || false} />
        
  
              </div>
              <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
              <Typography style={{marginTop:"9px"}} className={classes.inputLabel}>Download To Go</Typography>
              <Switch disabled defaultChecked={appConfig?.downloadToGo || false} />
              </div>
              </Box>
             </div>
            
          

             </div>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };
  
  const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationConfig);
  