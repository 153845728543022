import { Box, Button, Card, CardHeader, FormHelperText, Grid, Typography, makeStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import Styles from "assets/jss/material-dashboard-react/components/platformSelectorStyle";
import { useEffect, useState } from "react";
import { isDev } from "utils/getEnvironment";

const useStyle = makeStyles(Styles);

export const Platforms = [
  "WEB_DES",
  "WEB_MOB",
  "AND_MOB",
  "AND_TAB",
  "IOS_MOB",
  "IOS_TAB",
  "AND_TV",
  "SAMSUNG_SMART_TV",
  "LG_SMART_TV",
  "APPLE_TV",
  "VIDAA_TV",
  "DONGLE",
  "FIRESTICK",
  "STB",
  "SKYGLASS_TV"
];

export const PlatformNames = {
  WEB_DES: "Web Desktop",
  WEB_MOB: "Web Mobile",
  AND_MOB: "Android Mobile",
  AND_TAB: "Android Tablet",
  IOS_MOB: "  IOS Mobile",
  IOS_TAB: "IOS Tablet",
  AND_TV: "Android Tv",
  SAMSUNG_SMART_TV: "Samsung Tv",
  LG_SMART_TV: "LG Smart Tv",
  APPLE_TV: "Apple TV",
  VIDAA_TV: "Vidaa TV",
  DONGLE:"DONGLE",
  FIRESTICK: "FIRESTICK",
  "STB":"STB",
  "SKYGLASS_TV":"Sky glass TV"
};

export default function PlatformSeletor(props) {
  const [checked, setChecked] = useState(false);


  const classes = useStyle();

  const { clickHandler, handleSelectAll, platformSelected, selectedPlatforms, platformList, ErrorMessage, widgetHelper, lg, xl } = props;
  const PlatformArray = platformList ?? Platforms;
  const handleCheckboxChange = (event) => {
    handleSelectAll(event.target.checked?PlatformArray:[])
    setChecked(event.target.checked);
  };
  useEffect(()=>{
    setChecked(platformSelected?.length===PlatformArray?.length)
  },[platformSelected?.length || 0])
  return (
    <Grid item xs={12} md={12} lg={lg ?? 6} xl={xl ?? "auto"} style={{maxWidth:"59rem" ,border:"1px solid #8d8d8d61" ,borderRadius:"24px" ,height:"290px" ,marginTop:"30px"}}>
      <Box >
        <Card className={classes.card} style={{maxWidth:"59rem",height:"290px"}}>
          <Box>
            <Box style={{background:"#5b87e32e"}} >
            <Box style={{display:"flex", justifyContent:"space-between"}} >
              <CardHeader title="Select Platform Supported" classes={{ title: classes.cardHeader }} />
              <FormControlLabel
            control={
            <Checkbox                
            checked={checked} 
            onChange={handleCheckboxChange} 
            color="primary"
            />}
            label={
                  <Typography color={checked?"error":"textPrimary"} variant="body2" component="span">
                    {
                      checked ? "Remove All Platforms":"Select All Platforms "
                    }
                    </Typography>
            }
             />
            </Box>
            <Box  ml={6}>
         
            </Box>
            </Box>
            <Box pb="16px" display="flex" justifyContent="center" alignContent="flex-start" flexWrap="wrap" height={"200px"} overflow={"scroll"}>
              {PlatformArray.map((platform, index) => (
                <Box px={1} my={1} key={index}>
                  <Card
                    className={platformSelected?.includes(platform) ? classes.selectPlatformCard : classes.platformCard}
                    onClick={() => clickHandler(platform)}
                  >
                    <Box
                      justifyContent="space-evenly"
                      display="flex"
                      alignContent="vertical"
                      flexDirection="column"
                      alignItems="center"
                      height="100%"
                    >
                      {console.log(platform,"iconns")}
                      <img
                        src={`${process.env.REACT_APP_BASE_ASSETS_URL}${
                          isDev() ? "" : "/assets"
                        }/platformIcons/${platform}.svg`}
                        style={{ width: "35px",height:"35px" }}
                      />
                      <Typography
                        className={
                          platformSelected?.includes(platform) ? classes.selectCardTypo : classes.cardTypography
                        }
                      >
                        {PlatformNames[platform]}
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
        {widgetHelper ? (
          platformSelected.length ? null : (
            <span className={classes.validationText}>* Mandatory Field</span>
          )
        ) : null}
      </Box>
      <Box mx={5}>
        <FormHelperText>{selectedPlatforms?.length ? "" : ErrorMessage}</FormHelperText>
      </Box>
    </Grid>
  );
}
