
import axios from "axios";

import { homeApis } from "../../../config";

export const getCategoryTreeApi = async (projectId) => {
  /* var configue = {
    method: "GET",
    url: homeApis.getCategoryTree,
    headers: {
      "x-api-key": "6DVHFKCKOIWWFrA0ag4OGolkXFIL2l9EobaoMl80",

          Authorization:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0X2lkIjoiODE2MTk1NDgtYjFhMy00ZGQyLTg0ZDAtYmQ1OGE4MDk0YWU5IiwiYWNjZXNzTGV2ZWwiOiJhZG1pbiIsImlhdCI6MTY1MzM4MjQ1MCwiZXhwIjoxNjUzOTg3MjUwfQ.XR2sv2b3f4zKgXGuhUkocnuSt2G7XJvEHpb3WjlYrlk",
    }
  };
  return axios(configue); */
  const res = await axios.get(homeApis.getCategoryTree, {
    params: { p_id: projectId },
    headers: {
      Authorization: localStorage.getItem("token"),
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  });

  return res
};

export const getCategoryPlaylistResponseApi = async (id, projectId) => {
  const res = await axios.get(`${homeApis.getCategoryPlaylistv2}?categoryId=${id}`, {
    params: { p_id: projectId },
    headers: {
      Authorization: localStorage.getItem("token"),
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  });

  return res;
};

export const createWidgetApi = async (data, parameters) => {
  const options = {
    method: "POST",
    url: `${homeApis.createWidget}`,
    params: parameters,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  };

  const response = await axios.request(options);
  return response;
  /*axios.request(options).then(function (response) {
  }).catch(function (error) {
    console.error(error);
  }); */
};
export const editWidgetApi = async (id,data, parameters) => {
  console.log("WidgetId",id);
 const options = {
    method: "PUT",
    url: `${homeApis.editWidget}/${id}`,
    params: parameters,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  };

  const response = await axios.request(options);
  return response; 
  /*axios.request(options).then(function (response) {
  }).catch(function (error) {
    console.error(error);
  }); */
};

export const getWidgetApi = async (pageDetails) => {
  let pagenumber = pageDetails.pageNumber;
  let projectId = pageDetails.projectId;
  let sara = pageDetails.sara;
  const options = {
    method: "GET",
    url: `${homeApis.getWidget}`,
    //Project id should be changed
    params: { p_id: projectId, page: pagenumber, limit: 10,sara},
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = axios.request(options);

  return response;
};

//Get Project By Id Api call

export const getProjectByApiCall = async (projectId) => {
  const options = {
    method: "GET",
    url: `${homeApis.getProjetcByID}`,
    params: { p_id: projectId },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response;
};

export const getSearchWidgetAPI = async (data) => {
  let pagenumber = data.pageNumber;
  let projectId = data.projectId;
  let sara = data.sara;
  let params = {p_id: projectId,page: pagenumber,limit: 10,sara}
  if(data?.layout){
    params["layout"]=data.layout
  }
  if(data?.platform){
    params["platform"]=data.platform
  }
  if(data?.status){
    params["status"]=data.status
  }

  const options = {
    method: "POST",
    url: `${homeApis.searchApi}`,
    //Project id should be changed
    params,
    data:{
      searchWidget:data.searchWidget
    } ,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = axios.request(options);

  return response;
}


//Delete Api call
export const deleteWidgetApiCall=async(widgetId)=>{
  console.log("Widget Delete",widgetId.id);
  const options = {
    method: "DELETE",
    url: `${homeApis.deleteWidget}`,
    params: { widget:widgetId.id,
      p_id: widgetId.p_id },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response;
}