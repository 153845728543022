import Primary from "components/Typography/Primary";
import React, { useEffect, useState } from "react";

import VivreAutocomplete from "../../../components/Autocomplete/Autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-react/views/pushNotificationStyle";
import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SubHead from "components/Typography/SubHead";
import { PlatformNames } from "components/PlatformScreensView/PlatformSelector";
import { updatePushNotification } from "../actions";

const useStyle = makeStyles(styles);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const getPushNotificationOptions = ({ platforms = null }) => {
  let pushNotificationOptions = {
    frequency: [1, 3, 7, 15, 30, 60],
    campaignSchedule: {
      from: "",
      to: "",
    },
    eventTrigger: [
      "LaunchApp",
      "CloseApp",
      "Uninstall",
      "Cancellation",
      "Upgrade",
    ],
    category: ["Personalised", "New Releases", "Popular"],
    content: {
      type: ["Text", "Image"],
      layout: ["Thumbnail", "Poster"],
      position: [
        {
          label:"Left Top",
          value:"TL"
        }
        ,
        {
          label:"Right Top",
          value:"TR"
        },
        {
          label:"Center",
          value:"C"
        },
        {
          label:"Bottom Left",
          value:"BL"
        }
        ,
        {
          label:"Bottom Right",
          value:"BR"
        }
      ],
    },
    targetGroup: "",
    PlatformTarget: platforms,
  };
  return pushNotificationOptions;
};



const EditPushNotification = ({ projectDetails, location,updatePushNotification }) => {
  const classes = useStyle();
  const platforms = projectDetails?.platformList;
  console.log("🚀 ~ EditPushNotification ~ platforms:", projectDetails);
  const notificationDetails = location?.state?.pushNotificationDetails;
  console.log("🚀 ~ EditPushNotification ~ notificationDetails:", location);

  const history = useHistory();
  const [ipushNotificationDetails, setipushNotificationDetails] = useState({
    id: "",
    notification_type: "",
    frequency: "",
    campaign_schedule: {
      from_date: "",
      till_date: "",
    },
    event_trigger: [],
    category: "",
    notification_content: {
      type: [],
      layout: "",
      position: "",
    },
    target_group: "",
    platform_target: [],
  });
  const [mandatoryWarn, setMandatoryWarn] = useState(false);

  useEffect(() => {
    setipushNotificationDetails(notificationDetails);
  }, [notificationDetails]);

  if (!notificationDetails) {
    history.push({
      pathname: "/admin/pushnotification",
    });
  }

  let pushNotificationOptions = {};
  if (platforms?.length > 0) {
    pushNotificationOptions = getPushNotificationOptions({ platforms });
  }

  if (!pushNotificationOptions) {
    return null;
  }
  const {
    frequency: frequencyOptions = [],
    category: categoryOptions = [],
    eventTrigger: eventTriggerOptions = [],
    content:{position:positionOptions=[],
            type:contentTypeOptions=[],
            layout:layoutOptions=[],
    },
    PlatformTarget:PlatformTargetOptions=[]
    
  } = pushNotificationOptions;

console.log(PlatformTargetOptions,"PlatformTargetOptions")
  const handleEventTrigger = (event) => {
    const {
      target: { value },
    } = event;
    setipushNotificationDetails((state) => ({
      ...state,
      event_trigger: (typeof value === "string" ? value.split(",") : value),
    }));
  };
  const handleContentType = (event) => {
    const {
      target: { value },
    } = event;
    setipushNotificationDetails((state) => ({
      ...state,
      notification_content:{
        ...state.notification_content,
        type:(typeof value === "string" ? value.split(",") : value)
      }
    }));
  };
  const handlePlatformTarget = (event) => {
    const {
      target: { value },
    } = event;
    setipushNotificationDetails((state) => ({
      ...state,
      platform_target:(typeof value === "string" ? value.split(",") : value)
    }));
  };

  const handleNotificationsValues = (value, type) => {
    console.log(value,"value")
    switch (type) {
      case "frequency":
        setipushNotificationDetails((state) => ({
          ...state,
          frequency: Number(value),
        }));
        break
      case "category":
        setipushNotificationDetails((state) => ({
          ...state,
          category: value,
        }));
        break
      case "eventTrigger":
        handleEventTrigger(value)
        break
      case "position":
        setipushNotificationDetails((state) => ({
          ...state,
          notification_content: {
            ...state.notification_content,
            position:value.value
          },
        }));
        break
      case "layout":
        console.log(value,"layout")
          setipushNotificationDetails((state) => ({
            ...state,
            notification_content: {
              ...state.notification_content,
              layout:value
            },
          }));
          break
      case "contentType":
        handleContentType(value)
        break
      case "campaign_schedule_from_date":
         let from_date = value.target.value
         setipushNotificationDetails((state) => ({
          ...state,
          campaign_schedule: {
            ...state.campaign_schedule,
            from_date
          },
        }));
        break
      case "campaign_schedule_till_date":
          let till_date = value.target.value
          setipushNotificationDetails((state) => ({
           ...state,
           campaign_schedule: {
             ...state.campaign_schedule,
             till_date
           },
         }));
         break
      case "platformTarget":
        handlePlatformTarget(value)
        break
      case "targetGroup":
        setipushNotificationDetails((state) => ({
          ...state,
         target_group:value
        }));
    }
  };
  console.log(ipushNotificationDetails,"ipushNotificationDetails")
const validate = ()=>{
  console.log(ipushNotificationDetails)
  const isValid = (
    ipushNotificationDetails?.category &&
    ipushNotificationDetails?.frequency &&
    ipushNotificationDetails?.notification_type &&
    Array.isArray(ipushNotificationDetails?.event_trigger)  &&
    ipushNotificationDetails?.notification_content?.position &&
    ipushNotificationDetails?.notification_content?.layout &&
    Array.isArray(ipushNotificationDetails?.notification_content?.type) &&
    ipushNotificationDetails?.campaign_schedule?.from_date &&
    ipushNotificationDetails?.campaign_schedule?.till_date &&
    ipushNotificationDetails?.target_group &&
    Array.isArray(ipushNotificationDetails?.platform_target) 
  )
  if(!isValid){
    setMandatoryWarn(true)
  }
  return isValid
}
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        overflowY: "auto",
        marginBottom: "50px",
    
      }}
    >
      <Primary>{"Edit Push Notification"}</Primary>
      <div >
        <SubHead>Basic Information</SubHead>
        <div className={classes.pushNotificationContainer}>
          <Box sx={{ py: 2 }} className={classes.validateBox}>
            <VivreAutocomplete
              options={categoryOptions}
              optionListStyle={{ width: "312px" }}
              getOptionLabel={(option) => option}
              defaultValue={ipushNotificationDetails?.category || ""}
              
              renderInput={(params) => (
                <TextField
                  size="small"
                  
                  className={classes.textField}
                  {...params}
                  placeholder="Select Category*"
                  variant="outlined"
                  label={"Select Category*"}
                  style={{ height: "40px", width: "312px", fontSize: "5px" }}
                />
              )}
              onChange={(event,value) => {
                handleNotificationsValues(value, "category");
              }}
              onReset={()=>{
                handleNotificationsValues("", "category");
              }}
            />
            {mandatoryWarn ? (
              ipushNotificationDetails?.category ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
          <Box sx={{ py: 2 }} className={classes.validateBox}>
            <VivreAutocomplete
              options={frequencyOptions}
              optionListStyle={{ width: "312px" }}
              getOptionLabel={(option) =>
                `${option} ${option > 1 ? "Days" : "Day"}`
              }
              defaultValue={  notificationDetails?.frequency? `${notificationDetails?.frequency} ${notificationDetails?.frequency > 1 ? "Days" : "Day"}`: ""}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Frequency*"
                  variant="outlined"
                  label={"Select Frequency*"}
                  style={{ height: "40px", width: "312px", fontSize: "5px" }}
                />
              )}
              onChange={(event,value) => {
                handleNotificationsValues(value, "frequency");
              }}
              onReset={()=>{
                handleNotificationsValues("", "frequency");
              }}
            />
            {mandatoryWarn ? (
              ipushNotificationDetails?.frequency ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
          <Box style={{marginTop:"-12px"}} >
            <Typography id="Select Event Trigger*" className={classes.inputLabel}>
            Event Trigger
            </Typography>
            <Select
              labelId="Select Event Trigger*"
              multiple
              className={classes.textfield}
              variant="outlined"
              fullWidth
              size="small"
              style={{height: "40px", width: "312px", fontSize: "15px" ,backgroundColor:"white" }}
              onChange={(event,value)=>{
                handleNotificationsValues(event,"eventTrigger")
              }}
              value={ipushNotificationDetails?.event_trigger || []}
              MenuProps={MenuProps}
            >
              {eventTriggerOptions.map((x, i) => {
                return (
                  <MenuItem value={x} key={i}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </div>
      </div>
      <div >
        <SubHead>Content Information</SubHead>
      
        <div className={classes.pushNotificationContainer}>
       
          <Box sx={{ py: 2 }} className={classes.validateBox}>
            <VivreAutocomplete
              options={layoutOptions}
              optionListStyle={{ width: "312px" }}
              getOptionLabel={(option) => option}
              defaultValue={ipushNotificationDetails?.notification_content?.layout|| ""}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select layout Type*"
                  variant="outlined"
                  label={"Select layout Type*"}
                  style={{ height: "40px", width: "312px", fontSize: "5px" }}
                />
              )}
              onChange={(event,value) => {
                handleNotificationsValues(value, "layout");
              }}
              onReset={()=>{
                handleNotificationsValues("", "layout");
              }}
            />
            {mandatoryWarn ? (
              ipushNotificationDetails?.notification_content?.layout? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
          <Box sx={{ py: 2 }} className={classes.validateBox}>
            <VivreAutocomplete
              options={positionOptions}
              optionListStyle={{ width: "312px" }}
              getOptionLabel={(option) =>option.label
              }
              defaultValue={(new Array(...positionOptions).find(ele=>ele.value === ipushNotificationDetails?.notification_content?.position))?.label
              || ""}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Position*"
                  variant="outlined"
                  label={"Select Position*"}
                  style={{ height: "40px", width: "312px", fontSize: "5px" }}
                />
              )}
              onChange={(event,value) => {
                handleNotificationsValues(value, "position");
              }}
              onReset={()=>{
                handleNotificationsValues({value:""}, "position");
              }}
            />
            {mandatoryWarn ? (
              ipushNotificationDetails?.notification_content?.position ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
          <Box  style={{marginTop:"-12px"}}>
            <Typography id="Select Content Type*" className={classes.inputLabel}>
            Content Type
            </Typography>
            <Select
              labelId="Select Content Type*"
              multiple
              className={classes.textfield}
              variant="outlined"
              fullWidth
              size="small"
              style={{height: "40px", width: "312px", fontSize: "15px" ,backgroundColor:"white" }}
              onChange={(event)=>{
                handleNotificationsValues(event,"contentType")
              }}
              value={ipushNotificationDetails?.notification_content?.type || []}
              MenuProps={MenuProps}
            >
              {contentTypeOptions.map((x, i) => {
                return (
                  <MenuItem value={x} key={i}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
       
      <div>

      </div>
      {
                 mandatoryWarn && !(ipushNotificationDetails?.notification_content?.type.length>0 ) &&   <span className={classes.validationText}>
                  * Mandatory Field
                </span>
                }
          </Box>
        </div>

      </div>
      <div >
      <SubHead>Schedule Information</SubHead>
      <div className={classes.pushNotificationContainer}>
          <Box
            style={{
              width: "360px",
              display: "flex",
              justifyContent: "space-between",
              marginTop:"15px"
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{}}>Start Date</Typography>{" "}
              <Box className={classes.validateBox}>
                <TextField
                  name="from_date" 
                  value={ipushNotificationDetails?.campaign_schedule?.from_date || ""}
                  type="date"
                  inputProps={{min: new Date().toISOString().split('T')[0]}}
                  size="small"
                  id="campaign_schedule_from_date"
                  variant="outlined"
                  className={classes.textField}
                  style={{height: "40px", width: "160px", fontSize: "15px" ,backgroundColor:"white" }}
                  onChange={(e)=>{
                    handleNotificationsValues(e,"campaign_schedule_from_date")
                  }}
                />
                {
                 mandatoryWarn && !(ipushNotificationDetails?.campaign_schedule?.from_date) &&   <span className={classes.validationText}>
                  * Mandatory Field
                </span>
                }
              </Box>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{}}>End Date</Typography>{" "}
              <Box className={classes.validateBox}>
                <TextField
                  name="till_date" 
                  inputProps={{min: new Date().toISOString().split('T')[0]}}
                  value={ipushNotificationDetails?.campaign_schedule?.till_date || ""}
                  type="date"
                  size="small"
                  id="campaign_schedule_till_date"
                  variant="outlined"
                  className={classes.textField}
                  style={{height: "40px", width: "160px", fontSize: "15px" ,backgroundColor:"white" }}
                  onChange={(e)=>{
                    handleNotificationsValues(e,"campaign_schedule_till_date")
                  }}
                />
                {
                 mandatoryWarn && !(ipushNotificationDetails?.campaign_schedule?.till_date) &&   <span className={classes.validationText}>
                  * Mandatory Field
                </span>
                }
              </Box>
            </div>
       
          </Box>
        </div>
    </div>
    <div style={{marginTop:"20px"}}>
    <SubHead>Target Information</SubHead>
    <div        style={{
              width: "360px",
              display: "flex",
              justifyContent: "space-between",
              marginTop:"15px",
              paddingBottom:"20px",
              
            }}>
      <div>
      <div className={classes.pushNotificationContainer}>
              <TextField
                  size="small"
                  className={classes.textField}
                  placeholder="Target Group*"
                  defaultValue={ipushNotificationDetails?.target_group || ""}
                  variant="outlined"
                  label={"Target Group*"}
                  value={ipushNotificationDetails?.target_group}
                  style={{ height: "40px", width: "312px", fontSize: "5px" , marginTop:"15px" }}
                  onChange={(e)=>{
                    handleNotificationsValues(e.target.value,"targetGroup")
                  }}
                />
                 
    </div>
      {
                 mandatoryWarn && !(ipushNotificationDetails?.target_group ) &&   <span className={classes.validationText}>
                  * Mandatory Field
                </span>
                }
      </div>
      {
      (ipushNotificationDetails?.notification_type.toLowerCase()==="normal"||
        ipushNotificationDetails?.notification_type.toLowerCase()==="inapp"
      ) ?
      <div className={classes.pushNotificationContainer}>
      <Box  style={{marginTop:"-12px"}}>
        <Typography id="Select Platform Target*" className={classes.inputLabel}>
        Platform Target
        </Typography>
        <Select
          labelId="Select Platform Target*"
          multiple
          className={classes.textfield}
          variant="outlined"
          fullWidth
          size="small"
          style={{height: "40px", width: "312px", fontSize: "15px" ,backgroundColor:"white" }}
          onChange={(event,value)=>{
            handleNotificationsValues(event,"platformTarget")
          }}
          // key={ipushNotificationDetails?.platform_target }
          value={ipushNotificationDetails?.platform_target || []}
          MenuProps={MenuProps}
        >
          {PlatformTargetOptions.map((x, i) => {
            return (
              <MenuItem value={x} key={i}>
                {PlatformNames[x]}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </div> :
    <Box  style={{marginTop:"-12px"}}>
      
    </Box>
    }
   
    </div>
 
    </div>
    <Paper
                  style={{
                    width: "95%",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100vw",
                    zIndex: 2,
                    boxSizing: "border-box",
                  }}
                >
    <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      height: "80px",
                      paddingRight: "60px",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                        className={classes.submitButton}
                        onClick={() => {
                          console.log(validate(),"validate")
                          if(validate()){
                            updatePushNotification({
                              body:{...ipushNotificationDetails,status:"PUBLISHED"},
                              parameters:{
                                notification_type:ipushNotificationDetails.notification_type,
                                p_id:projectDetails?.p_id,
                              }
                            })
                          }
                          // publishNotification()
                        }}
                      >
                        {"Publish" }
                      </Button>
                      </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectDetails: state.ProjectReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePushNotification: ({body,parameters}) => dispatch(updatePushNotification(body,parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPushNotification);
