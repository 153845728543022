import React from 'react'
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";


const styles = {
    menu: {
      borderRadius:'2px'
    },
  };
  const useStyles = makeStyles(styles);


export default function Menuitem(props){
    const classes = useStyles();

    const { items, getValue, getLabel , selectItem } = props;
    console.log(getValue)
    return(<>
   {items.map((menu,index)=><MenuItem key={index} className={classes.menu} value={getValue(menu)} onClick={()=>{selectItem(menu)}}>{getLabel(menu)}</MenuItem>)}
    </>)
}

  

