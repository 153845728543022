import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";

const data = [
    { "timeline": "2024-08-01", "web_desk": 1000, "Android": 1500, "AppleTV": 900 },
    { "timeline": "2024-08-02", "web_desk": 1800, "Android": 1300, "AppleTV": 1000 },
    { "timeline": "2024-08-03", "web_desk": 1400, "Android": 1600, "AppleTV": 1300 },
    { "timeline": "2024-08-04", "web_desk": 2000, "Android": 1100, "AppleTV": 900 },
    { "timeline": "2024-08-05", "web_desk": 1500, "Android": 2000, "AppleTV": 1200 },
    { "timeline": "2024-08-06", "web_desk": 2100, "Android": 900, "AppleTV": 1600 },
    { "timeline": "2024-08-07", "web_desk": 1700, "Android": 2500, "AppleTV": 1000 },
    { "timeline": "2024-08-08", "web_desk": 2300, "Android": 1300, "AppleTV": 1800 },
    { "timeline": "2024-08-09", "web_desk": 1900, "Android": 1800, "AppleTV": 1100 },
    { "timeline": "2024-08-10", "web_desk": 2600, "Android": 2100, "AppleTV": 1500 },
    { "timeline": "2024-08-11", "web_desk": 2300, "Android": 1500, "AppleTV": 1900 },
    { "timeline": "2024-08-12", "web_desk": 2500, "Android": 1000, "AppleTV": 2000 },
    { "timeline": "2024-08-13", "web_desk": 3000, "Android": 1700, "AppleTV": 2200 },
    { "timeline": "2024-08-14", "web_desk": 2100, "Android": 2500, "AppleTV": 1700 },
    { "timeline": "2024-08-15", "web_desk": 3200, "Android": 1900, "AppleTV": 2300 },
    { "timeline": "2024-08-16", "web_desk": 2900, "Android": 2200, "AppleTV": 1800 },
    { "timeline": "2024-08-17", "web_desk": 3500, "Android": 2700, "AppleTV": 1500 },
    { "timeline": "2024-08-18", "web_desk": 2200, "Android": 1900, "AppleTV": 2500 },
    { "timeline": "2024-08-19", "web_desk": 3600, "Android": 3000, "AppleTV": 1700 },
    { "timeline": "2024-08-20", "web_desk": 3100, "Android": 2500, "AppleTV": 2800 },
    { "timeline": "2024-08-21", "web_desk": 3800, "Android": 2000, "AppleTV": 2100 },
    { "timeline": "2024-08-22", "web_desk": 3300, "Android": 2700, "AppleTV": 2300 },
    { "timeline": "2024-08-23", "web_desk": 3900, "Android": 2200, "AppleTV": 1900 },
    { "timeline": "2024-08-24", "web_desk": 3500, "Android": 3000, "AppleTV": 2600 },
    { "timeline": "2024-08-25", "web_desk": 4100, "Android": 2400, "AppleTV": 2800 },
    { "timeline": "2024-08-26", "web_desk": 3200, "Android": 3200, "AppleTV": 2300 },
    { "timeline": "2024-08-27", "web_desk": 4400, "Android": 2900, "AppleTV": 2600 },
    { "timeline": "2024-08-28", "web_desk": 3700, "Android": 2700, "AppleTV": 2100 },
    { "timeline": "2024-08-29", "web_desk": 4500, "Android": 3100, "AppleTV": 2500 },
    { "timeline": "2024-08-30", "web_desk": 3800, "Android": 3500, "AppleTV": 2700 }
  ];
  
export default function PlatformsLineChart() {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <XAxis
          dataKey="timeline"
          tickFormatter={(tick, index) => {
            if (index % 7 === 0) {
              return tick;
            }
            return "";
          }}
          tick={{ fill: "#000",
            fontSize: 14,     
            fontWeight: 400,
            }}
        />
        <YAxis tick={{ fill: "#000",
            fontSize: 14,     
            fontWeight: 400,
            }}/>
        <Tooltip
          labelFormatter={(label) => `Date: ${label}`}
          formatter={(value) => [`${value} devices`, "Total Devices"]}
        />
        <Legend verticalAlign="top" height={36} />
        {/* Line for web_desk */}
        <Line
          type="monotone"
          dataKey="web_desk"
          stroke="#ff7300"
          strokeWidth={2}
          dot={false}
        />
        {/* Line for Android */}
        <Line
          type="monotone"
          dataKey="Android"
          stroke="#387908"
          strokeWidth={2}
          dot={false}
        />
        {/* Line for AppleTV */}
        <Line
          type="monotone"
          dataKey="AppleTV"
          stroke="#8884d8"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
