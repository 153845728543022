import {
  Box,
  Card,
  Grid,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Typography,
  Button,
} from "@material-ui/core";
//import Card from "components/Card/Card";
import Menuitem from "components/Menu/MenuItem";
import React, { useState, useEffect } from "react";
import EditProjectDetails from "./EditProjectDetails";

import Style from "../../../../assets/jss/material-dashboard-react/views/EditProjectStyle";
import EditProjectSettings from "./EditProjectSettings";

import { updateProjectDetailsAction } from "screens/Projects/actions/projectActions";
import { addProjectNameInNavbarAction, getAllLanguage } from "../../actions/projectActions";
import { connect, useSelector } from "react-redux";

import { useHistory, Redirect } from "react-router-dom";
import EditProjectConfig from "./EditProjectConfig";
import FeatureConfig from "./FeatureConfig";
import EditSubscriptionConfig from "./EditSubscriptionConfig";
import { getViewJsonAction } from "screens/Projects/actions/projectActions";
import { updateConfigDetails } from "screens/Projects/actions/projectActions";
import ApplicationConfig from "./ApplicationConfig";
import ThirdpartyConfig from "./ThirdpartyConfig";

const useStyle = makeStyles(Style);

const EditProjects = (props) => {

  if(typeof(props?.location?.state?.data?._id)!=="string"){
    return <Redirect  to="/admin" />
  }

  const [Change, setChange] = useState(0);
  const [projectData, setProjectData] = useState(props.location.state.data);
  const [UpdateDetails, setUpdateDetails] = useState({});
  const [image16, setImage16] = useState(false);
  const [image1, setImage1] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [CmsErrorMessage, setCmsErrorMessage] = useState("");
// subscription view json state
  const [jsonData, setJsonData] = useState(props?.viewJson || {
    "prompt_show_count": "",
    "prompt_interval_days": "",
    "prompt_image_url_16_9": "",
    "prompt_image_url_16_9_fallback": "",
    "prompt_image_url_9_14": "",
    "prompt_image_url_9_14_fallback": "",
    "prompt_screen_package_subs_btn_label": "",
    "prompt_screen_login_btn_label": "",
    "subscription_footer_tax": "",
    "beta_testing_enabled": false,
    "is_enable_FPX": false,
    "is_enable_eWallet": false,
    "is_disneyhotstar_promo": false,
    "prompt_enabled_for_android_app": false,
    "prompt_enabled_for_ios_app": false,
    "prompt_enabled_for_web_app": false,
    "prompt_enabled_for_web_mobile": false,
    "web_onboarding_bg_image_url": "",
    "web_onboarding_bg_image_url_fallback": "",
    "last_modified": "",
    "config_version": ""
});
const viewJsonFromStore = useSelector((state) => state.ProjectReducer.viewJson);
 // Manage state here
  const history = useHistory();

  const [cmsConfig, setCmsConfig] = useState(props.Config?.data?.config);

  const classes = useStyle();
  

  useEffect(() => {
    props.getAllLanguage();
  }, []);

  useEffect(() => {
    setUpdateDetails({ ...UpdateDetails, p_id: projectData.p_id });
  }, []);
  useEffect(() => {
    const payload={t_id:props?.authToken?.t_id,p_id:projectData?.p_id}
    props.getViewJsonAction(payload);
  }, []);
  useEffect(() => {
    if (viewJsonFromStore) {
      setJsonData(viewJsonFromStore); // Ensure state is updated properly
    }
  }, [viewJsonFromStore]);

  useEffect(() => {
    setProjectData({ ...projectData, cmsConfig: cmsConfig });
  }, [cmsConfig]);

  useEffect(() => {
    setCmsConfig(props.Config?.data?.config);
  }, [props.Config?.data?.config]);

  const handleFeatureConfig = () => {
    setChange(3);
  };

  const handleappConfig = () => {
    setChange(4);
  };

  const handleThirdpartyConfig = () => {
    setChange(5);
  };
  const handleConfig = () => {
    setChange(2);
  };

  const handleSettings = () => {
    setChange(1);
  };

  const handleProject = () => {
    setChange(0);
  };

  const handleSubscriptionConfig = () =>{
    setChange(6);
  }

  const handleUpdate = () => {
    if (Change === 6 ) {
       // call post request for update subscription config tool
       const payload={data:jsonData,p_id:projectData.p_id}
       setJsonData(jsonData);
       props.updateConfigDetails(payload)
       props.addProjectNameInNavbarAction({...props.allProjects[0],isAll:true})
       history.push({ pathname: "/admin/projects" });
    }else{
      const bgOption = projectData.config?.bgOption || "bgColor"
      if(
        (bgOption==="bgImage" && !projectData.config?.bgImage?.["1x1"] && !projectData.config?.bgImage?.["16x9"] ) ||
        (bgOption==="gdColor" && !projectData.config?.colors?.gdColor) ||
        (bgOption==="bgColor" && !projectData.config?.colors?.bgColor) ||
        (projectData?.aspectRatios?.length<=0) ||
        (projectData.config?.colors?.fgColor === undefined || projectData.config?.colors?.fgColor === "") ||
        (projectData.config?.font === undefined || projectData.config?.font === "") ||
        (props?.projectData?.tags?.length<=0)
        ){
        setErrorMessage("mandatory field *");
      }else if(
        (projectData.cmsConfig.apiVersion === undefined ||projectData.cmsConfig.apiVersion === "") ||
        (projectData.cmsConfig.rootCategoryId  === undefined || projectData.cmsConfig.rootCategoryId === "") ||
        (projectData.cmsConfig.baseUrl === undefined || projectData.cmsConfig.baseUrl === "") ||
        (projectData.cmsConfig.clientVersion === undefined || projectData.cmsConfig.clientVersion === "") ||
       ( projectData.cmsConfig.partnerId === undefined || projectData.cmsConfig.partnerId === "") ||
        (projectData.cmsConfig.username === undefined || projectData.cmsConfig.username === undefined  ) ||
        (projectData.cmsConfig.password === undefined || projectData.cmsConfig.password === "") ||
        (projectData.cmsConfig.applicationName === undefined || projectData.cmsConfig.applicationName === "") ||
        (projectData.cmsConfig.platform === undefined || projectData.cmsConfig.platform === "") ||
        (projectData.cmsConfig.tag === undefined || projectData.cmsConfig.tag === "") ||
        (projectData.cmsConfig.udid === undefined || projectData.cmsConfig.udid === "")
  
      ){
        setCmsErrorMessage("mandatory field *");
      }
      else{
        props.addProjectNameInNavbarAction({...props.allProjects[0],isAll:true})
        props.updateProjectDetailsAction(projectData);
        history.push({ pathname: "/admin/projects" });
      }
    }
  };

  const handleCancel = () => {
    history.push({ pathname: "/admin/projects" });
  };
  // temp data subscription tool
  // const jsonData = {
  //   config_version: 1151,
  //   prompt_show_count: 5,
  //   prompt_interval_days: 1,
  //   subscription_footer_tax: 'All prices displayed are exclusive of tax',
  //   is_disneyhotstar_promo: true,
  //   beta_testing_enabled: false,
  //   is_enable_FPX: true,
  //   is_enable_eWallet: true,
  //   prompt_image_url_16_9: 'https://d125znkpbh4qlm.cloudfront.net/prompt/16x9/main',
  //   prompt_image_url_16_9_fallback: 'https://d125znkpbh4qlm.cloudfront.net/prompt/16x9/fallback',
  //   package_details: [
  //     {
  //       package_name: 'sooka Premium 1M + Dongle Bundle',
  //       ovpSKU: 11919,
  //       last_modified: '',
  //       plan_title: 'TV STICK ONLY',
  //       plan_description: 'Premium content for only RM 349.00',
  //       web_tab_images: {
  //         promo_image_url: 'https://d125znkpbh4qlm.cloudfront.net/packages/11919/16x9/promo_web_tab',
  //         image_url: 'https://d125znkpbh4qlm.cloudfront.net/packages/11919/16x9/Subscription_web_tab',
  //         fallback_image_url: 'https://d125znkpbh4qlm.cloudfront.net/packages/11919/16x9/fallback',
  //       },
  //     },
  //   ],
  // };

  console.log(projectData,"projectData");
  
  return (
    <Box>
      <Box>
        <Typography className={classes.Heading}>Edit Project</Typography>
      </Box>
      <Box pt="24px" pb="75px">
        <Grid container>
          <Grid item sm={4} md={4} lg={3}>
            <Box>
              <Card className={classes.Card}>
                <Box paddingTop="24px" paddingLeft="16px">
                  <MenuList>
                    <MenuItem onClick={handleProject}>
                      <ListItemText className={Change === 0 ? classes.SelectedList : classes.List}>
                        Project Details
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleSettings}>
                      <ListItemText className={Change === 1 ? classes.SelectedList : classes.List}>
                        Project Settings
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleConfig}>
                      <ListItemText className={Change === 2 ? classes.SelectedList : classes.List}>
                        CMS Config
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleFeatureConfig}>
                      <ListItemText className={Change === 3 ? classes.SelectedList : classes.List}>
                        Feature Config
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleappConfig}>
                      <ListItemText className={Change === 4 ? classes.SelectedList : classes.List}>
                        Application Config
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleThirdpartyConfig}>
                      <ListItemText className={Change === 5 ? classes.SelectedList : classes.List}>
                        Thirdparty Config
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleSubscriptionConfig}>
                      <ListItemText className={Change === 6 ? classes.SelectedList : classes.List}>
                      Subscription Config
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item sm={8} md={8} lg={9}>
            {Change === 0 ? (
              <EditProjectDetails
                projectData={projectData}
                setProjectData={setProjectData}
                setImage16={setImage16}
                setImage1={setImage1}
                image1={image1}
                image16={image16}
              />
            ) : Change === 1 ? (
              <EditProjectSettings
                ErrorMessage={ErrorMessage}
                projectData={projectData}
                setProjectData={setProjectData}
                allLanguage={props.allLanguage}
              />
            ) : Change === 2 ? (
              <EditProjectConfig cmsErrorMessage={CmsErrorMessage} configDetails={projectData} setConfigDetails={setCmsConfig} cmsConfig={cmsConfig} />
              ) :Change === 3 ?  (
                
                <FeatureConfig featureConfig={props.featureConfig}  />
            ):Change === 4 ?  (
              <ApplicationConfig appConfig={props.appConfig}  />
          ):Change === 5 ?  (
              <ThirdpartyConfig thirdpartyConfig={props.thirdpartyConfig}  />
        ):(Change === 6 && props?.featureConfig?.sia) ?(
          <EditSubscriptionConfig jsonData={jsonData} setJsonData={setJsonData}/>
        ):
          (
              ""
            )
            }
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          boxSizing: "border-box",
        }}
      >
        <Card className={classes.bottomCard}>
          <Box justifyContent="flex-end" display="flex" flexDirection="row" alignItems="center">
            <Box paddingLeft="auto" paddingTop="1%" paddingBottom="2%">
              <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
                <Typography className={classes.cancelButtonText}>Cancel</Typography>
              </Button>
            </Box>
            <Box paddingLeft="2%" paddingTop="1%" paddingBottom="2%" paddingRight="5%">
              <Button className={classes.addButton} onClick={handleUpdate}>
                <Typography className={classes.addButtonText}>Update</Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    allLanguage: State.ProjectReducer.allLanguage,
    Config: State.ProjectReducer.kalturaConfig,
    featureConfig: State.ProjectReducer.featureConfig,
    appConfig: State.ProjectReducer.appConfig,
    thirdpartyConfig: State.ProjectReducer.thirdpartyConfig,
    allProjects: State.ProjectReducer.allProjects,
    viewJson: State.ProjectReducer.viewJson,
    authToken: State.loginReducer.authTokens,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    updateProjectDetailsAction: (updateDetails) => {
      dispatch(updateProjectDetailsAction(updateDetails));
    },
    getAllLanguage: () => {
      dispatch(getAllLanguage());
    },
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    },
    getViewJsonAction: (payload) => {
      dispatch(getViewJsonAction(payload));
    },
    updateConfigDetails: (data) => {
      dispatch(updateConfigDetails(data));
    },
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(EditProjects);
