import React from "react";
import { connect } from "react-redux";

//Typegraphy component
import Primary from "../../../components/Typography/Primary";

//Material ui component
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

//Icons and images imported
import SearchIcon from "../../../assets/img/icons/SearchIcon.svg";

import NextIcon from "assets/img/platformScreenVisualization/NextIcon.svg";
import BackIcon from "assets/img/platformScreenVisualization/BackIcon.svg";

//CSS JSS file
import style from "../../../assets/jss/material-dashboard-react/views/blackoutScheduleStyle";
import GridContainer from "components/Grid/GridContainer";
//Import Component
import LinearChannel from "../../../components/blackoutScheduleComponents/LinearChannel";
import ScheduleBlackoutEPG from "../../../components/blackoutScheduleComponents/ScheduleBlackoutEPG";
import ScheduleBlackoutDetails from "../../../components/blackoutScheduleComponents/ScheduleBlackoutDetails";

//Action
import { getAllEpgAction, getAllLinearChannelAction } from "../actions";
import { set } from "express/lib/application";

const useStyle = makeStyles(style);

//Temporary Channel List
const rail = [
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
];
var days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var dateTitle = new Date();
var FixDate = new Date();
var FixDate2 = new Date();
var fix = FixDate2.setDate(FixDate.getDate() + 2);
//Add a Blackout for the selected channel
const ScheduleBlackout = (props) => {
  const classes = useStyle();
  const [channelDetails, setChannelDetails] = React.useState({});
  const [scheduleBlackoutDetails, setScheduleBlackoutDetails] = React.useState({
    channelId: "",
    startDate: "",
    endDate: "",
    message: "",
    channelName: "",
    blockRegion: "",
    blockDevice: [],
    blackoutContent: {
      "1x1":'',
      "3x2":"",
      "16x9":""
    },
  });
  const [epgDetails, setEpgDetails] = React.useState({});
  const [selectedEpgIndex, setSelectedEpgIndex] = React.useState();
  const [selectedChannelIndex, setSelectedChannelIndex] = React.useState();
  const [epgStartTimeSelected, setEpgStartTimeSelected] = React.useState(false);
  const [epgEndTimeSelected, setEpgEndTimeSelected] = React.useState(false);
  const [dateChanged, setDateChanged] = React.useState(false);
  const [imageUpload,setImageUpload]=React.useState(false)

  React.useEffect(() => {
    props.getAllLinearChannelAction(props?.projectDetails?.p_id);
  }, []);

  React.useEffect(() => {
  }, [props.linearChannel, dateChanged]);

  //Select the linear channel for open the epg
  const selectLinearChannel = (linearChannel, index) => {

    let dateCurrent = new Date();
    let day = days[dateCurrent.getDay()];
    let date = dateCurrent.getDate();
    let month = months[dateCurrent.getMonth()];
    let year = dateCurrent.getFullYear();

    let start = `${day} ${month} ${date}  ${year} 12:00:00 PM GMT+0530 (India Standard Time)`;
    let end = `${day} ${month} ${date}  ${year} 11:59:00 PM GMT+0530 (India Standard Time)`;
    let StartEpoch = new Date(start).getTime() / 1000;
    let EndEpoch = new Date(end).getTime() / 1000;

    let dateAndId = {
      channelId: linearChannel.externalIds.toString(),
      startDate: StartEpoch.toString(),
      endDate: EndEpoch.toString(),
    };
    setChannelDetails(linearChannel);
    setSelectedChannelIndex(index);
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      channelName: linearChannel?.name,
      channelId: linearChannel?.id,
    });

    props.getAllEpgAction(dateAndId, props.projectDetails?.p_id);
  };


  //Select Epg
  const epgSelected = (epg, index) => {

    setEpgStartTimeSelected(true);
    setEpgEndTimeSelected(true);

    setSelectedEpgIndex(index);
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      startDate: epg?.startDate,
      endDate: epg?.endDate,
    });
    setEpgDetails(epg);
  };

  //Making Start and end date in the epoch form
  const makeStartAndEndDateTime = (dateTitle) => {
    let day = days[dateTitle.getDay()];
    let date = dateTitle.getDate();
    let month = months[dateTitle.getMonth()];
    let year = dateTitle.getFullYear();

    let start = `${day} ${month} ${date}  ${year} 12:00:00 PM GMT+0530 (India Standard Time)`;
    let end = `${day} ${month} ${date}  ${year} 11:59:00 PM GMT+0530 (India Standard Time)`;
    let StartEpoch = new Date(start).getTime() / 1000;
    let EndEpoch = new Date(end).getTime() / 1000;
    let dateAndId = {
      channelId: channelDetails.externalIds.toString(),
      startDate: StartEpoch.toString(),
      endDate: EndEpoch.toString(),
    };
    return dateAndId;
  };

  //next day
  const nextDay = () => {
    if (fix > dateTitle && Object.keys(channelDetails).length > 0) {
      dateTitle.setDate(dateTitle.getDate() + 1);
      let dateAndId = makeStartAndEndDateTime(dateTitle);

      setDateChanged(!dateChanged);

      props.getAllEpgAction(dateAndId, props.projectDetails?.p_id);
    }
  };

  //previous day
  const prevDay = () => {
    if (dateTitle > FixDate && Object.keys(channelDetails).length > 0) {
      dateTitle.setDate(dateTitle.getDate() - 1);
      setDateChanged(!dateChanged);
      let dateAndId = makeStartAndEndDateTime(dateTitle);

      props.getAllEpgAction(dateAndId, props.projectDetails?.p_id);
      //  props.getAllEpgAction(dateAndId, props.projectDetails?.p_id);
    }
  };

  return (
    <div>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Primary>Schedule Blackout</Primary>
        <Grid item xs={6} md={6} lg={6}>
          <Box display="flex" style={{ marginLeft: "150px" }}>
            <Box className={classes.searchBoxLinearChannelBox}>
              <img
                className={classes.searchIcon}
                src={SearchIcon}
                alt="Search Icon"
              />
              <InputBase
                placeholder="Search Linear Channel"
                className={classes.blackoutScheduleViewTextField}
                labelwidth={0}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
      <div>
        {props?.linearChannelLoading ? (
          <LinearChannel
            rail={props?.linearChannel}
            selectedChannelIndex={selectedChannelIndex}
            selectLinearChannel={selectLinearChannel}
          />
        ) : (
          <div>{props?.linearChannelErrorMsg?props?.linearChannelErrorMsg: "Loading ...."}</div>
        )}
      </div>
      <div className={classes.scheduleBlackoutBox}>
        <Box className={classes.scheduleBlackoutEpgBox}>
          <Typography
            className={classes.heading}
            style={{ marginLeft: "-1px" }}
          >
            Select Channel EPG
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            {dateTitle > FixDate && Object.keys(channelDetails).length > 0 ? (
              <div className={classes.arrow} onClick={prevDay}>
                <img style={{ width: "18px", height: "18px" }} src={BackIcon} />
              </div>
            ) : null}
            {Object.keys(channelDetails).length > 0 ? (
              <Typography
                variant="h5"
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginTop: "-4px",
                }}
              >
                {`${days[dateTitle.getDay()]},${
                  months[dateTitle.getMonth()]
                }${dateTitle.getDate()}`}
              </Typography>
            ) : null}
            {dateTitle < fix && Object.keys(channelDetails).length > 0 ? (
              <div className={classes.arrow} onClick={nextDay}>
                <img style={{ width: "18px", height: "18px" }} src={NextIcon} />
              </div>
            ) : null}
          </Box>
          <Box style={{ marginTop: "20px" }}>
            {props?.epgLoading ? (
              <ScheduleBlackoutEPG
                epg={props?.epg}
                epgDetails={epgDetails}
                epgSelected={epgSelected}
                selectedEpgIndex={selectedEpgIndex}
                selectedChannelIndex={selectedChannelIndex}
                
              />
            ) : (
              <div>Loading ....</div>
            )}
          </Box>
        </Box>
        <Box className={classes.scheduleBlackoutDetailsBox}>
          <Typography className={classes.heading}>Blackout Details</Typography>
          <>
            <ScheduleBlackoutDetails
              scheduleBlackoutDetails={scheduleBlackoutDetails}
              setScheduleBlackoutDetails={setScheduleBlackoutDetails}
              channelDetails={channelDetails}
              epgDetails={epgDetails}
              projectDetails={props.projectDetails}
              epgStartTimeSelected={epgStartTimeSelected}
              setEpgStartTimeSelected={setEpgStartTimeSelected}
              epgEndTimeSelected={epgEndTimeSelected}
              setEpgEndTimeSelected={setEpgEndTimeSelected}
              selectedEpgIndex={selectedEpgIndex}
              setImageUpload={setImageUpload}
              imageUpload={imageUpload}
            />
          </>
        </Box>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectDetails: state.ProjectReducer.projectDetails,
    linearChannel: state.BlackoutScheduleReducer.linearChannels,
    epg: state.BlackoutScheduleReducer.epg,
    linearChannelLoading: state.BlackoutScheduleReducer.linearChannelLoading,
    epgLoading: state.BlackoutScheduleReducer.epgLoading,
    linearChannelErrorMsg:state.BlackoutScheduleReducer.linearChannelErrorMsg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllEpgAction: (linearChannel, p_id) =>
      dispatch(getAllEpgAction(linearChannel, p_id)),
    getAllLinearChannelAction: (p_id) => {
      dispatch(getAllLinearChannelAction(p_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBlackout);
