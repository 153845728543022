import { colors } from "@material-ui/core";
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
  darkText,
  gradiant,
  fontColor,
  displayFlexView,
  iconSizes,
} from "assets/jss/material-dashboard-react.js";

const blackoutScheduleStyle = (theme) => ({
  heading: {
    //  width: '150px',
    //  height: '33px',
    marginLeft: "18px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "33px",
    color: "#141D46",
  },
  searchBoxLinearChannelBox: {
    display: "flex",
    width: "470px",
    height: "35px",
    background: "white",
    border: "none",
    borderRadius: "30px",
    padding: "0px 5px 0px 7px",
    boxSizing: "border-box",
  },
  searchIcon: {
    ...iconSizes,
    marginRight: "8px",
    marginTop: "2px",
    marginLeft: "3px",
  },
  blackoutScheduleViewTextField: {
    width: "450px",
    height: "35px",
    background: "white",
    border: "none",
    borderRadius: "30px",
    color: fontColor[2],
  
  },
  Button: {
    position: "absolute",
    width: "180px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    // textFillColor: "transparent",
    alignItem: "right",
    pointer: "cursor",
    "&.MuiButton-root": {
      padding: 0,
      textTransform: "none",
    },
    color:"#141D45"
  },
  scheduleBlackoutBox: {
    display: "flex",
    marginTop: "30px",

    //justifycontent:'space-between'
  },
  scheduleBlackoutEpgBox: {
    width: "50%",
  },
  scheduleBlackoutDetailsBox: {
    width: "50%",
  },
  arrow: {
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  epgMainBox: {
    padding: "15px",
    boxSizing: "border-box",
    border: "1px dotted grey",
    cursor: "pointer",
  },
  selectedType: {
    border: "1px solid blue",
  },
  channelDetailsBox: {
    display: "flex",
    marginTop: "10px",
  },
  textField: {
    width: "250px",
    background: "white",
    height: "inherit",
    borderRadius: "5px",
    margin: "10px 20px 10px 0px",
  },
  multiLineTextField: {
    width: "520px",
    background: "white",
    // height: "55px",
    borderRadius: "5px",
    margin: "10px 20px 10px 0px",
    height: "75px",
  },
  logofield: {
    boxSizing: "border-box",
    background: "#FFFFFF",
    opacity: "0.5",
    border: "1px dashed #777777",
    bordeRadius: "4px",
    height: "50px",
    width: "165px",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "inital",
  //  marginRight:'10px'
  },
  logoTypo: {
    
    maxWidth: "100px",
    maxHeight: "24px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    backgroundImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    
  },
  submitButton: {
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "140%",
    height: "50px",
    width: "180px",
    marginLeft: "20px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    borderRadius: "33px",
    margin: "0px 10px 0px 0px",
    marginLeft: "-1px",

    // border: "1px solid transparent",
    // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
  },
  cancelButton: {
    height: "50px",
    width: "180px",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "140%",

    color: "#0192D4",
    border: "1px solid transparent",
    background:
      "linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box",
    borderRadius: "33px",
    border: " 1px solid transparent",
    margin: "0px 10px 0px 0px",
    // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
  },
  filterSelectChannelName: {
    ...displayFlexView,
    ...defaultFont,
    justifyContent: "space-around",
    // border: "1px solid black",

    width: "150px",
    height: "45px",
    borderRadius: "30px",
    background: "white",
  },
  dropDownClassChannelCategory: {
    cursor: "pointer",
  },
  epgMainBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    marginRight: "40px",
    cursor:'pointer',border:'1px dotted grey',marginTop:'5px',marginLeft:'5px',marginBottom:'5px'
  },
  epgImageBox: {},
  epgImage: {
    height: "81px",
    width: "144px",
  },
  epgText:{

  },
  selectChannel: {
    border: "1px blue solid",
  },
 
});

export default blackoutScheduleStyle;
