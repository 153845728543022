import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect ,useHistory } from 'react-router-dom';
import { Box, Typography, TextField, Checkbox, Button, InputAdornment,IconButton } from '@mui/material';
import Snackbar from "components/Snackbar/CustomSnackbar";
import HorizonLogo from '../../../../assets/img/newLogin/Horizontal_logo1.svg';
import { LoginAction, ResetLogin } from "../../actions/loginAction";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HttpsIcon from '@mui/icons-material/Https';
import Carousel from '../customCarousel/carousel';
import { styled } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const StyledTextField = styled(TextField)({
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 30px #8b93b1 inset !important',
    '-webkit-text-fill-color': 'white !important', 
  },
  '& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active': {
    '-webkit-box-shadow': '0 0 0 30px #8b93b1 inset !important',
    '-webkit-text-fill-color': 'white !important',  
  },
});

const Signin = (props) => {
  const [authDetails, setAuthDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorSnack, setErrorSnack] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const { email, password } = authDetails;
    const isEmailValid = emailRegex.test(email || '');
    const isPasswordValid = (password || '').length >= 3;
    setIsButtonDisabled(!(isEmailValid && isPasswordValid));
  }, [authDetails]);

  useEffect(() => {
    if (props.loginDetails?.errorMessages) {
      setErrorSnack(true);
    }
  }, [props.loginDetails?.errorMessages]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAuthDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = authDetails;
    if (email && password) {
      props.LoginAction(authDetails);
    } else {
      setErrorMessage(true);
    }
  };

  const history = useHistory();

  const handleRedirect = () => {
    history.push('/tearmsandconditions'); 
  };



  if (localStorage.getItem("token")) {
    return <Redirect to="/admin" />;
  }

  return (
    <>
      <Snackbar
        open={errorSnack}
        severity="warning"
        message={props.loginDetails?.errorMessages}
        setOpen={() => {
          setErrorSnack(false);
          setTimeout(() => props.ResetLogin(), 200);
        }}
      />
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          background: 'linear-gradient(270deg, #162452 0%, #25337d 33.33%, #25337d 66.67%, #162452 100%)',
        }}
      >
        <Box sx={{ display: 'flex', margin: '0 10vw', gap: '5vw' }}>
          <Box
            sx={{
              width: '37.5vw',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
           <Carousel/>
          </Box>
          <Box
            sx={{
              width: '37.5vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                padding: '15vh 3vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                gap: '3vh',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={HorizonLogo} alt='formHorizonLogo' />
              </Box>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2vh',
                  padding: '45px',
                  boxShadow: '0px 20px 44px 0px #00000040',
                  backgroundColor: '#4b5686',
                  borderRadius: "5px",
                }}
              >
               <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' ,marginBottom:"15px"}}>
                  Sign In
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#8B93B1',
                    borderRadius: '5px',
                    padding: '5px',
                  }}
                >
                  <StyledTextField
                    fullWidth
                    type='email'
                    name='email'
                    placeholder='Email'
                    onChange={handleChange}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MarkEmailReadIcon sx={{ color: 'white' }} />
                        </InputAdornment>
                      ),
                      sx: {
                        background: 'transparent',
                        borderRadius: '5px',
                        color: 'white',
                        '::placeholder': {
                          color: 'white',
                        },
                        
                      },
                    }}
                  />
                </Box> 
               

<Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: '#8B93B1',
        borderRadius: '5px',
        padding: '5px',
      }}
    >
      <StyledTextField
        fullWidth
        type={showPassword ? 'text' : 'password'}  
        name="password"
        placeholder="Password"
        onChange={handleChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsIcon sx={{ color: 'white' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? (
                  <VisibilityOff sx={{ color: 'white' }} />
                ) : (
                  <Visibility sx={{ color: 'white' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            background: 'transparent',
            borderRadius: '5px',
            color: 'white',
            '::placeholder': {
              color: 'white',
            },
          },
        }}
      />
    </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'white', fontSize: '0.5rem' }}>
                  <Checkbox required />
                  <Typography sx = {{fontSize:'.8rem'}}>
                    I agree to the <a onClick={handleRedirect} style={{color:"white", textDecoration:'underline', cursor:'pointer'}}>Terms & Conditions</a>
                  </Typography>
                </Box>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={isButtonDisabled}
                  sx={{ backgroundColor: '#856ac7', color: 'white' }}
                  fullWidth
                >
                  Login
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            color: 'white',
            fontSize: '0.8rem',
            fontFamily: 'Open Sans',
          }}
        >
          Powered by Horizon Broadband
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.loginReducer
});

const mapDispatchToProps = (dispatch) => ({
  LoginAction: (authDetails) => dispatch(LoginAction(authDetails)),
  ResetLogin: () => dispatch(ResetLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);

