import { Box, Button, Card, FormHelperText, Grid, MenuItem, Select, TextField, Typography,makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import style from 'assets/jss/material-dashboard-react/views/userStyle';
import { connect } from "react-redux";
import { getAllProjectAction } from "screens/Projects/actions/projectActions";
import { updateUserDetailsAction } from "../actions";

const useStyle = makeStyles(style)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    variant:'menu',
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      }
  };

  var options = ['admin','user']


const EditUser = (props)=>{
    const classes = useStyle();
    const history = useHistory();
    const [UserDetails,setUserDetails] = useState(props.location.state)
    const [ErrorMessage, setErrorMessage] = useState("");

    useEffect(()=>{
        props.getAllProjectAction();
    },[])

    const handleFristName = (event)=>{
      console.log('ss',event.target.value);
      setUserDetails({...UserDetails,firstName:event.target.value})
  }
  
  const handleLastName = (event)=>{
   setUserDetails({...UserDetails,lastName:event.target.value})
  }
  
  
  /*const handlePassword = (event)=>{
   setUserDetails({...UserDetails,password:event.target.value})
  }*/
  
  const handlePhoneNumber = (event)=>{
     setUserDetails({...UserDetails,phoneNumber:event.target.value})
  }
  
  const handleAccessLevel = (event)=>{
    setUserDetails({...UserDetails,accessLevel:event.target.value})
  }
  
  const handleProject = (event)=>{
   setUserDetails({...UserDetails,projects:event.target.value})
  }

  const handleUpdateUser = ()=>{
    console.log('user details',UserDetails);
    if(UserDetails.firstName === "" || UserDetails.firstName === undefined){
      setErrorMessage("mandatory field *")
    }else if(UserDetails.lastName === "" || UserDetails.lastName === undefined){
      setErrorMessage("mandatory field *")
    }else if(UserDetails.email === "" || UserDetails.email === undefined){
      setErrorMessage("mandatory field *")
    }else if(UserDetails.phoneNumber === "" || UserDetails.phoneNumber === undefined){
      setErrorMessage("mandatory field *")
    }else if(UserDetails.accessLevel === "" || UserDetails.accessLevel === undefined){
      setErrorMessage("mandatory field *")
    }else if(UserDetails.projects.length < 0){
      setErrorMessage("mandatory field *")
    }else{
       props.updateUserDetailsAction(UserDetails);
       history.push('/admin/users');
    }
  }

  const handleCancel = ()=>{
    history.push('/admin/users');
}


    return (
        <Box>
          <Grid container>
        <Grid item xs={12} md={12} lg={12}>
       <Box  pb={2}>
         <Typography className={classes.heading}>Edit User</Typography>
       </Box>
       </Grid>
       </Grid>
       <Grid container>
          <Grid item xs={12} md={12} lg={6}>
             <Box>
             <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>First Name</Typography>
                      <TextField
                          className={classes.textfield}
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter the First Name"
                          onChange={handleFristName}
                          required
                          value={UserDetails.firstName}
                          //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                          />
                           <FormHelperText>
                    {UserDetails.firstName === "" || UserDetails.firstName === undefined
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>Last Name</Typography>
                      <TextField
                          className={classes.textfield}
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter the Last Name"
                          onChange={handleLastName}
                          required
                          value={UserDetails.lastName}
                          //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                          />
                          <FormHelperText>
                    {UserDetails.lastName === "" || UserDetails.lastName === undefined
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>Email</Typography>
                      <TextField
                          className={classes.textfield}
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter the Email Id"
                          //onChange={handleEmail}
                          required
                          value={UserDetails.email}
                          disabled
                          //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                          />
                          <FormHelperText>
                    {UserDetails.email === '' || UserDetails.email === undefined
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>Phone Number</Typography>
                      <TextField
                          className={classes.textfield}
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter the Phone Number"
                          onChange={handlePhoneNumber}
                          required
                          value={UserDetails.phoneNumber}
                          //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                          />
                          <FormHelperText>
                    {UserDetails.phoneNumber === "" || UserDetails.phoneNumber === undefined
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>Select Role</Typography>
                  <Select
                         labelId="Select Languages"
                         className={classes.textfield}
                         variant='outlined'
                         fullWidth
                         size='small'
                         style={{width:'100%'}}
                         onChange={handleAccessLevel}
                         value={UserDetails.accessLevel}      
                         MenuProps={MenuProps}   
                         defaultValue=""
                         >
                        
                         {options.map((x)=>{
                             return (
                              <MenuItem value={x}>{x}</MenuItem>
                             )
                         })}
                         </Select>
                         <FormHelperText>
                    {UserDetails.accessLevel === "" || UserDetails.accessLevel === undefined
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom='20px'>
                  <Typography className={classes.inputlabel}>Select Projects</Typography>
                  <Select
                         labelId="Select Languages"
                         className={classes.textfield}
                         variant='outlined'
                         fullWidth
                         multiple
                         size='small'
                         style={{width:'100%'}}
                         onChange={handleProject}
                         //value={options}   
                         value={UserDetails?.projects}   
                         MenuProps={MenuProps}   
                         defaultValue=""
                         >
                        
                         {props?.projects?.map((x)=>{
                             return (
                              <MenuItem value={x?.p_id}>{x?.commonName}</MenuItem>
                             )
                         })}
                         </Select>
                         <FormHelperText>
                    {UserDetails.projects.length <= 0 
                      ? ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
             </Box>
          </Grid>
       </Grid>
       <Box style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left:0,
          width:'100vw',
          boxSizing: "border-box"}}> 
           <Card className={classes.bottomCard}>
             <Box  justifyContent='flex-end' display='flex' flexDirection='row' alignItems='center'>
               <Box paddingLeft='auto' paddingTop='1%' paddingBottom='2%' >
                  <Button variant='outlined' className={classes.cancelButton} 
                  onClick={handleCancel}
                   >
                      <Typography className={classes.cancelButtonText}>Cancel</Typography>
                  </Button>
              </Box>
              <Box paddingLeft='2%' paddingTop='1%' paddingBottom='2%' paddingRight='5%'>
                 <Button className={classes.addButton} 
                 onClick={() => handleUpdateUser()}
                 >
                    <Typography className={classes.addButtonText}>UPDATE</Typography>
                 </Button>
              </Box>
             </Box>
             </Card>
          </Box>
      </Box>
    )
}

const mapDispatchToProps = (dispatch)=>{
    return {
        addNewUserAction:(userDetails)=>{
          dispatch(addNewUserAction(userDetails))
        },
        getAllProjectAction: () => {     
          dispatch(getAllProjectAction());
        },
        updateUserDetailsAction:(updateDetails)=>{
          dispatch(updateUserDetailsAction(updateDetails))
        }
    }
}

const mapStateToProps = (State)=>{
    return {
        projects: State.ProjectReducer.allProjects,
      }
}

export default connect(mapStateToProps,mapDispatchToProps)(EditUser);