import { GET_ALL_PACKAGES, SUCCESSFULLY_GET_ALL_PACKAGES, SUCCESSFULLY_UPDATE_JSON_DETAILS } from "../actionType";
import { IntialState } from "../initialState";

export default function packageReducer(state = IntialState,action){
    console.log(action,"actio")
    switch(action.type){
        case SUCCESSFULLY_GET_ALL_PACKAGES : {
            return {...state,allPackages:action.data}
        }
        case SUCCESSFULLY_UPDATE_JSON_DETAILS : {
            return{...state,updateJson:action.data,updated:!state.updated}
        }
        default:{
            return state
        }
    }
}