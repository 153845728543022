import { put, call, takeLatest, all, takeEvery } from "redux-saga/effects";
import { useHistory } from "react-router-dom";

import {
  getCategoryTreeApi,
  getCategoryPlaylistResponseApi,
  createWidgetApi,
  editWidgetApi,
  getWidgetApi,
  getProjectByApiCall,
  deleteWidgetApiCall,
  getSearchWidgetAPI
} from "../apis";
import {
  getCategoryTreeResponse,
  getCategoryPlayListResponse,
  playListError,
  getWidgetAction,
  getWidgetResponse,
  getSingleWidgetAction,
  getSingleWidgetResponse,
  getProjectByIdResponse,
  createWidgetResponse,
  editWidgetResponse,
  getCategoryErrorMeesage,
  deleteWidgetResponse,
  successfullyGetSearchWidget,
  closeSnackBarFromPVS
} from "../actions";
import { func } from "prop-types";

function* CategoryTreeAction({payload}) {
  try {
    const response = yield call(getCategoryTreeApi,payload);
    if(response.data && response.data.hzStatus==200){    yield put(getCategoryTreeResponse(response.data.data));
    }
    else if(response.hzStatus==400){
yield put(getCategoryErrorMeesage(response.message))
    }
  } catch (error) {
  }
}

function* CategoryPlayListByIdAction({ payload ,projectId}) {
  try {
    const response = yield call(getCategoryPlaylistResponseApi, payload,projectId);
    if (response.status === 200) {
      yield put(getCategoryPlayListResponse(response.data.data));
    } else {
      yield put(playListError(response.message));
    }
  } catch (error) {
  }
}

function* handleGetWidgetAction({ payload }) {
  try {
    const response = yield call(getWidgetApi, payload);
    if (response.status === 200) {
      yield put(getWidgetResponse(response.data.data));
    } else {
      yield put(playListError(response.message));
    }
  } catch (error) {}
}

function* handleCreateWidgetAction({ payload, parameters ,prevScreen}) {

  // try {
    const response = yield call(createWidgetApi, payload, parameters);
    if(response.status === 200) {
      yield put(createWidgetResponse(response?.data))
    }
    else {
      yield put(createWidgetResponse(response))
    } 
if(prevScreen==='WidgetManager'){
 
 window.location = "/admin/widgetmanager";
} 
  else if(prevScreen==='PlatformVis'){
    window.history.back("/admin/projectsplateformmanager/screens")
  }
  
   //  window.location="/admin/projectsplateformmanager/screens"
  
  else{
    
    window.location = "/admin/widgetmanager" 
  } 
  //window.goBack()
  // } catch (error) {}
}

function* handleEditWidgetAction({id,payload,parameters,prevScreen}){
  // try {
    console.log("Id checking",id,payload,parameters,prevScreen);
 const response = yield call(editWidgetApi,id, payload, parameters);
 console.log("resfff",response.status);
 if(response.status === 200) {
      yield put(editWidgetResponse(response?.data))
    }
    else {
      yield put(editWidgetResponse(response))
    } 
if(prevScreen==='WidgetManager'){
 window.location = "/admin/widgetmanager";
} 
  else if(prevScreen==='PlatformVis'){
    
    window.history.back("/admin/projectsplateformmanager/screens")
  }
  
  //  window.location="/admin/projectsplateformmanager/screens"
  else if(prevScreen==="AIWIDGET" || prevScreen==="screens"){
yield put(closeSnackBarFromPVS())
  }
  else{
   window.location = "/admin/widgetmanager" 
  } 
}

function* handleGetProjectByIdAction({ payload }) {
  try {
    const projectDetails=yield call(getProjectByApiCall,payload)
    const aspectRatio=projectDetails.data.project?.aspectRatios
    yield put(getProjectByIdResponse(aspectRatio))
  } catch (error) {}
}


//Delete Widget Action handler
function* handleDeleteWidgetAction({payload}){
  try{
    const widgetDeleteResponse=yield call(deleteWidgetApiCall,payload)
    
   // else{
      yield put(deleteWidgetResponse(widgetDeleteResponse.data))

  //  }

  }
  catch(err){
  }
}

function* handleGetSearchWidgetAction({payload}){
  try{
    const widgetDetails = yield call(getSearchWidgetAPI,payload)
    if (widgetDetails.status === 200) {
      yield put(successfullyGetSearchWidget(widgetDetails.data.data));
    } else {
      yield put(playListError(response.message));
    }
  }
  catch (error) {
  }
}

export default function* widgetMangerWatcher() {
  yield takeLatest("GET_CATEGORY_TREE", CategoryTreeAction);
  yield takeLatest("GET_CATEGORY_PLAYLIST_BY_ID", CategoryPlayListByIdAction);
  yield takeLatest("CREATE_WIDGET_ACTION", handleCreateWidgetAction);
  yield takeLatest("GET_WIDGET_ACTION", handleGetWidgetAction);
  yield takeLatest("GET_PROJECT_BY_ID", handleGetProjectByIdAction);
  yield takeLatest("EDIT_WIDGET_ACTION",handleEditWidgetAction)
  yield takeLatest("DELETE_WIDGET_ACTION",handleDeleteWidgetAction)
  yield takeLatest('GET_SEARCH_WIDGET_ACTION',handleGetSearchWidgetAction)
}
