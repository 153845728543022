import {
  Box,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
  Card,
} from "@material-ui/core";
import { connect } from "react-redux";

import Style from "../../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";

const useStyle = makeStyles(Style);

const EditProjectConfig = (props) => {
  const classes = useStyle();

  const handleApiversion = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, apiVersion: event.target.value });
  };

  const handlePartnerId = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, partnerId: event.target.value });
  };

  const handleRootCategoryId = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, rootCategoryId: event.target.value });
  };

  const handleBaseUrl = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, baseUrl: event.target.value });
  };

  const handleUsername = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, username: event.target.value });
  };

  const handlePassword = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, password: event.target.value });
  };

  const handleApplicationName = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, applicationName: event.target.value });
  };

  const handleClientVersion = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, clientVersion: event.target.value });
  };

  const handleplatform = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, platform: event.target.value });
  };

  const handleTag = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, tag: event.target.value });
  };

  const handleUDID = (event) => {
    props.setConfigDetails({ ...props.cmsConfig, udid: event.target.value });
  };

  return (
    <Box>
      <Card className={classes.EditCard}>
        <Box paddingLeft="24px" paddingTop="24px" paddingBottom="24px">
          <Box pb={3} paddingRight="33px">
            <Box component="form" role="form">
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  API Version
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the API Version"
                  onChange={handleApiversion}
                  required
                  value={props.cmsConfig?.apiVersion}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                  {props.cmsConfig?.apiVersion
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Partner Id
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Partner ID"
                  onChange={handlePartnerId}
                  required
                  value={props.cmsConfig?.partnerId}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                {props.cmsConfig?.partnerId
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Root CatgeoryID
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Root CatgeoryID"
                  required
                  onChange={handleRootCategoryId}
                  value={props.cmsConfig?.rootCategoryId}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                {props.cmsConfig?.rootCategoryId
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>Base URL</Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Base URL"
                  required
                  onChange={handleBaseUrl}
                  value={props.cmsConfig?.baseUrl}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                {props.cmsConfig?.baseUrl
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  CMS Username
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the CMS Username"
                  required
                  onChange={handleUsername}
                  value={props.cmsConfig?.username}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                {props.cmsConfig?.username
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  CMS Password
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the CMS Password"
                  required
                  onChange={handlePassword}
                  value={props.cmsConfig?.password}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                {props.cmsConfig?.password
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Application Name
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Application Name"
                  required
                  onChange={handleApplicationName}
                  value={props.cmsConfig?.applicationName ?? ""}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                  {props.cmsConfig?.applicationName
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Client Version
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Client Version"
                  required
                  onChange={handleClientVersion}
                  value={props.cmsConfig?.clientVersion ?? ""}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                  {props.cmsConfig?.clientVersion
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Platform
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Platform"
                  required
                  onChange={handleplatform}
                  value={props.cmsConfig?.platform ?? ""}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                  {props.cmsConfig?.platform
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  Tag
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the Tag"
                  onChange={handleTag}
                  value={props.cmsConfig?.tag ?? ""}
                  style={{width:'50%'}}
                  required
                />
                <FormHelperText>
                  {props.cmsConfig?.platform
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
              <Box paddingBottom="20px">
                <Typography className={classes.inputLabel}>
                  UDID
                </Typography>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter the UDID"
                  required
                  onChange={handleUDID}
                  value={props.cmsConfig?.udid ?? ""}
                  style={{width:'50%'}}
                />
                <FormHelperText>
                  {props.cmsConfig?.udid
                    ? ""
                    : props.cmsErrorMessage}
                </FormHelperText>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectConfig);
