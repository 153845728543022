import axios from 'axios';

export const imageHandlerUtil = async ({
  event,
  supportedImageTypes,
  maxSize = 1048576 * 5, // Default maximum file size is 5MB
  uploadAPIRoute,
  handleImageInputError,
  handleUploadSuccess,
  getBase64
}) => {
  const files = event.target.files;

  // Get file sizes
  const imageSizes = [...Array(files.length)].map((_, index) => files[index].size);

  // Get file types
  const fileType = [...Array(files.length)].map((_, index) => files[index].type);

  // Validate file types
  if (
    fileType.every((type) => {
      console.log(type, "type");
      return !supportedImageTypes.includes(type);
    })
  ) {
    return files.length
      ? handleImageInputError(
          event.target.id,
          `Only ${supportedImageTypes.map(type => type.slice(5)).join(",")} files are supported.`
        )
      : null;
  }

  // Validate file sizes
  if (imageSizes.every((size) => size < maxSize)) {
    const images = await getBase64(files);
    console.log("images", images, images.length);

    // Upload API
    const uploadAPI = async (images) => {
      return await axios
        .post(
          uploadAPIRoute,
          { file: images[0].b64 },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_X_API_KEY,
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    };

    if (images.length && event.target.id) {
      const S3image = await uploadAPI(images);
      if (S3image?.data?.uploadResult?.Location) {
        handleImageInputError(event.target.id, "");

        // Call success handler to update your component's state or perform other actions
        handleUploadSuccess(event.target.id, S3image?.data?.uploadResult?.Location);
      } else {
        handleImageInputError(
          event.target.id,
          "Image could not be uploaded. Please try again."
        );
      }
    } else {
      handleImageInputError(
        event.target.id,
        "Image could not be uploaded. Please try again."
      );
    }
  } else {
    handleImageInputError(event.target.id, "Maximum file limit is 150MB.");
  }

  // Clear the file input value after processing
  event.target.value = null;
};
