import { GET_ALL_PACKAGES, SUCCESSFULLY_GET_ALL_PACKAGES, SUCCESSFULLY_UPDATE_JSON_DETAILS, UPDATE_JSON_DETAILS ,ADD_NEW_JSON_DETAILS} from "../actionType"

export const getAllPackages = (data)=>{
    console.log(data,'es')
   return {type:GET_ALL_PACKAGES,data}
}

export const successfullyGetAllPackages = (data)=>{
    return {type:SUCCESSFULLY_GET_ALL_PACKAGES,data}
}

export const updateJsonDetails = (data)=>{
    console.log('action',data)
    return{type:UPDATE_JSON_DETAILS,payload:data}
}

export const addNewJsonDetails = (data)=>{
    console.log('action',data)
    return{type:ADD_NEW_JSON_DETAILS,payload:data}
}

export const successfullyUpdateJsonDetails = (data)=>{
    return{type:SUCCESSFULLY_UPDATE_JSON_DETAILS,data}
}
