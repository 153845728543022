/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useHistory, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { CollectionsOutlined } from "@material-ui/icons";
import { connect } from "react-redux";
import Snackbar from "components/Snackbar/CustomSnackbar";
import { addProjectNameInNavbarAction } from "screens/Projects/actions/projectActions";
import { Divider } from "@mui/material";
import Profile from "./UserProfile";
import vivIcon from "assets/img/sideNavIcons/logo.svg"

const useStyles = makeStyles(styles);

function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();

  const [accessSnack, setAccessSnack] = React.useState(false);
  const [accessSnackMessage, setAccessSnackMessage] = React.useState(false);

  const accessLevel = props.accessLevel;

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    const pathname = location.pathname;

    if (pathname.includes(routeName)) {
      return true;
    } else {
      return location.pathname == routeName;
    }
  }
  const { color, logo, image, logoText, routes, drawerStatus, open } = props;

  var links = (
    <List className={classes.list}>
      {routes?.map((prop, key) => {
        if (key < 10) {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true,
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path),
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          }); 
          return (
            <>
            <NavLink
              to={accessLevel === "user" && (prop.name === "Users" || prop.name === "Settings")
                ? history.location.pathname
                : prop.layout + prop.path}
              className={classes.item + listItemClasses}
              activeClassName="active"
              key={prop.layout + prop.path}
              onClick={() => {
                accessLevel === "user"
                  ? prop.name === "Users"
                    ? [
                      setAccessSnackMessage("You do not have access to view users."),
                      setAccessSnack(true),
                    ]
                    : prop.name === "Settings"
                    ? [
                      setAccessSnackMessage("You do not have access to view settings."),
                      setAccessSnack(true),
                    ]
                    : null
                  : null;
            if(prop.name==="Projects"){
                    props.addProjectNameInNavbarAction(
                      {...props.allProjects[0],isAll:true}
                 )
                  }
                else if(!(accessLevel==="user" && (prop.name==="Users" || prop.name==="Settings"))){
            if (props?.ProjectDetails?.isAll){
                    props.addProjectNameInNavbarAction(
                      {...props?.ProjectDetails,isAll:false}
                 )
                  }
                }
              }}
            >
              <ListItem className={classes.itemLink}>
                {activeRoute(prop.layout + prop.path) 
                  ? (
                    <img
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive,
                      })}
                      src={prop.darkIcon}
                    />
                  )
                  : (
                    <img
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive,
                      })}
                      src={prop.icon}
                    />
                  )}
                {props.drawerStatus
                  ? (
                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(classes.itemText, whiteFontClasses, {
                        [classes.itemTextRTL]: props.rtlActive,
                      })}
                      disableTypography={true}
                    />
                  )
                  : null}
              </ListItem>
            </NavLink>
            {key === 6 && <Divider className={classes.dividerWithMargin}/>}
            </>
            
          );
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="/admin/projects"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        // target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={vivIcon} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
  
  return (
    <div>
      <Snackbar
        open={accessSnack}
        severity="warning"
        message={accessSnackMessage}
        setOpen={() => setAccessSnack(false)}
      />
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div >
            <Profile name={props.name} isDrawerStatus={props.drawerStatus} setDrawerStatus={props.setDrawerStatus}/>
          </div>
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {brand}
          <Divider className={classes.dividerWithMargin}/>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="persistent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: props.drawerStatus,
              [classes.drawerClose]: !props.drawerStatus,
            }),
          }}
        >
          <div>
          <Profile name={props.name} isDrawerStatus={props.drawerStatus} setDrawerStatus={props.setDrawerStatus}/>
          <div
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerOpen]: props.drawerStatus,
                  [classes.drawerClose]: !props.drawerStatus,
                }),
              }}
            >
              {links}
            </div>
            <Divider className={classes.dividerWithMargin}/>
            {brand}
          </div>
          {props.drawerStatus ? (
            <div className={classes.appVersion}>
              {process.env.REACT_APP_VERSION}
            </div>
          ) : null} 
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

const mapStateToProps = (State) => {
  return {
    accessLevel: State.loginReducer.authTokens?.accessLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
