import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

//CSS jss file
import style from "assets/jss/material-dashboard-react/views/blackoutScheduleStyle";

//Utils import
import { epochToNormalTime } from "../../utils/epochAndNormalTime";

//Material ui component
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const EPG = [
  {
    name: "CHCH",
    time: "9:00-10:00",
    image: "https://static3.bigstockphoto.com/5/0/4/large1500/405927968.jpg",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
  {
    name: "CHCH",
    time: "9:00-10:00",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/CHCH2010.svg/1200px-CHCH2010.svg.png",
  },
];
const useStyle = makeStyles(style);

const ScheduleBlackoutEPG = (props) => {
  const classes = useStyle();
  return (
    <Box sx={{ flexGrow: 1, maxHeight: "70vh", overflow: "auto" }}>
      {props?.selectedChannelIndex>=0?props.epg?.length>0? props?.epg?.map((epg, index) => {
        let selectedEPG;
        if (index === props?.selectedEpgIndex) {
          selectedEPG = classNames({
            ["  " + classes.selectChannel]: true,
          });
        }
        let startTime = epochToNormalTime(epg?.startDate);
        let endTime = epochToNormalTime(epg?.endDate);

        return (
          <div
            className={classNames(selectedEPG, classes.epgMainBox)}
            onClick={() => props?.epgSelected(epg, index)}
          >
            <Box className={classes.epgImageBox}>
              <img className={classes.epgImage} src={`${epg?.images[0]?.url}/width/500/height/280`} />
            </Box>
            <Box>
              <Typography>
                {startTime}-{endTime}
              </Typography>
            </Box>
            <Box style={{width:"10vw"}}>
              <Typography>{epg.name}</Typography>
            </Box>
          </div>
        );
      }):"No epg Available":"Select Channel First"}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleBlackoutEPG);
