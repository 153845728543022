import axios from "axios";
import { homeApis } from "../../../config";


export const getAssetDetailsApi = async (assetId, projectID) => {
  var AssetID = {
    assetId: assetId,
    p_id: projectID,
  };
  var options = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-asset`,
    params: AssetID,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const res = await axios.request(options);
  let data = {
    data:res?.data?.data || res?.message,
    hzStatus:res?.data?.hzStatus|| res?.hzStatus
  }
  return data
};

export const getAllCategoriesApi = async (data) => {
  var params = {
    p_id: data,
  };
  var Options = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-category-tree`,
    params: params,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const res = await axios.request(Options);
  return res;
};

export const getPlaylistByIdApi = async (playlist, projectId) => {
  var playlist = {
    playlistId: playlist,
    p_id: projectId,
  };
  var options = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-all-assets-by-playlist`,
    params: playlist,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const res = await axios.request(options);
  return res.data;
};


//Clear Cache api
export const clearCaheApiCall=async(pid)=>{
  try{
  const options = {
    method: "DELETE",
    url: `${homeApis.clearchacheApi}?p_id=${pid}`,
   
    headers: {
      "x-api-key":process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
   
  };
  const response = await axios.request(options);
  console.log("Api call happening",response);
  return response
}
catch(error){
console.log("Error fdcbjd",error)
}}
