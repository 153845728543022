export const GET_ALL_USERS_ACTION = 'GET_ALL_USERS_ACTION'
export const SUCCESSFULLY_GET_ALL_USERS = 'SUCCESSFULLY_GET_ALL_USERS'
export const ADD_NEW_USER_ACTION = 'ADD_NEW_USER_ACTION'
export const SUCCESSFULLY_ADDED_USER_ACTION = 'SUCCESSFULLY_ADDED_USER_ACTION'
export const USER_ACTION_FAILED = 'USER_ACTION_FAILED'
export const DELETE_USER_ACTION = 'DELETE_USER_ACTION'
export const SUCCESSFULLY_DELETE_USER_ACTION = 'SUCCESSFULY_DELETE_USER_ACTION'
export const UPDATE_USER_DETAILS_ACTION='UPDATE_USER_DETAILS_ACTION'
export const DELETE_USER_ACTION_FAILED = 'DELETE_USER_ACTION_FAILED'
export const SUCCESSFULLY_UPDATE_USER_ACTION = 'SUCCESSFULLY_UPDATE_USER_ACTION'
export const UPDATE_USER_ACTION_FAILED = 'UPDATE_USER_ACTION_FAILED'
export const GET_ALL_AUDIT_LOGS_ACTION = 'GET_ALL_AUDIT_LOGS_ACTION'
export const SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION = 'SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION'
export const DOWNLOAD_AUDIT_LOGS_ACTION = 'DOWNLOAD_AUDIT_LOGS_ACTION'
