import {
  SUCCESSFULLY_ADDED_USER_ACTION,
  SUCCESSFULLY_GET_ALL_USERS,
  USER_ACTION_FAILED,
  SUCCESSFULLY_DELETE_USER_ACTION,
  DELETE_USER_ACTION_FAILED,
  SUCCESSFULLY_UPDATE_USER_ACTION,
  UPDATE_USER_ACTION_FAILED,
  SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION,
} from "../actionTypes";
import { InitialState } from "../initalState";

export default function usersReducers(state = InitialState, action) {
  switch (action.type) {
    case SUCCESSFULLY_GET_ALL_USERS: {
      return { 
        ...state, 
        allUsers: action.payload, 
        usersReset: false,
        deleteReset:false,
        updateReset:false, 
      };
    }
    case SUCCESSFULLY_ADDED_USER_ACTION: {
      return {
        ...state,
        createUser: action.payload,
        createUserFail: {},
        deleteUser:{},
        deleteUserFail:{},
        usersReset: true,
        deleteReset:false,
      };
    }
    case USER_ACTION_FAILED: {
      return {
        ...state,
        createUser: {},
        createUserFail: action.payload,
        usersReset: true,
        deleteReset:false
      };
    }
    case SUCCESSFULLY_DELETE_USER_ACTION:{
      return{
        ...state,
        createUser:{},
        createUserFail:{},
        deleteUser:action.payload,
        deleteUserFail:{},
        deleteReset:true,
        resetData:!state.resetData,
        usersReset:false,
      }
    }
    case DELETE_USER_ACTION_FAILED:{
      return{
        ...state,
        createUser:{},
        createUserFail:{},
        deleteUser:{},
        deleteUserFail:action.payload,
        deleteReset:true,
        resetData:!state.resetData,
        usersReset:false
      }
    }
    case SUCCESSFULLY_UPDATE_USER_ACTION:{
      return{
        ...state,
        createUser:{},
        createUserFail:{},
        deleteUser:{},
        deleteUserFail:{},
        deleteReset:false,
        resetData:!state.resetData,
        updateReset:true,
        updateUser:action.payload,
        updateUserFailed:{}
      }
    }
    case UPDATE_USER_ACTION_FAILED:{
      return{
        ...state,
        createUser:{},
        createUserFail:{},
        deleteUser:{},
        deleteUserFail:{},
        updateUser:{},
        updateUserFailed:action.payload,
        updateReset:true,
      }
    }
    case SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION:{
      return{
        ...state,
        auditLogs:action.payload
      }
    }
    default: {
      return state;
    }
  }
}
