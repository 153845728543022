import { homeApis } from "../../../config";
import axios from "axios";

export const getplatformApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-platforms`;
export const getScreensForAllplatformsApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-all-platforms`;
export const getScreenApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-all-screen`;
export const getScreenApiV3 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v3/get-all-screen`;
export const getWidgetApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-widgets`;
export const getSingleWidgetApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v3/get-widget`;
export const getWidgetByIdApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-widget`;
export const createScreenApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/create-screen`;

export const getSingleScreenApi = async (screenDetails) => {
  var screenParams = {
    p_id: screenDetails?.p_id,
    screenName: screenDetails?.name,
    platform: screenDetails?.platform?.shortName,
  };

  const options = {
    method: "GET",
    url: `${homeApis.getScreen}`,
    params: screenParams,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response?.data?.data;
};

export const uPdateScreenApi = async (updateScreenDetails) => {
  console.log('update',updateScreenDetails);
  var param = {
    p_id: updateScreenDetails.p_id,
    platform: updateScreenDetails.platform , 
    screenName: updateScreenDetails.newScreenName,
  };
  const options = {
    method: "PUT",
    url: `${homeApis.uPdateScreen}`,
    params: param,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,

      Authorization: localStorage.getItem("token"),
    },
    data: updateScreenDetails,
  };

  const response = await axios.request(options);
  return response;
  /* axios
    .request(options)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    }); */

  /*  const options = {
        method: 'PUT',
        url: `${homeApis.publishScreen}`,

        params: params,
        headers: {
          'content-type': 'application/json',
          'x-api-key': process.env.REACT_APP_X_API_KEY,
          Authorization:  localStorage.getItem('token')

        },
        data: updateScreenDetails
        
      };
      
      axios.request(options).then(function (response) {
        console.log(response.data,"updateScreen");
      }).catch(function (error) {
        console.error(error);
      });  
     */
};

export const upadteScreenDetailsApi = async (updateScreenDetails) => {
  try {
  let name = updateScreenDetails.screenName || updateScreenDetails.name;
  var param = {
    p_id: updateScreenDetails.p_id,
    platform: updateScreenDetails.platform , 
    screenName: name,
  };
  if(updateScreenDetails.context === "theme"){
    param["context"] = "theme"
  }
  if(updateScreenDetails.context === "pinning"){
    updateScreenDetails = {
      pinning:updateScreenDetails.pinning
    }
  }

  const options = {
    method: "PUT",
    url: `${homeApis.updateScreenDetails}`,
    params: param,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,

      Authorization: localStorage.getItem("token"),
    },
    data: updateScreenDetails,
  };
  
  const response = await axios.request(options);
  return response;
 }
 catch(err){
  console.log(err);
 }

}

// Update Screen Sctatus Api
export const updateScreenStatusApi = async (screenDetails) => {
  let param = {
    p_id: screenDetails.p_id,
    status: screenDetails.status,
  };
  let screenId = screenDetails.id;

  const options = {
    method: "PUT",
    url: `${homeApis.updateScreenStatus}/${screenId}`,
    params: param,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response;

  /* axios
    .request(options)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    }); */
};

export const exportScreenApi = async (details) => {
  let param = {
    p_id: details.p_id,
  };

  let data = {
    screenName: details.screenName,
    status: details.status,
    widgets: details.widgets,
    platforms: details.platforms,
    currentPlatform: details.currentPlatform
  };
  const options = {
    method: "POST",
    url: `${homeApis.exportScreens}`,
    params: param,
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  };

  const response = await axios.request(options);
  return response;
  /* axios
    .request(options)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    }); */
};

export const reorderScreenApiCall = async (order, projectId, platform) => {
  const options = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/screen/order`,
    params: { p_id: projectId, platform: platform },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data: { stateHash: localStorage.getItem("stateHash"), order: order },
  };
  const response = await axios.request(options);
  return response;
};

export const deleteScreenApiCall = async (screen) => {
  const screenId = screen._id;
  const pid = screen.p_id;
  const options = {
    method: "DELETE",
    url: `${homeApis.deleteScreen}/${screenId}`,
    params: { p_id: pid },
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const response = await axios.request(options);
  return response;
};

export const publishSplashScreenApiCall = async (payload) => {
  const { p_id, platform, screenURL } = payload;
  const data = { splashScreen: screenURL };
  const options = {
    method: "POST",
    url: homeApis.publishSplash,
    params: { p_id, platform },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data,
  };
  const response = await axios.request(options);
  return response;
};

export const uploadSplashScrenApi = async (payload) => {
  const { p_id, platform, screenURL } = payload;
  const data = { file: screenURL };
  const options = {
    method: "POST",
    url: homeApis.publishSplash,
    params: { p_id, platform },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data,
  };
  const response = await axios.request(options);
  return response;
};

export const publishIntroScreenApi = async (payload) => {
  const { p_id, platform, screenURL } = payload;
  const data = { introScreen: screenURL };
  const options = {
    method: "POST",
    url: homeApis.publishIntro,
    params: { p_id, platform },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    data,
  };
  const response = await axios.request(options);
  return response;
};


