import { GET_PUSHNOTIFICATIONS_ACTION, GET_PUSHNOTIFICATIONS_RESPONSE, PUSHNOTIFICATIONS_Error, UPDATE_PUSHNOTIFICATIONS_RESPONSE } from "../actionTypes";
import { pushNotificationIntialState } from "../initialState";
  
export const PushNotificationReducer = (state = pushNotificationIntialState, action) => {
  console.log(action,"action.payload")
    switch (action.type) {
      case GET_PUSHNOTIFICATIONS_ACTION:
        return {
          ...state,
          loading: true,
          error:false
        };
      case PUSHNOTIFICATIONS_Error:
        return {
          ...state,
          loading: false,
          error:true
        };
      case UPDATE_PUSHNOTIFICATIONS_RESPONSE:{
        return{...state,
            notification:action.payload,
            isPushnotificationEdited :!state.isPushnotificationEdited,
            error:false
        }
          }
      case GET_PUSHNOTIFICATIONS_RESPONSE:
              return{
                ...state,
                notifications: action.payload,
                error: false,
                loading: false,
                errorMessage:''
              }
      default:
        return {...state};
    }
  };
  