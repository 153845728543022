import { Box, FormHelperText, Grid, Input, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Styles from "../../../assets/jss/material-dashboard-react/views/addProjectStyle";
import axios from "axios";

import { createNewProjectAction } from "../actions/projectActions";
import { connect } from "react-redux";
import PlatformSeletor from "components/PlatformScreensView/PlatformSelector";

const useStyle = makeStyles(Styles);

const AddProject = (props) => {
  const classes = useStyle();

  const platforms = props?.addProject?.platforms != undefined ? props?.addProject?.platforms : [];
  const [platformList, setPlatformList] = useState(platforms);

  useEffect(() => {
    props.setAddProject({ ...props.addProject, platforms: platformList });
  }, [platformList]);

  const handlePlatforms = (value) => {
    setPlatformList((platforms) => {
      return platforms?.includes(value) ? platforms.filter((platform) => platform !== value) : [...platforms, value];
    });
  };
  
  const handleSelectAllPlatforms = (allPlatforms) => {
    setPlatformList(allPlatforms);
  };

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleImage = async (event) => {
    const files = event.target.files;
    const file = files[0];
    const images = await getBase64(file);
    if (typeof images != undefined) {
      const S3image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`,
        { file: images },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      props.setAddProject({
        ...props.addProject,
        projectIcon: {
          ...props.addProject.projectIcon,
          "16x9": S3image?.data?.uploadResult?.Location,
        },
      });
      props.setImage(true);
    } else {
    }
  };

  const handleImage1 = async (event) => {
    const files = event.target.files;
    const file = files[0];
    const images = await getBase64(file);
    if (typeof images != undefined) {
      const S3image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`,
        { file: images },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      props.setAddProject({
        ...props.addProject,
        projectIcon: { ...props.addProject.projectIcon, "1x1": S3image.data.uploadResult.Location },
      });
      props.setImage1(true);
    } else {
    }
  };

  const handleProjectName = (event) => {
    props.setAddProject({ ...props.addProject, commonName: event.target.value });
  };

  const handleProjectDescription = (event) => {
    props.setAddProject({ ...props.addProject, description: event.target.value });
  };

  return (
    <Box>
      <Box paddingBottom="27px" paddingLeft="24px">
        <Grid container spaceing={1}>
          <Grid item sx={12} md={6} lg={6}>
            <Box pb={3} paddingRight="33px">
              <Box component="form" role="form">
                <Box paddingBottom="20px">
                  <Typography className={classes.inputlabel}>Project Name</Typography>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter the Project Name"
                    onChange={handleProjectName}
                    required
                    value={props.addProject.commonName ?? ""}
                  />
                  <FormHelperText>
                    {props.addProject.commonName === "" || props.addProject.commonName === undefined
                      ? props.ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box paddingBottom="20px">
                  <Typography className={classes.inputlabel}> Project Description</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                    className={classes.textfield}
                    placeholder="Enter the Project Description"
                    onChange={handleProjectDescription}
                    value={props.addProject.description}
                    required
                  />
                  <FormHelperText>
                    {props.addProject.description === "" || props.addProject.description === undefined
                      ? props.ErrorMessage
                      : ""}
                  </FormHelperText>
                </Box>
                <Box display="flex" flexDirection="row" gridColumnGap="30px">
                  <Box paddingBottom="5px">
                    <Typography className={classes.inputlabel}>Project Logo 16x9</Typography>
                    <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        multiple
                        onChange={handleImage}
                        required
                        inputProps={{ accept: "image/*" }}
                        fullWidth
                        cursor="pointer"
                      />
                      <Box
                        className={classes.logofield}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        <Box>
                          <Typography className={classes.logoTypo}>Upload Logo 16x9</Typography>
                        </Box>
                      </Box>
                    </label>
                    <FormHelperText>
                      {props.addProject.projectIcon === undefined ||
                      props.addProject.projectIcon === "" ||
                      props.addProject.projectIcon["16x9"] == undefined
                        ? props.ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>

                  <Box paddingBottom="5px" paddingLeft="30px">
                    <Typography className={classes.inputlabel}>Project Logo 1x1</Typography>
                    <label htmlFor="upload-photo1" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ display: "none" }}
                        id="upload-photo1"
                        name="upload-photo1"
                        type="file"
                        multiple
                        onChange={handleImage1}
                        required
                        fullWidth
                        inputProps={{ accept: "image/*" }}
                      />
                      <Box
                        className={classes.logofield}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        <Box>
                          <Typography className={classes.logoTypo}>Upload Logo 1x1</Typography>
                        </Box>
                      </Box>
                    </label>
                    <FormHelperText>
                      {props.addProject.projectIcon === "" ||
                      props.addProject.projectIcon === undefined ||
                      props.addProject.projectIcon["1x1"] === undefined
                        ? props.ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  <Box></Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" gridColumnGap="55px">
                {props.image ? (
                  <Box className={classes.Image16}>
                    <img className={classes.Image16} src={props?.addProject?.projectIcon["16x9"]}></img>
                  </Box>
                ) : (
                  ""
                )}
                {props.image1 ? (
                  <Box className={classes.updateImg}>
                    <img className={classes.updateImg} src={props?.addProject?.projectIcon["1x1"]}></img>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
          <PlatformSeletor
            handleSelectAll={handleSelectAllPlatforms}
            clickHandler={handlePlatforms}
            platformSelected={platformList}
            selectedPlatforms={props.addProject?.platforms}
            ErrorMessage={props.ErrorMessage}
          />
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewProjectAction: (addProjects) => {
      dispatch(createNewProjectAction(addProjects));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
