import { Box, Dialog, makeStyles } from "@material-ui/core";
import React from "react";

import LaptopDevice from "assets/img/laptop.png";
import Device from "assets/img/Device.png";
import Iphone from "assets/img/Iphone_xs.png";
import style from "assets/jss/material-dashboard-react/views/screenStyle";
import AppScreenView from "./AppScreenView";

const useStyle = makeStyles(style);

const AppScreenDevice = (props) => {
  const classes = useStyle();

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleLaptopClose = () => {
    props.setLaptopPreview(false);
  };

  return props.platform !== "IOS_MOB" ||
    props.platform !== "AND_MOB" ? (
    <Box>
      <Dialog
        open={props.laptopPreview}
        onClose={handleLaptopClose}
        maxWidth="lg"
        style={{
          width: "73%",
          left: "13%",
        }}
        PaperProps={{
          variant: "outlined",
          style: {
            background: "none",
            backgroundColor: "none",
            backdropFilter: "blur(16px)",
            borderColor: "rgb(0,0,0,0)",
          },
        }}
      >
        <img src={LaptopDevice} className={classes.laptopImg} />
        <Box className={classes.laptopAppView}>
          {props.value ? (
            <AppScreenView
              screenName={props.screenName}
              introScreens={props.introScreens}
            />
          ) : (
            <AppScreenView
              screenName={props.screenName}
              splashScreen={props.splashScreen}
            />
          )}
        </Box>
      </Dialog>
    </Box>
  ) : (
    <Box>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          variant: "outlined",
          elevation: 16,
          style: {
            left: "31px",
            position: "relative",
            background: "none",
            backgroundColor: "none",
            "backdrop-filter": "blur(16px)",
            borderColor: "rgb(0,0,0,0)",
            objectFit: "contain",
            overflow: "clip",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
        }}
      >
        <img
          src={props.platform === "Android Mobile App" ? Device : Iphone}
          className={classes.deviceImg}
        />
        <Box className={classes.view}>
          {props.value ? (
            <AppScreenView
              screenName={props.screenName}
              introScreens={props.introScreens}
            />
          ) : (
            <AppScreenView
              screenName={props.screenName}
              splashScreen={props.splashScreen}
            />
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default AppScreenDevice;
