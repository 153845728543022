import axios from "axios";

axios.interceptors.response.use(
  (res) => res,
  async (error) => {

    if (localStorage.getItem("token")) {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry && !originalRequest?.url.includes("refresh-token")) {
        originalRequest._retry = true;
        
        try {
          const refresh = await axios.get(
            `${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/refresh-token`,
            {
              headers: {
                "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
                Authorization: localStorage.getItem("refreshToken"),
              },
            }
          );
          if (refresh?.data?.hzStatus === 200) {
            localStorage.setItem("token", refresh.data.jwtToken);
            localStorage.setItem("refreshToken", refresh?.data?.refreshToken);
            originalRequest.headers["Authorization"] = refresh.data.jwtToken;
            return axios(originalRequest);
          } else {

            window.location = '/login';
            localStorage.clear();
            return Promise.reject(error);
          }
        } catch (err) {

          window.location = '/login';
          localStorage.clear();
          return Promise.reject(err); 
        }
      }
    }
    return error.response.data;
  }
);
