import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const MaintenanceNotification = ({ type, message }) => {
  const getIconForType = (type) => {
    switch (type) {
      case 'major':
        return <WarningIcon fontSize="inherit" />;
      case 'minor':
        return <WarningIcon fontSize="inherit" />;
      case 'patch':
        return <WarningIcon fontSize="inherit" />;
      default:
        return <WarningIcon fontSize="inherit" />;
    }
  };

  const getSeverityForType = (type) => {
    switch (type) {
      case 'major':
        return 'error';
      case 'minor':
        return 'error';
      case 'patch':
        return 'error';
      default:
        return 'error';
    }
  };

  return (
    <Alert 
      severity={getSeverityForType(type)} 
      icon={getIconForType(type)} 
      sx={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  maxWidth: '450px', margin: 'auto' }}
    >
      <AlertTitle>Maintenance Notice</AlertTitle>
      {message}
    </Alert>
  );
};


export default MaintenanceNotification;
