import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
  darkText,
  gradiant,
  fontColor,
  displayFlexView,
  iconSizes,
} from "assets/jss/material-dashboard-react.js";

const linearChannelStyle = (theme) => ({
  Arrows: {
    position: "absolute",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    zIndex: "1",
    cursor: "pointer",
  },

  nextArrowHorizontal: {
    right: 0,
  },

  backArrowHorizontal: {
    left: 0,
  },
  selectChannel: {
    border: "1px blue solid",
  },
  linearChannelDetailBox: {
    padding: "10px 0px 10px 10px",
    //  paddingRight:'-10px'
  },
});

export default linearChannelStyle;
