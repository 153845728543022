import React from 'react'
import { Box, Button, makeStyles,List, ListItem, ListItemIcon, ListItemText , Tab, Tabs, Typography } from '@material-ui/core'
import additionalSetting from "assets/img/navbarLogo/template-dark.svg"
import Styles from '../../assets/jss/material-dashboard-react/views/contentExplorerStyle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BlockIcon from '@mui/icons-material/Block'; // Example icons
import CustomTemplate from './CustomTemplates';

const useStyle  = makeStyles(Styles);
export default function Templates() {
    const classes = useStyle();
  return (
    <Box>
      <Box>
         <Box display="flex">
         <img src={additionalSetting} alt="Your Icon" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
           <Typography className={classes.heading}>Template Options</Typography>
         </Box>
         <Box paddingTop='16px'>
          <CustomTemplate/>
         </Box>
      </Box>
    </Box>
  )
}
