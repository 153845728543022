import { InitialState } from "../initalstate/platformState";
import {
  EXPORT_SCREEN_RESPONSE,
  PUBLISH_INTRO_RESPONSE,
  PUBLISH_SPLASH_RESPONSE,
  SCREEN_STATUS_RESPONSE,
  SUCCEESFULLY_UPDATE_IMAGE_TYPE_DATA,
  PLATFORM_DETAILS,
  CLEAR_SCREEN_WIDGETS,
  SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS,
  CLEAR_CREATE_SCREEN_DATA,
  CLEAR_DELETE_SCREEN_DATA,
  CLEAR_EXPORT_SCREEN_DATA,
  CLEAR_PUBLISH_SCREEN_DATA,
  CLEAR_UPDATE_SCREEN_DATA,
  SUCCESSFULLY_UPDATE_SCREEN_DETAILS,
  CLEAR_UPDATE_SCREEN_DETAILS,
  CLEAR_SCREEN_REORDER_RESPONSE,
  getAllPlatformActionDataType,
} from "../actionType/platformActionType";
import {
  REORDER_WIDGET_MANAGER,
  GET_SINGLE_SCREEN_ACTION,
  SCREEN_REORDER_RESPONSE,
  UPDATE_SCREEN_RESPONSE,
  DELETE_SCREEN_ACTION,
  DELETE_SCREEN_RESPONSE,
  SCREEN_STATUS_CHANGE,
  SCREEN_REORDER_ACTION_ACTION,
  getSingleWidgetDataType
} from "../actionType/platformActionType";

export function screenVisualizationReducer(state = InitialState, action) {
  switch (action.type) {
    case "SUCCESSFULLY_CREATE_NEW_SCREEN": {
      return { ...state, createScreenData: action.payload, screenReset: !state.screenReset };
    }
    case "SUCCESSFULLY_GET_ALL_PLATFROMS": {
      return { ...state, platforms: action.payload };
    }
    case getAllPlatformActionDataType: {
      console.log("updating state:",action.payload)
      return { ...state, allPlatforms: action.payload };
    }
    case "SUCCESSFULLY_GET_ALL_SCREENS": {
      return { ...state, screens: action.payload };
    }
    case SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS: {
      return { ...state, defaultScreens: action.payload };
    }
    case "GET_SCREEN_RESET":
      return { ...state, screens: [] };
    case "SUCCESSFULLY_GET_WIDGET_BY_ID_DATA": {
      return { ...state, widgetDetailsArray: action.payload };
    }
    case REORDER_WIDGET_MANAGER: {
      return { ...state, widgetDetails: action.payload, reorderWidgets: !state.reorderWidgets };
    }
    case "SUCCESSFULLY_GET_ALL_WIDGETS": {
     
      return { ...state, widgets: action.payload };
    }
    case SCREEN_REORDER_RESPONSE: {
      return { ...state, reorderScreenData: action.payload };
    }
    case CLEAR_SCREEN_REORDER_RESPONSE: {
      return { ...state, reorderScreenData: {} };
    }
    case UPDATE_SCREEN_RESPONSE: {
      return { ...state, updatedScreen: [action.payload, action.screenName] };
    }
    case SUCCESSFULLY_UPDATE_SCREEN_DETAILS: {
      return { ...state, updatedScreenDetails: [action.payload, action.screenName] };
    }
    case SUCCEESFULLY_UPDATE_IMAGE_TYPE_DATA: {
      return { ...state, updateWidgetData: action.payload, widgetReset: !state.widgetReset };
    }
    case DELETE_SCREEN_RESPONSE: {
      return { ...state, loading: false, screenReset: !state.screenReset, deleteScreenRes: action.payload };
    }
    case DELETE_SCREEN_ACTION: {
      return { ...state, loading: true };
    }
    case SCREEN_STATUS_CHANGE: {
      return { ...state, statusChanged: !state.statusChanged };
    }
    case EXPORT_SCREEN_RESPONSE: {
      return { ...state, exportScreenRes: action.payload };
    }
    case SCREEN_STATUS_RESPONSE: {
      return { ...state, screenStatusRes: { payload: action.payload, action: action.initialAction } };
    }
    case PUBLISH_SPLASH_RESPONSE: {
      return { ...state, splashResponse: action.payload };
    }
    case PUBLISH_INTRO_RESPONSE: {
      return { ...state, introResponse: action.payload };
    }
    case PLATFORM_DETAILS: {
      return { ...state, platformDetails: action.payload };
    }
    case CLEAR_SCREEN_WIDGETS: {
      return { ...state, widgetDetailsArray: [] };
    }
    case CLEAR_CREATE_SCREEN_DATA: {
      return { ...state, createScreenData: {} };
    }
    case CLEAR_DELETE_SCREEN_DATA: {
      return { ...state, deleteScreenRes: {} };
    }
    case CLEAR_EXPORT_SCREEN_DATA: {
      return { ...state, exportScreenRes: {} };
    }
    case CLEAR_PUBLISH_SCREEN_DATA: {
      return { ...state, screenStatusRes: {} };
    }
    case CLEAR_UPDATE_SCREEN_DATA: {
      return { ...state, updatedScreen: [] };
    }
    case CLEAR_UPDATE_SCREEN_DETAILS: {
      return { ...state, updatedScreenDetails: [] };
    }
    case getSingleWidgetDataType: {
      return {
        ...state,
        widgetUsage: action.payload
      }
    }

    default: {
      return { ...state };
    }
  }
}
