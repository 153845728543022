import { InitialState } from "../intialState/LoginState";
import {RESET_LOGIN, SuccessfullyLoginType, UNSUCCESSFULL_LOGIN} from '../actionTypes/loginActionType'

export default function loginReducer (state = InitialState,action){
     switch(action.type){
         case SuccessfullyLoginType: {
             return{...state,authTokens:action.payload,loginSuccesFully:true}
         }
         case UNSUCCESSFULL_LOGIN:{
             return{...state,errorMessages:action.payload,loginSuccesFully:false}
         }
         case RESET_LOGIN:{
            return{...state,errorMessages:"",loginSuccesFully:false}
        }
         default:{
             return state
         }
     }
}