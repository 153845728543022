import React, { useMemo } from "react";
import { DndContext, useSensor, useSensors, PointerSensor, KeyboardSensor, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";

const SortableScreensList = ({
  items,
  onChange,
  renderItem,
  accessLevel,
  setSnackOpen,
  setSnackMessage,
  snackMessage,
  setExpanded,
  handleEditScreen,
  handleScreenUpdate,
  handleEditScreenType,
  deleteScreen,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const itemIds = useMemo(() => items.map((item) => item._id), [items]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={() => setExpanded(-1)}
      onDragEnd={({ active, over }) => {
        if (accessLevel !== "user") {
          if (over && active.id !== over.id) {
            onChange((items) => {
              const activeIndex = items.findIndex((item) => item._id === active.id);
              const overIndex = items.findIndex((item) => item._id === over.id);
              return arrayMove(items, activeIndex, overIndex);
            });
          }
        } else {
          setSnackMessage(snackMessage);
          setSnackOpen(true);
        }
      }}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {itemIds?.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem(item, index, items, handleEditScreen, handleScreenUpdate, handleEditScreenType, deleteScreen)}
          </React.Fragment>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableScreensList;
