import { Box, Button, Card, FormHelperText, Grid, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import AddUserIcon from 'assets/img/icons/svg/AddUser.svg';
import React, { useEffect, useState } from "react";

import style from 'assets/jss/material-dashboard-react/views/userStyle';
import { useHistory } from "react-router-dom";
import { addNewUserAction } from "../actions";
import { connect } from "react-redux";
import Dialog from 'components/Dialog/Dialog';
import { getAllProjectAction } from "screens/Projects/actions/projectActions";

const useStyle = makeStyles(style)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    variant:'menu',
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      }
  };

  var options = ['admin','user']

const AddUser = (props)=>{
    const classes = useStyle();
    const history = useHistory();
    const [UserDetails,setUserDetails] = useState({
      firstName:"",
      lastName:"",
      email:"",
      phoneNumber:"",
      accessLevel:"",
      projects:[]
    })
    const [ErrorMessage, setErrorMessage] = useState("");
    // const [createDialog, setCreateDialog] = useState(false);

    const handleFristName = (event)=>{
         setUserDetails({...UserDetails,firstName:event.target.value})
    }

    const handleLastName = (event)=>{
      setUserDetails({...UserDetails,lastName:event.target.value})
    }

    const handleEmail = (event)=>{
      setUserDetails({...UserDetails,email:event.target.value})
    }

    /*const handlePassword = (event)=>{
      setUserDetails({...UserDetails,password:event.target.value})
    }*/

    const handlePhoneNumber = (event)=>{
        setUserDetails({...UserDetails,phoneNumber:event.target.value})
    }

    const handleAccessLevel = (event)=>{
       setUserDetails({...UserDetails,accessLevel:event.target.value})
    }

    const handleProject = (event)=>{
      setUserDetails({...UserDetails,projects:event.target.value})
    }

    

    const handleCancel = ()=>{
         history.push('/admin/users');
    }

    const handleAddUser = ()=>{
      console.log('user details',UserDetails);
      if(UserDetails.firstName === "" || UserDetails.firstName === undefined){
        setErrorMessage("mandatory field *")
      }else if(UserDetails.lastName === "" || UserDetails.lastName === undefined){
        setErrorMessage("mandatory field *")
      }else if(UserDetails.email === "" || UserDetails.email === undefined){
        setErrorMessage("mandatory field *")
      }else if(UserDetails.phoneNumber === "" || UserDetails.phoneNumber === undefined){
        setErrorMessage("mandatory field *")
      }else if(UserDetails.accessLevel === "" || UserDetails.accessLevel === undefined){
        setErrorMessage("mandatory field *")
      }else if(UserDetails.projects.length < 0){
        setErrorMessage("mandatory field *")
      }else{
        props.addNewUserAction(UserDetails);
        history.push('/admin/users');
      }
    }

    useEffect(()=>{
       props.getAllProjectAction();
    },[])

    return (
        <Box>
          {/* <Dialog
            open={createDialog} 
            title={<span className={classes.dialog}>Are you sure you want to create{" "}
              <span className={classes.dialogFocus}>
                {UserDetails?.firstName} {UserDetails?.lastName}</span> as a/an{" "}
                <span className={classes.dialogFocus}>{UserDetails?.accessLevel}</span>?</span>}
            button1="Cancel"
            button2="Yes"
            button1Event={() => setCreateDialog(false)}
            button2Event={handleAddUser}
            icon={AddUserIcon}
            margin="8px 8px 8px 16px"
            /> */}
            <Grid container>
          <Grid item xs={12} md={12} lg={12}>
         <Box  pb={2}>
           <Typography className={classes.heading}>New User</Typography>
         </Box>
         </Grid>
         </Grid>
         <Grid container>
            <Grid item xs={12} md={12} lg={6}>
               <Box>
               <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>First Name</Typography>
                        <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter the First Name"
                            onChange={handleFristName}
                            required
                            //value={props.addProject.commonName}
                            //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                            />
                             <FormHelperText>
                      {UserDetails.firstName === "" || UserDetails.firstName === undefined
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Last Name</Typography>
                        <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter the Last Name"
                            onChange={handleLastName}
                            required
                            //value={props.addProject.commonName}
                            //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                            />
                            <FormHelperText>
                      {UserDetails.lastName === "" || UserDetails.lastName === undefined
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Email</Typography>
                        <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter the Email Id"
                            onChange={handleEmail}
                            required
                            //value={props.addProject.commonName}
                            //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                            />
                            <FormHelperText>
                      {UserDetails.email === '' || UserDetails.email === undefined
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Phone Number</Typography>
                        <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter the Phone Number"
                            onChange={handlePhoneNumber}
                            required
                            //value={props.addProject.commonName}
                            //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                            />
                            <FormHelperText>
                      {UserDetails.phoneNumber === "" || UserDetails.phoneNumber === undefined
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  {/*<Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Password</Typography>
                        <TextField
                            className={classes.textfield}
                            type='password'
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Enter the Password"
                            onChange={handlePassword}
                            required
                            //value={props.addProject.commonName}
                            //helperText={addProject.commonName === "" || addProject.commonName === undefined ? ErrorMessage :""}
                            />
                  </Box>*/}
                  <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Select Role</Typography>
                    <Select
                           labelId="Select Languages"
                           className={classes.textfield}
                           variant='outlined'
                           fullWidth
                           size='small'
                           style={{width:'100%'}}
                           onChange={handleAccessLevel}
                           //value={options}      
                           MenuProps={MenuProps}   
                           defaultValue=""
                           >
                          
                           {options.map((x)=>{
                               return (
                                <MenuItem value={x}>{x}</MenuItem>
                               )
                           })}
                           </Select>
                           <FormHelperText>
                      {UserDetails.accessLevel === "" || UserDetails.accessLevel === undefined
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
                  <Box paddingBottom='20px'>
                    <Typography className={classes.inputlabel}>Select Projects</Typography>
                    <Select
                           labelId="Select Languages"
                           className={classes.textfield}
                           variant='outlined'
                           fullWidth
                           multiple
                           size='small'
                           style={{width:'100%'}}
                           onChange={handleProject}
                           //value={options}   
                           value={props.projects?.p_id?.flatMap(Object.keys) ?? UserDetails?.projects}   
                           MenuProps={MenuProps}   
                           defaultValue=""
                           >
                          
                           {props.projects.map((x)=>{
                               return (
                                <MenuItem value={x?.p_id}>{x?.commonName}</MenuItem>
                               )
                           })}
                           </Select>
                           <FormHelperText>
                      {UserDetails.projects.length <= 0 
                        ? ErrorMessage
                        : ""}
                    </FormHelperText>
                  </Box>
               </Box>
            </Grid>
         </Grid>
         <Box style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left:0,
            width:'100vw',
            boxSizing: "border-box"}}> 
             <Card className={classes.bottomCard}>
               <Box  justifyContent='flex-end' display='flex' flexDirection='row' alignItems='center'>
                 <Box paddingLeft='auto' paddingTop='1%' paddingBottom='2%' >
                    <Button variant='outlined' className={classes.cancelButton} onClick={handleCancel} >
                        <Typography className={classes.cancelButtonText}>Cancel</Typography>
                    </Button>
                </Box>
                <Box paddingLeft='2%' paddingTop='1%' paddingBottom='2%' paddingRight='5%'>
                   <Button className={classes.addButton} onClick={() => handleAddUser()}>
                      <Typography className={classes.addButtonText}>ADD</Typography>
                   </Button>
                </Box>
               </Box>
               </Card>
            </Box>
        </Box>
    )
}

const mapStateToProps = (State)=>{
  return {
    projects: State.ProjectReducer.allProjects,
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
      addNewUserAction:(userDetails)=>{
        dispatch(addNewUserAction(userDetails))
      },
      getAllProjectAction: () => {     
        dispatch(getAllProjectAction());
      },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddUser);