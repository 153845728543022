//importing saga prebuilt functions
import { put, call, takeLatest, all, takeEvery } from "redux-saga/effects";
//import action types
import {
  GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_ACTION,
  GET_ALL_BLACKOUT_ACTION,
  GET_ALL_LINEAR_CHANNEL_ACTION,
  SCHEDULE_BLACKOUT_ACTION,
  GET_EPG_ACTION,
  DELETE_BLACKOUT_ACTION,
  UPLOAD_BLACKOUT_IMAGE_CONTENT_ON_S3_ACTION,
} from "../actionTypes";
//import actions
import * as actions from "../actions";
//import apis
import {
  getAllBlackoutForAllTheChannlesApiCall,
  getAllLinearChannelApiCall,
  scheduleBlackoutApiCall,
  deleteBlackoutApiCall,
  getAllEpgApiCall
} from "../apis";

//Handlers for the different Actions
//Handler for get all the blackout for  all the channels
function* handleGetBlackoutsForAllChannelsAction({ payload }) {
  try {
    const blackoutResponseForAllChannels = yield call(
      getAllBlackoutForAllTheChannlesApiCall,
      payload
    );
    yield put(actions.getAllBlackoutForAllChannelResponse(blackoutResponseForAllChannels?.data?.data))
   // 
  } catch (error) {
    console.log("Error", error);
  }
}

//Handler for getting all the Blackout Schedules
function* handleGetAllBlackoutScheduleAction() {}

//Handler for getting all the Linear Channels(Live Channels)
function* handleGetAllLinearChannelAction({ payload }) {
  try {
    const linearChannelResponse = yield call(
      getAllLinearChannelApiCall,
      payload
    );
if(linearChannelResponse.data && linearChannelResponse.data.hzStatus==200){
  yield put(actions.getAllLinearChannelResponse(linearChannelResponse?.data?.data?.objects))}
else if(linearChannelResponse.hzStatus==400){
  yield put(actions.getAllLinearChannelErroMessage(linearChannelResponse.message))
}
  } catch (error) {}
}

//Handler for the getting all the EPG
function* handleGetAllTheEpgAction({ payload }) {
try{
    const epgResponse=yield call(getAllEpgApiCall,payload)
    yield put(actions.getAllEpgResponse(epgResponse?.data?.data?.result))
}
catch(error){

}
}

//Handler for the Scheduling the blackout
function* handleScheduleBlackoutAction({ payload }) {
  try {
    const scheduleBlackoutResponse = yield call(
      scheduleBlackoutApiCall,
      payload
    );
    window.location = "/admin/blackoutschedule" 
  } catch (error) {}
}

//Handler for the delete the Schedule
function* handleDeleteBlackoutAction({ payload }) {
  const deleteResponse = yield call(deleteBlackoutApiCall, payload);
  yield put(actions.deleteBlackoutResponse())
  window.location = "/admin/blackoutschedule" 
}

//Actions watcher for get all linear channel,get all the blackout,delete schedule,get epg,and create schedule
export default function* blackoutScheduleWatcher() {
  yield takeLatest(
    GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_ACTION,
    handleGetBlackoutsForAllChannelsAction
  );
  yield takeLatest(GET_ALL_BLACKOUT_ACTION, handleGetAllBlackoutScheduleAction);
  yield takeLatest(
    GET_ALL_LINEAR_CHANNEL_ACTION,
    handleGetAllLinearChannelAction
  );
  yield takeLatest(SCHEDULE_BLACKOUT_ACTION, handleScheduleBlackoutAction);
  yield takeLatest(GET_EPG_ACTION, handleGetAllTheEpgAction);
  yield takeLatest(DELETE_BLACKOUT_ACTION, handleDeleteBlackoutAction);
}
