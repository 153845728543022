export const InitialState = {
    allUsers:[],
    createUser:{},
    createUserFail:{},
    usersReset:false,
    deleteUser:{},
    deleteUserFail:{},
    deleteReset:false,
    resetData:false,
    updateUser:{},
    updateUserFail:{},
    updateReset:false,
    auditLogs:[]
}