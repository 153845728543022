import { call, put, takeEvery } from "redux-saga/effects";
import { successfullyGetAllPackages, successfullyUpdateJsonDetails } from "../actions";
import { GET_ALL_PACKAGES, SUCCESSFULLY_GET_ALL_PACKAGES, UPDATE_JSON_DETAILS,ADD_NEW_JSON_DETAILS } from "../actionType";
import { handleGetAllPackageApi ,handleUpdateJsonDetailsApi,handleAddNewJsonDetailsApi} from "../apis";

export function* watchPackageSaga(){
    yield takeEvery(GET_ALL_PACKAGES,handleGetAllPackages)
    yield takeEvery(UPDATE_JSON_DETAILS,handleUpdateJsonDetails)
    yield takeEvery(ADD_NEW_JSON_DETAILS,handleAddNewJsonDetails)
} 

function* handleGetAllPackages(payload){
    console.log(payload,"getallpayload");
    
    const res = yield call(handleGetAllPackageApi,payload?.data)
    console.log(res,"getallpackage2")
    yield put(successfullyGetAllPackages(res))
}

function* handleUpdateJsonDetails({payload}){
    console.log(payload,"update payload");
    
    const res = yield call(handleUpdateJsonDetailsApi,payload)
    yield put(successfullyUpdateJsonDetails(res));
}

function* handleAddNewJsonDetails({payload}){
    const res = yield call(handleAddNewJsonDetailsApi,payload)
    yield put(successfullyUpdateJsonDetails(res));
}