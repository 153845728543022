import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { restrictToHorizontalAxis, restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {publishIntroAction} from '../../screens/PlatformManager/actions/PlatformAction'
const SortableIntroList = ({
  p_id,platform,
  items,
  onChange,
  renderItem,
  accessLevel,
  setAccessSnack,
  setAccessSnackMessage,
  setAccessSnackSeverity,
  publishIntroAction
}) => {
  console.log("dndList", items);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );


  const removeItem = useCallback(
    
    (id) => {
      var remainingList
      onChange((items) =>
       remainingList= items.filter((item) => item !== id))
       
      publishIntroAction({
        p_id:p_id,
        platform: platform,
        screenURL:remainingList,
      })
  }
   
    ,
    []
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
      onDragEnd={({ active, over }) => {
        if (accessLevel !== "user") {
          if (over && active.id !== over?.id) {
            onChange(() => {
              const activeIndex = items.indexOf(active.id);
              const overIndex = items.indexOf(over.id);
              return arrayMove(items, activeIndex, overIndex);
            });
          }
        } else {
          setAccessSnackMessage("You do not have access to reorder intro screens.");
          setAccessSnack(true);
        }
      }}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        {items?.map((item, index) => (
          <React.Fragment key={index}>{renderItem(item, index, removeItem, items)}</React.Fragment>
        ))}
      </SortableContext>
    </DndContext>
  );
};


const mapStateToProps = (State) => {
  return {
 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  
    publishIntroAction: (screenDetails) => {
      dispatch(publishIntroAction(screenDetails));
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps) (SortableIntroList);
