import React from 'react';
import Slider from 'react-slick';
import './carousel.css';
import { images } from '../../constants/index'; 

const Carousel = () => {
    const settings = {
        dots: false, 
        infinite: true, 
        speed: 500, 
        slidesToShow: 1, 
        slidesToScroll: 1, 
        autoplay: true, 
        autoplaySpeed: 3000, 
        pauseOnHover: true, 
        arrows: false, 
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image) => (
                    <div className="slide" key={image.id}>
                        <div 
                            style={{
                             position:'absolute',
                             width: '20vw',
                             height: '20vw',
                             background: 'radial-gradient(50% 50% at 50% 50%, #8F90D2 0%, rgba(143, 144, 210, 0) 100%)',
                             transform:'translateX(6vw)',
                             zIndex:'-1',
                         }}
                        > 
                        </div>
                        <img src={image.path} alt={image.title} sty />
                        <div className="content-container">
                            <h5>{image.title}</h5>
                            <p>{image.content}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;

