import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classNames from "classnames";

import "./DeviceSlider.scss";

import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  InputBase,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import NextIcon from "assets/img/platformScreenVisualization/NextIcon.svg";
import BackIcon from "assets/img/platformScreenVisualization/BackIcon.svg";
import Info from "components/Typography/Info";

import styles from "assets/jss/material-dashboard-react/components/ScreenViewStyle";

import { drawerWidth } from "assets/jss/material-dashboard-react";
import reactDom from "react-dom";

const useStyles = makeStyles(styles);

const NextArrow = ({ onClick, type }) => {
  const classes = useStyles();

  if (type === "Carousel") {
    return (
      <div
        className={classNames(classes.Arrows, classes.nextArrowCarousel)}
        onClick={() => onClick()}
      >
        <img src={NextIcon} />
      </div>
    );
  } else if (type === "HorizontalRail") {
    return (
      <div
        className={classNames(classes.Arrows, classes.nextArrowHorizontal)}
        onClick={() => onClick()}
      >
        <img src={NextIcon} />
      </div>
    );
  }
};

const BackArrow = ({ onClick, type }) => {
  const classes = useStyles();

  if (type === "Carousel") {
    return (
      <div
        className={classNames(classes.Arrows, classes.backArrowCarousel)}
        onClick={() => onClick()}
      >
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  } else if (type === "HorizontalRail") {
    return (
      <div
        className={classNames(classes.Arrows, classes.backArrowHorizontal)}
        onClick={() => onClick()}
      >
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  }
};

const DeviceScreenView = (props) => {
  const [value, setValue] = React.useState(false);
  const [imageRatio, setImageRatio] = React.useState(1);

  React.useEffect(() => {
    if (
      eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) > 1
    ) {
      setImageRatio(1);
    } else if (
      eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) <= 1
    ) {
      setImageRatio(2);
    }
  });

  const classes = useStyles();
  const settings = {
    className: "center",
    //centerMode: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5500,
    dots: true,
    arrows: true,
    slidesToShow: imageRatio,
    autoplay: true,
    swipeToSlide: true,
    centerPadding: (window.innerWidth - 1100) / 2 + "px",
    swipe: true,
    //nextArrow: <NextArrow type="Carousel" />,
    //prevArrow: <BackArrow type="Carousel" />,
  };
  //console.log("Hello Check Image type",props.widget)
  //Events Function
  const responsive = {
    extraLarge: {
      breakpoint: { max: 800, min: 700 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) > 1 ? 1 : 3,
      paritialVisibilityGutter: 30,
    },
  };

  //Calculation of the image resolution
  const imageResolutionCalc = (ratio) => {
   if(ratio){
    let nonNumericIndex;
    let resolution = {};
    let height;
    let width;
    for (var i = 0; i < ratio?.length; i++) {
      if (isNaN(ratio[i])) {
        nonNumericIndex = i;
        break;
      }
    }
    width = ratio.substr(0, i) * 30;
    height = ratio.substr(i + 1) * 30;

    return { height: height, width: width };
   }
  };

  //Horizontal Rails
  if (props?.widget?.layout === "CAR") {
    return (
      <div style={{ marginTop: "2px", height: "", marginBottom: "20px" }}>
        <Slider {...settings}>
          {props?.widget?.item?.playlist?.data?.result?.objects?.map(
            (widget, index) => {
              let resolution = imageResolutionCalc(
                props?.widget?.kalturaOTTImageType
              );

              const calImageRatio = eval(
                props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")
              );
              
              return(
              <div key={index}>
                {calImageRatio > 1 && calImageRatio<=1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        (img) => img?.ratio?.trim()?.replace(/\D/g, "x") ===  props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      cursor: "pointer",
                      //objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "70%",
                      borderRadius: props?.widget?.item?.imageType === "LDS"
                      ? "4px"
                      : "45%",
                      // margin: 0,
                      //objectFit:'contain'
                    }}
                    // align="center"
                  />
                ) :calImageRatio>1.5?  <img
                alt="Imge"
                src={`${
                  widget?.images?.find(
                    (img) => img?.ratio?.trim().replace(/\D/g, "x") ===  props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                  )?.url
                } /width/${resolution.width}/height/${resolution.height}`}
                style={{
                  cursor: "pointer",
                  //objectFit: "contain",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "85%",
                  borderRadius: props?.widget?.item?.imageType === "LDS"
                  ? "4px"
                  : "45%",
                  // margin: 0,
                  //objectFit:'contain'
                }}
                // align="center"
              /> : calImageRatio < 1 && calImageRatio > 0.5  ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget?.images?.find(
                        (img) => img?.ratio.trim().replace(/\D/g, "x") === props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                      )?.url
                    }/width/${resolution.width*3}/height/${resolution.height*3}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginTop: "5px",
                      width: "76%",
                      borderRadius:  props?.widget?.item?.imageType === "PR2"
                      ? "4px"
                      : "60%",
                      //height:280,
                      // margin: 0,
                      //objectFit:'contain'
                    }}
                    // align="center"
                  />
                ) :calImageRatio < 0.5?   <img
                alt="Imge"
                src={`${
                  widget?.images?.find(
                    (img) => img.ratio.trim().replace(/\D/g, "x") === props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                  )?.url
                }/width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{
                  cursor: "pointer",
                  objectFit: "contain",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "5px",
                  width: "76%",
                  borderRadius: props?.widget?.item?.imageType === "PR2"
                  ? "4px"
                  : "60%",
                  //height:280,
                  // margin: 0,
                  //objectFit:'contain'
                }}
                // align="center"
              />:(
                  <img
                    alt="Imge"
                    src={`${
                      widget?.images?.find(
                        (img) => img.ratio.trim().replace(/\D/g, "x") === props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width * 8}/height/${
                      resolution.height * 8}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginTop: "5px",
                      width: "60%",
                      borderRadius:  props?.widget?.item?.imageType === "SQR"
                      ? "4px"
                      : "50%",
                      //height:280,
                      // margin: 0,
                      //objectFit:'contain'
                    }}
                    // align="center"
                  />
                )}
              </div>
            )}
          )}
        </Slider>
      </div>
    );
  } else if (props?.widget?.layout === "HOR") {
    /*Carousel */
    return (
      <div style={{}}>
        {/* <Info >{props?.widget?.name}</Info >{" "}*/}
        <Carousel
          // style={{ marginTop: "8px" }}
          swipeable={true}
          //partialVisbile
          deviceType={props.deviceType}
          itemClass="image-item"
          keyBoardControl={true}
          customTransition={"transform 500ms ease-in-out"}
          showDots={false}
          //transitionDuration={300}
          // ref={(el) => (Carousel = el)}
          centerMode={true}
          //customLeftArrow={<BackArrow type="HorizontalRail" />}
          //customRightArrow={<NextArrow type="HorizontalRail" />}
          //customRightArrow={
          //  <CustomRightArrow
          // onbuttonClick={() => {
          // setTimeout(() => {
          //         if (items.length > 0) {
          //           items = [];
          //         }
          //       }, 2000);
          //     }}
          //   />
          // }
          // customLeftArrow={<CustomLeftArrow />}
          // containerClass='carousel-container'
          //removeArrowOnDeviceType={["mobile", "tablet"]}
          sliderClass="sliderClass"
          //  CustomButtonGroupAsArrows={<CustomButtonGroupAsArrows />}
          responsive={responsive}
          minimumTouchDrag={10}
          slidesToSlide={2}
          additionalTransfrom={0}
        >
          {props?.widget?.item?.playlist?.data?.result?.objects?.map(
            (value, index) =>{  
              let resolution = imageResolutionCalc(
                props?.widget.kalturaOTTImageType
              );
              const calImageRatio = eval(
                props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/")
              );
               return(
              <div key={index}>
                {calImageRatio >1&&calImageRatio<1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        (img) => img.ratio.trim().replace(/\D/g, "x") ===props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                      )?.url
                    }/width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "95%",
                      borderRadius:  props?.widget?.item?.imageType == "LDS"
                      ? "4px"
                      : "45%",
                      //height: "52px",
                      // margin: 0,
                      // objectFit:''
                    }}
                    // align="center"
                  />
                ) :calImageRatio>1.5?  <img
                alt="Imge"
                src={`${
                  value.images.find(
                    (img) => img.ratio.trim().replace(/\D/g, "x") ===props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "x")
                  )?.url
                }/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  cursor: "pointer",
                  objectFit: "contain",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "95%",
                  borderRadius:  props?.widget?.item?.imageType == "LDS"
                  ? "4px"
                  : "45%",
                  //height: "52px",
                  // margin: 0,
                  // objectFit:''
                }}
                // align="center"
              />:calImageRatio<1 && calImageRatio>0.5  ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        (img) => img.ratio.trim().replace(/\D/g, "x") === props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width*3}/height/${resolution.height*3}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginTop: "5px",
                      width: "76%",
                      borderRadius: props?.widget?.item?.imageType == "PR2"
                      ? "4px"
                      : "45%",
                      //height:280,
                      // margin: 0,
                      //objectFit:'contain'
                    }}
                    // align="center"
                  />
                ) :calImageRatio<0.5?  <img
                alt="Imge"
                src={`${
                  value.images.find(
                    (img) => img.ratio.trim().replace(/\D/g, "x") === props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                  )?.url
                } /width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{
                  cursor: "pointer",
                  objectFit: "contain",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "5px",
                  width: "76%",
                  borderRadius: props?.widget?.item?.imageType == "PR2"
                  ? "4px"
                  : "45%",
                  //height:280,
                  // margin: 0,
                  //objectFit:'contain'
                }}
                // align="center"
              />: (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        (img) => img.ratio.trim().replace(/\D/g, "x") === props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    }/width/${resolution.width*8}/height/${resolution.height*8}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginTop: "5px",
                      width: "60%",
                      borderRadius: props?.widget?.item?.imageType == "SQR"
                      ? "4px"
                      : "50%",
                      //height:280,
                      // margin: 0,
                      //objectFit:'contain'
                    }}
                    // align="center"
                  />
                )}
              </div>
            )}
          )}
        </Carousel>
      </div>
    );
  } else if (props?.widget?.layout === "HRO") {
    if (props?.widget?.item?.imageSource === "MNL") {
      let resolution = imageResolutionCalc(props?.widget.kalturaOTTImageType);

      if (props?.widget?.item?.landingPage?.type === "PLT") {
        const calImageRatio = eval(
          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/")
        );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
             {calImageRatio >1&&calImageRatio<1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                maxWidth: "70%",marginTop:'5px' }}
              />
            ) :calImageRatio>1.5? <img
            src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
            style={{ borderRadius:  props?.widget?.item?.imageType === "LDS" ? "4px" : "45%"
           ,marginTop:'5px', width: "100%" ,}}
            /> :calImageRatio<1 && calImageRatio>0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{ borderRadius:props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                maxWidth: "70%",marginTop:'5px' }}
              />
            ) :calImageRatio<0.5? <img
            src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
            style={{ borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
            maxWidth: "70%",marginTop:'5px'}}
            /> :(
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*8}/height/${resolution.height*8}`}
                style={{ borderRadius:  props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                maxWidth: "70%",marginTop:'5px'  }}
              />
            )}
          </div>
        );
      } else if (props?.widget?.item?.landingPage?.type === "AST") {
        const calImageRatio = eval(
          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/")
        );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6px",
              paddingLeft: "3px",
            }}
          >
               { calImageRatio >1&&calImageRatio<1.5? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",  maxWidth: "70%",marginTop:'5px'}}
              />
            ) :calImageRatio>1.5? <img
            src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
            style={{ borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",  maxWidth: "70%",marginTop:'5px' }}
            /> : calImageRatio<1 && calImageRatio>0.5  ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",  maxWidth: "70%",marginTop:'5px',marginTop:'5px' }}
              />
            ) :calImageRatio<0.5?
            <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{ borderRadius:  props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",  maxWidth: "70%",marginTop:'5px',marginTop:'5px' }}
              />
            : (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*8}/height/${resolution.height*8}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                maxWidth: "70%",marginTop:'5px' }}
              />
            )}
          </div>
        );
      } else if (props?.widget?.item?.landingPage?.type === "HTM") {
        const calImageRatio = eval(
          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/")
        );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              //marginTop: "10px",
            }}
          >
             {calImageRatio >1&&calImageRatio<1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%", maxWidth: "70%", marginTop:'5px' }}
              />
            ) :calImageRatio>1.5 ?
            <img
            src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
            style={{ borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",   maxWidth: "70%",marginTop:'5px'  }}
            />  :  calImageRatio<1 && calImageRatio>0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
                style={{ borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                maxWidth: "70%",marginTop:'5px'}}
              />
            ) :calImageRatio<0.5?  <img
            src={`${props?.widget?.item?.imageURL}/width/${resolution.width*3}/height/${resolution.height*3}`}
            style={{ borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
            maxWidth: "70%",marginTop:'5px' }}
            />: (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width*8}/height/${resolution.height*8}`}
                style={{ borderRadius:  props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                maxWidth: "70%",marginTop:'5px' }}
              />
            )}
          </div>
        );
      }
    } else if (props?.widget?.item?.imageSource === "AST") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            //marginTop: "10px"
          }}
        >
          {props?.widget?.kalturaOTTImageType === "16x9" ? (
            <img
              src={`${props?.widget?.item?.assetId?.result?.images[0]?.url}/width/500/height/280`}
              style={{ borderRadius: "4px" }}
              />
          ) : (
            <img
              src={`${props?.widget?.item?.assetId?.result?.images[0]?.url}/width/500/height/280`}
              style={{ borderRadius: "4px" }}
              />
          )}
        </div>
      );
    }
  }
  else{
    return <div></div>
  }
  /* return (
    <div>
      <GridContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{ border: "1px solid black", width: "21%", height: "215px" }}
          >
            1
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
                width: "28px",
                height: "28px",
                borderRadius: "50%",
                float: "left",
              }}
            >
              <img src={BackIcon} />
            </div>
          </div>
          <div
            style={{ border: "1px solid black", width: "56%", height: "215px" }}
          >
            2
          </div>
          <div
            style={{ border: "1px solid black", width: "21%", height: "215px" }}
          >
            3
            <div
              style={{
                float: "right",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
                width: "28px",
                height: "28px",
                borderRadius: "50%",
              }}
            >
              <img src={NextIcon} />
            </div>
          </div>
        </Box>
      </GridContainer>
    </div>
  ); */
};

export default DeviceScreenView;
