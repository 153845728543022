import {
  defaultFont,
  displayFlexView,
} from "assets/jss/material-dashboard-react.js";

const userStyle = {
 heading:{
    width: '198px',
    height: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '33px'
  },
  button:{
    position: 'absolute',
    width: '180px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
    'WebkitBackgroundClip': 'text',
    // 'WebkitTextFillColor': 'transparent',
    backgroundClip: 'text',
    // textFillColor: 'transparent',
    alignItem:'right',
    textTransform: 'none',
    color:"#141D45" 
  },
  card:{
    //'width': '303px',
      height: 'auto',
      background: '#FFFFFF',
      //'box-shadow': '13.0709px 13.0709px 104.567px rgba(63, 39, 102, 0.1)',
      'backdrop-filter': 'blur(26.1418px)',
      'border-radius': '12px',
  },
  list:{
    width: '200px',
    height: '22px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '22px',
    color: '#00000',
    transition: 'background-color 0.3s ease', 
    cursor: 'pointer',
    padding: '10px 12px', 
    '&:hover': {
      // backgroundColor: '#f5f5f5', // Light background on hover
    },
},
selectedList:{
  width: '196px',
  height: '22px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '22px',
  color: '#424242',
  backgroundColor: '#e0e0e0',
  padding: '10px 12px',
  borderRadius: '8px', 
},
textfield:{
    boxSizing: 'border-box',
    background: '#FCFCFC',
    border: '1px  #C7C7C7',
    borderRadius: '8px'
},
inputlabel:{
    height: '20px',
    top: 'calc(50% - 20px/2 + 26px)',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '140%',
    color: ' #141D46',
    paddingBottom:'8px',

},
bottomCard:{
    maxwidth: '100%',
    height: '75px',
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(24px)',
    //left: 'calc(50% - 1440px/2)',
    bottom: '0px',
    Top:'0px',
    width:'100%'
},
cancelButton:{
    width:'180px',
    height:'48px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px 2px 20px rgba(0, 60, 118, 0.6))',
    borderRadius: '33px'
},
addButton:{
    width:'180px',
    height:'48px',
    background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
    boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
    'border-radius': '33px'
},
downloadAddButton:{
  width:'180px',
  height:'48px',
  background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
  boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
  'border-radius': '33px',
  marginTop:"22px"
},
cancelButtonText:{
    width: '111px',
    height: '22px',
    'font-family': 'Open Sans',
    'font-style': 'normal',
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '140%',
    'text-align': 'center',
    'background': 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
    'WebkitBackgroundClip': 'text',
    'WebkitTextFillColor': 'transparent',
    'background-clip': 'text',
    'text-fill-color': 'transparent'
},
addButtonText:{
    width: '111px',
    height: '22px',
    left: 'calc(50% - 311px/2 - 0.5px)',
    top: 'calc(50% - 22px/2)',
    'font-family': 'Open Sans',
    'font-style': 'normal', 
    'font-weight': 700,
    'font-size': '16px',
    'line-height': '140%',
    'text-align': 'center',
    'color': '#FFFFFF'
},
downloadButtonText:{
  width: '170px',
  height: '22px',
  left: 'calc(50% - 311px/2 - 0.5px)',
  top: 'calc(50% - 22px/2)',
  'font-family': 'Open Sans',
  'font-style': 'normal', 
  'font-weight': 700,
  'font-size': '16px',
  'line-height': '140%',
  'text-align': 'center',
  'color': '#FFFFFF'
},
dialog: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#424242',
  },
  dialogFocus: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#141D46',
  },

  widgetManagerViewCategoryContainer: {

    ...defaultFont,
    justifyContent: "space-around",
   // border: "1px solid black",

    width: "150px",
    borderRadius: "30px",
    background: "white",
  },
  dropDownClassWidgetCategory: {
    cursor: "pointer",
  },

}

export default userStyle;