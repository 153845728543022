import { call, put, takeLatest } from "@redux-saga/core/effects";
import { handleDeleteUserApi } from "screens/Users/apis";
import { createDictSuccessfully, getDictSuccessfully, publishDictSuccessfully, resetDictAction,editDictRespone ,deleteDictRespone} from "../actions";
import { CREATE_DICT_API, GET_DICT_API, PUBLISH_DICT_API, BULK_UPLOAD_ACTION,
  EDIT_DICT_ACTION,
  DELETE_DICT_ACTION,
  DELETE_DICT_RESPONSE,
  EDIT_DICT_RESPONSE

   } from "../actionTypes";

const { getDictApi, publishDictApi, createDictApi ,bulkUploadApiCall,editDictApiCall,deleteDictApiCall,bulkUploadTemplateApiCall} = require("../apis");

function* getDictSaga({ payload }) {
  console.log("Payloads",payload)
  const dicts = yield call(getDictApi, payload);
  yield put(getDictSuccessfully(dicts));
}

function* createDictSaga({ payload }) {
  console.log("Perhaps til here we arrived",payload);
  const dicts = yield call(createDictApi, payload.p_id, payload.data);
  yield put(createDictSuccessfully(dicts));
  // const dictStatus = yield call(publishDictApi, payload.p_id, payload.status);
  // if (dictStatus?.hzStatus === 200) {
  //   yield put(publishDictSuccessfully(payload.status));
  //   yield put(resetDictAction(true));
  // }
}

function* publishDictSaga({ payload }) {
  const dictStatus = yield call(publishDictApi, payload.p_id, payload.status,payload.m_id,payload.data);
  yield put(publishDictSuccessfully(payload.status));
  yield put(resetDictAction(true));
}

function* handleEditDictAction({payload}){
  console.log("Edit sga ",payload)
  const dictEditResponse=yield call(editDictApiCall,payload.p_id,payload.m_id,payload.data)
  yield put(editDictRespone(dictEditResponse))
}

function* handleDeleteDictAction({payload}){
const deleteDictResponse =yield call(deleteDictApiCall,payload.p_id,payload.m_id)
yield put(deleteDictRespone(deleteDictResponse))
}


function* handleBulkUploadSaga({payload}){
  const bulkUploadResposne=yield call(bulkUploadApiCall,payload)
  if(bulkUploadResposne.status===200){
    yield call(bulkUploadTemplateApiCall,payload.p_id)
  }
}

export default function* watchDictSaga() {
  yield takeLatest(GET_DICT_API, getDictSaga);
  yield takeLatest(CREATE_DICT_API, createDictSaga);
  yield takeLatest(PUBLISH_DICT_API, publishDictSaga);
  yield takeLatest( BULK_UPLOAD_ACTION,handleBulkUploadSaga)
  yield takeLatest(EDIT_DICT_ACTION,handleEditDictAction)
  yield takeLatest(DELETE_DICT_ACTION,handleDeleteDictAction)

}
