import {
    Box,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
    Select,
    MenuItem,
    Chip,
    Card,
    Input,
  } from "@material-ui/core";
  import Switch from '@mui/material/Switch';
  import React from "react";
  import { connect } from "react-redux";
  

  import Style from "../../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
  

  
  const useStyle = makeStyles(Style);

  
  const ThirdpartyConfig = (props)=>{
    const classes = useStyle();
    const thirdpartyConfig = props?.thirdpartyConfig || null

    return (
      <Box>
        <Card className={classes.EditCard}>
          <Box paddingLeft="24px" paddingTop="24px" paddingBottom="24px">
            <Box  pb={3} paddingRight="33px">
              <Box gridGap={"50px"} component="form" role="form">
             <div style={{ cursor:"not-allowed"}}>
             <Typography>Third Party Config</Typography>
             <div style={{display:"grid",width:"50%",marginTop:"10px" , marginBottom:"10px"}}>
             <Box margin={"3px"} width={"100%"}  paddingBottom="10px">
                  <Typography className={classes.inputLabel}>Player</Typography>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                
                    disabled
                    variant="outlined"
                    size="small"
                    style={{ width: "300px",cursor:"not-allowed"}}
                    value={thirdpartyConfig?.player || ""}
                    name='player'
                    id='player'
                  />
  
                </Box>
                <Box margin={"3px"} width={"100%"}  paddingBottom="10px">
                  <Typography className={classes.inputLabel}>Payment Gateway</Typography>
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    disabled
                    variant="outlined"
                    size="small"
                    style={{ width: "300px",cursor:"not-allowed"}}
                    value={thirdpartyConfig?.paymentGateway?.name || "Not Enabled"}
                    name='player'
                    id='player'
                  />
  
                </Box>
             </div>
             </div>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };
  
  const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ThirdpartyConfig);
  