import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        // backgroundColor: '#d8e5ff',
        padding: { xs: '20px', md: '50px 20px' },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '900px',
          // backgroundColor: '#d8e5ff',
          padding: { xs: '20px', md: '40px' },
          borderRadius: '10px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          lineHeight: 1.8,
          color: '#333',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.5em', md: '1.75em' },
            color: '#002b5c',
            textAlign: 'center',
            marginBottom: '40px',
            fontWeight: 'bold',
          }}
        >
          Data Protection Policy and Privacy Policy
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          <strong>Effective date:</strong> 3 Sep 2024
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Introduction
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          This Data Protection Policy and Privacy Policy ("Policy") outlines how we, Horizon Broadband Private Limited, collect, use, disclose, and protect personal information. We are committed to ensuring the privacy and security of your personal data in compliance with the relevant data protection laws and regulations in both countries.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Definitions
        </Typography>

        <List sx={{ marginBottom: '20px', paddingLeft: '0' }}>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Personal Information</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                Refers to any information that can directly or indirectly identify an individual, such as name, address, email, phone number, etc.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Horizon</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                Refers to our organization.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Data Controller</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                Means the entity responsible for determining the purposes and means of processing personal information.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Data Processor</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                Means any party that processes personal information on behalf of the Data Controller.
              </span>
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Data Collection and Use
        </Typography>

        <List sx={{ marginBottom: '20px', paddingLeft: '0' }}>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Consent:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We will obtain explicit consent from individuals before collecting their personal information. By using our services, you consent to the collection, storage, and use of your personal information as outlined in this Policy.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Purpose Limitation:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We will collect and process personal information only for specific, explicit, and legitimate purposes. Personal information will not be further processed in a manner incompatible with these purposes.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Data Minimization:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We will only collect personal information that is relevant, adequate, and limited to what is necessary for the intended purposes.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Information Security:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We implement reasonable technical and organizational measures to protect personal information from unauthorized access, disclosure, alteration, and destruction.
              </span>
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Data Disclosure and Sharing
        </Typography>

        <List sx={{ marginBottom: '20px', paddingLeft: '0' }}>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Third-party Service Providers:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We may share personal information with trusted third-party service providers who assist us in delivering our services. These providers are required to maintain the confidentiality and security of the information and are prohibited from using it for any other purpose.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Legal Obligations:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                We may disclose personal information if required by law or in response to valid legal requests from competent authorities.
              </span>
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Data Subject Rights
        </Typography>

        <List sx={{ marginBottom: '20px', paddingLeft: '0' }}>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Access and Correction:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                You have the right to access your personal information and request correction of any inaccurate or incomplete data.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Data Portability:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                You have the right to receive your personal information in a structured, commonly used, and machine-readable format, and to transmit it to another data controller.
              </span>
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '15px', paddingLeft: '0', fontSize: '1.1em', color: '#555' }}>
            <Typography component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Right to Object:</strong>
              <span style={{ display: 'block', marginTop: '5px' }}>
                You have the right to object to the processing of your personal information for certain purposes, including direct marketing.
              </span>
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.5em', md: '1.5em' },
            color: '#003366',
            marginBottom: '20px',
            fontWeight: '600',
          }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          If you have any questions or concerns about this Policy or our data practices, please contact us at:
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          Email: <a href="mailto:vivre.cs@horizonind.org">vivre.cs@horizonind.org</a>
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          Address: Horizon Broadband Private Limited, 123 Tech Park, Innovation City, Country.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
