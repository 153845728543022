import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import SubHead from "components/Typography/SubHead";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { calculateImageRatio } from "../../../../utils/imageRatioCalculate";

import React, { useEffect, useState } from "react";
import { Edit, Image as ImageIcon } from "@material-ui/icons";

import style from "../../../../assets/jss/material-dashboard-react/views/screenStyle";

import AddIcon from "../../../../assets/img/icons/svg/Ic_add.svg";
import Expand_More from "../../../../assets/img/icons/svg/Ic_expand_more.svg";
import Athena_Logo from "../../../../assets/img/icons/Athena-Logo.svg";
import { SearchSharp } from "@material-ui/icons";
import { connect } from "react-redux";
import { updateWidgetImageTypeAction } from "screens/PlatformManager/actions/PlatformAction";
import {editWidgetAction} from "../../../WidgetManager/actions"
import { closeSnackBarFromPVS } from "screens/WidgetManager/actions";
import { useHistory } from "react-router-dom";
const editAiWidgetCategory = [
  {
    category: "genre",
    biaspercentage: "Genre_BiasPercentage",
  },
  { category: "subgenre", biaspercentage: "Subgenre_BiasPercentage" },
  { category: "actor", biaspercentage: "Actor_BiasPercentage" },
  { category: "director", biaspercentage: "Director_BiasPercentage" },
  { category: "title", biaspercentage: "Title_BiasPercentage" },
  { category: "language", biaspercentage: "Language_BiasPercentage" },
];
const useStyle = makeStyles(style);

const Widgets = (props) => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState(-1);
  const history = useHistory();

  const customWidgets = props.widgets?.widgets;
  const defaultWidgets = props.widgets?.defaultWidgets;
  const aiWidgets = props.widgets?.aiWidgets;
  const gerneMapping = {
    "Movie": "766",
    "Episode": "767",
    "Series": "768",
}

  const [widgets, setWidgets] = useState(customWidgets);
  const [updateWidgetDetails, setUpdateWidgetDetails] = useState();
  const [day,setDay]=React.useState()
  const [trendingLiveError,setTrendingLiveError]=React.useState(false)
  console.log("🚀 ~ Widgets ~ day:", day)

  //for AI widget Edit
  const [typeSelected, setTypeSelected] = React.useState(0);
  const [type, setType] = React.useState("Hero");
  const [imageRatio, setImageRatio] = React.useState();
  const [bannerSelected, setBannerSelected] = React.useState();

  const [title,setTitle] = React.useState();


  const [imageType, setImageType] = React.useState({
    imageType: "",
  });

  const [widgetCommonDetails, setWidgetCommonDetails] = React.useState({
    name: "",
    description: "",
    playlistId: "",
    playlistName:"",
    enableSchedule: false,
    startDate: undefined,
    endDate: undefined,
    platforms: [],
  });


  const [parameters, setParameters] = React.useState({
    layout: "HRO",
    type: "AST",
    p_id: props?.projectDetails?.p_id,
  });

  const [genre, setGenre] = React.useState();
  const [subGenre, setSubGenre] = React.useState();
  const [actor, setActor] = React.useState();
  const [director, setDirector] = React.useState();
  const [titlesEd, setTitleEd] = React.useState();
  const [language, setLanguage] = React.useState();
  const [changeButton, setButtonChanged] = React.useState(false);
  const [biass,setBiass]=React.useState([])


  const changeCategory = (e, category) => {
    if (category == "genre") {
      setGenre({
        genre: e.target.value,
      });
    } else if (category == "subgenre") {
      setSubGenre({
        sungenre: e.target.value,
      });
    } else if (category == "actor") {
      setActor({
        actor: e.target.value,
      });
    } else if (category == "director") {
      setDirector({
        director: e.target.value,
      });
    } else if (category == "title") {
      setTitleEd({ titlesEd: e.target.value });
    } else if (category == "language") {
      setLanguage({ language: e.target.value });
    }

    if (e.target.value) {
      //  setButtonChanged(true)
    } else {
    }
  };

  const changeBiasPercentage = (e, biaspercentage) => {
    console.log("KFjsnfj", biaspercentage);
    if (biaspercentage === "genre") {
      setGenre({
        ...genre,
        biaspercentage: e.target.value,
      });
    } else if (biaspercentage == "subgenre") {
      setSubGenre({
        ...subGenre,
        biaspercentage: e.target.value,
      });
    } else if (biaspercentage == "actor") {
      setActor({ ...actor, biaspercentage: e.target.value });
    } else if (biaspercentage == "director") {
      setDirector({ ...director, biaspercentage: e.target.value });
    } else if (biaspercentage == "title") {
      setTitleEd({ ...titlesEd, biaspercentage: e.target.value });
    } else if (biaspercentage == "language") {
      setLanguage({ ...language, biaspercentage: e.target.value });
    }
  };

  const handleClick = (x, i) => {
   // setUpdateWidgetDetails(x);
    //setExpanded(expanded === i ? -1 : i);
    props.closeSnackBarFromPVS()
    console.log("Widget in th epvs",props,x);
    history.push({
      pathname: "/admin/widgetmanager/edit",
      state: { widgetDetails: x,
          prevPage: "PlatformVis" },
    })
    localStorage.setItem("tabValue","1")
  };
  console.log("Widget with prodecyId",props);

  const expandWidget=(widgetInformation, i)=>{
   // console.log("Widget in the Widget",x);
   setTrendingLiveError(false)
 setUpdateWidgetDetails(widgetInformation);
    setExpanded(expanded === i ? -1 : i);

     setTitle(widgetInformation?.item?.title);

    setImageRatio(widgetInformation?.kalturaOTTImageType)
    if(widgetInformation?.item?.filter){
     setDay(widgetInformation?.item?.filter)
    }
  }


  const detailsChange=(event,value,names)=>{
    console.log("Event and value",event.target.name,event.target.value, value,names);

//setDay(event.target.value)
setDay({days:parseInt(value,10)})


}
const TrendingLivedetailsChange=(event,value,name)=>{
  console.log(event,value,name)
if(name==="days" && value){
  setDay(filter=>({...filter,days:parseInt(value,10)}))
}

else if( name==="minutes"&& value){
  setDay(filter=>({...filter,minutes:parseInt(value,10)}))
}
else if( name==="genre"&& value){
  setDay(filter=>({...filter,genre:value,subgenres:filter?.subgenres||""}))
}
else if( name==="subgenres"&& value){
  setDay(filter=>({...filter,genre:filter?.genre||"",subgenres:value}))
}
if( name==="type"&& value){
  setDay(filter=>({...filter,type:(value)}))
}

}

const handleChangeTitle = (e,widgetInformation)=>{
  setTitle(e.target.value);
}

const addCongigrationForAiWidget=(x)=>{
  if(x?.item?.playlist?.predefPlaylistType==="TRND_NW_LIVE"){
    if(day?.type==="Vod" && (!(day?.days)||!(day?.genre)||!(day?.subgenres))){
        setTrendingLiveError(true)
        return
    }
    else if(day?.type==="Live" && !(day?.minutes)){
      setTrendingLiveError(true)
      return
  }
  
  
  }
  let temArr = [genre, subGenre, director, actor, language, titlesEd];
  let biasss=[]
 
 if(x?.item?.playlist?.predefPlaylistType=="AT_MRC"){
  biasss= temArr?.filter((category, index) => {
    return (
      category !== undefined 
     
    );
  })
 }
  
  let widgetId=x._id
let params={
  layout:x.layout,
  type:x.type,
  p_id:props.projectId
}
Object.assign(x,{
  playlistId:x?.item?.playlist?.playlistId,
 platforms:x.supportedDevices,
   itemData:x.item,})
   Object.assign(x.itemData,{
    filter:biasss?.length?{bias:biasss}:day,
    title:title,
    imageRatio:imageRatio
   })
   
if (x?.itemData?.config?.params && (x.name==="Most Popular" || x.name === "Hot Picks" || x.name==="Trending Now" ||  x.name==="Trending Live" || x?.item?.playlist?.predefPlaylistType=="TRND_NW_LIVE")){
  if(x?.item?.playlist?.predefPlaylistType=="TRND_NW_LIVE"){
    if(day?.type==="Vod" && (day?.days && day?.subgenres && day?.genre)){
      Object.assign(x.itemData.config,{
        ...x.item.config,
        params:`?days=${day.days}&type=${parseInt(gerneMapping[day?.genre])}&subgenres=${day?.subgenres}`
      })
  }
  else if(day?.type==="Live" && (day?.minutes)){
    Object.assign(x.itemData.config,{
      ...x.item.config,
      params:`?minutes=${day.minutes}`
    })
}
  }
  if(x.name==="Most Popular" || x.name==="Trending Now" || x.name ==="Hot Picks")  {
    Object.assign(x.itemData.config,{
      ...x.item.config,
      params:`?days=${day.days}`
    })
  }
  else if(x.name==="Trending Live"){
      if(day?.type==="Vod" && (day?.days)){
        Object.assign(x.itemData.config,{
          ...x.item.config,
          params:`?days=${day.days}`
        })
    }
    else if(day?.type==="Live" && (day?.minutes)){
      Object.assign(x.itemData.config,{
        ...x.item.config,
        params:`?minutes=${day.minutes}`
      })
  }
    }
}

delete x.item
delete x.kalturaOTTImageType
delete x.supportedDevices
delete x.layout
delete x.type
delete x._id
//delete x.itemData.playlist

if(day || title || imageRatio||biass){
  console.log('coming here',props);
   props.editWidgetAction(widgetId,x,params,"AIWIDGET")
   setExpanded(-1);
   props.selectWidgetChip("All")
}

}

  const handleChange = (event) => {
    setExpanded(-1);

    const value = event.target.value.toLowerCase();

    if (value) {
      const filteredWidgets = [...(customWidgets ?? []), ...(defaultWidgets ?? []), ...(aiWidgets ?? [])]?.filter(
        (widget) => {
          const name = widget.name.toLowerCase();
          const id = widget._id.toLowerCase().substr(-5);

          if (
            name.startsWith(value) ||
            id.startsWith(value) ||
            name.includes(value) ||
            id.includes(value)
          ) {
            return widget;
          }
        }
      );

      filteredWidgets.sort((a, b) => {
        const aIndex = a.name.toLowerCase().indexOf(value);
        const bIndex = b.name.toLowerCase().indexOf(value);

        if (a.name.toLowerCase() === value) {
          return -1;
        } else if (b.name.toLowerCase() === value) {
          return 1;
        }

        return aIndex - bIndex;
      });

      setWidgets(filteredWidgets);
      return;
    }

    setWidgets(customWidgets);
  };

  const handleChangeType = (x, ratio) => {
    // x?.item?.imageType = ratio;
    // props.updateWidgetImageTypeAction(x);
    setImageRatio(ratio)
  };
  const handleChangeTypePR = (x) => {
    // x?.item?.imageType = "PR2";
    //  props.updateWidgetImageTypeAction(x);
    setImageRatio(ratio)
  };

  const updateAIWidgets = ()=>{

  }

  useEffect(() => {
    setWidgets(customWidgets);
  }, [props.widgets]);

  useEffect(() => {
    setExpanded(-1);

    if (props.widgetTypes.includes("Default")) {
      setWidgets(defaultWidgets);
      return;
    }

    if (props.widgetTypes.includes("Widgets")) {
      setWidgets(customWidgets);
      return;
    }

    if (props.widgetTypes.includes("AI")) {
      setWidgets(aiWidgets);
      return;
    }

    if (props.widgetTypes.includes("All")) {
      setWidgets([...(customWidgets || []), ...(defaultWidgets || []), ...(aiWidgets || [])]);
    }
  }, [props.widgetTypes]);

  return (
    <Box className={classes.widgetContainer}>
      <Box display="flex" flexDirection="row" paddingBottom="8px">
        <Box width="303px">
          <TextField
            className={classes.Widgetsearch}
            size="small"
            placeholder="Search by widget name or ID"
            variant="outlined"
            InputProps={{
              width: "inherit",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharp style={{ color: "#0192D4" }} />
                </InputAdornment>
              ),
              classes: {
                notchedOutline: classes.noborder,
                input: classes.placehold,
              },
            }}
            onChange={handleChange}
          />
        </Box>
        <Box
          paddingLeft="1rem"
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.closeSnackBarFromPVS()

            props.accessLevel !== "user"
              ? history.push({
                pathname: "/admin/widgetmanager/add",
                state: { prevPage: "PlatformVis" },
              })
              : [
                props.setSnackMessage("You do not have access to add new a widget."),
                props.setSnackSeverity("warning"),
                props.setSnackOpen(true),
              ];
          }}
        >
          <img src={AddIcon} />
          <Typography className={classes.addnew}>Add New</Typography>
        </Box>
      </Box>
      <Box className={classes.widgetBox} paddingTop="8px">
        {widgets?.map((x, i) => {
          const id = x?._id;
          const widgetId = id?.slice(-5);

          return (
            <Box
              key={id}
              draggable
              className={classes.dragWidget}
              onDragStart={(ev) => props.onDragStart(ev, x?._id)}
            >
              <Card className={classes.widgetCard}>
                <Box display="flex" flexDirection="row" m={2} justifyContent="space-between">
                  <Box>
                    <Typography className={classes.widgetName}>{x?.name}</Typography>
                    <Typography className={classes.widgetID}>ID: {widgetId}</Typography>
                  </Box>
                  {x?.item?.playlist?.type == "ATHENA"
                    ? (
                      <Box display='flex'>
                      <Box>  <img
                          src={Athena_Logo}
                          style={{ width: "34px", height: "34px", marginRight: "14px", pointerEvents: "none" }}
                        /></Box>
                          {  ( x?.item?.playlist?.type == "ATHENA" )  ? <Button
                         className={classes.screenAction}
                         style={{ minWidth: "22px" }}
                          onClick={() => {
                            expandWidget(x, i);
                          }}
                        >
                          <img src={Expand_More} />
                        </Button>:null}
                      </Box>
                    )
                    : x?.item?.playlist?.type != "K_PDF"
                    ? (
                      <Box display='flex'   >
                        <Button  className={classes.screenAction}
                         style={{ minWidth: "22px" }}  onClick={() => handleClick(x, i)}>
                       
                        <Edit
                         />
                        </Button>
                         <Button
                         className={classes.screenAction}
                         style={{ minWidth: "22px" }}
                          onClick={() => {
                            expandWidget(x, i);
                          }}
                        >
                          <img src={Expand_More} />
                        </Button>
                        
                      </Box>
                    )
                    : null}
                </Box>
                <Box>
                  <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                    <CardContent className={classes.widgetCollapse}>
                      <Box display="flex" flexDirection="column">
                        <Box>
                         { ( x?.item?.playlist?.predefPlaylistType=="TRND_NW"||x?.item?.playlist?.predefPlaylistType=='EVGN_PK' || x?.item?.playlist?.predefPlaylistType=='HP' || x?.item?.playlist?.type == 'ATHENA')?null: <><Typography className={classes.inputlabel}>Widget Type</Typography>
                          <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            disabled
                            value={x?.type}
                          />

                          <Typography className={classes.inputlabel} style={{ paddingTop: "8px" }}>
                            Layout
                          </Typography>
                          <TextField
                            className={classes.textfield}
                            fullWidth
                            variant="outlined"
                            size="small"
                            disabled
                            value={x?.layout}
                          /></>}
                          {
                      (x?.item?.playlist?.predefPlaylistType=="TRND_NW_LIVE"|| x?.item?.playlist?.predefPlaylistType=="TRND_NW"||x?.item?.playlist?.predefPlaylistType=='EVGN_PK'|| x?.item?.playlist?.predefPlaylistType=='HP' ||x?.item?.playlist?.predefPlaylistType=="AT_MRC")?  <>
                    {x?.item?.playlist?.predefPlaylistType == "TRND_NW_LIVE" 
                    && day
                                ? <div >
                                  <Typography className={classes.inputlabel} >Type</Typography>
                                  <Autocomplete

                                    options={["Live", "Vod"]}
                                    getOptionLabel={(option) => option}
                                    style={{ paddingBottom: "4px" }}
                                    renderInput={(params) => (
                                      <TextField
                                        name="Type"

                                        size="small"
                                        className={classes.editAiWidgetTextField}
                                        {...params}
                                        placeholder="Enter Type"
                                        variant="outlined"
                                        label={day?.type ? day?.type : ""}
                                      />
                                    )}


                                    onChange={(event, value) => TrendingLivedetailsChange(event, value, "type")}
                                  />

                                  {
                                    day?.type === "Vod" &&
                                    <>
                                      <Typography className={classes.inputlabel} >Genre</Typography>
                                      <Autocomplete
                                        style={{ paddingBottom: "10px" }}
                                        options={Object.keys(gerneMapping)}
                                        key={"Genre"}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            name="Genre"

                                            size="small"
                                            style={{ width: "100%" }}
                                            {...params}
                                            placeholder="Enter Genre"
                                            variant="outlined"
                                            label={day?.genre ? day?.genre : ""}
                                          />
                                        )}
                                        onChange={(event, value) => TrendingLivedetailsChange(event, value, "genre")}
                                      />
                                      {!(day?.genre) && trendingLiveError && <Typography style={{ fontSize: "13px", color: "red" }} key={"trendingLiveErrorGenre"}>
                                        *** This Field is manditary
                                      </Typography>}
                                    </>
                                  }
                                  {
                                    day?.type === "Vod" &&
                                    <>
                                      <Typography className={classes.inputlabel} >Sub Gerne</Typography>
                                      <Autocomplete
                                        style={{ paddingBottom: "4px" }}
                                        options={["Drama", "Football", "Comedy", "Travel", "Reality Show", "General", "Other Sports General", "Romance", "Romance", "Wellness", "Brand", "Entertainment", "Drama Romance", "Horror", "Talk Show", "Current Affairs General", "Variety", "Action", "Special Event", "Series", "Food", "Pre-school", "Adventure", "Sports Magazine", "TV Shows (General)"]}
                                        key={"subgernes"}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            name="subgernes"

                                            size="small"
                                            style={{ width: "100%" }}
                                            {...params}
                                            placeholder="Enter sub Gernes"
                                            variant="outlined"
                                            label={day?.subgenres ? day?.subgenres : ""}
                                          />
                                        )}
                                        onChange={(event, value) => TrendingLivedetailsChange(event, value, "subgenres")}
                                      />
                                      {!(day?.subgenres) && trendingLiveError && <Typography style={{ fontSize: "13px", color: "red" }} key={"trendingLiveErrorSubGenre"}>
                                        *** This Field is manditary
                                      </Typography>}
                                    </>
                                  }

                                  {
                                    day?.type === "Vod" &&
                                    <>
                                      <Typography className={classes.inputlabel} >Configure {day?.type === "Vod" ? "Days" : "Minutes"}</Typography>
                                      <Autocomplete
                                        style={{ paddingBottom: "4px" }}
                                        options={["1", "2", "7"]}
                                        key={"Days"}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            name="Days"

                                            size="small"
                                            style={{ width: "100%" }}
                                            {...params}
                                            placeholder="Enter Days"
                                            variant="outlined"
                                            label={day?.days ? day?.days : ""}
                                          />
                                        )}
                                        onChange={(event, value) => TrendingLivedetailsChange(event, value, "days")}
                                      />
                                    </>


                                  }
                                  {
                                    day?.type === "Live" && <>
                                      <Typography className={classes.inputlabel} >Configure {day?.type === "Vod" ? "Days" : "Minutes"}</Typography>
                                      <Autocomplete

                                        options={["30", "60"]}
                                        key={"Minutes"}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            name="Minutes"

                                            size="small"
                                            style={{ width: "100%" }}
                                            {...params}
                                            placeholder="Enter Minutes"
                                            variant="outlined"
                                            label={day?.minutes ? day?.minutes : ""}
                                          />
                                        )}
                                        onChange={(event, value) => {
                                          TrendingLivedetailsChange(event, value, "minutes")
                                        }}
                                      />
                                    </>

                                  }

                                  {!((day?.type === "Live" && day?.minutes && day?.minutes > 0) || (day?.type === "Vod" && day?.days && day?.days > 0)) && trendingLiveError && <Typography style={{ fontSize: "13px", color: "red" }} key={"trendingLiveErrorMinutes"}>
                                    *** This Field is manditary
                                  </Typography>}
                                </div> : null}
                    { x?.item?.playlist?.predefPlaylistType=="AT_MRC" || x?.item?.playlist?.predefPlaylistType=="TRND_NW_LIVE"?null:  <Typography className={classes.inputlabel} style={{ paddingTop: "3px" }}>Configure</Typography>}
                    
            
                      <Box className={classes.calenderInputField} >
                                {x?.item?.playlist?.predefPlaylistType == "EVGN_PK" ? (
                                  <div>
                                    <Autocomplete
                                      options={["30", "90", "120", "365"]}
                                      getOptionLabel={(option) => option}
                                      style={{ paddingBottom: "4px",width:"250px" }}
                                      renderInput={(params) => (
                                        <TextField
                                          name="Days"
                                          size="small"
                                          className={classes.editAiWidgetTextField}
                                          {...params}
                                          placeholder="Enter Days"
                                          variant="outlined"
                                          label={day?.days ? day?.days : ""}
                                        />
                                      )}
                                      onChange={(event, value) => detailsChange(event, value, "days")}
                                    />
                                    <div>
                                      <Typography className={classes.inputlabel} >Type</Typography>
                                      <Autocomplete
                                        options={["Vod", "TVShows","Movies"]}
                                        getOptionLabel={(option) => option}
                                        style={{ paddingBottom: "4px",width:"250px" }}
                                        renderInput={(params) => (
                                          <TextField
                                            name="Type"
                                            size="small"
                                            className={classes.editAiWidgetTextField}
                                            {...params}
                                            placeholder="Enter Type"
                                            variant="outlined"
                                            label={day?.type ? day?.type : ""}
                                          />
                                        )}
                                        onChange={(event, value) => TrendingLivedetailsChange(event, value, "type")}
                                      />
                                    </div>
                                    <div>
                                      {
                                        // day?.type === "Vod" || day?.type === "TVShows" || day?.type === "Movies" &&
                                        <>
                                          {/* <Typography className={classes.inputlabel} >Configure {day?.type === "Vod" ? "Days" : "Minutes"}</Typography> */}
                                          {/* <Typography className={classes.inputlabel} >Configure Days</Typography>
                                          <Autocomplete
                                            style={{ paddingBottom: "4px" }}
                                            options={["1", "2", "7"]}
                                            key={"Days"}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                              <TextField
                                                name="Days"
                                                size="small"
                                                style={{ width: "100%" }}
                                                {...params}
                                                placeholder="Enter Days"
                                                variant="outlined"
                                                label={day?.days ? day?.days : ""}
                                              />
                                            )}
                                            onChange={(event, value) => TrendingLivedetailsChange(event, value, "days")}
                                          /> */}
                                        </>


                                      }
                                    </div>
                                    {
                                    (day?.type === "Vod" || day?.type === "TVShows" || day?.type === "Movies") &&
                                    <div>
                                      <Typography className={classes.inputlabel} >Sub Gerne</Typography>
                                      <Autocomplete
                                        style={{ paddingBottom: "4px" }}
                                        options={["Drama", "Football", "Comedy", "Travel", "Reality Show", "General", "Other Sports General", "Romance", "Romance", "Wellness", "Brand", "Entertainment", "Drama Romance", "Horror", "Talk Show", "Current Affairs General", "Variety", "Action", "Special Event", "Series", "Food", "Pre-school", "Adventure", "Sports Magazine", "TV Shows (General)"]}
                                        key={"subgernes"}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            name="subgernes"
                                            size="small"
                                            style={{ width: "100%" }}
                                            {...params}
                                            placeholder="Enter sub-genre"
                                            variant="outlined"
                                            label={day?.subgenres ? day?.subgenres : ""}
                                          />
                                        )}
                                        onChange={(event, value) => TrendingLivedetailsChange(event, value, "subgenres")}
                                      />
                                      {!(day?.subgenres) && trendingLiveError && <Typography style={{ fontSize: "13px", color: "red" }} key={"trendingLiveErrorSubGenre"}>
                                        *** This Field is manditary
                                      </Typography>}
                                    </div>
                                  }
                                  </div>
                                ) : null}
                                 {x?.item?.playlist?.predefPlaylistType == "HP" ? (
                                  <div>
                                    <Autocomplete
                                      options={["30", "90", "120", "365"]}
                                      getOptionLabel={(option) => option}
                                      style={{ paddingBottom: "4px",width:"250px" }}
                                      renderInput={(params) => (
                                        <TextField
                                          name="Days"
                                          size="small"
                                          className={classes.editAiWidgetTextField}
                                          {...params}
                                          placeholder="Enter Days"
                                          variant="outlined"
                                          label={day?.days ? day?.days : ""}
                                        />
                                      )}
                                      onChange={(event, value) => detailsChange(event, value, "days")}
                                    />
                                  
                                   
                                  </div>
                                ) : null}
                       
                    {x?.item?.playlist?.predefPlaylistType == "TRND_NW" ?(
                         <Autocomplete
                          options={["1","2","7"]}
                          getOptionLabel={(option) => option}
                         
                          renderInput={(params) => (
                            <TextField
                            name="Days"
            
                              size="small"
                              className={classes.editAiWidgetTextField}
                              {...params}
                              placeholder="Enter Days"
                              variant="outlined"
                             label={day?.days ? day?.days : ""}
                            />
                          )}
                         onChange={(event,value)=>detailsChange(event,value,"Day")}
                        />):null}
                        {x?.item?.playlist?.predefPlaylistType=="AT_MRC"?(
                   <div className={classes.editAIWidgetMainCintainer}>
                   <Box className={classes.editAIWidgetTextfieldContainer}>
                     <SubHead>Configure EditorialBias AI Widget</SubHead>
                   </Box>
                   {editAiWidgetCategory.map((value, index) => (
                     <Box className={classes.editAIWidgetTextfieldContainer}>
                       <TextField
                         className={classes.editAiWidgetTextField}
                        // name={value.category}
                         placeholder={`Enter the ${value.category}`}
                         variant="outlined"
                         // value={widgetCommonDetails.name}
                         required
                         size="small"
                         // className={classes.textField}
                         labelWidth={0}
                        onChange={(event) => changeCategory(event, value.category)}
                       />
                       <TextField
                        // name={value.category}
                         className={classes.editAiWidgetTextField}
                         placeholder={`Enter the ${value.biaspercentage}`}
                         variant="outlined"
                         // value={widgetCommonDetails.name}
                         required
                         size="small"
                         // className={classes.textField}
                         labelWidth={0}
                         onChange={(event) =>
                          changeBiasPercentage(event, value.category)
                        }
                       />
                     </Box>
                   ))}
               {  /*  <Box className={classes.editWidgetAiButtonContainer}>
                     <Button
                       className={classes.editWidgetAiButton}
                      // onClick={()=>props.continueToAddai(genre,subGenre,director,actor,language,title)}
                     >
               {//changeButton ? "Continue" : "Continue Without adding bias"
               }
                     </Button>
              </Box> */}
                 </div>
                        ):null}
                        {/* <TextField
                                      name="Day"
            
                          className={classes.editAiWidgetTextField}
                          variant="outlined"
                          placeholder="Enter Day"
                          // value={widgetCommonDetails.name}
                          required
                          size="Normal"
                          // className={classes.textField}
                          labelWidth={0}
                          onChange={detailsChange}
            
                        ></TextField> */}
                   
                      {/* <Box className={classes.editWidgetAiButtonContainer}>
                        <Box
                          className={classes.editWidgetAiButton}
                        onClick={()=>addCongigrationForAiWidget(x)}
                        >
                      <img src={AddIcon}/>  
                        </Box>
                      </Box>   */}
                       </Box>
                    </>:null

                          }
                          {x?.layout === "HRO" || x?.type === "ADS"||x?.item?.playlist?.predefPlaylistType=="TRND_NW"||x?.item?.playlist?.predefPlaylistType=='EVGN_PK' || x?.item?.playlist?.type === 'ATHENA' || x?.item?.playlist?.predefPlaylistType=='HP' ? null : (
                            <>
                              <Typography className={classes.inputlabel} style={{ paddingTop: "8px" }}>
                                Banner Layouts
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="row"
                                style={{ gap: "10px", width: "99%", overflowX: "auto" }}
                              >
                                {props?.aspectRatio?.map((ratio, index) => {
                                  const projectRatio = ratio?.trim().replace(/\D/g, ":");
                                  const kalturaRatio = x?.kalturaOTTImageType?.trim().replace(/\D/g, ":");
                                  const ratioArray = projectRatio?.trim().split(/\D/g).map((num) => Number(num));
                                  const calImageRatio = ratioArray[0] / ratioArray[1];
                                  if(kalturaRatio===projectRatio){
                                  return calImageRatio > 1
                                    ? (
                                      <Box key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          style={{
                                            width: "108px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          // onClick={() => {
                                          //   if (props.widgetDetails.layout !== "HRO") {
                                          //     handleChangeType(props.widgetDetails, ratio);
                                          //   }
                                          // }}
                                        >
                                          {ratio}
                                        </Card>
                                      </Box>
                                    )
                                    : calImageRatio < 1
                                    ? (
                                      <Box key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          // onClick={() => {
                                          //   if (props.widgetDetails.layout !== "HRO") {
                                          //     handleChangeType(props.widgetDetails, ratio);
                                          //   }
                                          // }}
                                          style={{
                                            width: "40px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {ratio}
                                        </Card>
                                      </Box>
                                    )
                                    : (
                                      <Box paddingLeft="10px" key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          // onClick={() => {
                                          //   if (props.widgetDetails.layout !== "HRO") {
                                          //     handleChangeType(props.widgetDetails, ratio);
                                          //   }
                                          // }}
                                          style={{
                                            width: "60px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          1:1
                                        </Card>
                                      </Box>
                                    )
                                   }
                                  })}
                              </Box>
                            </>
                          )}
                          {
                            x?.item?.playlist?.type === 'ATHENA' ? 
                            (
                              <Box>
                                   <Typography className={classes.inputlabel} style={{ paddingTop: "8px" }}>
                                    widget Title
                                  </Typography>
                                  <TextField
                                    className={classes.textfield}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={title}
                                    onChange={(e)=>{handleChangeTitle(e,x)}}
                                  />
                                </Box>
                            )
                            : null
                          }
                          
                          {(x?.item?.playlist?.type == 'ATHENA' && x?.item?.playlist?.predefPlaylistType!=='HP')?  (
                            <Box>
                              <Typography className={classes.inputlabel} style={{ paddingTop: "8px" }}>
                                Banner Layouts
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="row"
                                style={{ gap: "10px", width: "99%", overflowX: "auto" }}
                              >
                                {props?.aspectRatio?.map((ratio, index) => {
                                  const projectRatio = ratio?.trim().replace(/\D/g, ":");
                                  const kalturaRatio = imageRatio?.trim().replace(/\D/g, ":") ;
                                  const ratioArray = projectRatio?.trim().split(/\D/g).map((num) => Number(num));
                                  const calImageRatio = ratioArray[0] / ratioArray[1];
                                  //if(kalturaRatio===projectRatio){
                                  return calImageRatio > 1
                                    ? (
                                      <Box key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          style={{
                                            width: "108px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => {
                                            //if (props.widgetDetails.layout !== "HRO") {
                                              handleChangeType(props.widgetDetails, ratio);
                                            //}
                                          }}
                                        >
                                          {ratio}
                                        </Card>
                                      </Box>
                                    )
                                    : calImageRatio < 1
                                    ? (
                                      <Box key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          onClick={() => {
                                            //if (props.widgetDetails.layout !== "HRO") {
                                              handleChangeType(props.widgetDetails, ratio);
                                            //}
                                          }}
                                          style={{
                                            width: "40px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {ratio}
                                        </Card>
                                      </Box>
                                    )
                                    : (
                                      <Box paddingLeft="10px" key={index}>
                                        <Card
                                          className={projectRatio === kalturaRatio
                                            ? classes.selectImage
                                            : classes.imageType}
                                          onClick={() => {
                                            //if (props.widgetDetails.layout !== "HRO") {
                                              handleChangeType(props.widgetDetails, ratio);
                                            //}
                                          }}
                                          style={{
                                            width: "60px",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          1:1
                                        </Card>
                                      </Box>
                                    ) 
                                 // }
                                  })}
                              </Box>
                            </Box>
                          )
                        : null 
                        }
                        {
                          x?.item?.playlist?.type == 'ATHENA' ? 
                          (
                            <Box>
                            <Box display="flex" justifyContent='end' paddingTop='15px' >
                                          <Button variant="contained" className={classes.saveButton} onClick={()=>addCongigrationForAiWidget(x)} >
                                            <Typography className={classes.ButtonText}>Save</Typography>
                                          </Button>
                                        </Box>
                              </Box>
                          )
                          : null
                        }
                        </Box>
                      </Box>
                    </CardContent>
                  </Collapse>
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    widgets: State.screenVisualizationReducer.widgets,
    widgetDetails: State.screenVisualizationReducer.widgetDetails,
    widgetReset: State.screenVisualizationReducer.widgetReset,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
    projectAspectRatio: State.PlayListReducer.projectAspectRatio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateWidgetImageTypeAction: (widgetDetails) => {
      dispatch(updateWidgetImageTypeAction(widgetDetails));
    },
    editWidgetAction: (id,body, parameters, prevScreen) => dispatch(editWidgetAction(id,body, parameters, prevScreen)),

    closeSnackBarFromPVS:()=>{
      dispatch(closeSnackBarFromPVS())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
