import React, { useCallback, useMemo } from "react";
import { DndContext, useSensor, useSensors, PointerSensor, KeyboardSensor, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";

const SortableWidgetList = ({
  items,
  onChange,
  renderItem,
  expanded,
  setExpanded,
  accessLevel,
  setSnackOpen,
  setSnackMessage,
  setUpdateWidgets,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const itemIds = useMemo(() => items?.map((item) => item._id), [items]);

  const removeItem = useCallback((id) => {
    onChange((items) => items.filter((item) => item._id !== id));
    setUpdateWidgets(true);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={() => setExpanded(-1)}
      onDragEnd={({ active, over }) => {
        if (accessLevel !== "user") {
          if (over && active.id !== over.id) {
            onChange((items) => {
              const activeIndex = items.findIndex((item) => item._id === active.id);
              const overIndex = items.findIndex((item) => item._id === over.id);
              setUpdateWidgets(true);
              return arrayMove(items, activeIndex, overIndex);
            });
          }
        } else {
          setSnackMessage("You do not have access to reorder widgets.");
          setSnackOpen(true);
        }
      }}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {itemIds?.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem(item, index, items, expanded, setExpanded, removeItem)}
          </React.Fragment>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableWidgetList;
