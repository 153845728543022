import React, { useEffect, useState } from "react";
import { Card, Typography, Button, TextField, Box, Grid } from "@material-ui/core";

const EditSubscriptionConfig = ({ jsonData, setJsonData }) => {
  const [localJsonData, setLocalJsonData] = useState(jsonData); // Local state for rendering
  const [editState, setEditState] = useState({}); // Track which fields are being edited

  useEffect(() => {
    setLocalJsonData(jsonData);
  }, [jsonData]);


  const handleInputChange = (keyPath, value) => {
    const updatedJson = { ...localJsonData };
    let updatedValue = value;


    if (["prompt_show_count", "prompt_interval_days", "config_version"].includes(keyPath.at(-1))) {
      updatedValue = value === "" ? "" : parseInt(value, 10) || 0;
    }

    keyPath.reduce((acc, key, index) => {
      if (index === keyPath.length - 1) acc[key] = updatedValue;
      return acc[key];
    }, updatedJson);

    setLocalJsonData(updatedJson);
    setJsonData(updatedJson); 
  };

  const toggleEdit = (keyPath, isEditing) => {
    setEditState((prev) => ({
      ...prev,
      [keyPath.join(".")]: isEditing,
    }));
  };

  const renderField = (value, keyPath = []) => {
    if (Array.isArray(value)) {
      return (
        <Box sx={{ pl: 2 }}>
          <Typography component="span">[</Typography>
          {value.map((item, index) => (
            <Grid key={index}>
              <Typography component="span" sx={{ pl: 2 }}>
                {renderField(item, [...keyPath, index])}
                {index < value.length - 1 && ","}
              </Typography>
            </Grid>
          ))}
          <Typography component="span">]</Typography>
        </Box>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <Box sx={{ pl: 2 }}>
          <Typography component="span">{"{"}</Typography>
          {Object.entries(value).map(([key, val], index) => (
            <Grid key={key}>
              <Typography component="span" sx={{ pl: 2 }}>
                <strong>{key}:</strong> {renderField(val, [...keyPath, key])}
                {index < Object.entries(value).length - 1 && ","}
              </Typography>
            </Grid>
          ))}
          <Typography component="span">{"}"}</Typography>
        </Box>
      );
    } else {
      const isEditing = editState[keyPath.join(".")];

      return (
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
          {isEditing ? (
            <TextField
              variant="outlined"
              size="small"
              value={value}
              onChange={(e) => handleInputChange(keyPath, e.target.value)}
              style={{ marginRight: "8px", flex: 1 }}
            />
          ) : (
            <Typography style={{ flex: 1 }}>{String(value)}</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => toggleEdit(keyPath, !isEditing)}
            size="small"
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Subscription Config
      </Typography>
      <Card style={{ maxHeight: "80vh", overflowY: "auto", padding: "16px", background: "rgba(255, 255, 255, 0.6)" }}>
        {renderField(localJsonData)}
      </Card>
    </Box>
  );
};

export default EditSubscriptionConfig;
