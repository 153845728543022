import { Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

export default function CustomSnackbar(props) {
  const { open, severity, border, message, setOpen } = props;

  const bgColor = {
    success:
      "linear-gradient(135deg, hsla(136, 57%, 69%, 1) 0%, hsla(205, 69%, 51%, 1) 100%)",
    warning:
      "linear-gradient(315deg, hsla(259, 100%, 73%, 1) 0%, hsla(206, 100%, 69%, 1) 100%)",
    error:
      "linear-gradient(315deg, hsla(0, 78%, 70%, 1) 32%, hsla(34, 80%, 55%, 1) 82%)",
  };

  const closeSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    return setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={closeSnack} style={{ }}>
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        style={{
          background: bgColor[severity],
          border: border ?? "none",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          bottom: "36px",
        }}
        onClose={closeSnack}
      >
        <Typography
          style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
            color: "#FFFFFF",
          }}
        >
          {message}
        </Typography>
      </MuiAlert>
    </Snackbar>
  );
}
