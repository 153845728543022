import {
  SUCCESSFULLY_CREATE_PROJECT,
  GET_ALL_PROJECTS_ACTION,
  CREARE_NEW_PROJECT_ACTION,
  SUCCESSFULLY_GET_ALL_PROJECTS,
  ADD_PROEJCTNAME_IN_NAVBAR,
  GET_ALL_LANGUAGE,
  SUCCESSFULLY_GET_ALL_LANGUAGE,
  CREATE_PROJECT_SETTINGS,
  SUCCESSFULLY_CREATE_PROJECT_SETTINGS,
  UPDATE_PROJECT_DETAILS,
  SUCCESSFULLY_UPDATE_PROJECT,
  CREATE_CMS_CONFIG,
  SUCCESSFULLY_GET_CMS_CONFIG,
  GET_CMS_CONFIG,
  UPDATE_CMS_CONFIG,
  RESET_CREATE_PROJECT,
  RESET_UPDATE_PROJECT,
  UPDATE_INTROS,
  UPDATE_SPLASH,
  GET_FEATURECONFIG_ACTION,
  SUCCESSFULLY_GET_FEATURECONFIG,
  GET_VIEW_JSON_ACTION,
  SUCCESSFULLY_GET_VIEW_JSON,
  UPDATE_CONFIG_DETAILS,
  SUCCESSFULLY_UPDATE_CONFIG_DETAILS
} from "../actionTypes/projectActionsType";

export const getAllProjectAction = (details) => {
  return { type: GET_ALL_PROJECTS_ACTION, payload: details };
};

export const getAllProjectsData = (allProjects) => {
  return { type: SUCCESSFULLY_GET_ALL_PROJECTS, payload: allProjects };
};

export const createNewProjectAction = (addProject) => {
  return { type: CREARE_NEW_PROJECT_ACTION, payload: addProject };
};

export const createNewProjectData = (data) => {
  return { type: SUCCESSFULLY_CREATE_PROJECT, payload: data };
};

export const addProjectNameInNavbarAction = (projectDetails) => {
  return { type: ADD_PROEJCTNAME_IN_NAVBAR, payload: projectDetails };
};

export const getAllLanguage = ()=>{
  return{type:GET_ALL_LANGUAGE}
}

export const getAllLanguageData = (data)=>{
  return{type:SUCCESSFULLY_GET_ALL_LANGUAGE,payload:data}
}

export const createProjectsettingsAction = (configdata)=>{
  return{type:CREATE_PROJECT_SETTINGS,payload:configdata}
}

export const createProjectSettingsData = (resData)=>{
  return{type:SUCCESSFULLY_CREATE_PROJECT_SETTINGS,payload:resData}
}

export const updateProjectDetailsAction = (projectDetails)=>{
  return{type:UPDATE_PROJECT_DETAILS,payload:projectDetails}
}

export const updateProjectDetailsData = (resData)=>{
  return{type:SUCCESSFULLY_UPDATE_PROJECT,payload:resData}
}

export const createCMSConfigAction = (cmsdata)=>{
  return{type:CREATE_CMS_CONFIG,payload:cmsdata}
}

export const getCMSConfig = (resData)=>{
  return{type:GET_CMS_CONFIG,payload:resData}
} 

export const getCMSConfigsuccess = (cmsConfig)=>{
  return{type:SUCCESSFULLY_GET_CMS_CONFIG,payload:cmsConfig}
}

export const getFeatureConfigAction = (details) => {
  return { type: GET_FEATURECONFIG_ACTION, payload: details };
};

export const getFeatureConfigData = (config) => {
  return { type: SUCCESSFULLY_GET_FEATURECONFIG, payload: config };
};

export const updateCMSConfig = (cmsConfig)=>{
  return{type:UPDATE_CMS_CONFIG,payload:cmsConfig}
}

export const resetCreateProject = () => {
  return{type: RESET_CREATE_PROJECT}
}

export const resetUpdateProject = () => {
  return{type: RESET_UPDATE_PROJECT}
}

export const updateIntros = (payload) => {
  return {type: UPDATE_INTROS,payload}
}

export const updateSplashScreens = (payload) => {
  return {type: UPDATE_SPLASH,payload}
}

// subscription management

export const getViewJsonAction = (details) => {
  return { type: GET_VIEW_JSON_ACTION, payload: details };
};

export const getAllViewJsonData = (allViewJson) => {
  return { type: SUCCESSFULLY_GET_VIEW_JSON, payload: allViewJson };
};

// update subscription config

export const updateConfigDetails = (data)=>{
  return{type:UPDATE_CONFIG_DETAILS,payload:data}
}   

export const successfullyUpdatedConfigDetails = (data)=>{
  return{type:SUCCESSFULLY_UPDATE_CONFIG_DETAILS,payload:data}
}
