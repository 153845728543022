import { AspectRatio } from "@material-ui/icons";
import {
  GET_CATEGORY_TREE,
  GET_CATEGORY_ASSET_LIST,
  GET_CATEGORY_PLAYLIST,
  GET_CATEGORY_PLAYLIST_RESPONSE,
  GET_PLAYLIST_ERROR_MSG,
  CREATE_WIDGET_ACTION,
  EDIT_WIDGET_ACTION,
  GET_WIDGET_ACTION,
  GET_WIDGET_RESPONSE,
  GET_SINGLE_WIDGET_ACTION,
  GET_SINGLE_WIDGET_RESPONSE,
  SEARCH_PLAYLIST_ACTION,
  GET_PROJECT_BY_ID,
  GET_PROJECT_BY_ID_RESPONSE,
  CLOSE_SNACKBAR_FROM_PVS,
  CREATE_WIDGET_RESPONSE,GET_CATEGORY_ASSET_LIST_ERROR,EDIT_WIDGET_RESPONSE,DELETE_WIDGET_RESPONSE,DELETE_WIDGET_ACTION, GET_SEARCH_WIDGET_ACTION, SUCCESSFULLY_GET_SEARCH_WIDEGT
} from "../actionTypes";

export const getCategoryTreeResponse = (data) => {
  return {
    type: GET_CATEGORY_ASSET_LIST,
    payload: data,
  };
};

//Error for category message
export const getCategoryErrorMeesage=(errorMessage)=>{
  return{
    type:GET_CATEGORY_ASSET_LIST_ERROR,
    payload:errorMessage
  }
}

export const getCategoryTreeAction = (p_id) => {
  return {
    type: GET_CATEGORY_TREE,
    payload:p_id
  };
};

export const getCategoryPlayListByIdAction = (id,projectId) => {
  return {
    type: GET_CATEGORY_PLAYLIST,
    payload: id,
    projectId:projectId
  };
};

export const getCategoryPlayListResponse = (data) => {
  return { type: GET_CATEGORY_PLAYLIST_RESPONSE, payload: data };
};

export const playListError = (error) => {
  return {
    type: GET_PLAYLIST_ERROR_MSG,
    payload: error,
  };
};

export const getWidgetAction = (pageNumber) => {
  return {
    type: GET_WIDGET_ACTION,
    payload: pageNumber,
  };
};

export const getWidgetResponse = (data) => {
  return {
    type: GET_WIDGET_RESPONSE,
    payload: data,
  };
};

export const getSingleWidgetAction = (id,dispOrder,p_id) => {
  return {
    type: GET_SINGLE_WIDGET_ACTION,
    payload: {
      widget: id,
      dispOrder: dispOrder,
      p_id: p_id
    }
  };
};

export const getSingleWidgetResponse = (data) => {
  return {
    type: GET_SINGLE_WIDGET_RESPONSE,
    payload: data,
  };
};

export const createWidgetAction = (data, parameter,prevScreen) => {

  return {
    type: CREATE_WIDGET_ACTION,
    payload: data,
    parameters: parameter,
    prevScreen:prevScreen,
  };
};


export const editWidgetAction=(id,data,parameter,prevScreen)=>{
  console.log("Edit widget Action,data",id,data,parameter,prevScreen);
  return {
    type: EDIT_WIDGET_ACTION,
    id:id,
    payload: data,
    parameters: parameter,
    prevScreen:prevScreen,
  };
}

//Search Action
export const searchPlaylistAction = (searchText) => {
  return {
    type: SEARCH_PLAYLIST_ACTION,
    payload: searchText,
  };
};

//Pagination page Value acton

//Get PRoject Action
export const getProjectByIdAction = (projectId) => {
  return {
    type: GET_PROJECT_BY_ID,
    payload: projectId,
  };
};



//Get project Response action

export const getProjectByIdResponse=(aspectRatio)=>{
  return{
    type:GET_PROJECT_BY_ID_RESPONSE,
    payload:aspectRatio
  }
}

//Create Widget Response action

export const createWidgetResponse = (data) => {
  
  return {
    type: CREATE_WIDGET_RESPONSE,
    payload: data,
  }
}


export const editWidgetResponse=(data)=>{
  return{
    type:EDIT_WIDGET_RESPONSE,
    payload:data
  }
}

export const deleteWidgetAction=(data)=>{
  return{
    type:DELETE_WIDGET_ACTION,
    payload:data
  }
}

export const deleteWidgetResponse=(data)=>{
  return{
    type:DELETE_WIDGET_RESPONSE,
    payload:data
  }
}

export const getSearchWidget = (data)=>{
  return {
    type:GET_SEARCH_WIDGET_ACTION,
    payload:data
  }
}

export const successfullyGetSearchWidget = (res)=>{
  return{
    type:SUCCESSFULLY_GET_SEARCH_WIDEGT,
    payload:res
  }
}

export const closeSnackBarFromPVS=()=>{
  return {
    type:CLOSE_SNACKBAR_FROM_PVS
  }
}