const chipTabStyle = {
  chipContainer: {
    display: "flex",
    position: "relative",
    padding: "12px",
    zIndex: 1,
    cursor: "pointer",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: "max-content",
    flexDirection: "row",
    borderRadius: "0 50px 50px 0",
    justifyContent: "space-between",
    gap: "10px",
    right: "-44px",
    backgroundColor: "#e2daf7",
  },
  chipTitleSelected: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "650",
    fontStyle: "normal",
    lineHeight: "140%",
    textAlign: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  chipTitleUnselected: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "650",
    fontStyle: "normal",
    lineHeight: "140%",
    textAlign: "center",
    color: "#000",
  },
  chipIcon: {
    width: "24px",
    pointerEvents: "none",
  },
};

export default chipTabStyle;
