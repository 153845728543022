import React from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";



import Info from "components/Typography/Info";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import projectStyle from "assets/jss/material-dashboard-react/views/projectStyle";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";
const useStyles = makeStyles(styles);


const PlayListTable = (props) => {
  const [PlayListId, setPlayListId] = React.useState([]);
  const { imageHeight, imageWidth } = props;
  const classes = useStyles();

  const Title = ({ title, tb }) => {
    return (
     
        <div style={{ display: "flex", justifyContent: "space-around",width:'100%' }}>
        
          <Info>{title.id}</Info>
          <Info>{title.name}</Info>
          <Info>images</Info>
        </div>
      
    );
  };


  const getPlayListId = (id) => {
    setPlayListId([...PlayListId, id]);
  };
  if (props.getAssetIdBrowse) {
    return (
      <div style={{ overflow: "auto", maxHeight: "510px",}}>
        <Title title={props.tableHead} />
        <Divider />
        {props?.tableData?.map((data, index) => {
          let isValidPlaylist = false
          const ValidPlaylist = (
            <Paper style={{ margin: "10px", height: 170 }}>
              <Title title={data} tb="ASSET" />
              <div
                style={{
                  display: "flex",
                  //flex:'1',
                  marginLeft: "10px",
                  marginRight:'10px',
                  marginTop:'10px',
                
                  overflowX: "auto",
                }}
              >
                
                {data?.assets?.objects?.map((image, index) => {
                  if(image?.images?.length>0) isValidPlaylist = true
                  return image?.images?.map((img, index) =>{
                    var ratio=img?.ratio?.trim().replace(/\D/g,'x')
                    var vivImageRtio=props?.imageRatio?.trim().replace(/\D/g,'x')
  
                  return  vivImageRtio ==ratio ? (
                      <img
                      className={classes.assetImageSelect}
                        style={{
                          width: imageWidth * 1.6,
                          height: imageHeight * 1.35,
                          marginLeft: "20px",
                          borderRadius: "10px",
                      
                        }}
                        src={
                          img.url +
                          `/width/${imageWidth * 2}/height/${imageHeight * 2}`
                        }
                        onClick={() => {
                          // getPlayListId(data.id);
                          // props.setPlayListId(data.id);
                        if(props.heroWidgetData.landingPage==='AST' || props.heroWidgetData.landingPage==='PLT' ||props.heroWidgetData.landingPage==='HTM'){
                          props.setHeroAssetId({
                            ...props.heroAssetId,
                            assetId: image.id.toString(),
                            imageURL: props.heroWidgetData.imageSource === 'MNL' ? props.heroWidgetData.imageURL : img.url
                          });
                          props.setAssetIdBrowse(false);
                          props.closeModal(false);
                        }
                        else{
                          props.setHeroWidgetDataType({...props.heroWidgetData,imageURL: img.url })
                          props.setAssetIdBrowse(false);
                          props.closeModal(false);}
                        }}
                      />
                    ) : null}
                  );
                })}
              </div>
            </Paper>
          )
          return isValidPlaylist ? ValidPlaylist : null
        })}

      </div>
    );
  } else {
    return (
      <div style={{ overflow: "auto", maxHeight: "510px",  }}>
        <Title title={props.tableHead} />
        <Divider />
        {props?.tableData?.map((data, index) => {
          
          let isValidPlaylist = false
          const ValidPlaylist =  (
            <Paper
              style={{ margin: "10px", height: 180, }}
             
            >
              <div style={{display:'flex',width:'100%'}}>
                 <Radio style={{marginTop:'-7px',marginLeft:'-5px',}}  onClick={() => {
                getPlayListId(data.id);
                props.setPlayListId(data.id);
                props.setWidgetCommonDetails({
                  ...props.widgetCommonDetails,
                  playlistId: data.id.toString(),
                  playlistName:data.name.toString()
                });
                props.closeModal(false);
              }}/>
              <Title title={data} tb="TB" /></div>
              <div style={{ display: "flex", marginLeft: "35px" ,overflowX:'auto'}}>
           
                {data.assets.objects?.map((image, index) => {
                  if (index <= props.numOfImage ) {
                    if(image?.images?.length>0) isValidPlaylist = true
                    return image.images.map((img, index) =>{
                    var ratio=img.ratio?.trim().replace(/\D/g,'x')
                    var vivImageRtio=props.imageRatio?.trim().replace(/\D/g,'x')
                    return  vivImageRtio == ratio ? (
                        <img
                          style={{
                            width: imageWidth * 1.35,
                            height: imageHeight * 1.35,
                            marginLeft: "30px",
                            borderRadius: "10px",
                          }}
                          src={
                            img.url +
                            `/width/${imageWidth * 2}/height/${imageHeight * 2}`
                          }
                        />
                      ) : null
                    } );
                  }
                })}
              </div>
            </Paper>
          )
          return isValidPlaylist ? ValidPlaylist : null
        })}
      </div>
    );
  }
};

export default PlayListTable;
