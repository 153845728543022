import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";

const useStyles = makeStyles(styles);

const Pagination = (props) => {
  const classes = useStyles();

  //Change the page on page click
  function selectPage(pageNo){
    props.handlePageChange("cus",pageNo)
  }

  const pageButtons = getPageButtons(props.currpageNumber,props.totalPages)
  return pageButtons.map((value,index)=>{
    if (props.currpageNumber === value) {
      var selectedPageBox;
      selectedPageBox = classNames({
        ["  " + classes.selectedPageNum]: true,
      });
    }
    if(value==="break"){
      return <span key={value+index} style={{color:"#6f7373",minWidth:"20px",fontSize:"20px"}} aria-hidden="true">
              ...
             </span>
    }
    return (<span
      className={classNames(classes.paginationBox, selectedPageBox)}
      style={{}}
      onClick={() => selectPage(value)}
      key={value}
    >
      {value}
    </span>
    )
  })

};


const getPageButtons = (currentPage, totalPages) => {
  const pages = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
            pages.push(i)
    }
  }
  else if ((totalPages > 5 && currentPage>=5) || (totalPages <=7 && currentPage>=4) ){
    pages.push(1)
    pages.push("break")
  }
  else if(totalPages > 5 && currentPage<5 && currentPage<totalPages-3){
    let pagestoRender = 5
    if(pagestoRender<=currentPage){
      pagestoRender = currentPage + 1
    }
    for (let i = 1; i <= pagestoRender; i++) {
      pages.push(i)
}
  }
 if(totalPages >= 5 && currentPage>=5 && currentPage<totalPages-3){
 
    pages.push(currentPage-1)
    pages.push(currentPage)
    if (currentPage+1<=totalPages) pages.push(currentPage+1)
  }
  if(totalPages > 5 && currentPage>=totalPages-3){
    for (let i=totalPages-4 ; i<=totalPages; i++){
      pages.push(i)
    } 
  }
  if (totalPages > 5 && currentPage<totalPages-3){

    pages.push("break")
    pages.push(totalPages)
}

  return pages
}

export default Pagination