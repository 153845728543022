import {
  Box,
  Button,
  Grid,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Card from "components/Card/Card";
import React, { useState, useEffect } from "react";
import AddProject from "./addProject";
import { useHistory } from "react-router-dom";

import Style from "../../../assets/jss/material-dashboard-react/views/addProjectStepperStyle";
import ProjectSettings from "./projectSettings";
import { connect } from "react-redux";
import { createProjectsettingsAction, createNewProjectAction } from "../actions/projectActions";
import { getAllLanguage } from "../actions/projectActions";
import ProjectConfig from "./projectConfig";
import Dialog from "components/Dialog/Dialog";
import Robot from "assets/img/icons/svg/Robot.svg";

const useStyle = makeStyles(Style);

const step = ["Projects Details", "Project Settings", "CMS Config"];

const AddProjectstepper = (props) => {
  const [activeStep, setactiveStep] = useState(0);
  const [addProject, setAddProject] = useState({});
  const [configDetails, setConfigDetails] = useState({});
  const [ErrorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(false);
  const [image1, setImage1] = useState(false);
  const [kalturaConfig, setKalturaConfig] = useState({});
  const [createDialog, setCreateDialog] = useState(false);

  const classes = useStyle();

  const [AllDetails, setAllDetails] = useState({});

  const history = useHistory();

  useEffect(() => {
    props.getAllLanguage();
  }, []);

  useEffect(() => {
    setAllDetails({
      ...AllDetails,
      addProject: addProject,
      configDetails: configDetails,
      kalturaConfig: kalturaConfig,
    });
  }, [addProject, configDetails, kalturaConfig]);

  const handleaddProject = () => {
    if (addProject.commonName === "" || addProject.commonName === undefined) {
      setErrorMessage("mandatory field *");
    } else if (addProject.description === "" || addProject.description === undefined) {
      setErrorMessage("mandatory field *");
    } else if (
      addProject?.projectIcon === undefined ||
      addProject?.projectIcon["16x9"] === "" ||
      addProject.projectIcon["16x9"] === undefined
    ) {
      setErrorMessage("mandatory field *");
    } else if (
      addProject?.projectIcon === undefined ||
      addProject?.projectIcon["1x1"] === "" ||
      addProject.projectIcon["1x1"] === undefined
    ) {
      setErrorMessage("mandatory field*");
    } else if (addProject.platforms.length == 0) {
      setErrorMessage("mandatory field *");
    } else {
      setactiveStep((prevActiveStep) => prevActiveStep + 1);
      setErrorMessage("");
    }
  };
  const handleaddConfig = () => {
    if (addProject.aspectRatios === undefined || addProject.aspectRatios.length === 0) {
      setErrorMessage("mandatory field *");
    } 
    if (configDetails.bgOption === undefined || configDetails.bgOption === "") {
      setErrorMessage("mandatory field *");
    }
    else if (configDetails.bgOption==="bgColor" && (configDetails.bgColor === undefined || configDetails.bgColor === "") ){
      setErrorMessage("mandatory field *");
    }
    else if (configDetails.bgOption==="bgImage" && (configDetails.bgImage === undefined || configDetails.bgImage === "") ){
      setErrorMessage("mandatory field *");
    }
    else if (configDetails.bgOption==="gdColor" && (configDetails.gdColor === undefined || configDetails.gdColor === "") ){
      setErrorMessage("mandatory field *");
    }
     else if (configDetails.fgColor === undefined || configDetails.fgColor === "") {
      setErrorMessage("mandatory field *");
    } else if (configDetails.hgColor === undefined || configDetails.hgColor === "") {
      setErrorMessage("mandatory field *");
    } else if (configDetails.font === undefined || configDetails.font === "") {
      setErrorMessage("mandatory field *");
    } else if (!configDetails.enabledLanguages || configDetails.enabledLanguages.length === 0) {
      setErrorMessage("mandatory field *");
    } else if (!configDetails.tags || configDetails.tags.length === 0) {
      setErrorMessage("mandatory field *");
    } else {
      setactiveStep((prevActiveStep) => prevActiveStep + 1);
      setErrorMessage("");
    }
  };

  const handleCreateProject = () => {
    const checkUrl = kalturaConfig?.baseUrl?.split(":");
    if (!kalturaConfig.apiVersion) {
      setErrorMessage("mandatory field *");
    } else if (!kalturaConfig.partnerId) {
      setErrorMessage("mandatory field *");
    } else if (!kalturaConfig.rootCategoryId) {
      setErrorMessage("mandatory field *");
    } else if (!kalturaConfig.baseUrl && checkUrl != "https") {
      setErrorMessage("mandatory field *");
    } else if (!kalturaConfig.username) {
      setErrorMessage("mandatory field *");
    } else if (!kalturaConfig.password) {
      setErrorMessage("mandatory field *");
    }else if(!kalturaConfig.applicationName){
      setErrorMessage("mandatory field *");
    }else if(!kalturaConfig.clientVersion){
      setErrorMessage("mandatory field *");
    } else if(!kalturaConfig.platform){
      setErrorMessage("mandatory field *");
    } else if(!kalturaConfig.tag){
      setErrorMessage("mandatory field *");
    } else if(!kalturaConfig.udid){
      setErrorMessage("mandatory field *");
    } else {
      setErrorMessage("");
      props.createNewProjectAction(AllDetails);
      //props.createProjectSettingAction(configDetails)
      history.push("/admin/projects");
    }
  };

  const handleBack = () => {
    setactiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelProject = () => {
    setAddProject({});
    history.push("/admin/projects");
  };

  const openCreateDialog = () => {
    if (
      kalturaConfig.apiVersion &&
      kalturaConfig.partnerId &&
      kalturaConfig.rootCategoryId &&
      kalturaConfig.baseUrl &&
      kalturaConfig.username &&
      kalturaConfig.password &&
      kalturaConfig.applicationName &&
      kalturaConfig.clientVersion &&
      kalturaConfig.platform &&
      kalturaConfig.tag &&
      kalturaConfig.udid

    ) {
      setCreateDialog(true);
    } else {
      handleCreateProject();
    }
  };

  return (
    <Box>
      <Box paddingBottom="40px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Card>
              <Box mx={2} mt={-6} py={3} px={1}>
                <Stepper
                  nonLinear
                  className={classes.stepper}
                  connector={
                    <StepConnector
                      className={classes.stepperConnector}
                      classes={{ lineHorizontal: "2px" }}
                    />
                  }
                  activeStep={activeStep}
                >
                  {step.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              {activeStep === 0 ? (
                <AddProject
                  setAddProject={setAddProject}
                  addProject={addProject}
                  setErrorMessage={setErrorMessage}
                  ErrorMessage={ErrorMessage}
                  image={image}
                  setImage={setImage}
                  image1={image1}
                  setImage1={setImage1}
                />
              ) : (
                ""
              )}
              {activeStep === 1 ? (
                <ProjectSettings
                  setConfigDetails={setConfigDetails}
                  configDetails={configDetails}
                  ErrorMessage={ErrorMessage}
                  allLocales={props.allLanguage}
                  aspectRatios={addProject}
                  setAspectRatios={setAddProject}
                />
              ) : (
                ""
              )}
              {activeStep === 2 ? (
                <ProjectConfig
                  setConfigDetails={setKalturaConfig}
                  configDetails={kalturaConfig}
                  ErrorMessage={ErrorMessage}
                />
              ) : (
                ""
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          boxSizing: "border-box",
        }}
      >
        <Grid container>
          <Grid item sm={12} md={12} lg={12}>
            <Card className={classes.bottomCard}>
              <Box justifyContent="flex-end" display="flex" flexDirection="row" alignItems="center">
                {activeStep !== 0 ? (
                  <Box paddingLeft="7%" paddingTop="1%" paddingBottom="2%" flexGrow="15">
                    <Button className={classes.addButton} onClick={handleBack}>
                      <Typography className={classes.addButtonText}>Back</Typography>
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
                <Box paddingLeft="auto" paddingTop="1%" paddingBottom="2%">
                  <Button
                    variant="outlined"
                    onClick={cancelProject}
                    className={classes.cancelButton}
                  >
                    <Typography className={classes.cancelButtonText}>Cancel</Typography>
                  </Button>
                </Box>
                {activeStep !== 2 ? (
                  <Box paddingLeft="2%" paddingTop="1%" paddingBottom="2%" paddingRight="5%">
                    <Button
                      className={classes.addButton}
                      onClick={activeStep === 0 ? handleaddProject : handleaddConfig}
                    >
                      <Typography className={classes.addButtonText}>Next</Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box paddingLeft="2%" paddingTop="1%" paddingBottom="2%" paddingRight="5%">
                    <Button className={classes.addButton} onClick={openCreateDialog}>
                      <Typography className={classes.addButtonText}>ADD</Typography>
                    </Button>
                  </Box>
                )}
                <Dialog
                  open={createDialog}
                  title={
                    <span className={classes.dialog}>
                      Are you sure you want to create{" "}
                      <span className={classes.dialogFocus}>{addProject?.commonName}</span> project?
                    </span>
                  }
                  button1="Cancel"
                  button2="Yes"
                  button1Event={() => setCreateDialog(false)}
                  button2Event={handleCreateProject}
                  icon={Robot}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    createdData: State.ProjectReducer.createdData,
    allLanguage: State.ProjectReducer.allLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewProjectAction: (addProjects) => {
      dispatch(createNewProjectAction(addProjects));
    },
    createProjectSettingAction: (configDetails) => {
      dispatch(createProjectsettingsAction(configDetails));
    },
    getAllLanguage: () => {
      dispatch(getAllLanguage());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectstepper);
