import { Box, Button, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Switch, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import Styles from '../../../assets/jss/material-dashboard-react/views/contentExplorerStyle';

import Empty_Img from '../../../assets/img/icons/svg/Img_Empty.svg';
import { ClockLoader } from "react-spinners";
import Vector from '../../../assets/img/icons/svg/Vector.svg';
import { ExpandMore } from "@material-ui/icons";
import { clearAssetByIdAction, getAssetByIdAction } from "../actions";
import { connect } from "react-redux";

const useStyle = makeStyles(Styles)

const AssestExplorer = (props)=>{
    const classes = useStyle();


    const [expanded, setexpanded] = useState(false)
    const [OpenTag,setOpenTag] = useState(false)
    const [assetId,setAssetId] = useState();
    const [OpenImageCard,setOpenImageCard] = useState(false)
    const [OpenMediaCard,setOpenMediaCard] = useState(false)
    const [loader,setLoader] = useState(false)

    let  assetDetailsData = props?.assetDetails?.data
    const handleGetAssetDetails = ()=>{
        setLoader(true)
        props.getAssetByIdAction(assetId,props.projectDetails.p_id, ()=>{setLoader(false)})
    }
useEffect(()=>{
    props.clearAssetByIdAction()
},[])
    const handleDateConvertor = (value)=>{
        var s = new Date(value*1000).toLocaleDateString('en-IN',{ year: 'numeric', month: '2-digit', day: '2-digit' })
        return s;
    }

    const handleExpand = ()=>{
        if(expanded == false){
        setexpanded(true)
        setOpenTag(false)
        setOpenImageCard(false)
        setOpenMediaCard(false)
        }
        else{
            setexpanded(false)
        }
    }

    const handleOpenTag = ()=>{
        if(OpenTag == false){
            setexpanded(false)
            setOpenTag(true)
            setOpenImageCard(false)
            setOpenMediaCard(false)
            }
            else{
                setOpenTag(false)
            }
    }

    const handleImageCard = ()=>{
         if(OpenImageCard == false){
             setOpenImageCard(true);
             setexpanded(false);
             setOpenTag(false)
             setOpenMediaCard(false)
         }
         else{
             setOpenImageCard(false)
         }
    }
    
    const handleMediacard =()=>{
        if(OpenMediaCard == false){
            setOpenMediaCard(true);
            setOpenImageCard(false)
            setOpenTag(false)
            setexpanded(false)
        }
        else{
            setOpenMediaCard(false)
        }
    }


    const handleAssetId = (event)=>{
        setAssetId(event.target.value)
    }

    return(
        <Box>
         <Grid>
             <Grid item sm={12} md={12} lg={12}>
            <Box paddingTop='24px'>
                <Typography style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '140%',
                    color: '#00000',
                }}>
                    Asset ID
                </Typography>
            </Box>
            <Box display='flex' flexDirection='row'  paddingTop='8px' gridGap='20px'>
                <TextField className={classes.textfield}
                                fullWidth
                                variant="outlined"
                                size="small"
                                placeholder="Enter Asset ID"
                                style={{width:'700px',height:'40px'}}
                                onChange={handleAssetId}
                                ></TextField>          
                <Button className={classes.addButton} onClick={handleGetAssetDetails}>
                    <Typography className={classes.ButtonText} >Get details</Typography>
                </Button>
            </Box>
            <div>
                {
                    loader?
                    <Box      display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          height="80vh"
          textAlign="center"><ClockLoader  size={70} color="rgb(63, 81, 181)" /></Box>
                    :
                    typeof assetDetailsData !=="undefined" ?
                   <>
                   {
                    props?.assetDetails?.hzStatus === 200 && assetDetailsData?.status === true
                    ?
                    <Box paddingTop='24px'>
                    <Box>
                     <Card className={classes.card}>
                         <CardHeader title='Meta Data' classes={{title:classes.cardTitle}}
                         action={
                             <IconButton onClick={handleExpand}>
                                 <ExpandMore/>
                             </IconButton>
                         }></CardHeader>
                         <Collapse in={expanded}>
                              <CardContent>
                                 <Box  display='flex' flexWrap='wrap' gridColumnGap='150px'>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Name</Typography>
                                         <Typography className="">{assetDetailsData?.name}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Entery ID</Typography>
                                         <Typography>{assetDetailsData?.entryId}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>AssetId</Typography>
                                         <Typography>{assetDetailsData?.id}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Description</Typography>
                                         <Box width='180px' overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'>
                                             <Typography>{assetDetailsData?.description}</Typography>
                                         </Box>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Type</Typography>
                                         <Typography>{assetDetailsData?.type}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Type Description</Typography>
                                         <Typography>{props.assetDetails?.typeDescription}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Duration</Typography>
                                         <Typography>{props.assetDetails?.metas?.duration?.value}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Downloadable</Typography>
                                         <Typography>{props.assetDetails?.metas?.Downloadable?.value  ? "true" : "false"}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Adult</Typography>
                                         <Typography >{props.assetDetails?.metas?.Adult?.value ? "true" : "false"}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Star Rating</Typography>
                                         <Typography>{assetDetailsData?.metas?.StarRating?.value}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Offline viewing</Typography>
                                         <Typography>{props.assetDetails?.metas?.offlineviewing  ? "true" : "false"}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Created Date</Typography>
                                         <Typography>{handleDateConvertor(assetDetailsData?.createDate)}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>End Date</Typography>
                                         <Typography>{ handleDateConvertor(assetDetailsData?.endDate)}</Typography>
                                     </Box>
                                     {assetDetailsData?.typeDescription === "Linear" ?
                                      ( <>
                                          <Box paddingTop='12px'>
                                            <Typography className={classes.title}>Channel No</Typography>
                                            <Typography>{ assetDetailsData?.metas?.ChannelNumber?.value}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>Catalog StartDate Time</Typography>
                                            <Typography>{ handleDateConvertor(assetDetailsData?.metas?.CatalogStartDateTime?.value)}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>PlaybackEndDateTime</Typography>
                                            <Typography>{ handleDateConvertor(assetDetailsData?.metas?.PlaybackEndDateTime?.value)}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>channelType</Typography>
                                            <Typography>{ assetDetailsData?.channelType}</Typography>
                                         </Box>
                                          <Box paddingTop='12px'>
                                            <Typography className={classes.title}>External Ids</Typography>
                                            <Typography>{ assetDetailsData?.externalIds}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>External CdvrId</Typography>
                                            <Typography>{ assetDetailsData?.externalCdvrId}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>External EpgIngestId</Typography>
                                            <Typography>{ assetDetailsData?.externalEpgIngestId}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>catchUpBuffer</Typography>
                                            <Typography>{assetDetailsData?.catchUpBuffer}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>Enable CdvrState</Typography>
                                            <Typography>{assetDetailsData?.enableCdvrState}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>Enable CatchUp</Typography>
                                            <Typography>{assetDetailsData?.enableCatchUpState}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>enableStartOver</Typography>
                                            <Typography>{assetDetailsData?.enableStartOverState}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>enableTrickPlay</Typography>
                                            <Typography>{assetDetailsData?. enableTrickPlayState}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>external Id</Typography>
                                            <Box width='180px' overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'>
                                                <Typography>{assetDetailsData?.externalId}</Typography>
                                            </Box>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>CatalogStartDateTime</Typography>
                                            <Typography>{ handleDateConvertor(assetDetailsData?.metas?.CatalogStartDateTime?.value)}</Typography>
                                         </Box>
                                         <Box paddingTop='12px'>
                                            <Typography className={classes.title}>PlaybackEndDateTime</Typography>
                                            <Typography>{handleDateConvertor(assetDetailsData?.metas?.PlaybackEndDateTime?.value)}</Typography>
                                         </Box>
                                         </>
                                      )
                                      :
                                      ""
                                     }
                                 </Box>
                              </CardContent>
                         </Collapse>
                     </Card>
                     </Box>
  
                     <Box paddingTop='24px'>
                     <Card className={classes.card}>
                         <CardHeader title='Image' classes={{title:classes.cardTitle}}
                         action={
                             <IconButton onClick={handleImageCard}>
                                 <ExpandMore/>
                             </IconButton>
                         }></CardHeader>
                         <Collapse in={OpenImageCard}>
                             <CardContent>
                             <Box  display='flex' flexWrap='wrap'>
                             {assetDetailsData?.images?.map((x,index)=>{
                                 return (
                                  <Box paddingRight='14px'>
                                  <Card className={classes.ImageCard} key={index}>
                                     <Box paddingTop='16px' paddingLeft='16px'>
                                     <Typography className={classes.ImageRatio}>Image Ratio {x.ratio}</Typography>
                                     </Box>
                                     <Box paddingLeft='16px' paddingTop='16px'>
                                         <Box display='flex' flexDirection='row' gridColumnGap='60px'>
                                         <Box >
                                         <Typography className={classes.title}>URL</Typography>
                                         <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='200px' >
                                         <Typography>{x.url}</Typography>
                                         </Box>
                                         </Box>
                                         <Typography>Default</Typography>
                                         <Typography>{x.isDefault === true ? "true" : "false"}</Typography>
                                         </Box>
                                         <Box paddingTop='16px'>
                                         <img className={x.ratio === '16x9' ? classes.image :"" ||
                                                         x.ratio === '2x3'  ? classes.image_2:"" ||
                                                         x.ratio === '1x1' ? classes.image_3:""} src={`${x.url}/width/500/height/280`} ></img>
                                         </Box>
                                     </Box>
                                 </Card>
                                 </Box>
                                 )
                             })}
                             </Box>
                             </CardContent>
                         </Collapse>
                     </Card>
                     </Box>
  
                     <Box paddingTop='24px'>
                     <Card className={classes.card}>
                         <CardHeader title='Media Files' classes={{title:classes.cardTitle}}
                         action={
                             <IconButton onClick={handleMediacard}>
                                 <ExpandMore/>
                             </IconButton>
                         }></CardHeader>
                         <Collapse in={OpenMediaCard}>
                             <CardContent>
                                <Box  display='flex' flexWrap='wrap'>
                                   {assetDetailsData?.mediaFiles?.map((x,i)=>{
                                       return (
                                          <Box paddingRight='14px'>
                                           <Card className={classes.ImageCard} key={i}>
                                               <Box  paddingTop='16px' paddingLeft='16px'>
                                               <Typography className={classes.ImageRatio}>Media #{i}</Typography>
                                               </Box>
                                               <Box display='flex' flexDirection='row' paddingLeft='14px' paddingTop='24px'>
                                                   <Box>
                                                      <Typography className={classes.title}>Type</Typography>
                                                      <Typography>{x.type}</Typography>
                                                   </Box>
                                                   <Box>
                                                      <Typography className={classes.title}>Duration</Typography>
                                                      <Typography>{x.duration}</Typography>
                                                   </Box>
                                               </Box>
                                               <Box paddingTop='16px' paddingLeft='16px'>
                                                    <Typography className={classes.title}>URL</Typography>
                                                    <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='325px'>
                                                        <Typography>{x.url}</Typography>
                                                    </Box>
                                               </Box>
                                               <Box paddingTop='16px' paddingLeft='16px'>
                                                    <Typography className={classes.title}>External Id</Typography>
                                                    <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='325px'>
                                                        <Typography>{x.externalId}</Typography>
                                                    </Box>
                                               </Box>
                                           </Card>
                                           </Box>
                                       )
                                   })}
                                </Box>
                             </CardContent>
                         </Collapse>
                     </Card>
                     </Box>
  
                     
                     <Box paddingTop='24px'>
                     <Card className={classes.card}>
                         <CardHeader title='Tags' classes={{title:classes.cardTitle}}
                         action={
                             <IconButton onClick={handleOpenTag}>
                                 <ExpandMore/>
                             </IconButton>
                         }></CardHeader>
                         <Collapse in={OpenTag}>
                             <CardContent>
                             <Box  display='flex' flexWrap='wrap' gridColumnGap='150px'>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Actors</Typography>
                                         <Box width='120px'>
                                         <Typography>{assetDetailsData?.tags?.Actors?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                         </Box>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Directors</Typography>
                                         <Typography>{assetDetailsData?.tags?.Director?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Video Quality</Typography>
                                         <Typography>{assetDetailsData?.tags?.Quality?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Audio Language</Typography>
                                         <Box>
                                             <Typography>{assetDetailsData?.tags?.AudioLanguage?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                         </Box>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Subtitle Language</Typography>
                                         <Typography>{assetDetailsData?.tags?.SubtitleLanguage?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                     </Box>
                                     <Box paddingTop='12px' width='180px'>
                                         <Typography className={classes.title}>Genre</Typography>
                                         <Typography>{assetDetailsData?.tags?.Genre?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                     </Box>
                                     <Box paddingTop='12px'>
                                         <Typography className={classes.title}>Parental Rating</Typography>
                                         <Typography>{assetDetailsData?.tags?.ParentalRating?.objects?.map((x)=>{
                                             return x.value
                                         })}</Typography>
                                     </Box>
                                  </Box>
                             </CardContent>
                         </Collapse>
                     </Card>
                     </Box>
                  </Box> 
                    :
                    <Box>
                        <div style={{display:"flex" , flexDirection:"column"}}>
                        <Box display='flex' flexDirection='row' justifyContent='center' paddingTop='100px' >
                        <img src={Empty_Img}/>
                    </Box>
                        <Typography style={{textAlign:"center" , "marginTop":"10px"}}>
                            {assetDetailsData}
                        </Typography>
                        </div>

                </Box>
                   }
                   </>
                    :<></>
                }
            </div>
            </Grid>
         </Grid>
        </Box>
    )
}

//overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'

const mapStateToProps = (State)=>{
    return{
        assetDetails:State.ContentExplorerReducer.assetDetails,
        projectDetails:State.ProjectReducer.projectDetails,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getAssetByIdAction:(assetId,projectID, cb)=>{
           dispatch(getAssetByIdAction(assetId,projectID, cb))
        },
        clearAssetByIdAction:()=>{
            dispatch(clearAssetByIdAction())
         }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AssestExplorer);