import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { Box, Button, IconButton} from "@material-ui/core";
import { Cancel, Delete, Edit } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Dialog from "components/Dialog/Dialog";

//actions
import { deleteUserAction } from "screens/Users/actions";
import { connect } from "react-redux";
import Snackbar from "components/Snackbar/CustomSnackbar";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";

const useStyles = makeStyles(styles);

const  AuditTable = (props)=> {
  const [expand, setExpand] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [accessSnack, setAccessSnack] = useState(false);
  const [accessSnackMessage, setAccessSnackMessage] = useState("");
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor,accessLevel,projectDetails } = props;
  const [openDialog,setOpenDialog]= React.useState(false);
  const [user,setUser] = useState({});
  
  const history = useHistory();


  //Onclick actions
  const expandMoreOrLess = () => {
    setExpand(!expand);
  };

  const selectPlaylistId=(prop)=>{

  }

  const handleEditUser = (value)=>{
       props.accessLevel === "superAdmin"
       ? history.push({ pathname: "/admin/users/edituser" ,state:value})
       : [setAccessSnackMessage("You do not have access to edit user."), setAccessSnack(true)];
  }

  const checkRemove = (value)=>{
      setUser(value);
      props.accessLevel === "superAdmin"
    ? setOpenDialog(true)
    : [setAccessSnackMessage("You do not have access to delete a user."), setAccessSnack(true)];
      
  }

  const removeUser = (value)=>{
    // props.deleteUser(value);
    props.accessLevel === "superAdmin"
    ? props.deleteUserAction(user)
    : [setAccessSnackMessage("You do not have access to delete a user."), setAccessSnack(true)];
     setOpenDialog(false);
  }

 console.log(props,"auditlog");

  return (
    <Box>
      <Snackbar
        open={openSnackbar}
        severity={props.deleteUser?.hzStatus === 200 ? "success" : "error"}
        message={
          props.deleteUser?.hzStatus === 200
            ? createMessages.success
            : props.createUserFail?.message
        }
        setOpen={() => setOpenSnackbar(false)}
      />
      <Snackbar
        open={accessSnack}
        severity={"warning"}
        message={accessSnackMessage}
        setOpen={() => setAccessSnack(false)}
      />
    <div className={classes.userTableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
          <TableRow className={classes.tableHeadRow}>
            {tableHead.map((prop, i) => {
              return (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={i}
                >
                  {prop}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        ) : null}
         <TableBody>
          {tableData?.map((prop, i) => {
            // const nData=JSON.stringify(prop?.useraction)
            const data= prop?.useraction&&JSON.parse(prop?.useraction)
            console.log(prop,data,"lkjhgfd");
            return (
              <TableRow key={i} className={classes.tableBodyRow}>
                
                    <TableCell className={classes.tableCell + " "+ classes.tableBodyCell} >
                      {prop?.username}
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell}>
                      {props.projectDetails.name}
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} >
                      {prop?.apipath.split('/').slice(2, 5).join('/')}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell + " " + classes.tableBodyCell} >
                      {prop?.usertype}
                    </TableCell> */}
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} >
                      {
                        new Date(prop?.date).toISOString().slice(0, 16).replace("T", " ")
            
                      }
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} >
                      {data?.userAction.screenName || data?.userAction.newScreenName}           
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableBodyCell} >
                    {prop?.apipath.split('/')[2] !== 'screen' && data?.userAction?.name}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell + " " + classes.tableBodyCell} > */}
                      {/* {data?.userAction} */}
                    {/* </TableCell> */}
              </TableRow>
            );
          })}
            </TableBody>
      </Table>
    </div>
    <Dialog
          open={openDialog}
          title={
            <span className={classes.dialog}>
              Are you sure you want to remove{" "}
              {/* <span className={classes.dialogFocus}>{screenName.name}</span> screen? */}
            </span>
          }
          button1="Cancel"
          button2="Yes"
          button1Event={() => setOpenDialog(false)}
           button2Event={() => removeUser()}
           icon={deleteBucket}
          margin="8px"
          width="24px"
        />
    </Box>
  );
}

AuditTable.defaultProps = {
  tableHeaderColor: "gray",
};

AuditTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const mapStateToProps = (State)=>{
  return{

  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    deleteUserAction:(details)=>{
      dispatch(deleteUserAction(details));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AuditTable);