//import all the action tyes
import * as actionTypes from "../actionTypes";
import { GET_ALL_LINEAR_CHANNEL_RESPONSE } from "../actionTypes";

//import Intial state
import { blackoutScheduleState } from "../intialState";

//Reducer for the Schedule Blackout Action
export const BlackoutScheduleReducer = (
  state = blackoutScheduleState,
  action
) => {
 
  switch (action.type) {
   
    //Get all the blackout schedule
    //loading state changes while calling the  get all the blackout  schedule api
    case actionTypes.GET_ALL_BLACKOUT_ACTION:
      return { ...state };
    //storing get all blackout response in the state
    case actionTypes.GET_ALL_BLACKOUT_RESPONSE:
      return {
        ...state,
        linearChannelErrorMsg:''

      };

    //Get all linear channel
    // loading state changes while calling the api for get all the linear channel
    case actionTypes.GET_ALL_LINEAR_CHANNEL_ACTION:
      return {
        ...state,
        linearChannelLoading:false
      };
    //storing get all linear channel response in the state
    case GET_ALL_LINEAR_CHANNEL_RESPONSE: 
     return {
        ...state,
        linearChannels: action.payload,
        linearChannelLoading:true,
        linearChannelErrorMsg:''
      };
    

    //Create blackout schedule
    //changing loading state while creating the schedule
    case actionTypes.SCHEDULE_BLACKOUT_ACTION:
      return { ...state };
    //Saving response of the Create blackout schedule
    case actionTypes.SCHEDULE_BLACKOUT_RESPONSE:
      return { ...state };

    //Get all the epg
    //Changing loading state while getting all the EPG
    case actionTypes.GET_EPG_ACTION:
      return {
        ...state,
        epgLoading:false
      };
    //Saving response in the state of the all the EPG
    case actionTypes.GET_EPG_RESPONSE:
      return {
        ...state,
        epg:action.payload,
        epgLoading:true
      };

    //Delete the Blackout Schedule
    //changing the loading State while delete the schedule
    case actionTypes.DELETE_BLACKOUT_ACTION:
      return { ...state };
    //Saving response of the delete api call
    case actionTypes.DELETE_BLACKOUT_RESPONSE:
      return { ...state };

//All Channels Blackout response
case actionTypes.GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_RESPONSE:
  return{
    ...state,
    blackoutsForAllChannel:action.payload,
    linearChannelErrorMsg:''
    
  }

  case actionTypes.DELETE_BLACKOUT_RESPONSE:
    return{
      ...state,
      delete:!state.delete
    }


    //Error Message
    case actionTypes.GET_ALL_LINEAR_CHANNEL_ERROR_MESSAGE:
      return{
        ...state,
        linearChannelErrorMsg:action.payload
      }

    default:
      return { ...state };
  }
};
