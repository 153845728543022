export const GET_ALL_PROJECTS_ACTION = 'GET_ALL_PROJECTS_ACTION'
export const SUCCESSFULLY_GET_ALL_PROJECTS = 'SUCCESSFULLY_GET_ALL_PROJECTS'
export const CREARE_NEW_PROJECT_ACTION = 'CREARE_NEW_PROJECT_ACTION'
export const SUCCESSFULLY_CREATE_PROJECT = 'SUCCESSFULLY_CREATE_PROJECT'
export const ADD_PROEJCTNAME_IN_NAVBAR = 'ADD_PROEJCTNAME_IN_NAVBAR'
export const GET_ALL_LANGUAGE = 'GET_ALL_LANGUAGE'
export const SUCCESSFULLY_GET_ALL_LANGUAGE = 'SUCCESSFULLY_GET_ALL_LANGUAGE'
export const CREATE_PROJECT_SETTINGS = 'CREATE_PROJECT_SETTINGS'
export const SUCCESSFULLY_CREATE_PROJECT_SETTINGS = 'SUCCESSFULLY_CREATE_PROJECT_SETTINGS'
export const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS'
export const SUCCESSFULLY_UPDATE_PROJECT ='SUCCESSFULLY_UPDATE_PROJECT'
export const CREATE_CMS_CONFIG = 'CREATE_CMS_CONFIG'
export const GET_CMS_CONFIG = 'GET_CMS_CONFIG'
export const SUCCESSFULLY_GET_CMS_CONFIG = 'SUCCESSFULLY_GET_CMS_CONFIG'
export const GET_FEATURECONFIG_ACTION = 'GET_FEATURECONFIG_ACTION'
export const SUCCESSFULLY_GET_FEATURECONFIG = 'SUCCESSFULLY_GET_FEATURECONFIG'
export const UPDATE_CMS_CONFIG = 'UPDATE_CMS_CONFIG'
export const RESET_CREATE_PROJECT = 'RESET_CREATE_PROJECT'
export const RESET_UPDATE_PROJECT ='RESET_UPDATE_PROJECT'
export const UPDATE_INTROS='UPDATE_INTROS'
export const UPDATE_SPLASH='UPDATE_SPLASH'
// subscription config
export const GET_VIEW_JSON_ACTION='GET_VIEW_JSON_ACTION'
export const SUCCESSFULLY_GET_VIEW_JSON='SUCCESSFULLY_GET_VIEW_JSON'
export const UPDATE_CONFIG_DETAILS = 'UPDATE_CONFIG_DETAILS'
export const SUCCESSFULLY_UPDATE_CONFIG_DETAILS = 'SUCCESSFULLY_UPDATED_CONFIG_DETAILS'

//Clear Cache 
export const CLEAR_CACHE_ACTION="CLEAR_CACHE_ACTION"