import { call, put, takeLatest } from "redux-saga/effects";
import { deleteUserActionFailed, successfullyAddedNewUser, successfullyDeleteUserAction, successfullyGetAllUsers, successfullyUpdateUserAction, updateUserActionFailed, userActionFailed ,successfullyGetAllAudits} from "../actions";
import { ADD_NEW_USER_ACTION, DELETE_USER_ACTION, DOWNLOAD_AUDIT_LOGS_ACTION, GET_ALL_AUDIT_LOGS_ACTION, GET_ALL_USERS_ACTION, UPDATE_USER_DETAILS_ACTION } from "../actionTypes";
import { handleCreateUserApi, handleGetUsersApi,handleDeleteUserApi,handleUpdateUserApi,handleAuditLogsAPI,handleDownloadAuditApi } from "../apis";

export function* watchUsersSaga (){
    yield takeLatest(GET_ALL_USERS_ACTION,handleGetUsers)
    yield takeLatest(ADD_NEW_USER_ACTION,handleCreateUser)
    yield takeLatest(DELETE_USER_ACTION,handleDeleteUser)
    yield takeLatest(UPDATE_USER_DETAILS_ACTION,handleUpdateUserDetails)
    yield takeLatest(GET_ALL_AUDIT_LOGS_ACTION,handleAuditLogs)
    yield takeLatest(DOWNLOAD_AUDIT_LOGS_ACTION,handleDownloadAudit)
}

function* handleGetUsers (){
    const res = yield call(handleGetUsersApi)
    if(res.hzStatus === 200){
       yield put(successfullyGetAllUsers(res.data))
    }
}

function* handleCreateUser({payload}){
    const res = yield call(handleCreateUserApi,payload)
    if(res.status === 200){
        yield put(successfullyAddedNewUser(res.data))
    }
    else{
        yield put(userActionFailed(res))
    }
}

function* handleUpdateUserDetails({payload}){
    const res = yield call(handleUpdateUserApi,payload)
    if(typeof res!=="undefined"){
        if(res.status === 200){
            yield put(successfullyUpdateUserAction(res.data))
        }
        else{
            yield put(updateUserActionFailed(res))
        }
    }

}

function* handleDeleteUser({payload}){
    const res = yield call(handleDeleteUserApi,payload)
    if(res.status === 200){
        yield put(successfullyDeleteUserAction(res.data))
    }
    else{
        yield put(deleteUserActionFailed(res))
    }
}

function* handleAuditLogs({payload}){
    const res = yield call(handleAuditLogsAPI,payload)
    if(res.status === 200){
        yield put(successfullyGetAllAudits(res.data.data))
    }
}


function* handleDownloadAudit({payload}){
    const res =yield call(handleDownloadAuditApi,payload)
    if(res.status === 200){
        const link = document.createElement('a');
       // const blob = new Blob([res.data.s3Link], {type: 'text/csv'});
        //   link = res.data.s3Link
        link.href = res.data.s3Link
        link.download = 'download.csv';
        link.click();
        // this.closeModal();
    }

}