import React from "react";
import { makeStyles } from "@material-ui/core";
import { AntSwitch } from "components/Switch/AntSwitch";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
} from "@material-ui/core";
const useStyles = makeStyles(styles);

const HeroLandingPageWidget = (props) => {
  const classes = useStyles();

React.useEffect(()=>{

},[])

  //OnChange Function
  const getLandingHtmlPageTitle = (event) => {
    props.setHeroManualWidgetDetailsHTM({
      ...props.heroManualWidgetDetailsHTM,
      landingPageTitle: event.target.value,
    });
  };

  const getLandingHtmlPageLink = (event) => {
    props.setHeroManualWidgetDetailsHTM({
      ...props.heroManualWidgetDetailsHTM,
      link: event.target.value,
    });
  };

  if (props.heroWidgetData.landingPage === "PLT") {
    return (
      <>
        <Typography>PlayList*</Typography>
        <Box className={classes.validateBox}>
          <Box className={classes.playListBrowseContainer} style={{}}>
          <Box> {props.playListId ?  `${props.playListId}` + `${" "}` : "PlayList*"} {props.playlistName ? `${props.playlistName}` : ""}</Box>
            <Box
              className={classes.playlistBrowseButton}
              onClick={props.getPlayListId}
            >
              Browse
            </Box>
          </Box>
          {props.openMandatoryWarn ? (
            props.playListId ? null : (
              <span className={classes.validationText}>* Mandatory Field</span>
            )
          ) : null}
        </Box>
      </>
    );
  } else if (props.heroWidgetData?.landingPage === "AST") {
    return (
      <>
        {" "}
        <div>
          {" "}
          <Typography style={{ marginTop: "20px" }}>Asset ID</Typography>
          <Box className={classes.validateBox}>
            <Box className={classes.playListBrowseContainer}>
              <Box> {props.assetId ? props.assetId : "Asset ID *"}</Box>
              <Box
                className={classes.playlistBrowseButton}
                onClick={props.getAssetIdInPlayList}
              >
                Browse
              </Box>{" "}
            </Box>
            {props.openMandatoryWarn ? (
              props.assetId ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>{" "}
        </div>
        <span
          className={classes.widgetDetailContainer}
          style={{
            width: "350px",
          }}
        >
          {" "}
          <Typography style={{}}>PlayProgram</Typography>{" "}
          <AntSwitch key={props.heroWidgetData?.isProgram?props.heroWidgetData?.isProgram:false+"PlayProgram"} defaultChecked={props.heroWidgetData?.isProgram?props.heroWidgetData?.isProgram:false} name="checkedC" onChange={props.enablePlayProgram} />
        </span>
      </>
    );
  } else if (props.heroWidgetData?.landingPage === "HTM") {
    return (
      <>
        <div>
          <Typography style={{}}>Web Title</Typography>
          <div className={classes.widgetDetailContainer}>
            {" "}
            <Box className={classes.validateBox}>
              <TextField
              value={props.heroManualWidgetDetailsHTM.landingPageTitle}
                // disabled={!props.caresoulWidgetdataType?.autoRotate}
                placeholder="Web Title *"
                size="medium"
                variant="outlined"
                className={classes.textField}
                onChange={getLandingHtmlPageTitle}
               
              />
              {props.openMandatoryWarn ? (
                props.heroManualWidgetDetailsHTM.landingPageTitle ? null : (
                  <span className={classes.validationText}>
                    * Mandatory Field
                  </span>
                )
              ) : null}
            </Box>
          </div>
        </div>
        <div>
          <Typography style={{}}>Web Title Link</Typography>
          <div className={classes.widgetDetailContainer}>
            {" "}
            <Box className={classes.validateBox}>
              <TextField
                            value={props.heroManualWidgetDetailsHTM.link}

                // disabled={!props.caresoulWidgetdataType?.autoRotate}
                placeholder="Web Title Link *"
                size="medium"
                variant="outlined"
                className={classes.textField}
                onChange={getLandingHtmlPageLink}
               
              />
              {props.openMandatoryWarn ? (
                props.heroManualWidgetDetailsHTM.link ? null : (
                  <span className={classes.validationText}>
                    * Mandatory Field
                  </span>
                )
              ) : null}
             
            </Box>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default HeroLandingPageWidget;
