import React from "react";

import Carousel from "react-multi-carousel";
import classNames from "classnames";

import "./Carousel.scss";
import styles from "assets/jss/material-dashboard-react/components/ScreenViewStyle";

import { Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import NextIcon from "assets/img/platformScreenVisualization/NextIcon.svg";
import BackIcon from "assets/img/platformScreenVisualization/BackIcon.svg";

const useStyles = makeStyles(styles);

const AppScreenView = (props) => {

  const splashMediaType = getMediaType(props?.splashScreen || "")
  const introMediaType = getMediaType(props?.introScreens?.[0] || "")
  // alert(introMediaType + props.screenName)
  const classes = useStyles();

  const NextArrow = ({ onClick }) => {
    return (
      <div
        className={classNames(classes.Arrows, classes.nextArrowCarousel2)}
        onClick={() => onClick()}
      >
        <img src={NextIcon} />
      </div>
    );
  };

  const BackArrow = ({ onClick }) => {
    return (
      <div
        className={classNames(classes.Arrows, classes.backArrowCarousel2)}
        onClick={() => onClick()}
      >
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  };

  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li className={classes.dotList} onClick={() => onClick()}>
        <button className={active ? classes.activeDot : classes.inactiveDot} />
      </li>
    );
  };

  const responsive = {
    extraLarge: {
      breakpoint: { max: 800, min: 700 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  if (props.screenName === "Splash" ) {
    if(splashMediaType=="image"){
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "99%",
            marginTop: "2rem",
          }}
        >
          <img src={props.splashScreen} onError={props.onImgError} className={classes.introImg} onLoad={props.onLoad}/>
        </Box>
      );
    } else   if(splashMediaType=="video"){
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "99%",
            marginTop: "2rem",
          }}
        >
          <video key={props.splashScreen}  controls  autoplay onError={props.onImgError} className={classes.splashvideo} onLoad={props.onLoad}>
            <source src={props.splashScreen} />
          </video>
        </Box>
      );
    }
    
  } else if (props.screenName === "Intro" && props?.introScreens?.length>0) {
    if(introMediaType=="image"){
      return  (
        props?.introScreens?.length > 1 ? (
          <Carousel
            responsive={responsive}
            itemClass="carousel-item"
            containerClass="carousel-container"
            swipeable={true}
            keyBoardControl={true}
            showDots={true}
            autoPlaySpeed={999999}
            minimumTouchDrag={10}
            additionalTransfrom={0}
            customLeftArrow={<BackArrow />}
            customRightArrow={<NextArrow />}
            customDot={<CustomDot />}
          >
            {props?.introScreens?.map((intro, i) => {
              return (
                <Box key={i} className={classes.imgContainer}>
                  <img src={intro} className={classes.introImgs} />
                </Box>
              );
            })}
          </Carousel>
        ) : (
          <Box className={classes.imgContainer}>
            <img src={props.introScreens} className={classes.introImg} />
          </Box>
        )
      ) 
    } else   if(introMediaType=="video"){
     return <Box
      style={{
        display: "flex",
        justifyContent: "center",
        width: "99%",
        marginTop: "2rem",
      }}
    >
      <video  key={props.introScreens?.[0]}  controls  autoplay onError={props.onImgError} className={classes.splashvideo} onLoad={props.onLoad}>
        <source src={props.introScreens?.[0]} />
      </video>
    </Box>
    }

  }
  return null;
};

export function getMediaType(inputString) {

  
  const base64Pattern = /^data:(image|video)\/(png|jpg|jpeg|gif|webp|mp4|webm);base64,/;
  const base64Match = inputString?.match(base64Pattern);
  
  
  if (base64Match) {
      const mediaType = base64Match[1]; 
      return mediaType;
  }

  const urlPattern = /(\.jpg|\.jpeg|\.png|\.gif|\.webp|\.mp4|\.webm)$/i;
  const urlMatch = inputString?.match(urlPattern);
  

  if (urlMatch) {
      const extension = urlMatch[0].toLowerCase();
      if (['.jpg', '.jpeg', '.png', '.gif', '.webp'].includes(extension)) {
          return 'image';
      } else if (['.mp4', '.webm'].includes(extension)) {
          return 'video';
      }
  }

  return null;
}

export default AppScreenView;
