import { Box, FormHelperText, Grid, Input, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Styles from "../../../../assets/jss/material-dashboard-react/views/EditProjectDetailsStyle";
import axios from "axios";

import PlatformSeletor from "components/PlatformScreensView/PlatformSelector";

const useStyle = makeStyles(Styles);

const EditProjectDetails = (props) => {
  const classes = useStyle();

  const platforms = props.projectData.platforms;
  const [platformList, setPlatformList] = useState(platforms);

  useEffect(() => {
    props.setProjectData({ ...props.projectData, platforms: platformList });
  }, [platformList]);

  const handlePlatforms = (value) => {
    setPlatformList((platforms) => {
      return platforms?.includes(value) ? platforms.filter((platform) => platform !== value) : [...platforms, value];
    });
  };

  const handleSelectAllPlatforms = (allPlatforms) => {
    setPlatformList(allPlatforms)
  };

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleImage = async (event) => {
    const files = event.target.files;
    const file = files[0];
    const images = await getBase64(file);
    if (typeof images != undefined) {
      const S3image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`,
        { file: images },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      props.setProjectData({
        ...props.projectData,
        projectIcon: {
          ...props.projectData.projectIcon,
          "16x9": S3image?.data?.uploadResult?.Location,
        },
      });
      props.setImage16(true);
    } else {
    }
  };

  const handleImage1 = async (event) => {
    const files = event.target.files;
    const file = files[0];
    const images = await getBase64(file);
    if (typeof images != undefined) {
      const S3image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`,
        { file: images },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      props.setProjectData({
        ...props.projectData,
        projectIcon: {
          ...props.projectData.projectIcon,
          "1x1": S3image.data.uploadResult.Location,
        },
      });
      props.setImage1(true);
    } else {
    }
  };

  const handleProjectName = (event) => {
    props.setProjectData({ ...props.projectData, commonName: event.target.value });
  };

  const handleProjectDescription = (event) => {
    props.setProjectData({ ...props.projectData, description: event.target.value });
  };

  return (
    <Grid container spacing={6}>
      <Grid item sx={12} md={6} lg={6}>
        <Box pb={3}>
          <Box component="form" role="form">
            <Box paddingBottom="20px">
              <Typography className={classes.inputlabel}>Project Name</Typography>
              <TextField
                className={classes.textfield}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Enter the Project Name"
                onChange={handleProjectName}
                required
                value={props.projectData.commonName}
              />
              <FormHelperText>
                {props.projectData.commonName === "" || props.projectData.commonName === undefined
                  ? props.ErrorMessage
                  : ""}
              </FormHelperText>
            </Box>
            <Box paddingBottom="20px">
              <Typography className={classes.inputlabel}> Project Description</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={3}
                className={classes.textfield}
                placeholder="Enter the Project Description"
                onChange={handleProjectDescription}
                value={props.projectData.description}
                required
              />
              <FormHelperText>
                {props.projectData.description === "" || props.projectData.description === undefined
                  ? props.ErrorMessage
                  : ""}
              </FormHelperText>
            </Box>
            <Box display="flex" flexDirection="row" gridColumnGap="30px">
              <Box paddingBottom="5px">
                <Typography className={classes.inputlabel}>Project Logo 16x9</Typography>
                <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                  <Input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    multiple
                    onChange={handleImage}
                    required
                    inputProps={{ accept: "image/*" }}
                    fullWidth
                  />
                  <Box className={classes.logofield} display="flex" alignItems="center" justifyContent="space-evenly">
                    <Box>
                      <Typography className={classes.logoTypo}>Upload Logo 16x9</Typography>
                    </Box>
                  </Box>
                </label>
                <FormHelperText>
                  {props.projectData.projectIcon === undefined ||
                  props.projectData.projectIcon === "" ||
                  props.projectData.projectIcon["16x9"] == undefined
                    ? props.ErrorMessage
                    : ""}
                </FormHelperText>
              </Box>

              <Box paddingBottom="5px" paddingLeft="30px">
                <Typography className={classes.inputlabel}>Project Logo 1x1</Typography>
                <label htmlFor="upload-photo1" style={{ cursor: "pointer" }}>
                  <Input
                    style={{ display: "none" }}
                    id="upload-photo1"
                    name="upload-photo1"
                    type="file"
                    multiple
                    onChange={handleImage1}
                    required
                    fullWidth
                    inputProps={{ accept: "image/*" }}
                  />
                  <Box className={classes.logofield} display="flex" alignItems="center" justifyContent="space-evenly">
                    <Box>
                      <Typography className={classes.logoTypo}>Upload Logo 1x1</Typography>
                    </Box>
                  </Box>
                </label>
                <FormHelperText>
                  {props.projectData.projectIcon === "" ||
                  props.projectData.projectIcon === undefined ||
                  props.projectData.projectIcon["1x1"] === undefined
                    ? props.ErrorMessage
                    : ""}
                </FormHelperText>
              </Box>
              <Box></Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gridColumnGap="105px">
            {props.image16 || props.projectData ? (
              <Box className={classes.Image16}>
                <img className={classes.Image16} src={props.projectData.projectIcon["16x9"]}></img>
              </Box>
            ) : (
              ""
            )}
            {props.image1 || props.projectData ? (
              <Box className={classes.updateImg}>
                <img className={classes.updateImg} src={props.projectData.projectIcon["1x1"]}></img>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Grid>
      <PlatformSeletor
        handleSelectAll={handleSelectAllPlatforms}
        clickHandler={handlePlatforms}
        platformSelected={platformList}
        selectedPlatforms={props.projectData?.platforms}
        ErrorMessage={props.ErrorMessage}
        lg={9}
        xl={8}
      />
    </Grid>
  );
};

export default EditProjectDetails;
