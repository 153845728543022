import {
    GET_PUSHNOTIFICATIONS_ACTION,
    UPDATE_PUSHNOTIFICATIONS_ACTION,
    GET_PUSHNOTIFICATIONS_RESPONSE,
    UPDATE_PUSHNOTIFICATIONS_RESPONSE,
    PUSHNOTIFICATIONS_Error
} from '../actionTypes/'

export const updatePushNotification=(data,params)=>{

    return {
      type: UPDATE_PUSHNOTIFICATIONS_ACTION,
      payload: data,
      params
    };
  }

  export const updatePushNotificationResponse=(data,params)=>{
    return {
      type: UPDATE_PUSHNOTIFICATIONS_ACTION,
      payload: data,
      params
    };
  }

  export const getPushNotification=(params)=>{
    return {
      type: GET_PUSHNOTIFICATIONS_ACTION,
      payload:params
    };
  }

  export const getPushNotificationResponse=(data)=>{
    return {
      type: GET_PUSHNOTIFICATIONS_RESPONSE,
      payload:data
    };
  }

  export const pushNotificationErrorResponse=(data)=>{
    return {
      type: PUSHNOTIFICATIONS_Error,
      payload:data
    };
  }