import { drawerWidth, transition, container } from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    // background: "linear-gradient(180deg, #00ACC4 0%, #2F36CB 100%)",
    // background: "linear-gradient(180deg, #00acc4b3 1%, #171ebd61 100%)",
    // background: "linear-gradient(180deg, #D9F8FF 1%, #BDC0FF 100%)",
    background: "linear-gradient(180deg, #d8e5ff 1%, #97bafc 100%)",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
  },
  mainPanelOp: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 90px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
  },
  /* appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
   ...transition
  },*/
  content: {
    marginRight: "70px",
    padding: "30px 15px",
    eight: "calc(100vh - 140px)",
  },
  container,
  map: {
    // marginTop: "20px",
  },
});

export default appStyle;
