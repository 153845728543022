import React from "react";
import Modals from "components/Modal/Modals";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";
import { Box, Button } from "@material-ui/core";
import { connect } from "react-redux";


//Action
import {deleteWidgetAction} from '../../screens/WidgetManager/actions'

const DeleteWidgetPopUp = (props) => {


const deleteWidget=(id)=>{
props.deleteWidgetAction({id:id,p_id:props?.projectDetails?.p_id})
props.setRemoveWidgetPopUp(false)

}

  return (
    <Modals open={props.open} setModal={props.setRemoveWidgetPopUp} setAssetIdBrowse={() => {}}>
      <div
        style={{
          backgroundColor: "white",
          width: "inherit",
          padding:"50px",
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      >
        <span
          style={{
            background: "linear-gradient(180deg, rgba(1, 157, 243, 0.06468) 0%, rgba(1, 157, 243, 0.098) 100%)",
            boxShadow:
              "inset 8.8px -8.8px 8.8px rgba(255, 255, 255, 0.298), inset -8.8px 8.8px 8.8px rgba(255, 255, 255, 0.298)",
            borderRadius: "50%",
            width: "72px",
            height: "72px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <img style={{ width: "23px", height: "31px" }} src={deleteBucket} alt="Delete bucket" />
        </span>
        <span
          style={{
            marginBottom: "30px",
          }}
        >
          Do You Want to delete the {props.widgetName} ?
        </span>
        <span style={{ marginBottom: "30px" }}>Widget Id-{props.widgetId}</span>
        <Box style={{ display: "flex" }}>
          <Button
            style={{
              height: "45px",
              width: "160px",
              color: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "140%",

              color: "#0192D4",
              border: "1px solid transparent",
              background:
                "linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box",
              borderRadius: "33px",
              border: " 1px solid transparent",
            }}
           onClick={() => props.setRemoveWidgetPopUp(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              color: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "140%",
              height: "45px",
              width: "160px",
              marginLeft: "20px",
              background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
              borderRadius: "33px",
            }}
            onClick={() => deleteWidget(props.widgetId)}
          >
            Yes
          </Button>
        </Box>
      </div>
    </Modals>
  );
};

const mapStateToProps = (state) => {
    return {
      
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        deleteWidgetAction: (widgetId) => dispatch(deleteWidgetAction(widgetId)),
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(DeleteWidgetPopUp);
