import { takeEvery, call, put } from "redux-saga/effects";

import axios from "axios";
import { LoginApi } from "../apis/apis";
import { SuccessfullyLoginAction, UnsuccesFullyLogin } from "../actions/loginAction";

export function* watchLoginAction() {
  yield takeEvery("LOGIN_ACTION", handleLoginAction);
}

function* handleLoginAction({ payload }) {
  try {
    const res = yield call(handleloginAxios, payload);
    
    if(res){
      let auth = res?.data
      if (auth.hzStatus === 200) {
        //  window.location="/admin"
        yield put(SuccessfullyLoginAction(auth));
      } else if (auth.hzStatus === 400) {
        yield put(UnsuccesFullyLogin(auth.message));
      }
    }else{
      yield put(UnsuccesFullyLogin("Login ID or Password not Recognised"));
    }
  } catch (err) {}
}

const handleloginAxios = async (authDetails) => {
  try {
    const res = await axios.post(LoginApi, authDetails, {
      headers: { "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY },
    });
    if (res?.data?.hzStatus == 200) {
      localStorage.setItem("token", res.data.jwtToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
    } 
    return res
  } catch (err) {
    return { message: err.response.data.message };
  }
};
