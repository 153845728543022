import { Card, Typography, makeStyles } from "@material-ui/core";
import styles from "../../assets/jss/material-dashboard-react/components/chipTabStyle";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles(styles);

export default function ChipTab(props) {
  const { title, id, selectChip, onclick, icon } = props;

  const classes = useStyles();

  const [width, setWidth] = useState(0);
  const [pointer, setPointer] = useState(false);

  const ref = useRef(null);

  const chipStyle = {};

  if (selectChip) Object.assign(chipStyle, { backgroundColor: "#FFF" });

  if (pointer)
    Object.assign(chipStyle, {
      right: `-${width}px`,
      backgroundColor: "#FFF",
    });

  useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <Card
      className={classes.chipContainer}
      key={id}
      onClick={onclick}
      style={chipStyle}
      ref={ref}
      onPointerEnter={() => {
        setPointer(true);
      }}
      onPointerLeave={() => {
        setPointer(false);
      }}
    >
      <Typography className={selectChip ? classes.chipTitleSelected : classes.chipTitleUnselected}>{title}</Typography>
      <img src={icon} className={classes.chipIcon} />
    </Card>
  );
}
