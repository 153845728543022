const EditProjectStyle = {
    Heading:{
        width: '189px',
        height: '33px',
        //left: '108px',
        //top: '124px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '24px',
        'line-height': '33px',
        color: '#141D46',
    },
    Card:{
      'width': '90%',
    //   height: '315px',
      //left: '108px',
      //top: '181px',
      background: '#FFFFFF',
      'box-shadow': '13.0709px 13.0709px 104.567px rgba(63, 39, 102, 0.1)',
      'backdrop-filter': 'blur(26.1418px)',
      'border-radius': '12px',
    },
    List:{
        width: '200px',
        height: '22px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '16px',
        'line-height': '22px',
        color: '#777777',
        'flex': 'none',
        'order': '1',
        'flex-grow': '0'
    },
    SelectedList:{
        width: '196px',
        height: '22px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '600',
        'font-size': '16px',
        'line-height': '22px',
        color: '#424242',
        flex: 'none',
        order: '1',
        'flex-grow': '0',
    },
    bottomCard:{
        maxwidth: '100%',
        height: '75px',
        background: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(24px)',
        //left: 'calc(50% - 1440px/2)',
        bottom: '0px',
        Top:'0px',
        width:'100%'
    },
    cancelButton:{
        width:'180px',
        height:'48px',
        boxSizing: 'border-box',
        filter: 'drop-shadow(0px 2px 20px rgba(0, 60, 118, 0.6))',
        borderRadius: '33px',
        background: "linear-gradient(#FFF, #FFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) border-box",
        border: "1px solid transparent",
        boxShadow: "none",
    },
    addButton:{
        width:'180px',
        height:'48px',
        background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
        'border-radius': '33px'
    },
    cancelButtonText:{
        width: '111px',
        height: '22px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'background': 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        'WebkitBackgroundClip': 'text',
        'WebkitTextFillColor': 'transparent',
        'background-clip': 'text',
        'text-fill-color': 'transparent'
    },
    addButtonText:{
        width: '111px',
        height: '22px',
        left: 'calc(50% - 311px/2 - 0.5px)',
        top: 'calc(50% - 22px/2)',
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'color': '#FFFFFF'
    },
}

export default EditProjectStyle;