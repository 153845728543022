import axios from "axios";

export const dictUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/message-dictionary`;
export const addDictUrl = `${dictUrl}/add`;
export const publishDictUrl = `${dictUrl}/publish`;
export const bulkUploadApiURL=`${dictUrl}/uploadFile`
export const editDictApiURL=`${dictUrl}/update`
export const deleteDictApiURL=`${dictUrl}/delete`
export const bulkUploadTemplateApiURL=`${dictUrl}/importTemplate`


export const getDictApi = async (payload) => {
console.log("Payloaad",payload)
  const options = {
    method: "GET",
    url: dictUrl,
    params: {
      p_id:payload.p_id,
      platforms:payload.platform
    },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response?.data?.data;
};

export const createDictApi = async (p_id, data, ln = "en") => {
  const options = {
    method: "POST",
    url: addDictUrl,
    params: {
      p_id,
      ln,
    },
    data,
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response?.data?.data;
};

export const publishDictApi = async (p_id, status,m_id,data) => {
  console.log("IdM-",m_id,m_id.join(),data)
  const options = {
    method: "POST",
    url: publishDictUrl,
    params: {
      p_id,
      status,
      m_id:m_id.join()
    },
    data,
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response?.data;
};

export const editDictApiCall=async (p_id,m_id, data, ln = "en")=>{
  const options = {
    method: "PUT",
    url: editDictApiURL,
    params: {
      p_id,
      m_id,
      ln,
    },
    data,
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response
}

export const deleteDictApiCall = async (p_id,m_id) => {
  const options = {
    method: "DELETE",
    url: `${deleteDictApiURL}/${m_id}`,
    params: {
      p_id,
    },
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };

  const response = await axios.request(options);
  return response?.data?.data;
};


export  const bulkUploadApiCall=async(details)=>{
const options={
  method:"POST",
  url:bulkUploadApiURL,
  params:{
    p_id:details.p_id
  },
  data:{
    file:details.file
  }, 
   headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY,
    Authorization: localStorage.getItem("token"),
   

  },
}
const response = await axios.request(options);
 return response;
}

export const bulkUploadTemplateApiCall=async(p_id)=>{
  const options={
    method:"GET",
    url:bulkUploadTemplateApiURL,
    params:{
      p_id:p_id
    },
   
     headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
     
  
    },}

const response = await axios.request(options);
 return response;
}