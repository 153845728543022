export const getAllProjectsApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-all-projects`;
export const createNewProjectApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/create-project`;
export const getAllLanguageApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/locales`;
export const createConfigApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/project/config`;
export const UpdateProjectApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-project`;
export const createCMSConfigApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/createKalturaConfig`;
export const getCMSConfigApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/getKalturaConfig`;
export const getFeatureConfigApi = `${process.env.REACT_APP_BASE_URL_3}${process.env.REACT_APP_ENV}/v1/getfeatureConfig`;
export const updateCMSApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/updateKalturaConfig`;
export const cmsConfigCheckApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/cms/kaltura/config/check`;
// export const getViewJsonApi = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-all-projects`;