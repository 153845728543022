import { ADD_NEW_USER_ACTION, DELETE_USER_ACTION, DELETE_USER_ACTION_FAILED, DOWNLOAD_AUDIT_LOGS_ACTION, GET_ALL_AUDIT_LOGS_ACTION, GET_ALL_USERS_ACTION, SUCCESSFULLY_ADDED_USER_ACTION, SUCCESSFULLY_DELETE_USER_ACTION, SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION, SUCCESSFULLY_GET_ALL_USERS, SUCCESSFULLY_UPDATE_USER_ACTION, UPDATE_USER_ACTION_FAILED, UPDATE_USER_DETAILS_ACTION } from "../actionTypes"
import { USER_ACTION_FAILED } from '../actionTypes/index';

export const getAllUsersAction = ()=>{
   return{type:GET_ALL_USERS_ACTION}
}

export const successfullyGetAllUsers = (res)=>{
    return{type:SUCCESSFULLY_GET_ALL_USERS,payload:res}
}

export const addNewUserAction = (userDetails)=>{
    return{type:ADD_NEW_USER_ACTION,payload:userDetails}
}

export const successfullyAddedNewUser = (res)=>{
    return{type:SUCCESSFULLY_ADDED_USER_ACTION,payload:res}
}

export const userActionFailed = (res) => {
    return { type: USER_ACTION_FAILED, payload: res}
}

export const deleteUserAction = (details)=>{
    return{type:DELETE_USER_ACTION,payload:details}
}

export const successfullyDeleteUserAction = (res)=>{
    return{type:SUCCESSFULLY_DELETE_USER_ACTION,payload:res}
}

export const deleteUserActionFailed = (res)=>{
    return{type:DELETE_USER_ACTION_FAILED,payload:res}
}

export const updateUserDetailsAction= (data)=>{
    return{type:UPDATE_USER_DETAILS_ACTION,payload:data}
}

export const successfullyUpdateUserAction = (res)=>{
    return{type:SUCCESSFULLY_UPDATE_USER_ACTION,payload:res}
}

export const updateUserActionFailed = ()=>{
    return{type:UPDATE_USER_ACTION_FAILED,payload:res}
}

export const getAllAuditLogs = (projectId,username)=>{
    let loadData = {
        p_id:projectId,
        username:username
    } 
    return{type:GET_ALL_AUDIT_LOGS_ACTION,payload:loadData}
} 

export const successfullyGetAllAudits = (res)=>{
    return{type:SUCCESSFULLY_GET_ALL_AUDIT_LOGS_ACTION,payload:res}
}

export const downloadAuditLogsAction = (projectId)=>{
    return{type:DOWNLOAD_AUDIT_LOGS_ACTION,payload:projectId}
}