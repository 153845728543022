import { CREATE_DICT_SUCCESS, GET_DICT_SUCCESS, PUBLISH_DICT_SUCCESS, RESET_DICT , BULK_UPLOAD_ACTION,
  BULK_UPLOAD_RESPONSE,
  EDIT_DICT_ACTION,
  DELETE_DICT_ACTION,
  DELETE_DICT_RESPONSE,
  EDIT_DICT_RESPONSE,
  CREATE_DICT_API,PUBLISH_DICT_API
} from "../actionTypes";
import { initialState } from "../initialState";

export function dictReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DICT_SUCCESS: {
      return {
        ...state,
        dict: action.payload,
        add:false
      };
    }
    case CREATE_DICT_SUCCESS: {
      return {
        ...state,
       add:false
      };
    }
case CREATE_DICT_API:{
  return{
    ...state,
    add:true
  }
}

case PUBLISH_DICT_API:{
  return{
    ...state,
    add:true,

  }
}

    case PUBLISH_DICT_SUCCESS: {
      return {
        ...state,
       /* dict: {
          ...state.dict,
          status: action.payload,
        }, */
        add:false
      };
    }
    case RESET_DICT: {
      return {
        ...state,
        reset: action.payload,
      };
    }
case EDIT_DICT_RESPONSE:{
  return {
    ...state,add:false
  }
}
case EDIT_DICT_ACTION:{
  return{
    ...state,
    add:true
  }
}
case DELETE_DICT_ACTION:{
  return{
    ...state,
    add:true
  }
}

case DELETE_DICT_RESPONSE:{
  return{
    ...state,
    add:false
  }
}

    default: {
      return {
        ...state,
      };
    }
  }
}
