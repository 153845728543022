// Convert file to base64 string
export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        // Read file content on file loaded event
        reader.onload = function(event) {
            resolve(event.target.result);
        };
    
        reader.onerror = function(error) {
            reject(error);
        };
    
        // Convert data to base64
        reader.readAsDataURL(file);
    });
    // return new Promise(resolve => {
    //   var file = file[0]
    //   var reader = new FileReader();
    //   // Read file content on file loaded event
    //   reader.onload = function(event) {
    //     resolve(event.target.result);
    //   };
      
    //   // Convert data to base64 
    //   reader.readAsDataURL(file);
    // });
  };
  // Example call:
  