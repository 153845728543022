//All the action's type stored as in the varialble to be used with ease
//Action Type for the All the action to call apis
export const GET_ALL_LINEAR_CHANNEL_ACTION='GET_ALL_LINEAR_CHANNEL_ACTION'
export const GET_ALL_BLACKOUT_ACTION='GET_ALL_BLACKOUT_ACTION'
export const GET_EPG_ACTION='GET_EPG_ACTION'
export const SCHEDULE_BLACKOUT_ACTION='SCHEDULE_BLACKOUT_ACTION'
export const DELETE_BLACKOUT_ACTION='DELETE_BLACKOUT_ACTION'
export const GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_ACTION='GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_ACTION'

//Action Type for the Getting Api Response
export const GET_ALL_LINEAR_CHANNEL_RESPONSE='GET_ALL_LINEAR_CHANNEL_RESPONSE'
export const GET_ALL_BLACKOUT_RESPONSE='GET_ALL_BLACKOUT_RESPONSE'
export const GET_EPG_RESPONSE='GET_EPG_RESPONSE'
export const  SCHEDULE_BLACKOUT_RESPONSE='SCHEDULE_BLACKOUT_RESPONSE'
export const  DELETE_BLACKOUT_RESPONSE='DELETE_BLACKOUT_RESPONSE'
export const GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_RESPONSE='GET_ALL_BLACKOUT_FOR_ALL_CHANNEL_RESPONSE'

//Acton Type for uploading image on the s3
export const UPLOAD_BLACKOUT_IMAGE_CONTENT_ON_S3_ACTION='UPLOAD_BLACKOUT_IMAGE_CONTENT_ON_S3_ACTION'
export const UPLOAD_BLACKOUT_IMAGE_CONTENT_ON_S3_RESPONSE='UPLOAD_BLACKOUT_IMAGE_CONTENT_ON_S3_RESPONSE'

//For Error messages
export const GET_ALL_LINEAR_CHANNEL_ERROR_MESSAGE='GET_ALL_LINEAR_CHANNEL_ERROR_MESSAGE'