import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Pagination, PaginationItem } from "@material-ui/lab";
import AddIcon from "assets/img/icons/svg/Ic_add.svg";
import dictStyle from "assets/jss/material-dashboard-react/views/dictStyle";
import clsx from "clsx";
import Snackbar from "components/Snackbar/CustomSnackbar";
import Primary from "components/Typography/Primary";
import React from "react";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import {
  createDictAction,
  getDictAction,
  publishDictAction,
  resetDictAction,
  bulkUploadAction,
  editDictAction,
  deleteDictAction,
} from "../actions";
//Table Import
import MessageDictionaryTable from "../../../components/Table/MessageDictionaryTable";
import DropDownMenu from "../../../components/DropDown/Dropdown";
import { fileToBase64 } from "../../../utils/getBase64";
// svg import
import MessageDictIcon from "assets/img/sideNavIcons/MessageDirectory.svg"
 
const headCells = [
  { id: "event", label: "Event" },
  { id: "code", label: "Status Code" },
  { id: "message", label: "Description" },
  { id: "actions", label: "Actions" },
  // { id: "Edit", label: "Edit" },
  // { id: "delete", label: "delete" },
];

const useStyles = makeStyles(dictStyle);

const dicts = {
  error: "Error Messages",
  ufms: "General Messages",
  warning: "Warning Messages",
};
const dictIntegrations = ["Default", "Kaltura", "Evergent", "Conviva"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function MessageDict(props) {
  const classes = useStyles();
  const [addOrEdit, setAddOrEdit] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const [activeDict, setActiveDict] = React.useState(Object.keys(dicts)[0]);
  const [activeDictMessage, setActiveDictMessage] = React.useState(dicts.error);
  const [currentDict, setCurrentDict] = React.useState(dictIntegrations[0]);

  const [dictMessages, setDictMessages] = React.useState([]);
  const [addDictMessages, setAddDictMessages] = React.useState([]);
  const [addPlatforms, setAddPlatforms] = React.useState();
  const [csvFileNormal, setCSVFileNormal] = React.useState();
  const initialState = {
    event: null,
    code: null,
    message: "",
    status: "DRAFT",
    actions: [],
  };

  const [message_id, setMessageId] = React.useState();

  const [openDict, setOpenDict] = React.useState(false);
  const [addDictMsg, setAddDictMsg] = React.useState(initialState);

  const [snack, setSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");

  const [progress, setProgress] = React.useState(0);
  const [platforms, setPlatforms] = React.useState(
    props?.projectDetails?.platformList
  );
  const [currentPlatform, setCurrentPlatform] = React.useState(platforms?.[0]);

  //CSV file state
  const [csvFile, setCsvFile] = React.useState([]);

  React.useEffect(() => {
    console.log("Meesage dictionary", props.projectDetails?.platformList);
    setPlatforms[props?.projectDetails?.platformList];
    setCurrentPlatform(platforms&&platforms[0]);
  }, [platforms]);

  const filteredDicts = React.useMemo(() => {
  
    return dictMessages?.filter(
      (dict) =>
        dict?.dictionary?.messages[0].origin == currentDict &&
        dict?.dictionary?.messages[0].type == activeDict
    );

    //console.log("Dict message in the table", props.dict);
    // return dictMessages;
  }, [dictMessages, currentDict, activeDict, addDictMessages]);

  const filteredAddDicts = React.useMemo(() => {
    return addDictMessages?.filter(
      (dict) => dict.origin === currentDict && dict.type === activeDict
    );
  }, [addDictMessages, currentDict, activeDict]);

  const status = React.useMemo(() => {
    return (
      (filteredDicts?.length > 0 &&
        filteredDicts?.filter((dict) => dict?.status === "LIVE")?.length ===
          filteredDicts?.length) ??
      false
    );
  }, [filteredDicts]);

  React.useEffect(() => {
    if (!props.add) {
      
      props.getDictAction(props.p_id, currentPlatform);
      setAddPlatforms("");
     setSelected([])
     
    }
  }, [currentPlatform, props?.add]);

  React.useEffect(() => {
    console.log("CSV File naem", csvFile);
  }, [csvFile]);

  React.useEffect(() => {
    if (props?.dict) {
      setDictMessages(props?.dict);
      setAddDictMessages([]);
      setProgress(progress + 20);

      if (props?.dict && Object?.keys(props?.dict)?.length) setProgress(100);

      const timeout = setTimeout(() => {
        setProgress(100);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.dict]);

  console.log("dic tmessage", dictMessages);

  React.useEffect(() => {
    if (addDictMessages.length > 0) {
      if (addOrEdit === "Add") {
        console.log("Deletee");
        props.createDictAction({
          p_id: props.p_id,
          data: {
            dictionary: {
              default: {
                type: "default",
                message: "Asset Not Found Yet.",
              },
              messages: [...addDictMessages],
            },
          },
          status: "PUBLISHED",
        });
      } else if (addOrEdit === "Edit") {
        props.editDictAction({
          p_id: props.p_id,
          m_id: message_id,
          data: { dictionary: { messages: [...addDictMessages] } },
          status: "PUBLISHED",
        });
      }
    }
  }, [addDictMessages]);

  React.useEffect(() => {
    if (props.dictReset) {
      setSnackMessage(`Dictionary has been ${props.dict?.status}.`);
      setSnackSeverity("success");
      setSnack(true);
    }
    props.resetDictAction(false);
  }, [props.dictReset]);

  const handleStatusAll = (event) => {
    if (event.target.checked) {
      setDictMessages((prev) => [
        ...prev.filter(
          (dict) =>
            !filteredDicts.map((dict) => dict.eventId).includes(dict.eventId)
        ),
        ...filteredDicts.map((dict) => {
          return { ...dict, status: "LIVE" };
        }),
      ]);

      setAddDictMessages((prev) => [
        ...prev.map((dict) => {
          return { ...dict, status: dict.status === "LIVE" ? "DRAFT" : "LIVE" };
        }),
      ]);
      return;
    }

    setDictMessages((prev) => [
      ...prev.filter(
        (dict) =>
          !filteredDicts.map((dict) => dict.eventId).includes(dict.eventId)
      ),
      ...filteredDicts.map((dict) => {
        return { ...dict, status: "DRAFT" };
      }),
    ]);

    setAddDictMessages((prev) => [
      ...prev.map((dict) => {
        return { ...dict, status: dict.status === "LIVE" ? "DRAFT" : "LIVE" };
      }),
    ]);
  };

  const handleSelectAllClick = (event, message) => {
   if(selected.length===0) setSelected(filteredDicts)
   else if(selected.length>0)setSelected([])
  };

  const handleClickSwitch = (dict, type = "old") => {
    if (type === "add") {
      setAddDictMessages((prev) =>
        prev.map((oldDict) => {
          if (oldDict.eventId === dict)
            return {
              ...oldDict,
              status: oldDict.status === "LIVE" ? "DRAFT" : "LIVE",
            };
          return oldDict;
        })
      );

      return;
    }

    setDictMessages((prev) => [
      ...prev.filter(
        (dict) =>
          !filteredDicts.map((dict) => dict.eventId).includes(dict.eventId)
      ),
      ...filteredDicts.map((filteredDict) => {
        if (dict === filteredDict.eventId) {
          return {
            ...filteredDict,
            status: filteredDict.status === "DRAFT" ? "LIVE" : "DRAFT",
          };
        }
        return filteredDict;
      }),
    ]);
  };

  const editMessagePopOpen = (messageDetails) => {
    setAddOrEdit("Edit");
    setAddDictMsg({
      event: messageDetails.dictionary.messages[0].event,
      code: messageDetails.dictionary.messages[0].code,
      message: messageDetails.dictionary.messages[0].message,
      status: messageDetails.dictionary.messages[0].status,
      actions: messageDetails.dictionary.messages[0].actions,
    });
    setAddPlatforms(messageDetails.dictionary.messages[0].platform);
    setMessageId(messageDetails._id);
    setOpenDict(true);
  };

  const deleteMessage = (messageDetails) => {
    let infoMessage = {
      p_id: props.p_id,

      m_id: messageDetails._id,
    };

    props.deleteDictAction(infoMessage);
  };

  const handleClickCheckbox = (dictId) => {
    
    setSelected((prev) =>
      prev.includes(dictId)
        ? prev.filter((dicts) => dicts !== dictId)
        : [...prev, dictId]
    );
  };

  const deleteHandler = () => {
    setDictMessages(
      dictMessages?.filter((dict) => !selected.includes(dict.eventId))
    );

    setAddDictMessages(
      addDictMessages?.filter((dict) => !selected.includes(dict.eventId))
    );

    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //File Upload function
  const handleFileUpload = async (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter(
        (file) => file.type === "text/csv" || file.name.endsWith(".csv")
      );
      const base64Promises = validFiles.map((file) => fileToBase64(file));

      try {
        const base64Results = await Promise.all(base64Promises);
        //setBase64Files(base64Results);
        setCSVFileNormal(files[0]);
        setCsvFile(base64Results);
        console.log("File results", csvFile); // base64 encoded CSV content
      } catch (error) {
        console.error("Error converting files:", error);
      }
    } else {
      console.error("No files selected or invalid file type.");
    }
  };

  //Upload file to s3
  const uploadCSVFile = () => {
    // const formData = new FormData();
    // formData.append("file", csvFile);
    // console.log("Api for bulk upload", csvFile, formData);
    // console.log("JJJ", [...formData.entries()]);

    props.bulkUploadAction(props.p_id, csvFile[0]);
  };

  const isSelected = (id) => {
   
    
   return selected.map(sel=>sel._id).indexOf(id) !== -1};

  return (
    <>
      <Snackbar
        open={snack}
        severity={snackSeverity}
        message={snackMessage}
        setOpen={() => setSnack(false)}
      />
      <LoadingBar
        color="#885CF7"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        shadow={true}
        height={3}
      />
      <Box display={"flex"}>
        <img src={MessageDictIcon} alt="Your Icon" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
        <Primary>Message Dictionary</Primary>
      </Box>
      <Grid container className={classes.dictGrid}>
        <Grid item xs={2}>
          <Card className={classes.dictCard}>
            <MenuList className={classes.dictList}>
              {Object.values(dicts).map((dict, key) => (
                <MenuItem
                  key={key}
                  className={
                    dict === activeDictMessage
                      ? classes.activeDict
                      : classes.inActiveDict
                  }
                  id={Object.keys(dicts)[key]}
                  onClick={(e) => {
                    setActiveDict(Object.keys(dicts)[key]);
                    setActiveDictMessage(e.target.textContent);
                  }}
                >
                  {dict}
                </MenuItem>
              ))}
            </MenuList>
          </Card>
          <Box style={{ marginTop: "10px" }}>
            <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
              <Input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                multiple
                onChange={handleFileUpload}
                required
                inputProps={{ accept: ".csv" }}
                fullWidth
                cursor="pointer"
              />
              <Box
                className={classes.filefield}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Box>
                  <Typography className={classes.fileTypo}>
                    {csvFileNormal ? csvFileNormal.name : "Upload CSV File"}{" "}
                  </Typography>
                </Box>
              </Box>
            </label>
            <Button
              variant="contained"
              className={classes.importButton}
              onClick={uploadCSVFile}
            >
              Import
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Toolbar
                className={clsx(classes.dictToolbar, {
                  [classes.dictHighlight]: selected.length,
                })}
              >
                {selected.length ? (
                  <Typography
                    className={classes.dictTitle}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {selected.length} selected
                  </Typography>
                ) : (
                  <>
                    <Typography
                      className={classes.dictTitle}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      {activeDictMessage}
                      {JSON.stringify(dictMessages) ===
                      JSON.stringify(props.dict?.dictionary?.messages)
                        ? props.dict?.status && `[${props.dict?.status}]`
                        : "[DRAFT]"}{" "}
                      <span>
                        Total:{" "}
                        {dictMessages?.filter(
                          (dict) =>
                            dict?.type ===
                            Object.keys(dicts).find(
                              (key) => dicts[key] === activeDict
                            )
                        )?.length ?? 0}
                      </span>
                    </Typography>
                  </>
                )}

                {selected.length ? (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteHandler()}
                      style={{ marginRight: 20 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Toolbar>
              <Box className={classes.dictContent}>
                <Box className={classes.dictBox}>
                  <FormControl variant="filled" className={classes.dictControl}>
                    <InputLabel id="dictIntegrations">Integrations</InputLabel>
                    <Select
                      value={currentDict}
                      onChange={(event) => {
                        setCurrentDict(event.target.value);
                      }}
                      labelId="dictIntegrations"
                      id="demo-simple-select-filled"
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.dictItems}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          },
                        },
                      }}
                    >
                      {dictIntegrations.map((dict, i) => (
                        <MenuItem value={dict} key={i}>
                          {dict} (
                          {dictMessages?.filter(
                            (apiDict) =>
                              apiDict.origin === dict &&
                              apiDict.type ===
                                Object.keys(dicts).find(
                                  (key) => dicts[key] === activeDict
                                )
                          )?.length ?? 0}
                          )
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {console.log("platform List", platforms)}
                <Box className={classes.addDictPlatform}>
                  {" "}
                  <FormControl variant="filled" className={classes.dictControl}>
                    <InputLabel id="dictIntegrations">Platforms</InputLabel>
                    <Select
                      value={currentPlatform}
                      onChange={(event) => {
                        setCurrentPlatform(event.target.value);
                      }}
                      labelId="dictIntegrations"
                      id="demo-simple-select-filled"
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.dictItemsPlatform}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          },
                        },
                      }}
                    >
                      {platforms?.map((dict, i) => (
                        <MenuItem value={dict} key={i}>
                          {dict}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  className={classes.addDictCTA}
                  onClick={() => {
                    if (props.accessLevel !== "user") {
                      setAddOrEdit("Add");
                      setOpenDict(true);
                    } else {
                      setSnackMessage(
                        "You do not have access to add messages."
                      );
                      setSnackSeverity("warning");
                      setSnack(true);
                    }
                  }}
                >
                  <img src={AddIcon} />
                  <Typography className={classes.addNewDict}>
                    Add More
                  </Typography>
                </Button>
                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={openDict}
                  onClose={() => {
                    setAddDictMsg(initialState);
                    setOpenDict(false);
                  }}
                >
                  <DialogTitle id="max-width-dialog-title">
                    Create new {currentDict} {activeDict}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        id="event"
                        placeholder="Event"
                        value={addDictMsg.event ?? ""}
                        onChange={(e) =>
                          setAddDictMsg((prev) => {
                            return { ...prev, event: e.target.value };
                          })
                        }
                        label="Event"
                        required
                        error={addDictMsg.event ? false : true}
                      />
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        id="code"
                        placeholder="Status Code"
                        value={addDictMsg.code ?? ""}
                        onChange={(e) =>
                          setAddDictMsg((prev) => {
                            return { ...prev, code: e.target.value };
                          })
                        }
                        label="Status Code"
                        required
                        error={addDictMsg.code ? false : true}
                      />
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        id="message"
                        placeholder="Description"
                        rows={4}
                        multiline
                        value={addDictMsg.message ?? ""}
                        onChange={(e) =>
                          setAddDictMsg((prev) => {
                            return { ...prev, message: e.target.value };
                          })
                        }
                        label="Description"
                        required
                        error={addDictMsg.message ? false : true}
                      />
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        id="actions"
                        placeholder="Actions"
                        value={addDictMsg.actions?.join(", ") ?? ""}
                        onChange={(e) =>
                          setAddDictMsg((prev) => {
                            return { ...prev, actions: [e.target.value] };
                          })
                        }
                        label="Actions"
                      />
                      <Box>
                        <Typography>Status: {addDictMsg.status}</Typography>
                        <Switch
                          className={classes.dictSwitch}
                          checked={addDictMsg.status === "LIVE" ? true : false}
                          onClick={() =>
                            setAddDictMsg((prev) => {
                              return {
                                ...prev,
                                status:
                                  prev.status === "DRAFT" ? "LIVE" : "DRAFT",
                              };
                            })
                          }
                        />
                        <Box className={classes.addDictPlatformForm}>
                          {" "}
                          <FormControl
                            variant="filled"
                            className={classes.dictControl}
                          >
                            <InputLabel id="dictIntegrations">
                              Platforms
                            </InputLabel>
                            <Select
                              value={addPlatforms}
                              onChange={(event) => {
                                //  setCurrentPlatform(event.target.value);
                                setAddPlatforms(
                                  typeof event.target.value === "string"
                                    ? event.target.value.split(",")
                                    : event.target.value
                                );
                                console.log("Platforms select", addPlatforms);
                              }}
                              labelId="dictIntegrations"
                              id="demo-simple-select-filled"
                              variant="outlined"
                              fullWidth
                              size="small"
                              className={classes.dictItemsPlatform}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight:
                                      ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                  },
                                },
                              }}
                            >
                              {platforms?.map((dict, i) => (
                                <MenuItem value={dict} key={i}>
                                  {dict}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenDict(false);
                        setAddDictMsg(initialState);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          addDictMsg.code &&
                          addDictMsg.message &&
                          addDictMsg.event
                        ) {
                          setAddDictMessages((prev) => [
                            ...prev,
                            {
                              ...addDictMsg,

                              origin: currentDict,
                              type: activeDict,
                              eventId: crypto.randomUUID(),
                              platform: addPlatforms,
                            },
                          ]);
                          setOpenDict(false);
                          setAddDictMsg(initialState);
                          console.log("addDictmessage", addDictMessages);
                          // props.createDictAction({
                          //   p_id: props.p_id,
                          //   data: {
                          //     dictionary: {
                          //       default: {
                          //         type: "default",
                          //         message: "Asset Not Found Yet."
                          //       },
                          //       messages: [

                          //         ...addDictMessages,
                          //       ],
                          //     },
                          //   },
                          //   status: "PUBLISHED",
                          // })
                        }
                      }}
                      color="primary"
                    >
                      {addOrEdit == "Add" ? "Add" : "Edit"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              <MessageDictionaryTable
                selected={selected}
                setSelected={setSelected}
                handleSelectAllClick={handleSelectAllClick}
                headCells={headCells}
                handleStatusAll={handleStatusAll}
                filteredDicts={filteredDicts}
                filteredAddDicts={filteredAddDicts}
                isSelected={isSelected}
                handleClickSwitch={handleClickSwitch}
                status={status}
                openDict={openDict}
                setOpenDict={setOpenDict}
                editMessagePopOpen={editMessagePopOpen}
                addOrEdit={addOrEdit}
                deleteMessage={deleteMessage}
                handleClickCheckbox={handleClickCheckbox}
              />
              <Pagination
                page={page}
                style={{
                  padding: 12,
                  display: "flex",
                  justifyContent: "center",
                }}
                count={
                  Math.floor(filteredDicts?.length / 10) +
                  (filteredDicts?.length % 10 > 0 ? 1 : 0)
                }
                onChange={handleChangePage}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    classes={{ selected: classes.selected }}
                  />
                )}
              />
            </Paper>
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              minWidth: "70vw",
              maxWidth: "70vw",
            }}
          >
            <Button
              variant="contained"
              className={classes.unpublishDictCTA}
              onClick={() => {
                if (props.accessLevel !== "user") {
                  setProgress(progress + 20);
                  let publishDetails = {
                    p_id: props.p_id,
                    m_id: [],
                    data: [],
                    status: "UNPUBLISHED",
                  };

                  selected.map((sel, index) => {
                    publishDetails.m_id = [...publishDetails.m_id, sel._id];
                    publishDetails.data = [
                      ...publishDetails.data,
                      { dictionary: sel.dictionary },
                    ];
                  });
                  props.publishDictAction(publishDetails);
                } else {
                  setSnackMessage(
                    "You do not have access to unpublish the dictionary."
                  );
                  setSnackSeverity("warning");
                  setSnack(true);
                }
              }}
            >
              <Typography className={classes.unpublishDict}>
                Unpublish Dictionary
              </Typography>
            </Button>
            <Button
              variant="contained"
              className={classes.publishDictCTA}
              onClick={() => {
                if (props.accessLevel === "user") {
                  setSnackMessage(
                    "You do not have access to publish the dictionary."
                  );
                  setSnackSeverity("warning");
                  setSnack(true);
                  return;
                }

                let publishDetails = {
                  p_id: props.p_id,
                  m_id: [],
                  data: [],
                  status: "PUBLISHED",
                };

                selected?.map((sel, index) => {
                  publishDetails.m_id = [...publishDetails.m_id, sel._id];
                  publishDetails.data = [
                    ...publishDetails.data,
                    { dictionary: sel.dictionary },
                  ];
                });
                props.publishDictAction(publishDetails);
                //   const formatDict =
                //     dictMessages?.map(({ translations, ...rest }) => rest) ?? [];

                //   if (!formatDict?.length && !addDictMessages?.length) {
                //     setSnackMessage("Please add messages.");
                //     setSnackSeverity("warning");
                //     setSnack(true);
                //     return;
                //   }

                //   if (
                //     !Object.values(addDictMessages)?.every(
                //       (dict) =>
                //         Boolean(dict?.event) &&
                //         Boolean(dict?.code) &&
                //         Boolean(dict?.message)
                //     )
                //   ) {
                //     const invalidDict = addDictMessages?.filter(
                //       (dict) =>
                //         !Boolean(dict?.event) &&
                //         !Boolean(dict?.code) &&
                //         !Boolean(dict?.message)
                //     );

                //     const dictIndex = Object.keys(dicts).indexOf(
                //       invalidDict[0].type
                //     );

                //     setCurrentDict(invalidDict[0].origin);

                //     return;
                //   }

                //   setProgress(progress + 20);
                //   console.log("Default message");
                //   // props.createDictAction({
                //   //   p_id: props.p_id,
                //   //   data: {
                //   //     dictionary: {
                //   //       default: {
                //   //         type: "default",
                //   //         message: "Asset Not Found Yet."
                //   //       },
                //   //       messages: [
                //   //         ...(formatDict && formatDict),
                //   //         ...addDictMessages,
                //   //       ],
                //   //     },
                //   //   },
                //   //   status: "PUBLISHED",
                //   // });
                // }}
              }}
            >
              <Typography className={classes.publishDict}>
                Publish Dictionary
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    p_id: state.ProjectReducer?.projectDetails?.p_id,
    dict: state.dictReducer.dict,
    accessLevel: state.loginReducer.authTokens?.accessLevel,
    dictReset: state.dictReducer.reset,
    projectDetails: state.ProjectReducer.projectDetails,
    add: state.dictReducer.add,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDictAction: (p_id, platforml) =>
      dispatch(getDictAction(p_id, platforml)),
    createDictAction: (payload) => dispatch(createDictAction(payload)),
    publishDictAction: (payload) => dispatch(publishDictAction(payload)),
    resetDictAction: (payload) => dispatch(resetDictAction(payload)),
    bulkUploadAction: (p_id, file) => {
      dispatch(bulkUploadAction(p_id, file));
    },
    editDictAction: (payload) => {
      dispatch(editDictAction(payload));
    },
    deleteDictAction: (payload) => {
      dispatch(deleteDictAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageDict);
