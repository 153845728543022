/*eslint-disable*/
import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  Divider,
  Grid,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// nodejs library to set properties for components

import Style from "../../../assets/jss/material-dashboard-react/views/platformStyle";
import Live_icon from "../../../assets/img/icons/Group_live_icon.svg";
import { connect } from "react-redux";
import { clearScreenWidgetDetailsArray, getPlatformAction } from "../actions/PlatformAction";
import { addProjectNameInNavbarAction } from "screens/Projects/actions/projectActions";
import { platformDetails } from "../actions/PlatformAction";
import LoadingBar from "react-top-loading-bar";
import PlatformIcon from "assets/img/sideNavIcons/PlatformIcon.svg"

const useStyle = makeStyles(Style);
import { useHistory } from "react-router-dom";
import { isDev } from "utils/getEnvironment";

const PlatformManager = (props) => {
  const classes = useStyle();
  const [screenDetails, setScreenDetails] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [Open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const history = useHistory();

  useEffect(() => {
    props.getPlatformAction(props.projectDetails.p_id);
  }, [props.platformReset]);

  const handleScreen = () => {
    props.clearScreenWidgetDetailsArray();

    history.push({
      pathname: "/admin/projectsplateformmanager/screens",
      state: {
        projectId: props.location.state,
        platform: screenDetails._id.shortName,
        platformName: screenDetails._id.name,
      },
    });
    localStorage.setItem("platform", JSON.stringify(screenDetails._id));
  };

  const handleAppScreen = () => {
    history.push({
      pathname: "/admin/projectsplateformmanager/appscreens",
      state: {
        projectId: props.location.state,
        platform: screenDetails._id.shortName,
        platformName: screenDetails._id.name,
      },
    });
  };

  const handleClick = (x) => (event) => {
    setScreenDetails(x);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const open2 = Boolean(anchorEl);
  const id = open2 ? "simple-popover" : undefined;

  useEffect(() => {
    setProgress(progress + 20);
    if (props?.platforms) setProgress(100);
  }, [props?.platforms]);

  const PlatformArr = props.projectDetails?.platformList.map((platform) =>
    props?.platforms?.find((platObj) => platObj?._id?.shortName === platform)
  );

  return !props?.platforms?.length ? (
    <LoadingBar color="#885CF7" progress={progress} onLoaderFinished={() => setProgress(0)} shadow={true} height={3} />
  ) : (
    <Box>
      <Box paddingBottom="32px">
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" alignItems="center" px={2} pb={2}>
                <img src={PlatformIcon} alt="PlatformIcon" style={{ marginRight: '10px',width:"33px" }} />
                <Typography className={classes.heading}>Platform Manager</Typography>
              </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {PlatformArr?.map((x, i) => {
                return (
                  <Box m={2} onClick={handleClick(x)} aria-describedby={id} key={i}>
                    <Card
                      className={
                        Open && screenDetails._id.shortName == x?._id?.shortName ? classes.selectCard : classes.card
                      }
                    >
                      <Box display="flex" flexDirection="row" paddingBottom="8px">
                        <Box paddingTop="16px" paddingLeft="16px" paddingRight="6px">
                          <Card className={classes.IconCard}>
                            <img
                              src={`${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${
                                x?._id?.shortName
                              }.svg`}
                              width="100%"
                              height="100%"
                            />
                          </Card>
                        </Box>
                        <Box paddingLeft="6px" paddingTop="29px">
                          <Typography className={classes.appNameTypo}>{x?._id?.name}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-evenly">
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingLeft="16px"
                            paddingTop="10px"
                            paddingBottom="20px"
                          >
                            <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{x?.liveScreens?.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                  {x?.liveScreens?.length === 1 ? "Total Live Screen" : "Total Live Screens"}
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{x?.unpublishedScreens?.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                  {x?.unpublishedScreens?.length === 1 ? "Unpublished Screen" : "Unpublished Screens"}
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{x?.draftScreens?.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                  {x?.draftScreens?.length === 1 ? "Draft Screen" : "Draft Screens"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box paddingLeft="20px">
                            <img
                              src={`${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${
                                x?._id?.shortName
                              }.svg`}
                              className={classes.secIcon}
                            ></img>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {Open ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Popover
            open={open2}
            anchorEl={anchorEl}
            id={id}
            className={classes.popover}
            classes={{ paper: classes.popoverContent }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                background: "transparent",
                width: "273px",
                display: "flex",
                justifyContent: "flex-end",
                boxShadow: "none",
              },
            }}
            onClose={handleClose}
          >
            <Box className={classes.arrow} />
            <Box className={classes.popperContainter}>
              <Box className={classes.popper}>
                <Box paddingBottom="16px" paddingTop="16px" paddingLeft="16px" paddingRight="16px">
                  <Box>
                    <Typography className={classes.popTypo}>{screenDetails._id.name}</Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-evenly">
                    <Box paddingTop="10px">
                      <Typography className={classes.popuptotalScreen}>{screenDetails.liveScreens.length}</Typography>
                    </Box>
                    <Box paddingLeft="9px" paddingTop="25px">
                      <Typography className={classes.popupScreenLength}>
                        {screenDetails.liveScreens.length === 1 ? "Live Screen" : "Live Screens"}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.screenTypoContainer}>
                    {screenDetails.liveScreens
                      .sort((a, b) => {
                        return a.displayOrder - b.displayOrder;
                      })
                      .map((s_name, i) => {
                        return (
                          <Box
                            paddingTop="12px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-evenly"
                            key={i}
                          >
                            <Typography className={classes.allScreenTypo}>
                              Screen - {i + 1} : {s_name?.name}
                            </Typography>
                            <Box>
                              <img src={Live_icon} />
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                  <Box paddingTop="24px" paddingRight="8px">
                    <Button className={classes.popButton} onClick={handleScreen}>
                      <Typography className={classes.popbtnTypo}>View all Screens</Typography>
                    </Button>
                  </Box>
                  <Box paddingTop="24px" paddingRight="8px">
                    <Button className={classes.popButton} onClick={handleAppScreen}>
                      <Typography className={classes.popbtnTypo}>View App Screens</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Popover>
        </ClickAwayListener>
      ) : (
        ""
      )}
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    platforms: State.screenVisualizationReducer.platforms,
    platformReset: State.ProjectReducer.platformReset,
    projectDetails: State.ProjectReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformAction: (projectId) => {
      dispatch(getPlatformAction(projectId));
    },
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    },
    platformDetails: (details) => {
      dispatch(platformDetails(details));
    },
    clearScreenWidgetDetailsArray: () => {
      dispatch(clearScreenWidgetDetailsArray());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformManager);
