import {createStore,applyMiddleware} from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from '../rootSaga';
import rootReducers from  '../rootReducers'
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage,
	
};

const persistedReducer = persistReducer(persistConfig, rootReducers);


const sagaMiddleware=createSagaMiddleware();

 const store = configureStore({reducer:persistedReducer,middleware:[sagaMiddleware]});
 const  persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export {store, persistor};
