//Function for seprating the image Ratio in to two numeric value
export const seprateNumericFromImageRatio=(imageRatio)=>{
    let nonNumericIndex;
    let resolution = {};
    let height;
    let width;
    for (var i = 0; i < imageRatio.length; i++) {
      if (isNaN(ratio[i])) {
        nonNumericIndex = i;
        break;
      }
    }
    width = imageRatio.substr(0, i) * 30;
    height = imageRatio.substr(i + 1) * 30;

    return { height: height, width: width };
}






//Function for calculating image Ratio greater than 1,smaller than 1,or equals to one
export const calculateImageRatio=(imageRatio)=>{
    const calImageRatio = eval(
        imageRatio?.trim().replace(/\D/g, "/")
      );

    
      return calImageRatio
}