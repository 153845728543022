import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, IconButton, makeStyles, Modal, Paper, TextField, Typography } from "@material-ui/core";
import { Box,styled } from "@material-ui/core";
import Tree from 'react-d3-tree';
import { useCenteredTree } from "./treeHelper";
import './css/index.scss';
import {Add, Close, FullscreenSharp, KeyboardReturnOutlined, Remove} from '@material-ui/icons';
import { Minimize } from "@material-ui/icons";
import { connect } from "react-redux";

import Style from  '../../../assets/jss/material-dashboard-react/views/contentExplorerStyle';
import { getPlaylistByIdAction } from "../actions";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyle = makeStyles(Style)

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
    '& .Tree':{
      marginLeft:"30px",
      [theme.breakpoints.down('sm')]:{
        marginLeft:"15px"
      }
    }
}))

const style = {
  position: 'absolute',
  top: '0%',
  left: '0%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  height:'85%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const KalturaExplorer = (props)=>{
    const [treeChange,setTreeChange]=useState(true);
    const [zoomNumber,setZoomNumber] = useState(0.5);
    const WheelzoomNumber  = useRef(0)
    const [treeValue,setTreeValue] = useState([])
    const [Fullscreen,setFullScreen] = useState(false)
    const [open,setOpen] = useState(false)
    const [imageWidth, setImageWidth] = useState(170.6666);
    const [imageHeight, setImageHeight] = useState(96);
    const [imgRatio,setImgRatio] = useState(props?.imageRatio?.[0]);

    const classes = useStyle();

    const handleFullScreen = () =>{
        setFullScreen(true);
    }
   


    const handleClose = ()=>{
      setFullScreen(false);
    }

    useEffect(()=>{
      setImgRatio(props?.imageRatio?.[0]);
    },[]);

    const handleRatio = (event,data)=>{
      setImgRatio(data)
    }


    useEffect(()=>{
      var ratio= imgRatio?.trim().replace(/\D/g,'/')
      var imageCal=eval(ratio)
      if(imageCal>1.5){
        setImageHeight(96);
        setImageWidth(170);
      }
      if(imageCal>0.5 && imageCal <1) {
        setImageHeight(96);
        setImageWidth(64);
      }
      if(imageCal>1 && imageCal<=1.5){
        setImageWidth(144);
        setImageHeight(96);
      }
      if(imageCal<0.5){
        setImageWidth(54);
        setImageWidth(96);
      }
      if(imageCal== 1){
        setImageHeight(96);
        setImageWidth(96);
      }
    },[imgRatio])


    
    const containerStyles = {
        width: "100vw",
        height: "100vh"
      };
    //const [translate, containerRef] = useCenteredTree();
    const zoomIn = ()=>{
        if(WheelzoomNumber.current>0){
          setZoomNumber(WheelzoomNumber.current+0.1);
        }else{
          WheelzoomNumber.current = 0 
          setZoomNumber(zoomNumber+0.1);
        }
    }
    const zoomOut = ()=>{
      if(WheelzoomNumber.current>0){
        setZoomNumber(WheelzoomNumber.current-0.1);
      }else{
        WheelzoomNumber.current = 0 
        setZoomNumber(zoomNumber-0.1);
      }
  }
    const TreeView = ()=>{
      WheelzoomNumber.current = 0 
      setTreeChange(false)
    }

    const TreeViewVer = () =>{
      WheelzoomNumber.current = 0 
      setTreeChange(true);
    }
    const [translate, containerRef,dimensions] = useCenteredTree();
    

    const handleClick = (nodeData,evntdata)=>{
      props.getPlaylistByIdAction(nodeData,props.projectDetails.p_id);
      setOpen(true);
    }

    const handlePlaylistClose = ()=>{
      setOpen(false);
      setImgRatio(props.imageRatio?.[0])
    }

    const renderNodeWithCustomEvents = ({
      nodeDatum,
      toggleNode,
      handleNodeClick
    }) => 
    (
      <g>
        <circle r="15" onClick={nodeDatum.children ? toggleNode : ()=>{
          handleClick(nodeDatum?.id);
        } } />
        <text fill="white"  strokeWidth="0" x="20" dy="25" >
              {nodeDatum?.name}
        </text>  
        <text fill="white" x="20" dy="45" strokeWidth="0">
              {nodeDatum?.childrenCount} 
        </text>
        <text fill="white" x="20" dy="65" strokeWidth="0" >
                Id:{nodeDatum?.id}
        </text>
      </g>
    );

    const renderNodeWithCustomEventsHorizontal = ({
      nodeDatum,
      toggleNode,
      handleNodeClick
    }) => 
    (
      <g>
        <circle r="15" onClick={nodeDatum.children ? toggleNode : ()=>{
          handleClick(nodeDatum?.id);
        } }/>
        <text fill="white" strokeWidth="0" x="23" dy="15" >
              {nodeDatum?.name}
        </text>  
        <text fill="white" x="24" dy="30" strokeWidth="0">
              {nodeDatum?.childrenCount}
        </text>
        <text fill="white" x="24" dy="45" strokeWidth="0">
                id:{nodeDatum?.id}
        </text>
      </g>
    );

    if(props.loading){
      return (
        <Box>
           {props?.visualizationErrorMessage?props?.visualizationErrorMessage: "Loading..."}
        </Box>
      )
    }
    else{
    return (
        <Box paddingTop='16px' paddingBottom='16px'>
        <Box paddingBottom='24px' display='flex' justifyContent='center' gridColumnGap='5px'>
        {treeChange ?
            <Button variant="contained" className={classes.addButton} onClick={TreeView}>
                <Typography className={classes.ButtonText}>Horizontal</Typography>
            </Button>
             :
            <Button variant="contained" className={classes.addButton} onClick={TreeViewVer}>
                <Typography className={classes.ButtonText}>vertical</Typography>
            </Button>
            }
            {"                      "}
            <Button className={classes.addButton} style={{width:'20px'}} onClick={zoomIn}>
              <Add className={classes.ButtonText} style={{fontSize:"20px"}}/>
            </Button> 
             <Typography style={{paddingTop:'7px'}}>   Zoom   </Typography> 
            <Button className={classes.addButton} style={{width:'20px'}} onClick={zoomOut}>
              <Remove className={classes.ButtonText} style={{fontSize:"20px"}}/>
            </Button> 
         </Box>   
            <div className="breadcrumb">
                {/*<Breadcrumb
                    routeSegments={[
                        { name: 'Visualization', path: '/material/visualization' },
                        { name: 'Tree' },
                    ]}
                />
                <Box py="12px" />*/}
                <Paper className={classes.visualization}>
                   <Box paddingTop="10px" paddingLeft='10px'justifyContent='flex-end' display='flex' flexDirection='row' alignItems='center' paddingRight='24px'>
                       <Box paddingLeft='10px' paddingTop='10px' flexGrow='1'>
                          <Typography className={classes.text}>Total Playlist Counts : {props?.data?.totalPlaylistCount}</Typography>
                          <Typography className={classes.text}>Total Assets Counts : {props?.data?.totalAssetCount}</Typography>
                       </Box>
                     <Button onClick={handleFullScreen} endIcon={<FullscreenSharp style={{color:"white"}}/>}>
                          <Typography className={classes.FullScreenText}>Fullscreen</Typography>
                      </Button>
                    </Box>
                  {treeChange ? 
                     <div  style={{ width: '100%', height: "50vh" }}>
                     <Tree 
                           data={props?.data}
                           initialDepth={1}
                           renderCustomNodeElement={renderNodeWithCustomEvents}
                           orientation='vertical'
                           zoom={zoomNumber}
                           scaleExtent={{ max: 2, min: 0.5 }}
                           translate={{x:650,y:15}}
                           nodeSize={{
                                x:180,
                                y:80
                            }} 
                            pathFunc='elbow'
                            rootNodeClassName="root_node"
                            onNodeClick={handleClick.bind(this)}
                            onUpdate={(target)=>{
                              WheelzoomNumber.current = target.zoom      
                            }}
                      />
                     </div>
                     :
                     <div  id="treeWrapper" style={{ width: '100%', height: "100vh" }} ref={containerRef} >
                       <Tree 
                           data={props?.data}
                           initialDepth={1}
                           renderCustomNodeElement={renderNodeWithCustomEventsHorizontal}
                           orientation='horizontal'
                           zoom={zoomNumber}
                           scaleExtent={{ max: 1, min: 0.5 }}
                           zoomable={true}
                           nodeSize={{
                                x:200,
                                y:80
                            }}
                            translate={{x:350,y:200}}
                            onNodeClick={handleClick.bind(this)}
                            onUpdate={(target)=>{
                              WheelzoomNumber.current = target.zoom      
                            }}
                         />
                    </div> 
                  }
                  </Paper>
                  <Modal
                     open={Fullscreen}
                     onClose={handleClose}
                     aria-labelledby="modal-modal-title"
                     aria-describedby="modal-modal-description"
                    >
                    <Box className={classes.fullScreen}>
                    <Box  display='flex' justifyContent='flex-end'>
                        <Button onClick={handleClose} className={classes.closeButton}>
                             <Close style={{color:'black'}}/>
                        </Button>
                    </Box>
                    {treeChange ? 
                     <div  style={{ width: '100%', height: "100vh" }}>
                     <Tree 
                           data={props?.data}
                           initialDepth={1}
                           renderCustomNodeElement={renderNodeWithCustomEvents}
                           orientation='vertical'
                           zoom={zoomNumber}
                           scaleExtent={{ max: 2, min: 0.5 }}
                           translate={{x:650,y:15}}
                           nodeSize={{
                                x:180,
                                y:80
                            }} 
                            pathFunc='elbow'
                            rootNodeClassName="root_node"
                            onNodeClick={handleClick.bind(this)}
                      />
                     </div>
                     :
                     <div  id="treeWrapper" style={{ width: '100%', height: "100vh" }} ref={containerRef} >
                       <Tree 
                           data={props?.data}
                           initialDepth={1}
                           renderCustomNodeElement={renderNodeWithCustomEventsHorizontal}
                           orientation='horizontal'
                           zoom={zoomNumber}
                           scaleExtent={{ max: 1, min: 0.5 }}
                           zoomable={true}
                           nodeSize={{
                                x:200,
                                y:80
                            }}
                            translate={{x:350,y:200}}
                            onNodeClick={handleClick.bind(this)}
                         />
                    </div> 
                  }
                     </Box>
                  </Modal>
            </div>
            {props?.allAssetsDetails?.totalCount !== null  ? 
            <div>
              <Dialog open={open}
                     onClose={handlePlaylistClose}
                     
                     >
                     <Box margin='10px'> 
                        <Box style={{paddingLeft:'2%',paddingTop:'1%'}}>
                            <Typography className={classes.playlistText}> Playlist Details</Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.subText}>Playlist Assets Count</Typography>
                          <Typography className={classes.subText1}>{props?.allAssetsDetails?.totalCount}</Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.subText}>Select Image Type</Typography>
                           <Autocomplete 
                             options={props.imageRatio}
                             getOptionLabel={(option) => option}
                             style={{ width: 200,paddingLeft:'10px', }}
                             size='small'
                             defaultValue={props.imageRatio?.[0]}
                             renderInput={(params) => (
                             <TextField
                                 size="small"
                                 //className={classes.textField}
                                 {...params}
                                 placeholder="Select the number of item in Carousel *"
                                 variant="outlined"
                                />
                              )}
                              onChange={handleRatio}
                          />
                        </Box>
                        <Box style={{paddingBottom:'40px',height:'200px'}}>
                            <Typography className={classes.subText}>Assets</Typography>
                            <Box className={classes.playlistBox} marginLeft='3%'>
                            {props?.allAssetsDetails?.objects?.map((image, index) => {
                                if (index <= props?.allAssetsDetails?.objects?.length) {
                                   return image.images.map((img, index) =>{
                                   var ratio=img.ratio?.trim().replace(/\D/g,'x')
                                   var vivImageRtio=imgRatio?.trim().replace(/\D/g,'x')
                                    return vivImageRtio == ratio ? (
                                      <img
                                          key={index}
                                           style={{
                                             width: imageWidth * 1.6,
                                             height: imageHeight * 1.35,
                                             marginLeft: "20px",
                                             borderRadius: "10px",
                                           }}
                                         src={ img.url +`/width/${imageWidth * 2}/height/${imageHeight * 2}`  }
                                      />
                                      
                                   ) : null
                                 } );
                               }
                            })}
                            </Box>
                        </Box>
                     </Box>
              </Dialog>
            </div>
            : "" }
    </Box>    
    )
   }
}

const mapStateToProps = (state)=>{
  return{
    loading:state.ContentExplorerReducer.loading,
    allAssetsDetails:state.ContentExplorerReducer.allAssetsDetails,
    projectDetails:state.ProjectReducer.projectDetails,
    visualizationErrorMessage:state.ContentExplorerReducer.visualizationErrorMessage

  }
}

const mapStateToDispatch = (dispatch)=>{
  return{
    getPlaylistByIdAction:(playlistId,projectId)=>{
      dispatch(getPlaylistByIdAction(playlistId,projectId))
   }
  }
}

export default connect(mapStateToProps,mapStateToDispatch)(KalturaExplorer);




  