import {
    drawerWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb,
    darkText,
  } from "assets/jss/material-dashboard-react.js";
  

  const modalStyle={
 modalBox:{
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   background: 'rgba(0, 0, 0, 0.6)',
   backdropFilter: 'blur(6px)'
    
 },
 
  }
  export default modalStyle