import {
  CREATE_DICT_API,
  CREATE_DICT_SUCCESS,
  GET_DICT_API,
  GET_DICT_SUCCESS,
  PUBLISH_DICT_API,
  PUBLISH_DICT_SUCCESS,
  RESET_DICT,
  BULK_UPLOAD_ACTION,
  BULK_UPLOAD_RESPONSE,
  EDIT_DICT_ACTION,
  DELETE_DICT_ACTION,
  DELETE_DICT_RESPONSE,
  EDIT_DICT_RESPONSE

} from "../actionTypes";

export const getDictAction = (p_id,platform) => {
  return { type: GET_DICT_API, payload:{p_id:p_id,platform:platform}}
};

export const getDictSuccessfully = (payload) => {
  return { type: GET_DICT_SUCCESS, payload };
};

export const createDictAction = (payload) => {
  return { type: CREATE_DICT_API, payload };
};

export const createDictSuccessfully = (payload) => {
  return { type: CREATE_DICT_SUCCESS, payload };
};

export const publishDictAction = (payload) => {
  return { type: PUBLISH_DICT_API, payload };
};



export const publishDictSuccessfully = (payload) => {
  return { type: PUBLISH_DICT_SUCCESS, payload };
};

export const resetDictAction = (payload) => {
  return { type: RESET_DICT, payload };
};

export const editDictAction=(payload)=>{
  console.log("Paylod edit api",payload)
  return{
    type:EDIT_DICT_ACTION,
    payload:payload
  }
}

export const editDictRespone=(data)=>{
  return{
    type:EDIT_DICT_RESPONSE,
    payload:data
  }
}

export const deleteDictAction=(payload)=>{
  return{
    type:DELETE_DICT_ACTION,
    payload:payload
  }
}

export const deleteDictRespone=(data)=>{
  return{
    type:DELETE_DICT_RESPONSE,
    payload:data
  }
}

export const bulkUploadAction=(p_id,file)=>{
  console.log("Api for bulk upload",p_id,file);
  return{
    type:BULK_UPLOAD_ACTION,
    payload:{p_id:p_id,file:file}
  }
}