export const mediaIntialState={
    loading:false,
    categories:[],
    playList:[],
    loadingPlayList:false,
    error:false,
    errorMessage:'',
    widgets:[],
    playListFixed:[],
    totalPlayList:'',
    projectAspectRatio:[],
    widgetReset:false, 
    widgetEdit:false,
    widgetDelete:false,
    widgetDeleteRes:{},
    createdWidget:{},
    editWidget:{},
    categoryTreeErrorMessage:"",
    widgetUsage:[]
}