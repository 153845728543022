import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    { "timeline": "2024-08-01", "anonymous": 1000, "normal_users": 1200, "premium_users": 900 },
    { "timeline": "2024-08-02", "anonymous": 1400, "normal_users": 1300, "premium_users": 950 },
    { "timeline": "2024-08-03", "anonymous": 1600, "normal_users": 5000, "premium_users": 1100 }, // normal_users spike
    { "timeline": "2024-08-04", "anonymous": 1800, "normal_users": 1700, "premium_users": 3000 }, // premium_users spike
    { "timeline": "2024-08-05", "anonymous": 8000, "normal_users": 1900, "premium_users": 1400 }, // anonymous spike
    { "timeline": "2024-08-06", "anonymous": 2200, "normal_users": 2100, "premium_users": 1500 },
    { "timeline": "2024-08-07", "anonymous": 2300, "normal_users": 2200, "premium_users": 1600 },
    { "timeline": "2024-08-08", "anonymous": 2400, "normal_users": 7000, "premium_users": 1700 }, // normal_users spike
    { "timeline": "2024-08-09", "anonymous": 5000, "normal_users": 2400, "premium_users": 1800 }, // anonymous spike
    { "timeline": "2024-08-10", "anonymous": 2600, "normal_users": 2500, "premium_users": 1900 },
    { "timeline": "2024-08-11", "anonymous": 2700, "normal_users": 2600, "premium_users": 2000 },
    { "timeline": "2024-08-12", "anonymous": 2800, "normal_users": 2700, "premium_users": 2100 },
    { "timeline": "2024-08-13", "anonymous": 2900, "normal_users": 2800, "premium_users": 2200 },
    { "timeline": "2024-08-14", "anonymous": 3000, "normal_users": 2900, "premium_users": 2300 },
    { "timeline": "2024-08-15", "anonymous": 3100, "normal_users": 3000, "premium_users": 2400 },
    { "timeline": "2024-08-16", "anonymous": 3200, "normal_users": 9000, "premium_users": 2500 }, // normal_users major spike
    { "timeline": "2024-08-17", "anonymous": 8000, "normal_users": 3200, "premium_users": 5000 }, // anonymous and premium_users spikes
    { "timeline": "2024-08-18", "anonymous": 3400, "normal_users": 3300, "premium_users": 2700 },
    { "timeline": "2024-08-19", "anonymous": 3500, "normal_users": 3400, "premium_users": 2800 },
    { "timeline": "2024-08-20", "anonymous": 3600, "normal_users": 3500, "premium_users": 2900 },
    { "timeline": "2024-08-21", "anonymous": 9000, "normal_users": 3600, "premium_users": 7000 }, // anonymous and premium_users spikes
    { "timeline": "2024-08-22", "anonymous": 3800, "normal_users": 3700, "premium_users": 3100 },
    { "timeline": "2024-08-23", "anonymous": 3900, "normal_users": 8000, "premium_users": 3200 }, // normal_users spike
    { "timeline": "2024-08-24", "anonymous": 4000, "normal_users": 3900, "premium_users": 3300 },
    { "timeline": "2024-08-25", "anonymous": 4100, "normal_users": 4000, "premium_users": 3400 },
    { "timeline": "2024-08-26", "anonymous": 12000, "normal_users": 4100, "premium_users": 3500 }, // anonymous huge spike
    { "timeline": "2024-08-27", "anonymous": 4300, "normal_users": 8000, "premium_users": 3600 }, // normal_users spike
    { "timeline": "2024-08-28", "anonymous": 4400, "normal_users": 4300, "premium_users": 3700 },
    { "timeline": "2024-08-29", "anonymous": 8000, "normal_users": 4400, "premium_users": 3800 }, // anonymous spike
    { "timeline": "2024-08-30", "anonymous": 4600, "normal_users": 4500, "premium_users": 3900 }
  ];
  

const UserAreaChart = () => {
  return (
    <ResponsiveContainer width="100%" >
      <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
    
        <XAxis dataKey="timeline"tick={{ fill: "#000",
            fontSize: 14,     
            fontWeight: 400,
            }}
          />
        <YAxis tick={{ fill: "#000",
            fontSize: 14,     
            fontWeight: 400,
            }}
          />
        <Tooltip />
        <Area type="monotone" dataKey="anonymous" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="normal_users" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        <Area type="monotone" dataKey="premium_users" stackId="1" stroke="#ffc658" fill="#ffc658" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default UserAreaChart;
