import axios from "axios";
import { VIEW_PACKAGE_DETAILS } from "config";
import { SIA_BASE_URL } from "config";
import { VIEW_JSON_API } from "config";
// import {REACT_APP_SIA_GET_PACKAGES_BASE_URL, REACT_APP_SIA_POST_PACKAGES_BASE_URL,SUBSCRIPTION_PACKAGE_POST_API_KEY} from "../../../config/config";

export const handleGetAllPackageApi = async (data) => {
  console.log(data,"get api hit");
  
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_PACKAGES_BASE_URL}/subscription/config/${data?.t_id}/${data.p_id}/packages.json`,
    headers: {
      // "x-api-key":"XoBKmRJ8rF199s5ecOqhD6qmLhIPoXiR2qFt6Q6g",
    },
  };
  const res = await axios.request(options);
  console.log(res,"pckgres")
  return res.data;
};

export const handleUpdateJsonDetailsApi = async (data) => {
  try {
    console.log("update",data, data.allPackages);
    const options = {
      method: "POST",
      url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/subscription/packages?p_id=${data.p_id}`,
      data: data?.allPackages,
      headers: {
        "x-api-key":process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    };
    const res = await axios.request(options);
    console.log("res", res);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const handleAddNewJsonDetailsApi = async (data) => {
  const options = {
    method: "POST",
    url: process.env.SIA_PROD_BASE_URL + "/sia/package/json",
    data: {
      package_details: data,
    },
    headers: {
      "x-api-key": process.env.SIA_PROD_X_API_KEY,
    },
  };
  const res = await axios.request(options);
  return res.data;
};
