import { Box, Typography, Switch, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom'; 
import LayersIcon from '@mui/icons-material/Layers';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ScheduleIcon from "assets/img/settings/BlackoutSchedule.svg";
import MessageDirectory from "assets/img/settings/MessageDirectory.svg";
import Pushnotification from "assets/img/settings/Pushnotification.svg";
import Logout from "assets/img/settings/Logout.svg";
import Subscription from "assets/img/settings/Subscription.svg";

const CustomList = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ListItem
        icon={<img src={ScheduleIcon} alt="Blackout Schedule Icon" style={{ width: '30px', height: '30px' }} />}
        title="Blackout Schedule"
        description="Schedule custom blackouts for your platform"
        path="/admin/blackoutschedule"
      />
      <ListItem
        icon={<img src={MessageDirectory} alt="Blackout Schedule Icon" style={{ width: '30px', height: '30px' }} />}
        title="Message Directory"
        description="Modify and schedule custom messages for your users"
        path="/admin/messagedict"
      />
      <ListItem
        icon={<img src={Subscription} alt="Blackout Schedule Icon" style={{ width: '30px', height: '30px' }} />}
        title="Subscription Models"
        description="Create custom subscription packages for your platform"
        path="/admin/subscription-models"
      />
      <ListItem
        icon={<img src={Pushnotification} alt="Blackout Schedule Icon" style={{ width: '30px', height: '30px' }} />}
        title="Push Notifications"
        description="Modify how and when your users are given updates"
        path="/admin/pushnotification"
        switchChecked={true}
      />
    </Box>
  );
};

const ListItem = ({ icon, title, description, path, switchChecked = false }) => {
  const history = useHistory(); 

  const handleClick = () => {
    history.push(path); 
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        backgroundColor: '#e8ebf8',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        '&:hover': { backgroundColor: '#d7daf3' }, 
      }}
    >
      <Box display="flex" alignItems="center">
        {icon}
        <Box ml={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: '#6b6b6b' }}>
            {description}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton>
          <OpenInNewIcon sx={{ color: '#6b6b6b' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomList;
