import { put, call, takeLatest, all, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { X_API_KEY } from "config/config";
import { getAseetDetailsAction, getCategoryTreeVisualizationData, succcessfullyGetPlaylistById ,getCategoryTreeVisualizationErrorMessage} from "../actions";
import { getAssetDetailsApi,getAllCategoriesApi, getPlaylistByIdApi,clearCaheApiCall } from "../apis/contentExplorerApi";

function* handleGetAssetById ({payload,projectID, cb}){
    const AssetDetails  = yield call(getAssetDetailsApi,payload,projectID)
    cb()
    yield put(getAseetDetailsAction(AssetDetails))
}

function* handleGetCategories({payload}){
    const AllCategoreies = yield call(getAllCategoriesApi,payload)
    if(AllCategoreies?.data?.hzStatus === 200){
      yield put(getCategoryTreeVisualizationData(AllCategoreies?.data?.data))
    }
    else if(AllCategoreies.hzStatus===400){
        yield put(getCategoryTreeVisualizationErrorMessage(AllCategoreies.message))
    }
}

function* handleGetPlaylistById({payload,projectId}){
    const allAssests = yield call(getPlaylistByIdApi,payload,projectId)
    if(allAssests.hzStatus === 200){
        yield put(succcessfullyGetPlaylistById(allAssests.data))
    }
}


//Handle Clear cache Action
function* handleClearCacheAction({payload}){
    const clearCache = yield call(clearCaheApiCall, payload);
    console.log("Saga for clearing cache called",payload,clearCache)
  
  }

export default function* watchContentExplorerSaga (){
    yield takeLatest('GET_ASSET_BY_ID',handleGetAssetById)
    yield takeLatest('GET_CATEGORY_TREE_VISUALIZATION',handleGetCategories)
    yield takeLatest('GET_PLAYLIST_BY_ID',handleGetPlaylistById)
    yield takeLatest('CLEAR_CACHE_ACTION',handleClearCacheAction)

}