import multipleAcc from '../../../assets/img/newLogin/Multiple_accounts.png';
import gamification from '../../../assets/img/newLogin/Gamificaiton.png';
import personalisedExp from '../../../assets/img/newLogin/Personalised_experiences.png';

export const images = [
  {
    id: 1,
    path: multipleAcc,
    title: 'One Account, Multiple Experiences ',
    content:
      'With Vivre, you can have multiple profiles on the same account, and each profile will have it’s own personalised experience.',
  },
  {
    id: 2,
    path: gamification,
    title: 'Take streaming to the next level',
    content:
      'Unlock achievements, earn points, and compete with friends as you stream movies. Turn your movie nights into an epic quest with our gamified experience.',
  },
  {
    id: 3,
    path: personalisedExp,
    title: 'Take streaming to the next level',
    content:
      'Unlock achievements, earn points, and compete with friends as you stream movies. Turn your movie nights into an epic quest with our gamified experience.',
  },
]
