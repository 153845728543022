import axios from "axios";

export const handleGetUsersApi = async () => {
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-all-user`,
    headers: {
      "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const res = await axios.request(options);
  return res.data;
};

export const handleCreateUserApi = async (userDetails) => {
  const options = {
    method: "POST",
    url: `${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/create-user`,
    data: userDetails,
    headers: {
      "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const res = await axios.request(options);
  return res;
};

export const handleUpdateUserApi = async (userDetails)=>{
  console.log('sss',userDetails);
  const options = {
    method:"PUT",
    url:`${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-user/`+userDetails._id,
    data:userDetails,
    headers: {
      "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    }
  }
  const res = await axios.request(options);
  return res
}

export const handleDeleteUserApi = async (userDetails)=>{
  console.log('details',userDetails)
  const options = {
    method:"DELETE",
    url:`${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/delete-user/`+userDetails._id,
    headers: {
      "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  }
  const res = await axios.request(options);
  return res;
}

export const handleAuditLogsAPI = async (projectDetails)=>{
  let params;
  if(projectDetails.username){
     params = {
      p_id:projectDetails.p_id,
      username:projectDetails.username
    }
  }
  else{
     params = {
      p_id:projectDetails.p_id
     }
  }
  const options = {
    method:'GET',
    url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-audit-logs`,
    headers:{
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    params:params
  }
  const res = await axios.request(options)
  return res
}

export const handleDownloadAuditApi = async (projectId)=>{
//   if(projectDetails.username){
//     params = {
//      p_id:projectDetails.p_id,
//      username:projectDetails.username
//    }
//  }
//  else{
//     params = {
//      p_id:projectDetails.p_id
//     }
//  }
  const options = {
    method:'GET',
    url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-audit-logs-csv`,
    headers:{
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
    params:{
      p_id:projectId
    }
  }
  const res = await axios.request(options)
  return res
}
