export const GET_DICT_API = "GET_DICT_API";
export const GET_DICT_SUCCESS = "GET_DICT_SUCCESS";
export const PUBLISH_DICT_API = "PUBLISH_DICT_API";
export const PUBLISH_DICT_SUCCESS = "PUBLISH_DICT_SUCCESS";
export const CREATE_DICT_API = "CREATE_DICT_API";
export const CREATE_DICT_SUCCESS = "CREATE_DICT_SUCCESS";
export const RESET_DICT = "RESET_DICT";
export const BULK_UPLOAD_ACTION='BULK_UPLOAD_ACTION'
export const BULK_UPLOAD_RESPONSE='BULK_UPLOAD_RESPONSE';
export const EDIT_DICT_ACTION='EDIT_DICT_ACTION';
export const EDIT_DICT_RESPONSE='EDIT_DICT_RESPONSE';
export const DELETE_DICT_ACTION='DELETE_DICT_RESPONE';
export const DELETE_DICT_RESPONSE='DELETE_DICT_RESPONSE'