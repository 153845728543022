const screenStyle = {
  menuItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  typo: {
    display: "flex",
    alignItems: "center",
    fontFamily: "open sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: "#777777",
  },
  icon: {
    width: "24px",
    height: "24px",
    left: "1334px",
    top: "168px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    "border-radius": "33px",
  },
  editButton:{
    color:'#FFFFFF',
    fontSize: '16px',
    lineHeight:'140%',
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    marginTop:'6px'
  },
  addnew: {
    width: "53px",
    height: "16px",
    // left: "1319px",
    // top: "194px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "12px",
    "line-height": "16px",
    "text-align": "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
  },
  Widgetsearch: {
    height: "40px",
    background: "#FFFFFF",
    "border-radius": "45px",
  },
  noborder: {
    border: "none",
  },
  preview: {
    display: "flex",
    justifyContent: "center",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  placehold: {
    "&::placeholder": {
      color: "#777777",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },

  widgetBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    overflow: "auto",
    maxHeight: "420px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  widgetContainer: {
    maxWidth:"90%",
    borderRadius: "24px",
    padding: "19px",
    zIndex: 999,
    backgroundColor: "#CFDFFF",
  },
  widgetCard: {
    //width: '372px',
    maxHeight: "500px",
    background: "rgba(255, 255, 255, 0.6)",
    "border-radius": "16px",
    boxShadow: "none",
  },
  widgetName: {
    // width: "200px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "16px",
    "line-height": "140%",
    color: "#424242",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "white-space": "nowrap",
    "maxWidth":'250px',
  },
  widgetID: {
    width: "167px",
    height: "17px",
    left: "1016px",
    top: "359px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "12px",
    "line-height": "140%",
    color: "#777777",
    paddingTop: "8px",
  },
  textfield: {
    boxSizing: "border-box",
    background: "#FCFCFC",
    border: "1px  #C7C7C7",
    borderRadius: "8px",
  },
  inputlabel: {
    height: "20px",
    top: "calc(50% - 20px/2 + 26px)",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    color: " #141D46",
    paddingBottom: "8px",
  },
  imageType: {
    "box-sizing": "border-box",
    maxWidth: "108px",
    height: "60px",
    left: "1016px",
    top: "517px",
    background: "#FFFFFF",
    "backdrop-filter": "blur(20px)",
    "border-radius": "8px",
    border: "1px dashed #D0D0D1",
    color: "#424242",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
    boxShadow: "none",
  },
  selectImage: {
    "box-sizing": "border-box",
    maxWidth: "108px",
    height: "60px",
    "box-shadow": "0px 8px 20px rgba(63, 39, 102, 0.12)",
    "backdrop-filter": "blur(20px)",
    color: "#0192D4",
    border: "1px solid transparent",
    background:
      "linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "140%",
  },
  editLink: {
    width: "144px",
    height: "19px",
    left: "1114px",
    top: "739px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 700,
    "font-size": "14px",
    "line-height": "19px",
    "text-align": "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
  },
  screensContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingBottom: "6px",
    maxHeight: "420px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "-webkit-overflow-scrolling": "touch",
  },
  createScreenCard: {
    display: "flex",
    alignItems: "start",
    width: "auto",
    left: "1000px",
    top: "230px",
    background: "rgba(255, 255, 255, 0.7)",
    "border-radius": "8px",
    paddingTop: "12px",
    flexDirection: "column",
    paddingBottom: "10px",
  },
  screenCard: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: "rgba(255, 255, 255, 0.7)",
    "border-radius": "8px",
    flexDirection: "column",
    userSelect: "none",
  },
  screenName: {
    maxWidth: "155px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "16px",
    "line-height": "140%",
    color: "#424242",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  screenNo: {
    width: "28px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "16px",
    "line-height": "140%",
    color: "#424242",
    marginLeft: "24px",
  },
  screenDelete: {
    height: "22px",
    cursor: "pointer",
  },
  screenAction: {
    display: "flex",
    margin: "0",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
  },
  screenBar: {
    width: "99%",
    height: "52px",
    background: "#3467C6",
    "border-radius": "12px",
    boxShadow: "none",
  },
  screenIcon: {
    fontSize: "2.3rem",
    cursor: "pointer",
  },
  screenIconList: {
    fontSize: "1.8rem",
  },
  screenTab: {
    maxWidth: "100%",
    width: "auto",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "17px",
    "line-height": "140%",
    color: "#FFF",
    textTransform: "none",
  },
  selectedScreenTab: {
    maxWidth: "100%",
    width: "auto",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "17px",
    "line-height": "140%",
    color: "#FFFF",
    marginBottom: 0,
    textTransform: "none",
  },
  screenTabs: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  saveButton: {
    width: "80px",
    height: "30px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
  },
  ButtonText: {
    width: "111px",
    height: "22px",
    left: "calc(50% - 311px/2 - 0.5px)",
    top: "calc(50% - 22px/2)",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 600,
    "font-size": "16px",
    "line-height": "140%",
    "text-align": "center",
    color: "#FFFFFF",
  },

  lableIcon: {
    minHeight: "50px",
    paddingTop: "9px",
  },

  //List and Grid view button main container
  mainContainerListAndGridViewButton: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
  },
  gridListButtonsContainer: {
    display: "flex",
    borderRadius: "43px",
    backgroundColor: "#1D2F46",
    width: "76px",
    height: "40px",
    justifyContent: "space-around",
    alignItems: "center",
  },
  gridOrListViewButton: {
    display: "flex",
    justifyContent: "center",

    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  listSelected: {
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    // transition: "all 150ms ease 0s",
  },
  gridSelected: {
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    //transition: "all 150ms ease 0s",
  },

  //Device Previce Styles
  devicePreviewContainer: {
    display: "flex",
    borderRadius: "43px",
    //backgroundColor: "#1D2F46",
    width: "40px",
    height: "40px",
    justifyContent: "space-around",
    alignItems: "center",
  },

  //Device Dialog styles
  deviceDialog: {
    position: "relative",
    background: "rgba(0, 0, 0, 0.7)",
    backgroundColor: "transparent",
    "backdrop-filter": "blur(16px)",
  },
  deviceImg: {
    width: "342.3px",
    height: "689px",
  },
  view: {
    position: "absolute",
    left: "10%",
    right: "10%",
    top: "12%",
    height: "80%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflow:"scroll"
  },
  laptopImg: { maxWidth: "65vw" },
  laptopView: {
    position: "absolute",
    left: "12.5%",
    right: "12.5%",
    top: "9.26%",
    bottom: "12.75%",
    "overflow-x": "hidden",
    "overflow-y": "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  laptopAppView: {
    position: "absolute",
    left: "9.09%",
    right: "9.72%",
    top: "3.26%",
    bottom: "12.75%",
    "overflow-x": "hidden",
    "overflow-y": "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  skeletonArray: {
    paddingRight: "13px",
    overflow: "auto",
    maxHeight: "560px",
    marginTop: "16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  widgetArray: {
    paddingRight: "13px",
    overflow: "auto",
    height: "784px",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "11px",
    marginBottom: "20px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  dialog: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#424242",
  },
  dialogFocus: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#141D46",
  },
  dropdown: {
    width: "100%",
    //height: '30px',
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: "#141D46",
    boxShadow: "none",
  },
  menuLabel: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "33px",
    color: "#777777",
  },
  switch: {
    "& .Mui-checked": {
      color: "#3f51b5",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#3f51b5 !important",
    },
  },
  screenAvatar: {
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "500px",
    background: `repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) center / 4px 4px`,
  },
  screenIconContainer: {
    display: "flex",
    borderRadius: "4px",
  },
  iconPrompt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gridColumnGap: "6%",
    justifyContent: "center",
  },
  collapsedScreenCard: {
    width: "100%",
    display: "flex",
    margin: "12px 0px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dragWidget: {
   //maxWidth:'80%',
    margin: "0 4px",
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
  },
  widgetCollapse: {
  height:'50vh',
    padding: "0 16px",
  overflow:'scroll'
  },

editAIWidgetMainCintainer:{
 // padding:'40px'
},
editAIWidgetTextfieldContainer:{
  display:'flex',
  justifyContent:'space-between',
  margin:'10px',
},

editAiWidgetTextField:{
  minWidth:'5vw',
  //margin:'5px'
  marginBottom:'5px'
},
editWidgetAiButtonContainer:{
  display:'flex',
  justifyContent:'flex-end'
},

editWidgetAiButton:{
  color:'#FFFFFF',
    fontSize: '10px',
    lineHeight:'140%',
    height: "30px",
    width: "fitContent",
    margin: "15px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    borderRadius:'33px'},
   
   
 calenderInputField:{

      display:'flex',
      flexDirection:'row'
    }

};

export default screenStyle;
