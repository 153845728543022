export const environment = {
  name: "Experience manager",
  BASE_URL: "https://7z6ud4os4g.execute-api.ap-south-1.amazonaws.com${process.env.REACT_APP_ENV}/v1",
  x_api_key: "KcxUBNAwFn2shTij8PeuQ5KHMK7Jnjai7l9DvKXg",
  BASE_URL_V2: "https://7z6ud4os4g.execute-api.ap-south-1.amazonaws.com${process.env.REACT_APP_ENV}/v2",
};

export const homeApis = {
  getCategoryTree: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-category-tree`,
  getCategoryPlaylist: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-all-playlists-by-category`,
  getCategoryPlaylistv2: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v2/get-all-playlists-by-category`,
  createWidget: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/create-widget`,
  editWidget: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-widget`,
  deleteWidget:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/delete-widget`,
  getWidget: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-widgets`,
  getSingleWidget: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v3/get-widget`,
  getScreen: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-screen-admin`,
  uPdateScreen: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-screen`,
  updateScreenStatus: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-screen-status`,
  exportScreens: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/export-screen`,
  updateWidget: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/update-widget`,
  deleteScreen: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/delete-screen`,
  getProjetcByID: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-project`,
  getAllLinearChannel: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-linear-channels`,
  getAllBlackoutScheduleOfChannel: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-blackouts`,
  getEPG: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/get-epg`,
  scheduleBlackout: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/schedule-blackout`,
  deleteBlackout: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/delete-blackout`,
  getAllBlackout: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/blackouts/all`,
  publishSplash: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-splash`,
  publishIntro: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/config/add-intro-screen`,
  updateScreenDetails:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/screen/property/update`,
  clearchacheApi:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/cache/delete`,
  searchApi:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/search-widget`,
  getPushnotifications: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/notifications`,
  updatePushnotifications: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/notifications/add`,
};
