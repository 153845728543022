import { Box, Dialog, makeStyles } from "@material-ui/core";
import React from "react";
import LaptopDevice from 'assets/img/laptop.png';

import style from "assets/jss/material-dashboard-react/views/screenStyle";
import GridScreenView from "./GridScreenView";
import DeviceScreenView from "./DeviceScreenView";
import LaptopScreenView from "./LaptopScreenView";
import Desktop from 'assets/img/Computer_monitor_with_white_screen_generated.jpg';
import { Skeleton } from "@material-ui/lab";


const useStyle = makeStyles(style);


const LaptopDevicePreview = (props)=>{
    const classes = useStyle();

    const handleClose = ()=>{
        props.setLaptopPreview(false)
    }

    return (
        <Box>
            <Dialog open={props.laptopPreview} onClose={handleClose}
             maxWidth='lg'
             style={{
                width:'73%',
                //height:'100%',
                left:'13%'
             }}
             PaperProps={{
                       variant:'outlined',
                        style:{
                          //position: "relative",
                         background: "none",
                         backgroundColor: 'none',
                         backdropFilter: "blur(16px)",
                         borderColor:"rgb(0,0,0,0)",
                         }}} 
                         >
               <img src={LaptopDevice} className={classes.laptopImg} ></img>
               <Box className={classes.laptopView}>
                {
                  props?.widget?.some(widget=>{
                    if(typeof widget.containerId  ==="string"){
                        return true
                    }
                })? props?.widget?.filter((x)=>{
                  if(x?.item?.playlist?.type !== "K_PDF" && x?.item?.playlist?.type !== "ATHENA"){
                    if(x?.item?.playlist?.data?.result?.error?.code !== "4018"){
                      return x;
                    }
                  }
                }).map((widget,i)=>
                  <LaptopScreenView widget={widget}  />
                
                ):
                <>
                </>
                // <Skeleton
                //         variant="rect"
                //         component="div"
                //         style={{ height: "100px", marginBottom: "16px", borderRadius: "6px" }}
                //         key={"index"}
                //       />

                }
                 
               </Box>
               
            </Dialog>
        </Box>
    )
}

export default LaptopDevicePreview;