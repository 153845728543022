import React from "react";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./Slider.scss";
import classNames from "classnames";
import styles from "assets/jss/material-dashboard-react/components/ScreenViewStyle";
import { makeStyles } from "@material-ui/core";

import NextIcon from "assets/img/platformScreenVisualization/NextIcon.svg";
import BackIcon from "assets/img/platformScreenVisualization/BackIcon.svg";

import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(styles);

const NextArrow = ({ onClick, type }) => {
  const classes = useStyles();

  if (type === "Carousel") {
    return (
      <div className={classNames(classes.Arrows, classes.nextArrowCarousel)} onClick={() => onClick()}>
        <img src={NextIcon} />
      </div>
    );
  } else if (type === "HorizontalRail") {
    return (
      <div className={classNames(classes.Arrows, classes.nextArrowHorizontal)} onClick={() => onClick()}>
        <img src={NextIcon} />
      </div>
    );
  }
};

const BackArrow = ({ onClick, type }) => {
  const classes = useStyles();

  if (type === "Carousel") {
    return (
      <div className={classNames(classes.Arrows, classes.backArrowCarousel)} onClick={() => onClick()}>
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  } else if (type === "HorizontalRail") {
    return (
      <div className={classNames(classes.Arrows, classes.backArrowHorizontal)} onClick={() => onClick()}>
        <img src={BackIcon} alt="arrow" />
      </div>
    );
  }
};

const GridScreenView = props => {
  const [imageRatio, setImageRatio] = React.useState(1);

  React.useEffect(() => {
    if (eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) > 1) {
      setImageRatio(1);
    } else if (eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) <= 1) {
      setImageRatio(2);
    }
  });

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 6000,
    dots: true,
    arrows: true,
    slidesToShow: imageRatio,
    autoplay: true,
    swipeToSlide: true,
    centerPadding: (window.innerWidth - 1100) / 2 + "px",
    swipe: true,
    nextArrow: <NextArrow type="Carousel" />,
    prevArrow: <BackArrow type="Carousel" />,
  };

  //Events Function
  const responsive = {
    extraLarge: {
      breakpoint: { max: 800, min: 700 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      //Needed some changes here also
      items: eval(props?.widget?.kalturaOTTImageType?.trim().replace(/\D/g, "/")) > 1 ? 2 : 5,
      paritialVisibilityGutter: 30,
    },
  };

  //Calculation of the image resolution
  const imageResolutionCalc = ratio => {
    let nonNumericIndex;
    let height;
    let width;
    for (var i = 0; i < ratio.length; i++) {
      if (isNaN(ratio[i])) {
        nonNumericIndex = i;
        break;
      }
    }

    width = ratio.substr(0, nonNumericIndex) * 30;
    height = ratio.substr(nonNumericIndex + 1) * 30;
    return { height: height, width: width };
  };


  
   if ( typeof props?.widget?.containerId !=="string"){
    return <Skeleton
    variant="rect"
    component="div"
    style={{ height: "100px", marginBottom: "16px", borderRadius: "6px" }}
    key={props?.index}
  />

   }
  
   


  if(
    props?.widget?.item?.playlist?.type === "K_PDF" ||
    props?.widget?.item?.playlist?.type === "ATHENA" ||
    props?.widget?.item?.playlist?.data?.result?.error?.code == "4018" ||
    props?.widget?.type === "ADS"
  ){
 return   <div style={{ marginTop: "20px" ,display:"flex",alignItems:"center",
  justifyContent:"space-between"
 }}>
    <Typography
      style={{
        fontFamily: '"Open Sans","Roboto", "Helvetica", "Arial", sans-serif',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 15,
        marginTop: 5,
        marginBottom: 5,
      }}
    >
      {props?.widget?.name}
    </Typography>
    <Typography
      style={{
        fontFamily: '"Open Sans","Roboto", "Helvetica", "Arial", sans-serif',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 15,
        marginTop: 5,
        marginBottom: 5,
        marginRight:"20px"
      }}
    >
      {"No Preview"}
    </Typography>
  
</div>
  }




  //Horizontal Rails
  if (props?.widget?.layout === "CAR") {
    return props?.widget?.item?.playlist?.data?.result?.totalCount ? (
      <div style={{ marginTop: "26px", height: "", marginBottom: "20px" }}>
        <Slider {...settings}>
          {props?.widget?.item?.playlist?.data?.result?.objects?.map((widget, index) => {
            let resolution = imageResolutionCalc(props?.widget.kalturaOTTImageType);

            const calImageRatio = eval(props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/"));
            return (
              <div key={index}>
                {calImageRatio > 1 && calImageRatio <= 1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ==
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "80%",
                      borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                    }}
                  />
                ) : calImageRatio > 1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ==
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "90%",
                      borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                    }}
                  />
                ) : calImageRatio < 1 && calImageRatio > 0.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ==
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width * 8}/height/${resolution.height * 8}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "90%",
                      borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "60%",
                    }}
                  />
                ) : calImageRatio < 0.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ==
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "90%",
                      borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "60%",
                    }}
                  />
                ) : (
                  <img
                    alt="Imge"
                    src={`${
                      widget.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ==
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width * 8}/height/${resolution.height * 8}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "90%",
                      borderRadius: props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                    }}
                  />
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    ) : (
      <Typography
        style={{
          fontFamily: '"Open Sans","Roboto", "Helvetica", "Arial", sans-serif',
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 15,
          marginTop: 20,
          marginBottom: 5,
          marginLeft: -50,
          display: "flex",
          justifyContent: "center",
        }}
      >
        No Assets
      </Typography>
    );
  } else if (props?.widget?.layout === "HOR") {
    /*Carousel */

    return props?.widget?.item?.playlist?.data?.result?.totalCount ? (
      <div style={{ marginTop: "20px" }}>
        <Typography
          style={{
            fontFamily: '"Open Sans","Roboto", "Helvetica", "Arial", sans-serif',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 15,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          {props?.widget?.name}
        </Typography>
        <Carousel
          style={{ marginTop: "8px" }}
          swipeable={true}
          deviceType={props.deviceType}
          itemClass="image-item-grid"
          keyBoardControl={true}
          customTransition={"transform 500ms ease-in-out"}
          showDots={false}
          centerMode={true}
          customLeftArrow={<BackArrow type="HorizontalRail" />}
          customRightArrow={<NextArrow type="HorizontalRail" />}
          removeArrowOnDeviceType={["mobile", "tablet"]}
          sliderClass="sliderClassGrid"
          responsive={responsive}
          minimumTouchDrag={10}
          slidesToSlide={2}
          additionalTransfrom={0}
        >
          {props?.widget?.item?.playlist?.data?.result?.objects?.map((value, index) => {
            console.log("Image type", props?.widget);
            let resolution = imageResolutionCalc(props?.widget.kalturaOTTImageType);
            const calImageRatio = eval(props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/"));
            return (
              <div key={index}>
                {calImageRatio > 1 && calImageRatio < 1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        img => img.ratio.trim().replace(/\D/g, "x") === props.widget.kalturaOTTImageType
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "80%",
                      borderRadius: props?.widget?.item?.imageType == "LDS" ? "4px" : "45%",
                    }}
                  />
                ) : calImageRatio > 1.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ===
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    } /width/${resolution.width}/height/${resolution.height}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      width: "90%",
                      borderRadius: props?.widget?.item?.imageType == "LDS" ? "4px" : "45%",
                    }}
                  />
                ) : calImageRatio < 1 && calImageRatio > 0.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ===
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    }  /width/${resolution.width * 3}/height/${resolution.height * 3}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      height: 150,
                      borderRadius: props?.widget?.item?.imageType == "PR2" ? "4px" : "45%",
                    }}
                  />
                ) : calImageRatio < 0.5 ? (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ===
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    }  /width/${resolution.width * 3}/height/${resolution.height * 3}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      height: 150,
                      borderRadius: props?.widget?.item?.imageType == "PR2" ? "4px" : "45%",
                    }}
                  />
                ) : (
                  <img
                    alt="Imge"
                    src={`${
                      value.images.find(
                        img =>
                          img.ratio.trim().replace(/\D/g, "x") ===
                          props.widget.kalturaOTTImageType.trim().replace(/\D/g, "x")
                      )?.url
                    }  /width/${resolution.width * 8}/height/${resolution.height * 8}`}
                    style={{
                      objectFit: "contain",
                      textAlign: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                      height: 150,
                      borderRadius: props?.widget?.item?.imageType == "SQR" ? "4px" : "50%",
                    }}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    ) : (
      <Typography
        style={{
          fontFamily: '"Open Sans","Roboto", "Helvetica", "Arial", sans-serif',
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 15,
          marginTop: 20,
          marginBottom: 5,
          marginLeft: -50,
          display: "flex",
          justifyContent: "center",
        }}
      >
        No Assets
      </Typography>
    );
  } else if (props?.widget?.layout === "HRO") {
    if (props?.widget?.item?.imageSource === "MNL") {
      let resolution = imageResolutionCalc(props?.widget.kalturaOTTImageType);

      if (props?.widget?.item?.landingPage?.type === "PLT") {
        const calImageRatio = eval(props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/"));

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {calImageRatio > 1 && calImageRatio < 1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio > 1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio < 1 && calImageRatio > 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio < 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 8}/height/${resolution.height * 8}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                  maxWidth: "70%",
                }}
              />
            )}
          </div>
        );
      } else if (props?.widget?.item?.landingPage?.type === "AST") {
        const calImageRatio = eval(props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/"));

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {calImageRatio > 1 && calImageRatio < 1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio > 1.5 ? (
              <img
                style={{
                  maxWidth: "70%",
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                }}
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
              />
            ) : calImageRatio < 1 && calImageRatio > 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio < 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 8}/height/${resolution.height * 8}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                  maxWidth: "70%",
                }}
              />
            )}
          </div>
        );
      } else if (props?.widget?.item?.landingPage?.type === "HTM") {
        const calImageRatio = eval(props.widget.kalturaOTTImageType.trim().replace(/\D/g, "/"));
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {calImageRatio > 1 && calImageRatio < 1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio > 1.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width}/height/${resolution.height}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "LDS" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio < 1 && calImageRatio > 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : calImageRatio < 0.5 ? (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 3}/height/${resolution.height * 3}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "PR2" ? "4px" : "45%",
                  maxWidth: "70%",
                }}
              />
            ) : (
              <img
                src={`${props?.widget?.item?.imageURL}/width/${resolution.width * 8}/height/${resolution.height * 8}`}
                style={{
                  borderRadius: props?.widget?.item?.imageType === "SQR" ? "4px" : "50%",
                  maxWidth: "70%",
                }}
              />
            )}
          </div>
        );
      }
    } else if (props?.widget?.item?.imageSource === "AST") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {props?.widget?.kalturaOTTImageType === "16x9" ? (
            <img
              src={`${props?.widget?.item?.imageURL}/width/500/height/280`}
              style={{ borderRadius: "4px", maxWidth: "70%" }}
            />
          ) : props?.widget?.kalturaOTTImageType === "2x3" ? (
            <img
              src={`${props?.widget?.item?.imageURL}/width/200/height/300`}
              style={{ borderRadius: "4px", maxWidth: "70%" }}
            />
          ) : (
            <img
              src={`${props?.widget?.item?.imageURL}/width/180/height/320`}
              style={{ borderRadius: "4px", maxWidth: "70%" }}
            />
          )}
        </div>
      );
    }
  }
  return null;
};

export default GridScreenView;
