import { call, put, takeLatest } from "redux-saga/effects";
import { getPushNotificationApi, updatePushNotificationApi } from "../apis";

const { getPushNotificationResponse, updatePushNotificationResponse, pushNotificationErrorResponse } = require("../actions");
const { GET_PUSHNOTIFICATIONS_ACTION } = require("../actionTypes");

function* handleUpdatePushnotificationAction({payload,params}){

   const response = yield call(updatePushNotificationApi,payload,params);
   
   if(response.status === 200) {
        yield put(updatePushNotificationResponse(response?.data))
      }
      else {
        yield put(pushNotificationErrorResponse(response))
      } 
     window.location = "/admin/pushnotification" 
  }
  
function* handleGetPushnotificationAction({payload,params}) {

    try {
      const response = yield call(getPushNotificationApi, payload);
      if (response.status === 200) {
        console.log(response.data,"response")
        yield put(getPushNotificationResponse(response.data.data));
      } else {
        yield put(pushNotificationErrorResponse(response.message));
      }
    } catch (error) {}
  }
  
  
export default function* pushnotificationMangerWatcher() {
    yield takeLatest('GET_PUSHNOTIFICATIONS_ACTION',handleGetPushnotificationAction)
    yield takeLatest('UPDATE_PUSHNOTIFICATIONS',handleUpdatePushnotificationAction)
  }