import {
  GET_CATEGORY_TREE,
  GET_CATEGORY_ASSET_LIST,
  GET_CATEGORY_PLAYLIST_RESPONSE,
  GET_CATEGORY_PLAYLIST,
  GET_PLAYLIST_ERROR_MSG,
  GET_WIDGET_RESPONSE,
  SEARCH_PLAYLIST_ACTION,
  GET_TOTAL_PLAYLIST,
  GET_PROJECT_BY_ID_RESPONSE,
  CREATE_WIDGET_RESPONSE,
  GET_CATEGORY_ASSET_LIST_ERROR,
  EDIT_WIDGET_RESPONSE,DELETE_WIDGET_ACTION,DELETE_WIDGET_RESPONSE,
  SUCCESSFULLY_GET_SEARCH_WIDEGT,
  CLOSE_SNACKBAR_FROM_PVS,
  GET_SINGLE_WIDGET_RESPONSE
} from "../actionTypes";

import { mediaIntialState } from "../intialState";

export const PlayListReducer = (state = mediaIntialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_TREE:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORY_ASSET_LIST:
      return {
        ...state,
        loading: false,
        categories: action.payload.children,
        totalPlayList: action.payload.totalPlaylistCount,
        categoryTreeErrorMessage:''
      };

    case GET_CATEGORY_PLAYLIST:
      return { ...state, loadingPlayList: true };

    case GET_CATEGORY_PLAYLIST_RESPONSE:
      return {
        ...state,
        loadingPlayList: false,
        playList: action.payload,
        playListFixed: action.payload,
        error: false,
      };

    case GET_PLAYLIST_ERROR_MSG:
      return {
        ...state,
        loadingPlayList: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_WIDGET_RESPONSE:
      return {
        ...state,
        widgets: action.payload.widgets,
        widgetCount:action.payload.totalWidgets,
        error: false,
        loading: false,
        widgetReset: false,
        widgetEdit:false,
        widgetDelete:false,
        categoryTreeErrorMessage:''
      };
    case SEARCH_PLAYLIST_ACTION:
      return {
        ...state,
        playList: action.payload,
      };

      case GET_PROJECT_BY_ID_RESPONSE:
        return{
          ...state,
    projectAspectRatio:action.payload,
    splashScreens:action.payload.splashScreens,
    categoryTreeErrorMessage:''

        }

      case CREATE_WIDGET_RESPONSE:
        return{
          ...state,
          createdWidget:action.payload,
          widgetReset:true,
        }

        case EDIT_WIDGET_RESPONSE:{
          return{...state,
            editWidget:action.payload,
            widgetEdit :!state.widgetEdit,
          }
        }
        case DELETE_WIDGET_RESPONSE:{
          return{...state,
            widgetDeleteRes :action.payload,
            widgetDelete :true,
          }
        }

        case GET_CATEGORY_ASSET_LIST_ERROR:
         
          return{
            ...state,
            categoryTreeErrorMessage:action.payload
          }

          case SUCCESSFULLY_GET_SEARCH_WIDEGT:
            return{
              ...state,
              widgets: action.payload.widgets,
              widgetCount:action.payload.totalWidgets,
              error: false,
              loading: false,
              widgetReset: false,
              categoryTreeErrorMessage:''
            }
            case CLOSE_SNACKBAR_FROM_PVS:
              return{
                ...state,
                widgetReset:false,
                widgetEdit:false

              }
    case GET_SINGLE_WIDGET_RESPONSE:
      return {
        ...state,
        widgetUsage: action.payload
      }
    default:
      return {...state};
  }
};
