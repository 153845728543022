import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  fontColor,
} from "assets/jss/material-dashboard-react.js";
const dictStyle = {
  root: {
    width: "100%",
  },
  paper: {
    minWidth: 1000,
    maxWidth: 1000,
    marginBottom: 12,
    borderRadius: 12,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
  table: {
   // backgroundColor:'red',
   marginBottom: "0",
   width: "100%",
  // maxWidth: "100%",

   backgroundColor: " rgba(255, 255, 255, 0.6)",
    maxWidth: 1000,
  },
  dictLabel: {
    position: "relative",
    marginLeft: 0,
    "& .MuiFormControlLabel-label": {
      position: "relative",
      top: "4px",
      fontSize: "12px",
      fontWeight: 800,
      lineHeight: 0.5,
      color: "#000",
    },
  },
  dictCell: {
    color: fontColor[0],
    backgroundColor: primaryColor[6],
    "&, &$tableCell": {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Open Sans",
      fontStyle: "normal",
      lineHeight: "22px",
    },
  },
  dictSwitch: {
    "& .Mui-checked": {
      color: "#09ce5f",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#28c86e !important",
    },
  },
  dictToolbar: {
    backgroundColor: "transparent",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: "12px 12px 0 0",
    display: "flex",
    justifyContent: "space-between",
  },
  dictHighlight: {
    color: "red",
  },
  container: {
    maxHeight: 440,
    maxWidth: 1200,
  },
  selected: {
    backgroundColor: "transparent",
    color: "#19D5C6",
  },
  dictGrid: {
    gap: "0 2rem",
    minWidth: "1200px",
  
  },
  dictList: {
    display: "flex",
   
    flexDirection: "column",
    padding: "1rem 0",
    gap: "6px",
  },
  inActiveDict: {
    borderRadius: "8px",
  },
  dictControl: {
    width: "100%",
  },
  activeDict: {
    background:
      "linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) border-box",
    border: "1px solid transparent",
    borderRadius: "8px",
    color: "#0192D4",
    fontWeight: 500,
  },
  dictCard: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur(26.1418px)",
    padding: "1rem",
    borderRadius: "8px",
    minWidth: "172px",
  },
  dictCardRight: {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(26.1418px)",
    padding: "1rem",
    borderRadius: "8px",
    minWidth: "800px",
  },
  dictContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: ".8rem",
    position: "relative",
  },
  dictBox: {
    width: "30%",
  },
  dictTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 2rem 1rem 1rem",
    width: "100%",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "140%",
  },
  dictIntegrations: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "140%",
    padding: "8px",
  },
  addDictCTA: {
    position: "absolute",
    right: "3.5rem",
    display: "flex",
    height: "max-content",
    borderRadius: "500px",
    "& .MuiButton-label": {
      gap: "6px",
    },
  },

  addDictPlatform:{
    position: "absolute",
    right: "15rem",
    display: "flex",
    height: "max-content",
    width:'20vw',
    borderRadius: "500px",
    "& .MuiButton-label": {
      gap: "6px",
    },
  },
  addDictPlatformForm:{
width:'100%'
  },
  addNewDict: {
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "14px",
    "line-height": "16px",
    "text-align": "center",
    "text-transform": "initial",
    width: "68px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
  },
  totalDicts: {
    display: "flex",
    justifyContent: "end",
    padding: 0,
    paddingRight: "2rem",
  },
  dictItems: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    backgroundColor: "transparent",
  },
  
  dictItemsPlatform: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    backgroundColor: "transparent",
  },

  dictMessageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem",
    maxHeight: "45vh",
    "overflow-y": "auto",
    "overflow-x": "hidden",
  },
  addDictMsgTitle: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  dictMessage: {
    minWidth: "700px",
    borderRadius: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    padding: "1rem",
  },
  dictNumber: {
    fontWeight: 600,
  },
  expandableDict: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  publishDictCTA: {
   
   // margin: "1rem",
    float: "inline-end",
    padding: "0.7rem 2rem",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 1px 10px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
  },
  unpublishDictCTA: {
   
   
  //  margin: "1rem",
    float: "inline-end",
    padding: "0.7rem 2rem",
    backgroundColor: "rgba(255,255,255,0.8)",
    border: "1px solid #885CF7",
    boxShadow: "0px 1px 10px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  publishDict: {
    color: "white",
    overflow: "hidden",
    fontSize: "16px",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: "140%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "none",
  },
  unpublishDict: {
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    textTransform: "none",
    filter: "contrast(110%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  expandDict: {
    padding: "1rem",
  },
  deleteDictMsg: {
    "&.MuiButton-label": {
      width: "24px",
    },
    color: "red",
  },
  dictOptions: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "1rem 1.5rem",
  },
  dictMsgTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    width: "120px",
  },
  fileTypo:{
    boxSizing: 'border-box',
    background: '#FCFCFC',
    border: '1px  #C7C7C7',
    borderRadius: '8px'
  },
  filefield:{
    boxSizing: 'border-box',
    background: '#FFFFFF',
    opacity: '0.5',
    border: '1px dashed #777777', 
    bordeRadius: '4px',
    height:'50px',
    width: '250px',
    maxWidth:'100%',
    maxHeight:'100%',
    display:"inital",
  },
  importButton:{
    marginTop:'10px',
    //float: "inline-end",
    padding: "0.7rem 2rem",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 1px 10px rgba(0, 60, 118, 0.4)",
    width:'100%'
    
  }

};

export default dictStyle;
