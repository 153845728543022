import { successColor, whiteColor, grayColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

const PlatformStyle = {
  heading: {
    width: "219px",
    height: "33px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#141D46",
  },
  Button: {
    maxWidth: "180px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    alignItem: "right",
  },
  addIcon: {
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    "box-shadow": "0px 2px 20px rgba(0, 0, 0, 0.15)",
    "border-radius": "12px",
  },
  card: {
    maxWidth: "333px",
    maxHeight: "175px",
    left: "78px",
    top: "189px",
    background: "rgba(255, 255, 255, 0.2)",
    "box-shadow": "4px 4px 32px rgba(63, 39, 102, 0.1)",
    "backdrop-filter": "blur(13.0709px)",
    "border-radius": "16px",
    cursor: "pointer",
  },
  selectCard: {
    maxWidth: "333px",
    maxHeight: "175px",
    left: "78px",
    top: "189px",
    background: "#FFFFFF",
    "box-shadow": "4px 4px 32px rgba(63, 39, 102, 0.1)",
    "backdrop-filter": "blur(26.1418px)",
    "border-radius": "16px",
    cursor: "pointer",
  },
  IconCard: {
    width: "48px",
    height: "48px",
    left: "124px",
    top: "205px",
    background: "rgba(255, 255, 255, 0.6)",
    "backdrop-filter": "blur(10px)",
    "border-radius": "12px",
    boxShadow: "none",
  },
  Icon: {
    height: "20px",
    width: "16.28px",
    background: "#363636",
    left: "3.26px",
    Top: "2px",
  },
  appNameTypo: {
    width: "154px",
    height: "22px",
    left: "12.78%",
    right: "79.31%",
    top: "calc(50% - 22px/2 - 176px)",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 400,
    "font-size": "16px",
    "line-height": "22px",
    color: "#424242",
  },
  totalScreen: {
    width: "21px",
    height: "20px",
    left: "124px",
    top: "269px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "18px",
    "line-height": "0px",
    "text-align": "center",
    color: "#424242",
  },
  screenTypo: {
    width: "159px",
    height: "20px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "200",
    "font-size": "16px",
    "line-height": "22px",
    "text-align": "left",
    color: "#777777",
  },
  secIcon: {
    height: "80px",
    width: "80px",
    left: "331px",
    top: "255px",
    opacity: "0.06",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    "box-shadow": "0px 2px 24px rgba(0, 0, 0, 0.1)",
    "border-radius": "8px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  popperContainter: {
    maxHeight: "422px",
  },
  popper: {
    maxWidth: "259px",
    // maxHeight: '422px',
    left: "425px",
    top: "278px",
    background: "#D5E3FE",
    boxShadow: "inset 0px 1px 14px 0px rgb(0 0 0 / 5%)",
    "border-radius": "8px",
    zIndex: 2,
  },
  arrow: {
    content: "",
    position: "absolute",
    display: "block",
    width: "0px",
    left: "-7px",
    bottom: "50%",
    border: "19px solid transparent",
    borderBottom: 0,
    borderTop: "23px solid #D5E3FE",
    transform: "rotate(90deg)",
  },
  popTypo: {
    width: "227px",
    height: "25px",
    left: "441px",
    top: "294px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 600,
    "font-size": "18px",
    "line-height": "25px",
    color: "#252525",
  },
  typoScreenLegnth: {
    width: "14px",
    height: "33px",
    left: "441px",
    top: "323px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 300,
    "font-size": "24px",
    "line-height": "33px",
    "text-align": "center",
    color: "#252525",
  },
  popscreens: {
    width: "59px",
    height: "22px",
    left: "464px",
    top: "329px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 400,
    "font-size": "16px",
    "line-height": "22px",
    "text-align": "center",
    color: "#777777",
  },
  screenTypoContainer: {
    maxHeight: "160px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  allScreenTypo: {
    width: "250px",
    left: "0px",
    top: "0px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 400,
    "font-size": "16px",
    "line-height": "163%",
    color: "#252525",
  },
  popButton: {
    width: "227px",
    height: "36px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
  },
  popbtnTypo: {
    maxWidth: "170px",
    height: "20px",
    left: "calc(50% - 112px/2 + 0.5px)",
    top: "calc(50% - 20px/2)",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 700,
    "font-size": "14px",
    "line-height": "140%",
    "text-align": "center",
    color: "#FFFFFF",
  },
  popupScreenLength: {
    width: "150px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "16px",
    "line-height": "22px",
    // 'text-align': 'center',
    color: "#777777",
  },
  popuptotalScreen: {
    width: "21px",
    height: "69px",
    left: "124px",
    top: "269px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "36px",
    "line-height": "49px",
    "text-align": "center",
    color: "#424242",
  },
};

export default PlatformStyle;
