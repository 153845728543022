const addProjectStyle = {
    textfield:{
        boxSizing: 'border-box',
        background: '#FCFCFC',
        border: '1px  #C7C7C7',
        borderRadius: '8px'
    },
    logofield:{
        boxSizing: 'border-box',
        background: '#FFFFFF',
        opacity: '0.5',
        border: '1px dashed #777777', 
        bordeRadius: '4px',
        height:'50px',
        width: '250px',
        maxWidth:'100%',
        maxHeight:'100%',
        display:"inital",
    },
    inputlabel:{
        height: '20px',
        top: 'calc(50% - 20px/2 + 26px)',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%',
        color: ' #141D46',
        paddingBottom:'8px',

    },
    inputlableProps:{
        width: '243px',
        height: '24px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#424242',
        flex: 'none',
        border: '0',
        flexGrow: '0'
    },
    card:{ 
        //Width: '630px',
        //height: '530px',
        background: 'rgba(255, 255, 255, 0.6)',
        boxShadow: '0px 8px 20px rgba(63, 39, 102, 0.12)',
        backdropFilter: 'blur(20px)',
        borderRadius: '24px',
        maxheight:'100%',
        maxWidth:'100%',
        //paddingLeft:'20px',
        //width:'auto'
    },
    platformCard:{
        display: 'flex',
        flexDirection: 'column',
        padding: '1vh 1vw',
        gap: '8px',
        background: '#FFFFFF',
        border: '1px solid rgba(199, 199, 199, 0.3)',
        backdropFilter: 'blur(20px)',
        borderRadius: '12px',
        maxWidth: '100%',
        maxHeight: '100%',
        width:'5vw',
        height:'86px',
        justifyContent:'space-between',
        alignItems:'center',
        cursor: "pointer",
    },
    selectPlatformCard:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1vh 1vw',
        gap: '8px',
        background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
        border:' 1px solid transparent',
        backdropFilter: 'blur(10px)',
        borderRadius: '12px',
        maxWidth: '100%',
        maxHeight: '100%',
        width:'5vw',
        height:'86px',
        justifyContent:'space-between',
        cursor: "pointer",
    },
    cardIcon:{ 
        background: '#363636'
    },
    cardTypography:{
        // width:'90px',
        height: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: '#424242',
        flex: 'none',
        order: '1',
        flexGrow: '0'
    },
    selectCardTypo:{
        // width:'90px',
        height: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: '#0192D4',
        flex: 'none',
        order: '1',
        flexGrow: '0'
    },
    cardHeader:{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '25px',
        color: '#141D46',
    },
    bottomCard:{
        maxwidth: '100%',
        height: '75px',
        background: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(24px)',
        left: 'calc(50% - 1440px/2)',
        bottom: '0px',
        Top:'38px',
    },
    cancelButton:{
        width:'180px',
        height:'48px',
        boxSizing: 'border-box',
        filter: 'drop-shadow(0px 2px 20px rgba(0, 60, 118, 0.6))',
        borderRadius: '33px'
    },
    addButton:{
        width:'180px',
        height:'48px',
        background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
        'border-radius': '33px'
    },
    cancelButtonText:{
        width: '111px',
        height: '22px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'background': 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        'WebkitBackgroundClip': 'text',
        'WebkitTextFillColor': 'transparent',
        'background-clip': 'text',
        'text-fill-color': 'transparent'
    },
    addButtonText:{
        width: '111px',
        height: '22px',
        left: 'calc(50% - 311px/2 - 0.5px)',
        top: 'calc(50% - 22px/2)',
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'color': '#FFFFFF'
    },
    logoTypo:{
        maxWidth:"243px",
        maxHeight:'24px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
        textAlign: 'center',
        backgroundImage:'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        'WebkitTextFillColor': 'transparent',
        'WebkitBackgroundClip': 'text'
    },
    updateImg:{
        'width': '150px',
        'height': '150px',
        maxWidth: '100%',
        maxHeight:'100%',
        'background': '#2B2B2B',
        'border-radius': '12px'
    },
    Image16:{
        position: 'relative',
        maxWidth: '100%',
        maxHeight:'100%',
        width:'256px',
        height:'144px',
        //'padding-top': '1px',
        'border-radius': '12px',
        'background': '#2B2B2B',
    }
}

export default addProjectStyle;