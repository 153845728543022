import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle";
import { Box, TableFooter, Tooltip } from "@material-ui/core";
import DeleteWidgetPopUp from "components/widgetManagerComponents/deleteWidgetPopup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Edit } from "@material-ui/icons";

//Images
import deleteBucket from "../../assets/img/platformScreenVisualization/deleteBucket.svg";

// Styles
const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();

  const { tableHead, tableData, tableHeaderColor, pagination } = props;

  const history = useHistory();

  // Function to navigate to edit widget page
  const editNotification = (pushNotificationDetails) => {
    history.push({
      pathname: "/admin/pushnotification/edit",
      state: {
        pushNotificationDetails: pushNotificationDetails,
        prevPage: "pushnotification",
      },
    });
  };

  return (
    <div className={classes.tableResponsive} style={{ marginLeft: "20px" }}>
      <Table className={classes.table}>
        {/* Table Head */}
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, id) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={prop + id}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        {/* Table Body */}
        <TableBody>
          {tableData?.map((prop, key) => {
            return (
              <TableRow
                key={prop?._id || prop?.notification_type}
                className={classes.tableBodyRow}
                style={{ height: "50px" }}
              >
                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                  onClick={() => editNotification(prop)}
                >
                  {prop?.notification_type}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                  onClick={() => editNotification(prop)}
                >
                  {prop?.frequency
                    ? `${prop?.frequency} ${prop?.frequency > 1 ? "Days" : "Day"}`
                    : "-"}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                  onClick={() => editNotification(prop)}
                >
                  {prop?.category || "-"}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                  onClick={() => editNotification(prop)}
                >
                  {
                    prop?.notification_content?.type?.length===0 &&
                    "-"
                  }
                  {prop?.notification_content?.type?.length > 1
                    ? prop?.notification_content?.type?.join(" / ")
                    : prop?.notification_content?.type}
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                  onClick={() => editNotification(prop)}
                >
                  {prop?.status}
                </TableCell>

                <TableCell
                  className={classes.tableCell + " " + classes.tableBodyCell}
                >
                  <Box style={{ display: "flex", gap: "20px" }}>
                    {
                      <Edit
                        onClick={() => editNotification(prop)}
                        style={{ height: "18px" }}
                      />
                    }
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {/* Table Footer */}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={12} style={{ paddingBottom: "32px" ,backgroundColor:"#C0D2FA",borderBottom:"none" }}>
              {pagination}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
