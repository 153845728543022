import axios from "axios";
import { homeApis } from "../../../config";

export const updatePushNotificationApi = async (data, params) => {
   const options = {
      method: "POST",
      url: `${homeApis.updatePushnotifications}`,
      params: params,
      headers: {
        "content-type": "application/json",
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    };
  
    const response = await axios.request(options);
    return response; 
  };

export const getPushNotificationApi = async (params) => {
  
    const options = {
      method: "GET",
      url: `${homeApis.getPushnotifications}`,
      params,
      headers: {
        "content-type": "application/json",
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    };
  
    const response = axios.request(options);
  
    return response;
  };