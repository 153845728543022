import React from 'react';
import TotalPlatformsLineChart from '../../../components/analyticsComponents/charts/TotalPlatformsLineChart';
import { Box, Typography, Grid } from '@material-ui/core';
import PlatformsLineChart from 'components/analyticsComponents/charts/PlatformsLineChart';
import TotalUsersAreaChart from 'components/analyticsComponents/charts/TotalUsersAreaChart';
import UsersAreaChart from 'components/analyticsComponents/charts/UsersAreaChart';
import AnalyticsIconDark from "../../../assets/img/sideNavIcons/Analytics.svg";



const Analytics = () => (
  <Grid container style={{ paddingBottom: '0px',flexDirection: "column", gap:"2rem" }}>
  <div style={{display:"flex", }}>
  <Grid item xs={3} md={3} lg={3}>
      <Box display={"flex"}>
        <img src={AnalyticsIconDark} alt="Analytics" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
        <Typography style={{
          fontSize: "24px",
          fontStyle: "normal",
          fontFamily: "Open Sans",
          fontWeight: "700",
          lineHeight: "33px",

        }}>Analytics</Typography>
      </Box>
    </Grid>
    <div style={{width:"200%", }}>
    <Typography  style={{fontSize:"23px" , textAlign:"center" , fontWeight:900, marginLeft:"auto", marginRight:"auto", width:"200px"}} align="center">
      Coming Soon!!
      
      </Typography>
    </div>

  </div>
    <Box style={{ gap: "50px", display: "flex", flexDirection: "column" }}>
      <Box display={"flex"} flexDirection={"row"}>

        <Box height={300} width={"50%"}>

          <TotalPlatformsLineChart />
          <Typography style={{ textAlign: "center", marginTop: "10px", fontWeight: "600" }}>Total Platforms vs. Timeline</Typography>
        </Box>
        <Box height={300} width={"50%"}>

          <PlatformsLineChart />
          <Typography style={{ textAlign: "center", marginTop: "10px", fontWeight: "600" }}> Platforms vs. Timeline</Typography>
        </Box>

      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        <Box height={300} width={"50%"}>

          <TotalUsersAreaChart />
          <Typography style={{ textAlign: "center", marginTop: "10px", fontWeight: "600" }}> Total Users vs. Timeline</Typography>
        </Box>

        <Box height={300} width={"50%"}>
          <UsersAreaChart />
          <Typography style={{ textAlign: "center", marginTop: "10px", fontWeight: "600" }}> Users vs. Timeline</Typography>
        </Box>
      </Box>
    </Box>
  </Grid>




);

export default Analytics;
