import React, { useEffect } from "react";
import Modals from "components/Modal/Modals";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import SubHead from "components/Typography/SubHead";

import style from "assets/jss/material-dashboard-react/views/screenStyle";

import { makeStyles } from "@material-ui/core";
import projectSettings from "screens/Projects/views/projectSettings";

const useStyles = makeStyles(style);

const editAiWidgetCategory = [
  {
    category: "genre",
    biaspercentage: "Genre_BiasPercentage",
  },
  { category: "subgenre", biaspercentage: "Subgenre_BiasPercentage" },
  { category: "actor", biaspercentage: "Actor_BiasPercentage" },
  { category: "director", biaspercentage: "Director_BiasPercentage" },
  { category: "title", biaspercentage: "Title_BiasPercentage" },
  { category: "language", biaspercentage: "Language_BiasPercentage" },
];

const EditorialBadgeAIWidget = (props) => {
  const classes = useStyles();
  const [genre, setGenre] = React.useState();
  const [subGenre, setSubGenre] = React.useState();
  const [actor, setActor] = React.useState();
  const [director, setDirector] = React.useState();
  const [title, setTitle] = React.useState();
  const [language, setLanguage] = React.useState();
  const [changeButton, setButtonChanged] = React.useState(false);

  useEffect(() => {
    if (
      genre?.genre ||
      subGenre?.subGenre ||
      actor?.actor ||
      director?.director ||
      title?.title ||
      language?.language
    ) {
      console.log(
        "sdjfjsdfjsfd",
        genre?.genre,
        subGenre?.subGenre,
        actor?.actor,
        director?.director,
        title?.title,
        language?.language
      );
      setButtonChanged(true);
    } else {
      setButtonChanged(false);
    }
    console.log("Bias change the data", ...props.bias);
  }, [genre, subGenre, actor, director, title, language]);

  //Enter the Details
  const changeCategory = (e, category) => {
    if (category == "genre") {
      setGenre({
        genre: e.target.value,
      });
    } else if (category == "subgenre") {
      setSubGenre({
        sungenre: e.target.value,
      });
    } else if (category == "actor") {
      setActor({
        actor: e.target.value,
      });
    } else if (category == "director") {
      setDirector({
        director: e.target.value,
      });
    } else if (category == "title") {
      setTitle({ title: e.target.value });
    } else if (category == "language") {
      setLanguage({ language: e.target.value });
    }

    if (e.target.value) {
      //  setButtonChanged(true)
    } else {
    }
  };

  const changeBiasPercentage = (e, biaspercentage) => {
    console.log("KFjsnfj", biaspercentage);
    if (biaspercentage === "genre") {
      setGenre({
        ...genre,
        biaspercentage: e.target.value,
      });
    } else if (biaspercentage == "subgenre") {
      setSubGenre({
        ...subGenre,
        biaspercentage: e.target.value,
      });
    } else if (biaspercentage == "actor") {
      setActor({ ...actor, biaspercentage: e.target.value });
    } else if (biaspercentage == "director") {
      setDirector({ ...director, biaspercentage: e.target.value });
    } else if (biaspercentage == "title") {
      setTitle({ ...title, biaspercentage: e.target.value });
    } else if (biaspercentage == "language") {
      setLanguage({ ...language, biaspercentage: e.target.value });
    }
  };

 

  return (
    <Modals open={props.open}
    setModal={props.setModal}
    >
      <Paper>
        <div className={classes.editAIWidgetMainCintainer}>
          <Box className={classes.editAIWidgetTextfieldContainer}>
            <SubHead>Configure EditorialBias AI Widget</SubHead>
          </Box>
          {editAiWidgetCategory.map((value, index) => (
            <Box key={index} className={classes.editAIWidgetTextfieldContainer}>
              <TextField
                className={classes.editAiWidgetTextField}
                name={value.category}
                placeholder={`Enter the ${value.category}`}
                variant="outlined"
                // value={widgetCommonDetails.name}
                required
                size="Normal"
                // className={classes.textField}
                labelWidth={0}
                onChange={(event) => changeCategory(event, value.category)}
              />
              <TextField
                name={value.category}
                className={classes.editAiWidgetTextField}
                placeholder={`Enter the ${value.biaspercentage}`}
                variant="outlined"
                // value={widgetCommonDetails.name}
                required
                size="Normal"
                // className={classes.textField}
                labelWidth={0}
                onChange={(event) =>
                  changeBiasPercentage(event, value.category)
                }
              />
            </Box>
          ))}
          <Box className={classes.editWidgetAiButtonContainer}>
            <Button
              className={classes.editWidgetAiButton}
              onClick={()=>props.continueToAddai(genre,subGenre,director,actor,language,title)}
            >
              {changeButton ? "Continue" : "Continue Without adding bias"}
            </Button>
          </Box>
        </div>
      </Paper>
    </Modals>
  );
};

export default EditorialBadgeAIWidget;
