import {
  CreateScreenActionType,
  CreateScreenDataType,
  getAllScreensActionType,
  getAllScreensDataType,
  getAllWidgetsActionType,
  getAllWidgetsDataType,
  getSingleWidgetsActionType,
  getSingleWidgetDataType,
  GET_PLATFORM_ACTION,
  GET_ALL_PLATFORM_ACTION,
  getScreenResetType,
  getWidgetByIdDataType,
  REORDER_WIDGET_MANAGER,
  GET_SINGLE_SCREEN_ACTION,
  UPDATE_WIDGET_IMAGE_TYPE_ACTION,
  SUCCEESFULLY_UPDATE_IMAGE_TYPE_DATA,
  PUBLISH_SCREEN_ACTION,
  UPDATE_SCREEN_STATUS_ACTION,
  EXPORT_SCREEN_TO_PLATFORMS_ACTION,
  SCREEN_REORDER_ACTION_ACTION,
  SCREEN_REORDER_RESPONSE,
  UPDATE_SCREEN_RESPONSE,
  DELETE_SCREEN_ACTION,
  DELETE_SCREEN_RESPONSE,
  SCREEN_STATUS_CHANGE,
  EXPORT_SCREEN_RESPONSE,
  SCREEN_STATUS_RESPONSE,
  PLATFORM_DETAILS,
  PUBLISH_SPLASH_ACTION,
  PUBLISH_SPLASH_RESPONSE,
  PUBLISH_INTRO_ACTION,
  PUBLISH_INTRO_RESPONSE,
  UPDATE_SCREEN_DETAILS,
  CLEAR_SCREEN_WIDGETS,
  SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS,
  CLEAR_CREATE_SCREEN_DATA,
  CLEAR_DELETE_SCREEN_DATA,
  CLEAR_EXPORT_SCREEN_DATA,
  CLEAR_PUBLISH_SCREEN_DATA,
  GET_UPDATED_SCREEN_DATA,
  CLEAR_UPDATE_SCREEN_DATA,
  SUCCESSFULLY_UPDATE_SCREEN_DETAILS,
  CLEAR_UPDATE_SCREEN_DETAILS,
  CLEAR_SCREEN_REORDER_RESPONSE,
  getAllPlatformActionDataType,
  getPlatformActionDataType,
} from "../actionType/platformActionType";

export const getPlatformAction = (projectId) => {
  return { type: GET_PLATFORM_ACTION, payload: projectId };
};

export const getAllPlatformAction = (projectIds) => {
  return { type: GET_ALL_PLATFORM_ACTION, payload: projectIds };
};

export const getplatformDataAction = (platformsData) => {
  return { type: getPlatformActionDataType, payload: platformsData };
};

export const getAllplatformDataAction = (allplatformsData) => {
  return { type: getAllPlatformActionDataType, payload: allplatformsData };
};

export const getAllScreenAction = (projectId, platforms, screenName) => {
  return { type: getAllScreensActionType, payload: projectId, platforms, screenName };
};

export const getAllScreensDataAction = (allScreens) => {
  return { type: getAllScreensDataType, payload: allScreens };
};

export const getAllWidgetsAction = (projectId, platforms) => {
  return { type: getAllWidgetsActionType, payload: projectId, platforms };
};

export const getAllWidgetDataAction = (allWidgets) => {
  return { type: getAllWidgetsDataType, payload: allWidgets };
};

export const getSingleWidgetAction = (projectId, platforms) => {
  console.log(">>>>> getSingleWidgetAction projectId ", projectId);
  return { type: getSingleWidgetsActionType, payload: projectId, platforms };
};

export const getSingleWidgetDataAction = (singleWidget) => {
  console.log(">>???? ?>>>> >>???? ?>>>> >>???? ?>>>>", singleWidget);
  return { type: getSingleWidgetDataType, payload: singleWidget };
};

export const getWidgetByIdData = (widgetData) => {
  return { type: getWidgetByIdDataType, payload: widgetData };
};

export const CreateScreenAction = (ScreenDetails) => {
  return { type: CreateScreenActionType, payload: ScreenDetails };
};

export const CreateScreenDataAction = (screenData) => {
  return { type: CreateScreenDataType, payload: screenData };
};

export const getScreenResetAction = () => {
  return { type: getScreenResetType };
};

export const reorderWidgetDetailsAction = (array) => {
  return { type: REORDER_WIDGET_MANAGER, payload: array };
};

// Action for calling getScreen Api

export const getSingleScreenAction = (screenDetails) => {
  return { type: GET_SINGLE_SCREEN_ACTION, payload: screenDetails };
};

export const publishScreenAction = (details) => {
  return { type: PUBLISH_SCREEN_ACTION, payload: details };
};

export const updateScreenStatusAction = (updateStatus) => {
  return { type: UPDATE_SCREEN_STATUS_ACTION, payload: updateStatus };
};

export const exportScreenToPlatformAction = (details) => {
  console.table("Check Details", details);
  return { type: EXPORT_SCREEN_TO_PLATFORMS_ACTION, payload: details };
};

export const screenReorderAction = (order, p_id, platform) => {
  return {
    type: SCREEN_REORDER_ACTION_ACTION,
    payload: order,
    projectId: p_id,
    platform: platform,
  };
};

export const screenReorderResponse = (response) => {
  return { type: SCREEN_REORDER_RESPONSE, payload: response };
};

export const updateWidgetImageTypeAction = (widgetDetails) => {
  return { type: UPDATE_WIDGET_IMAGE_TYPE_ACTION, payload: widgetDetails };
};

export const updateWidgetImageTypeData = (res) => {
  return { type: SUCCEESFULLY_UPDATE_IMAGE_TYPE_DATA, payload: res };
};

export const updateScreenDetails = (res) => {
  return { type: UPDATE_SCREEN_DETAILS, payload: res };
};

export const updateScreenApiResponse = (payload, screenName) => {
  return { type: UPDATE_SCREEN_RESPONSE, payload, screenName };
};

export const successfullyUpdateScreenDetails = (payload, screenName) => {
  return { type: SUCCESSFULLY_UPDATE_SCREEN_DETAILS, payload, screenName };
};

export const deleteScreenAction = (screen) => {
  return { type: DELETE_SCREEN_ACTION, payload: screen };
};

export const deleteScreenResponse = (response) => {
  return { type: DELETE_SCREEN_RESPONSE, payload: response };
};

export const ScreenStatueChangeAction = () => {
  return { type: SCREEN_STATUS_CHANGE };
};

export const exportScreenResponse = (response) => {
  return { type: EXPORT_SCREEN_RESPONSE, payload: response };
};

export const screenStatusResponse = (response, initialAction) => {
  return { type: SCREEN_STATUS_RESPONSE, payload: response, initialAction };
};

export const platformDetails = (details) => {
  console.log("action", details);
  return { type: PLATFORM_DETAILS, payload: details };
};

export const publishSplashAction = (payload) => {
  return { type: PUBLISH_SPLASH_ACTION, payload };
};

export const publishSplashResponse = (response) => {
  return { type: PUBLISH_SPLASH_RESPONSE, payload: response };
};

export const publishIntroAction = (payload) => {
 
  return { type: PUBLISH_INTRO_ACTION, payload };
};

export const publishIntroResponse = (response) => {
  return { type: PUBLISH_INTRO_RESPONSE, payload: response };
};

export const clearScreenWidgetDetailsArray = () => {
  return { type: CLEAR_SCREEN_WIDGETS };
};

export const getDefaultScreensAction = (payload) => {
  return { type: SUCCESSFULLY_GET_ALL_DEFAULT_SCREENS, payload };
};

export const clearUpdateScreenDetails = () => {
  return { type: CLEAR_UPDATE_SCREEN_DETAILS };
};

export const clearCreateScreenData = () => {
  return { type: CLEAR_CREATE_SCREEN_DATA };
};

export const clearDeleteScreenData = () => {
  return { type: CLEAR_DELETE_SCREEN_DATA };
};

export const clearExportScreenData = () => {
  return { type: CLEAR_EXPORT_SCREEN_DATA };
};

export const clearPublishScreenData = () => {
  return { type: CLEAR_PUBLISH_SCREEN_DATA };
};

export const clearUpdateScreenData = () => {
  return { type: CLEAR_UPDATE_SCREEN_DATA };
};

export const clearScreenReorderResponse = () => {
  return { type: CLEAR_SCREEN_REORDER_RESPONSE };
};
