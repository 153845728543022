const ScreenViewer = {
  Arrows: {
    position: "absolute",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    zIndex: "1",
    cursor: "pointer",
  },

  nextArrowCarousel: {
    bottom: "47%",
    right: 0,
  },

  backArrowCarousel: {
    bottom: "47%",
  },

  nextArrowCarousel2: {
    bottom: "47%",
    right: "5%",
  },

  backArrowCarousel2: {
    bottom: "47%",
    left: "5%",
  },

  nextArrowHorizontal: {
    right: 0,
  },

  backArrowHorizontal: {
    left: 0,
  },

  imgContainer: {
    height: "fit-content",
    width: "99%",
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
  },

  introImg: {
    // maxWidth: "80%",
    maxHeight: "41rem",
    borderRadius: "12px",
    pointerEvents: "none",
    width:"60%"
  },
  splashvideo: {
    // maxWidth: "80%",
    maxHeight: "41rem",
    borderRadius: "12px",
    width:"60%"
  },
  introImgs: {
    maxWidth: "80%",
    maxHeight: "41rem",
    borderRadius: "12px",
    pointerEvents: "none",
  },
  dotList: {
    paddingRight: "6px",
    cursor: "pointer",
  },

  activeDot: {
    height: "14px",
    width: "14px",
    border: "none",
    boxShadow: "0px 0px 2px #885cf7",
    borderRadius: "100%",
    background: "linear-gradient(135deg, #339fed 0%, #885cf7 65.94%)",
    cursor: "pointer",
  },

  inactiveDot: {
    height: "12px",
    width: "12px",
    border: "none",
    boxShadow: "0px 0px 2px #888888",
    borderRadius: "100%",
    cursor: "pointer",
  },
};

export default ScreenViewer;
